import styled from 'styled-components';
import { Card, Radio, Col, Steps, Layout, Button } from 'antd';

// default ant design breakpoint widths
// {
//   xs: '480px',
//   sm: '576px',
//   md: '768px',
//   lg: '992px',
//   xl: '1200px',
//   xxl: '1600px',
// }
const { Content, Sider } = Layout;
/// ///////////////////////////// BUTTONS //////////////////////////////////
export const AddQuestionButton = styled(Button)`
  border: 1px dashed #8053d7;
  color: #8053d7;
  height: 50px;
  border-radius: 4px;
  width: 100%;
  margin: 10px 0;
  &:hover {
    background: #8053d7;
    color: #fff;
  }
`;
export const DoneButton = styled(Button)`
  margin: 10px auto;
  background: #8053d7;
  height: 50px;
  width: 100%;
  border: none;
  border-radius: 4px;
  font-weight: bolder;
  color: #fff;
  &:hover {
    background: #fad602;
    border: none;
  }
`;
export const NewButton = styled(Button)`
  float: right;
  background: #5bbcbd;
  border-radius: 6px;
  color: #fff;
`;
export const SaveButton = styled(Button)`
  background: #5bbcbd;
  border-radius: 6px;
  width: 150px;
  height: 40px;
  margin: 20px 0;
  color: #fff;
`;
export const StyledButton = styled(Button)`
  &.ant-btn {
    border: none;
    color: #ffffff;
    background: ${(props) => {
    return props.theme.primaryOrange;
  }};

    &:hover {
      color: #ffffff;
      background: ${(props) => {
    return props.theme.secondaryOrange;
  }};
    }

    &:focus > span,
    &:active > span {
      color: #e41e84;
    }

    &:active {
      span {
        color: #e41e84 !important;
      }
    }
  }
`;
export const BlueButton = styled(Button)`
  &.ant-btn {
    border: none;
    color: #2a2a2f;
    background: dodgerblue;
    border-radius: 8px;
    width: 170px;
    height: 50px;
    font-weight: bold;

    &:hover {
      color: #ffffff;
      background: blue;
    }

    &:focus > span,
    &:active > span {
      color: #e41e84;
    }

    &:active {
      span {
        color: #e41e84 !important;
      }
    }
  }
`;
export const OrangeButton = styled(Button)`
  &.ant-btn {
    border: none;
    color: #fff;
    background: #d7753e;
    border-radius: 8px;
    width: 170px;
    height: 50px;
    font-weight: bold;

    &:hover {
      color: #ffffff;
      background: orange;
    }

    &:focus > span,
    &:active > span {
      color: #e41e84;
    }

    &:active {
      span {
        color: #e41e84 !important;
      }
    }
  }
`;
export const PurpleButton = styled(Button)`
  &.ant-btn {
    border: none;
    color: #fff;
    background: #8053d7;
    border-radius: 8px;
    width: 170px;
    height: 50px;
    font-weight: bold;

    &:hover {
      color: #ffffff;
      background: purple;
    }

    &:focus > span,
    &:active > span {
      color: #e41e84;
    }

    &:active {
      span {
        color: #e41e84 !important;
      }
    }
  }
`;
/// ///////////////////////////// CARDS AND IMAGES //////////////////////////////////
export const Img = styled.img`
  display: inline-flex;
`;

export const StyledCard = styled(Card)`
  background: #fff;
  // padding: 20px;
  border-bottom-left-radius: 15px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);

  &.ant-card-bordered {
    border: none;
    border-top: 10px solid #d7753e;
  }
`;
export const RoundedCard = styled(Card)`
  background: #fff;
  // padding: 20px;
  border-radius: 15px;
  border-bottom-right-radius: 0px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
`;
export const RoundedContainer = styled(Col)`
  /* height: clamp(10rem, 18rem, 24rem); */
  background: #fff;
  background-size: 100% 100%;
  text-align: center;
  /* height: 35rem; */
  border-radius: 8px;
  padding: 40px;
  background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.2)
  );
  backdrop-filter: blur(2em);
  display: flex;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
`;
/// ///////////////////////////// CONTAINERS //////////////////////////////////
export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
  height: 100%;
  min-height: 150px;
  min-width: 150px;
  background: #fff;
  border-radius: 6px;
  border: 1px solid #e5e5e5;

  :hover {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    background: #8053d7;
    border: none;
    transition: all 0.25s ease-in-out;
  }
`;
export const InfoContainer = styled.div`
  width: 100%;
  height: 400px;
  background: #fff;
  height: 150px;
  border-radius: 6px;
  border: 1px solid #e5e5e5;
`;
export const OrangeStrokeContainer = styled.div`
  border: 1px solid #d7753e;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 15px;
`;
export const StyledContainer = styled.div`
  display: block;
  margin-bottom: 20px;
  padding: 30px;
  width: 100%;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  &:hover {
    box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.19);
  }
`;
/// ///////////////////////////// HEADERS AND TEXT //////////////////////////////////
export const BackPage = styled.small`
  color: #fff;
  position: absolute;
  font-weight: bold;
  bottom: 20px;
  left: 40px;
`;
export const BigTitle = styled.h1`
  font-size: 6em;
  font-weight: bolder;
  color: #fff;
  margin: 0;
`;
export const NextPage = styled.small`
  color: #fff;
  position: absolute;
  font-weight: bold;
  bottom: 20px;
  right: 40px;
`;
export const YellowUnderline = styled.h3`
  text-decoration: underline;
  text-decoration-color: #fad602;
  font-size: 30px;
`;
export const WhiteText = styled.p`
  font-family: Montserrat Bold;
  font-size: 2em;
  color: #fff;
`;
export const HoverText = styled.p`
  color: #fff;
  margin: 5px;
  :hover {
    font-weight: bolder;
    border-bottom: 4px solid darkorange;
  }
`;
/// ///////////////////////////// LAYOUT AND CONTENT //////////////////////////////////
export const MainLayout = styled(Layout)`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: flex-end;
`;
export const MainSider = styled(Sider)`
  overflow: auto;
  min-height: 100vh;
  background: #8053d7;
  position: fixed;
  left: 0;

  @media (max-width: 992px) {
    position: inherit;
  }
`;
export const SecondaryLayout = styled(Layout)`
  width: calc(100% - 200px) !important;
  background: #eee !important;
  flex: none;
  min-height: 100vh;

  @media (max-width: 992px) {
    margin-left: 0px;
    flex: auto;
    width: 100%;
  }
`;
export const StyledContent = styled(Content)`
  margin: 24px 16px 0;
  overflow: initial;
  padding: 40px 20px;
`;
/// ///////////////////////////// ROW AND COLUMNS //////////////////////////////////
export const MainCol = styled(Col)`
  padding: 90px 40px;
  background: #fff;
  min-height: 100vh;
`;
export const SignInUpCol = styled(Col)`
  padding: 90px 40px;
  background: #fff;
  min-height: 100vh;
  padding-left: 60px;
`;
/// ///////////////////////////// STEPS AND RADIOS //////////////////////////////////
export const StyledRadio = styled(Radio.Group)`
  ::selection {
    color: #fff;
    background: #d7753e;
  }
`;
export const StyledSteps = styled(Steps)`
  .ant-steps-item-icon {
    background: #faad14;
    border-color: #faad14;
  }
`;
