import React from 'react';
import { Col, Row } from 'antd';

export const StudentsJoinBeta = () => {
  return (
    <>
      <Row justify='center' style={{ margin: '40px auto' }}>
        <Col
          xl={20}
          style={{
            background: '#8053D7',
            borderRadius: 6,
            filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
            padding: 40
          }}
        >
          <p
            style={{
              fontSize: '2.5em',
              fontFamily: 'Montserrat Bold',
              color: '#fff',
              textAlign: 'center'
            }}
          >
            Lets be smart about education {' '}
            <a href='https://www.pewresearch.org/internet/fact-sheet/mobile/' target='_blank' rel='noreferrer'>
              <span style={{ color: '#FFDC54' }}>Everyone Has A Smartphone.</span>
            </a>
          </p>
          <p style={{ color: '#fff', textAlign: 'center' }}>
            Students live on their phones and meeting them with engaging curriculum via the platform they are
            most familiar with is critical to keeping students engaged.
          </p>
        </Col>
      </Row>
    </>
  );
};
