import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Layout, Typography } from 'antd';

import ClassroomIcon from '../../../../assets/classroom.svg';
import SiderNav from '../../../template/SiderNav';
import { MainLayout, SecondaryLayout, StyledContent } from '../../../../styles/StyledComponents';

import { EditQuestionSetForm } from './editQuestionSetForm';
import { dispatch } from '../../../../store';
import { handleQuestionSetRequest } from '../../../../modules/question-set/redux';

const { Title } = Typography;

// TODO: Verify the questionSet gets added to the application state object properly
// TODO: and then pass as a prop to the EditQuestionSetForm to be set as the initialValues
// TODO: and also add to the mapStateToProps then destructure the props object to access the questionSet
// TODO: to later pass on to the form...

class EditQuestionSetPage extends Component {
  componentDidMount () {
    const questionSetId = this.props.match.params.questionSetId;
    dispatch(handleQuestionSetRequest(questionSetId));
  }

  render () {
    const { isLoading, questionSet } = this.props;

    return (
      <MainLayout hasSider>
        {/* SIDE NAV BAR */}
        <SiderNav />
        <SecondaryLayout>
          <StyledContent>
            {/* <Link to="#">
              <img src={ClassroomIcon} style={{float: 'right'}} alt="" />
            </Link> */}
            <EditQuestionSetForm isLoading={isLoading} questionSet={questionSet} />
          </StyledContent>
        </SecondaryLayout>
      </MainLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: false,
    questionSet: state.questionSets.questionSet
  };
};

export default withRouter(connect(mapStateToProps, {})(EditQuestionSetPage));
