import MakeAsyncFunction from 'react-redux-promise-listener';
import React, { useEffect } from 'react';
import { Field as FField, Form as FForm } from 'react-final-form';
import { Col, Button, Form as AForm, Row, Typography } from 'antd';
import styled from 'styled-components';

import { promiseListener } from '../../../../store';
import {
  CustomSimpleSelectControl,
  InputControl
} from '../../../fields/Fields';
import { capitalize } from '../../../fields/renderFields';
import * as validations from '../../../../utils/validations';
import {
  CREATE_STUDENT_FAILURE,
  CREATE_STUDENT_REQUEST,
  CREATE_STUDENT_SUCCESS
} from '../../../../modules/students/redux';

const StyledSendButton = styled(Button)`
  background-color: #5bbcbd;
  border: none;
  border-radius: 10px;
  font-weight: bolder;
  color: #fff;
  height: 50px;
  padding: 0 17px;
  min-width: 100px;
  margin-top: 30px;

  @media (min-width: 1200px) {
    min-width: 200px;
  }

  &:hover,
  &:focus {
    background-color: #60e4e5;
    border: none;
    color: #fff;
  }
`;

const { Title } = Typography;

export const AddStudentForm = ({
  classes = [],
  isLoading = false,
  onClose,
  isActive
}) => {
  // CUSTOM VARIABLE TO RESET FORM FIELDS STATE
  let handleFieldReset;

  // RESET FORM FIELDS STATE WHEN THE DRAWER IS CLOSED
  useEffect(() => {
    if (!isActive && handleFieldReset) {
      handleFieldReset('firstName');
      handleFieldReset('lastName');
      handleFieldReset('email');
      handleFieldReset('classEnrollments');
    }
  }, [handleFieldReset, isActive]);

  return (
    <MakeAsyncFunction
      listener={promiseListener}
      start={CREATE_STUDENT_REQUEST}
      resolve={CREATE_STUDENT_SUCCESS}
      reject={CREATE_STUDENT_FAILURE}
    >
      {(onSubmit) => (
        <FForm
          name='add-student-drawer-form'
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            classEnrollments: []
          }}
          onSubmit={onSubmit}
          render={({
            submitError,
            pristine,
            submitting,
            handleSubmit,
            hasSubmitErrors,
            hasValidationErrors,
            dirtySinceLastSubmit,
            form,
            ...rest
          }) => {
            // VARIABLE TO RESET THE FORM STATE OUTSIDE THE FORM COMPONENT
            handleFieldReset = form?.resetFieldState;

            return (
              <div>
                <AForm
                  onFinish={(event) => {
                    console.log('onFinish, event: ', event);
                    handleSubmit();
                    onClose();
                  }}
                >
                  {isLoading && <div>Loading...</div>}
                  {submitError && <div className='error'>{submitError}</div>}
                  <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 16]}>
                    <Col xl={12} sm={20} xs={24}>
                      <Title level={5}>First Name</Title>
                      <FField
                        name='firstName'
                        component={InputControl}
                        type='text'
                        placeholder='First Name*'
                        parse={(value) => value && value.toLowerCase()}
                        format={capitalize}
                        validate={validations.composeValidators(
                          validations.required,
                          validations.minLength(2),
                          validations.maxLength(30)
                        )}
                      />
                    </Col>
                    <Col xl={12} sm={20} xs={24}>
                      <Title level={5}>Last Name</Title>
                      <FField
                        name='lastName'
                        component={InputControl}
                        type='text'
                        placeholder='Last Name*'
                        parse={(value) => value && value.toLowerCase()}
                        format={capitalize}
                        validate={validations.composeValidators(
                          validations.required,
                          validations.minLength(2),
                          validations.maxLength(30)
                        )}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 16]}>
                    <Col xl={12} sm={20} xs={24}>
                      <Title level={5}>Email Address</Title>
                      <FField
                        name='email'
                        component={InputControl}
                        type='text'
                        placeholder='student@institution.edu*'
                        parse={(value) => value && value.toLowerCase()}
                        validate={validations.composeValidators(
                          validations.email,
                          validations.required,
                          validations.minLength(4)
                        )}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 16]}>
                    <Col xl={12} sm={20} xs={24}>
                      <Title level={5}>Class Enrollments</Title>
                      <FField
                        name='classEnrollments'
                        placeholder='Choose the classes'
                        options={classes.map((_class) => ({
                          value: _class?.id,
                          label: _class?.title
                        }))}
                        mode='multiple'
                        component={CustomSimpleSelectControl}
                      />
                    </Col>
                  </Row>
                  <StyledSendButton
                    htmlType='submit'
                    disabled={
                      pristine ||
                      submitting ||
                      ((hasSubmitErrors || hasValidationErrors) &&
                        !dirtySinceLastSubmit)
                    }
                    loading={isLoading || submitting}

                  >
                    Send Invitation
                  </StyledSendButton>
                </AForm>
              </div>
            );
          }}
        />
      )}
    </MakeAsyncFunction>
  );
};
