import { ArrowRightOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import React, { Component } from 'react';
import { MainLayout, SecondaryLayout, StyledContent } from '../../../styles/StyledComponents';
import { StyledTitle } from '../../fields/Title';
import SiderNav from '../../template/SiderNav';
import MembersForm from './MembersForm';
import { dispatch } from '../../../store';
import { handleAccountRequest } from '../../../modules/account/redux';
import { connect } from 'react-redux';

class OrganizationMembersPage extends Component {
  componentWillMount () {
    console.log('componentWillMount');
    dispatch(handleAccountRequest());
  }

  render () {
    const { isLoading, account = { accountName: '' } } = this.props;
    return (
      <MainLayout hasSider>
        <SiderNav />
        <SecondaryLayout>
          <StyledContent>
            <Row justify='center' style={{ textAlign: 'center' }}>
              <Col>
                <small>Grow your organization</small>
              </Col>
              <Col span={24}>
                <StyledTitle>
                  Welcome to {account.accountName}
                </StyledTitle>
              </Col>
            </Row>
            <br />
            <Row gutter={[16, 16]} justify='center'>
              <Col xl={14} sm={20} xs={23} style={{ marginBottom: 10 }}>
                <strong>Add organization members</strong>
                <br />
                <small>Organization members will be able to...</small>
                <br />
                <a href=''>
                  <small>Learn more about permissions for organizations <ArrowRightOutlined /></small>
                </a>
              </Col>
              <Col xl={14} sm={20} xs={23}>
                <MembersForm isLoading={isLoading} />
              </Col>
            </Row>
          </StyledContent>
        </SecondaryLayout>
      </MainLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    account: state.account.data,
    isLoading: state.account.isLoading
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationMembersPage);
