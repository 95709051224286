import React, { Component } from 'react';
import { withRouter, Link, NavLink } from 'react-router-dom';
import { Layout } from 'antd';
import styled from 'styled-components';
import logo from '../../assets/tutord-logo-white.svg';
import { FormattedMessage } from 'react-intl';
import { HoverText, StyledButton } from '../../styles/StyledComponents';

const Logo = styled.img`
  display: inline-flex;
  width: auto;
`;

const StyledHeader = styled(Layout.Header)`
  &.header {
    width: 100%;
    background: none;
  }
`;

class Header extends Component {
  componentDidMount () {
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {
      // Add a click event on each of them
      $navbarBurgers.forEach(el => {
        el.addEventListener('click', () => {
          console.log('clicking burger nav');

          // Get the target from the "data-target" attribute
          const target = el.dataset.target;
          const $target = document.getElementById(target);

          // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
          el.classList.toggle('is-active');
          $target.classList.toggle('is-active');
        });
      });
    }
  }

  render () {
    const { path } = this.props.match;

    return (
      path === '/' ||
      path === '/educators' ||
      path === '/students' ||
      path === '/parents' ||
      path === '/contact' ||
      path === '/pricing' ||
      path === '*'
        ? <>
          <nav
            className='navbar is-fixed-top'
            role='navigation'
            aria-label='main navigation'
            style={{
              background: '#E5A84F',
              padding: '10px 8%',
              filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))'
            }}
          >
            {/* NAVBAR LOGO */}
            <div className='navbar-brand'>
              <Link to='/'>
                <img src={logo} alt='' style={{ margin: 5 }} />
              </Link>
              {/* HAMBURGER MENU */}
              <a
                role='button'
                data-target='navMenu'
                className='navbar-burger'
                aria-label='menu'
                aria-expanded='false'
                style={{ color: '#fff' }}
              >
                <span aria-hidden='true' />
                <span aria-hidden='true' />
                <span aria-hidden='true' />
              </a>
            </div>
            {/* NAVBAR MENU */}
            <div className='navbar-menu' id='navMenu' style={{ background: '#E5A84F' }}>
              <div className='navbar-end'>
                <div className='navbar-item'>
                  <NavLink exact to='/' activeStyle={{ fontWeight: 'bolder', borderBottom: '4px solid darkorange' }}>
                    <p style={{ color: '#fff' }}>HOME</p>
                  </NavLink>
                </div>
                <div className='navbar-item'>
                  <NavLink to='/educators' activeStyle={{ fontWeight: 'bolder', borderBottom: '4px solid darkorange' }}>
                    <p style={{ color: '#fff' }}>EDUCATORS</p>
                  </NavLink>
                </div>
                <div className='navbar-item'>
                  <NavLink to='/parents' activeStyle={{ fontWeight: 'bolder', borderBottom: '4px solid darkorange' }}>
                    <p style={{ color: '#fff' }}>PARENTS</p>
                  </NavLink>
                </div>
                <div className='navbar-item'>
                  <NavLink to='/students' activeStyle={{ fontWeight: 'bolder', borderBottom: '4px solid darkorange' }}>
                    <p style={{ color: '#fff' }}>STUDENTS</p>
                  </NavLink>
                </div>
                <div className='navbar-item'>
                  <NavLink to='/pricing' activeStyle={{ fontWeight: 'bolder', borderBottom: '4px solid darkorange' }}>
                    <p style={{ color: '#fff' }}>PRICING</p>
                  </NavLink>
                </div>
                <div className='navbar-item'>
                  <NavLink to='/contact' activeStyle={{ fontWeight: 'bolder', borderBottom: '4px solid darkorange' }}>
                    <p style={{ color: '#fff' }}>CONTACT</p>
                  </NavLink>
                </div>
                <div className='field is-grouped' style={{ margin: 5 }}>
                  <NavLink to='/sign-in' activeStyle={{ fontWeight: 'bolder', borderBottom: '4px solid darkorange' }}>
                    <StyledButton
                      name='sign-in-btn'
                      style={{
                        borderRadius: 8,
                        width: 150,
                        marginRight: 20,
                        height: 40,
                        background: 'rgba(238, 238, 238, 0.17)',
                        border: '1px solid #fff',
                        backgroundBlendMode: 'normal, screen'
                      }}
                    >
                      <FormattedMessage id='landing.sign-in' />
                    </StyledButton>
                  </NavLink>
                  <NavLink to='/sign-up' activeStyle={{ fontWeight: 'bolder' }}>
                    <StyledButton
                      name='sign-up-btn'
                      style={{ borderRadius: 8, width: 150, height: 40 }}
                    >
                      <FormattedMessage id='landing.sign-up' />
                    </StyledButton>
                  </NavLink>
                </div>
              </div>
            </div>
          </nav>
          </>
        : <StyledHeader className='header'>
          <NavLink to='/'>
            <Logo src={logo} alt='TutorD Logo' />
          </NavLink>
          </StyledHeader>
    );
  }
}

export default withRouter(Header);
