import React from 'react';
import { Col, Row, Typography } from 'antd';
import styled from 'styled-components';
import { PurpleButton } from '../../../styles/StyledComponents';
import { CheckCircleOutlined, RightOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const { Title } = Typography;

const FeatureTableContainer = styled.div`
  @media (max-width: 1200px) {
    display: none;
  }
`;

export const FeatureTable = () => {
  return (
    <FeatureTableContainer>
      {/* <Table
        dataSource={data}
        columns={columns}
      ></Table> */}
      {/* GET STARTED OPTIONS */}
      <Row align='middle' justify='center' style={{ margin: '20px auto', marginBottom: '40px' }}>
        <Col xl={6} style={{ width: '100%', height: '100%' }} />
        <Col xl={4} style={{ width: '100%', textAlign: 'center' }}>
          {/* <small>Starting at $21/mo</small> */}
          <Title>Basic</Title>
        </Col>
        <Col xl={4} style={{ width: '100%', textAlign: 'center' }}>
          {/* <small>Starting at $49/mo</small> */}
          <Title>Team</Title>
        </Col>
        <Col xl={4} style={{ width: '100%', textAlign: 'center' }}>
          {/* <small>Starting at $149/mo</small> */}
          <Title>Team Plus</Title>
        </Col>
        {/* <Col xl={4} style={{width: '100%', textAlign: 'center'}}> */}
        {/* <small>Custom Pricing</small> */}
        {/* <Title>Enterprise</Title> */}
        {/* </Col> */}
      </Row>
      {/* FEATURES SECTION */}
      <Row align='middle' justify='center' style={{ height: '4rem' }}>
        <Col
          xl={6}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <Title>Features</Title>
        </Col>
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        />
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        />
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        />
        {/* <Col xl={4} style={{width: '100%', height: '100%',borderBottom: '1px solid #ccc'}}> */}
        {/* </Col> */}
      </Row>
      {/* PHONE NUMBERS SECTION */}
      <Row align='middle' justify='center' style={{ height: '4rem' }}>
        <Col
          xl={6}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <Title level={4} style={{ margin: '15px auto' }}># of phone numbers</Title>
        </Col>
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Title level={3} style={{ fontWeight: 'bolder', color: 'purple', margin: '15px auto' }}>12</Title>
          </div>
        </Col>
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Title level={3} style={{ fontWeight: 'bolder', color: 'purple', margin: '15px auto' }}>&infin;</Title>
          </div>
        </Col>
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Title level={3} style={{ fontWeight: 'bolder', color: 'purple', margin: '15px auto' }}>&infin;</Title>
          </div>
        </Col>
        {/* <Col xl={4} style={{width: '100%', height: '100%',borderBottom: '1px solid #ccc'}}> */}
        {/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}> */}
        {/* <Title level={3} style={{fontWeight: 'bolder', color: 'purple', margin: '15px auto'}}>TBD</Title> */}
        {/* </div> */}
        {/* </Col> */}
      </Row>
      {/* TUTOR GROUPS SECTION */}
      <Row align='middle' justify='center' style={{ height: '4rem' }}>
        <Col
          xl={6}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <Title level={4} style={{ margin: '15px auto' }}># of Tutor Groups</Title>
        </Col>
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Title level={3} style={{ fontWeight: 'bolder', color: 'purple', margin: '15px auto' }}>&infin;</Title>
          </div>
        </Col>
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Title level={3} style={{ fontWeight: 'bolder', color: 'purple', margin: '15px auto' }}>&infin;</Title>
          </div>
        </Col>
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Title level={3} style={{ fontWeight: 'bolder', color: 'purple', margin: '15px auto' }}>&infin;</Title>
          </div>
        </Col>
        {/* <Col xl={4} style={{width: '100%', height: '100%',borderBottom: '1px solid #ccc'}}> */}
        {/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}> */}
        {/* <Title level={3} style={{fontWeight: 'bolder', color: 'purple', margin: '15px auto'}}>TBD</Title> */}
        {/* </div> */}
        {/* </Col> */}
      </Row>
      {/* CLASSES SECTION */}
      <Row align='middle' justify='center' style={{ height: '4rem' }}>
        <Col
          xl={6}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <Title level={4} style={{ margin: '15px auto' }}># of Classes</Title>
        </Col>
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Title level={3} style={{ fontWeight: 'bolder', color: 'purple', margin: '15px auto' }}>&infin;</Title>
          </div>
        </Col>
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Title level={3} style={{ fontWeight: 'bolder', color: 'purple', margin: '15px auto' }}>&infin;</Title>
          </div>
        </Col>
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Title level={3} style={{ fontWeight: 'bolder', color: 'purple', margin: '15px auto' }}>&infin;</Title>
          </div>
        </Col>
        {/* <Col xl={4} style={{width: '100%', height: '100%',borderBottom: '1px solid #ccc'}}> */}
        {/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}> */}
        {/* <Title level={3} style={{fontWeight: 'bolder', color: 'purple', margin: '15px auto'}}>TBD</Title> */}
        {/* </div> */}
        {/* </Col> */}
      </Row>
      {/* SCHOLARS SECTION */}
      <Row align='middle' justify='center' style={{ height: '4rem' }}>
        <Col
          xl={6}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <Title level={4} style={{ margin: '15px auto' }}># of Scholars/Class</Title>
        </Col>
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Title level={3} style={{ fontWeight: 'bolder', color: 'purple', margin: '15px auto' }}>&infin;</Title>
          </div>
        </Col>
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Title level={3} style={{ fontWeight: 'bolder', color: 'purple', margin: '15px auto' }}>&infin;</Title>
          </div>
        </Col>
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Title level={3} style={{ fontWeight: 'bolder', color: 'purple', margin: '15px auto' }}>&infin;</Title>
          </div>
        </Col>
        {/* <Col xl={4} style={{width: '100%', height: '100%',borderBottom: '1px solid #ccc'}}> */}
        {/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}> */}
        {/* <Title level={3} style={{fontWeight: 'bolder', color: 'purple', margin: '15px auto'}}>TBD</Title> */}
        {/* </div> */}
        {/* </Col> */}
      </Row>
      {/* ADMIN USERS SECTION */}
      <Row align='middle' justify='center' style={{ height: '4rem' }}>
        <Col
          xl={6}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <Title level={4} style={{ margin: '15px auto' }}>Admin Users</Title>
        </Col>
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        />
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CheckCircleOutlined style={{ fontSize: 30, color: 'purple', margin: '15px auto' }} />
          </div>
        </Col>
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CheckCircleOutlined style={{ fontSize: 30, color: 'purple', margin: '15px auto' }} />
          </div>
        </Col>
        {/* <Col xl={4} style={{width: '100%', height: '100%',borderBottom: '1px solid #ccc'}}> */}
        {/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}> */}
        {/* <CheckCircleOutlined style={{fontSize: 30, color: 'purple', margin: '15px auto'}} /> */}
        {/* </div> */}
        {/* </Col> */}
      </Row>
      {/* VANITY NUMBERS SECTION */}
      <Row align='middle' justify='center' style={{ height: '4rem' }}>
        <Col
          xl={6}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <Title level={4} style={{ margin: '15px auto' }}>Vanity Numbers</Title>
        </Col>
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        />
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        />
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CheckCircleOutlined style={{ fontSize: 30, color: 'purple', margin: '15px auto' }} />
          </div>
        </Col>
        {/* <Col xl={4} style={{width: '100%', height: '100%',borderBottom: '1px solid #ccc'}}> */}
        {/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}> */}
        {/* <CheckCircleOutlined style={{fontSize: 30, color: 'purple', margin: '15px auto'}} /> */}
        {/* </div> */}
        {/* </Col> */}
      </Row>
      {/* ADVANCED METRICS SECTION */}
      <Row align='middle' justify='center' style={{ height: '4rem' }}>
        <Col
          xl={6}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <Title level={4} style={{ margin: '15px auto' }}>Advanced Metrics</Title>
        </Col>
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        />
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        />
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CheckCircleOutlined style={{ fontSize: 30, color: 'purple', margin: '15px auto' }} />
          </div>
        </Col>
        {/* <Col xl={4} style={{width: '100%', height: '100%',borderBottom: '1px solid #ccc'}}> */}
        {/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}> */}
        {/* <CheckCircleOutlined style={{fontSize: 30, color: 'purple', margin: '15px auto'}} /> */}
        {/* </div> */}
        {/* </Col> */}
      </Row>
      {/* QUESTION SET PRIORITY SECTION */}
      <Row align='middle' justify='center' style={{ height: '4rem' }}>
        <Col
          xl={6}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <Title level={4} style={{ margin: '15px auto' }}>Question Set Priority</Title>
        </Col>
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Title level={4} style={{ fontWeight: 'bolder', color: 'purple', margin: '15px auto' }}>Public/Private</Title>
          </div>
        </Col>
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Title
              level={4}
              style={{ fontWeight: 'bolder', color: 'purple', margin: '15px auto' }}
            >Public/Private/Team
            </Title>
          </div>
        </Col>
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Title
              level={4}
              style={{ fontWeight: 'bolder', color: 'purple', margin: '15px auto' }}
            >Public/Private/Team
            </Title>
          </div>
        </Col>
        {/* <Col xl={4} style={{width: '100%', height: '100%',borderBottom: '1px solid #ccc'}}> */}
        {/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}> */}
        {/* <Title level={4} style={{fontWeight: 'bolder', color: 'purple', margin: '15px auto'}}>Public/Private/Team</Title> */}
        {/* </div> */}
        {/* </Col> */}
      </Row>
      {/* ORG INSIGHTS SECTION */}
      <Row align='middle' justify='center' style={{ height: '4rem' }}>
        <Col
          xl={6}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <Title level={4} style={{ margin: '15px auto' }}>Org Insights</Title>
        </Col>
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        />
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CheckCircleOutlined style={{ fontSize: 30, color: 'purple', margin: '15px auto' }} />
          </div>
        </Col>
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CheckCircleOutlined style={{ fontSize: 30, color: 'purple', margin: '15px auto' }} />
          </div>
        </Col>
        {/* <Col xl={4} style={{width: '100%', height: '100%',borderBottom: '1px solid #ccc'}}> */}
        {/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}> */}
        {/* <CheckCircleOutlined style={{fontSize: 30, color: 'purple', margin: '15px auto'}} /> */}
        {/* </div> */}
        {/* </Col> */}
      </Row>
      {/* ADMIN OVERSIGHTS SECTION */}
      <Row align='middle' justify='center' style={{ height: '4rem' }}>
        <Col
          xl={6}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <Title level={4} style={{ margin: '15px auto' }}>Administrative Oversight</Title>
        </Col>
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        />
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CheckCircleOutlined style={{ fontSize: 30, color: 'purple', margin: '15px auto' }} />
          </div>
        </Col>
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CheckCircleOutlined style={{ fontSize: 30, color: 'purple', margin: '15px auto' }} />
          </div>
        </Col>
        {/* <Col xl={4} style={{width: '100%', height: '100%',borderBottom: '1px solid #ccc'}}> */}
        {/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}> */}
        {/* <CheckCircleOutlined style={{fontSize: 30, color: 'purple', margin: '15px auto'}} /> */}
        {/* </div> */}
        {/* </Col> */}
      </Row>
      {/* ROSTERING SECTION */}
      <Row align='middle' justify='center' style={{ height: '4rem' }}>
        <Col
          xl={6}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <Title level={4} style={{ margin: '15px auto' }}>Rostering/SIS</Title>
        </Col>
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        />
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        />
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        />
        {/* <Col xl={4} style={{width: '100%', height: '100%',borderBottom: '1px solid #ccc'}}> */}
        {/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}> */}
        {/* <CheckCircleOutlined style={{fontSize: 30, color: 'purple', margin: '15px auto'}} /> */}
        {/* </div> */}
        {/* </Col> */}
      </Row>
      {/* lms integration SECTION */}
      <Row align='middle' justify='center' style={{ height: '4rem' }}>
        <Col
          xl={6}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <Title level={4} style={{ margin: '15px auto' }}>LMS Integration</Title>
        </Col>
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        />
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        />
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        />
        {/* <Col xl={4} style={{width: '100%', height: '100%',borderBottom: '1px solid #ccc'}}> */}
        {/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}> */}
        {/* <CheckCircleOutlined style={{fontSize: 30, color: 'purple', margin: '15px auto'}} /> */}
        {/* </div> */}
        {/* </Col> */}
      </Row>
      {/* ROLE BASED ACCESS RULES SECTION */}
      <Row align='middle' justify='center' style={{ height: '4rem' }}>
        <Col
          xl={6}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <Title level={4} style={{ margin: '15px auto' }}>Role based access rules</Title>
        </Col>
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        />
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CheckCircleOutlined style={{ fontSize: 30, color: 'purple', margin: '15px auto' }} />
          </div>
        </Col>
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CheckCircleOutlined style={{ fontSize: 30, color: 'purple', margin: '15px auto' }} />
          </div>
        </Col>
        {/* <Col xl={4} style={{width: '100%', height: '100%',borderBottom: '1px solid #ccc'}}> */}
        {/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}> */}
        {/* <CheckCircleOutlined style={{fontSize: 30, color: 'purple', margin: '15px auto'}} /> */}
        {/* </div> */}
        {/* </Col> */}
      </Row>
      {/* STUDENT PROFILE SECTION */}
      <Row align='middle' justify='center' style={{ height: '4rem' }}>
        <Col
          xl={6}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <Title level={4} style={{ margin: '15px auto' }}>Student Profile</Title>
        </Col>
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CheckCircleOutlined style={{ fontSize: 30, color: 'purple', margin: '15px auto' }} />
          </div>
        </Col>
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CheckCircleOutlined style={{ fontSize: 30, color: 'purple', margin: '15px auto' }} />
          </div>
        </Col>
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CheckCircleOutlined style={{ fontSize: 30, color: 'purple', margin: '15px auto' }} />
          </div>
        </Col>
        {/* <Col xl={4} style={{width: '100%', height: '100%',borderBottom: '1px solid #ccc'}}> */}
        {/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}> */}
        {/* <CheckCircleOutlined style={{fontSize: 30, color: 'purple', margin: '15px auto'}} /> */}
        {/* </div> */}
        {/* </Col> */}
      </Row>
      {/* EDUCATOR PROFILE SECTION */}
      <Row align='middle' justify='center' style={{ height: '4rem' }}>
        <Col
          xl={6}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <Title level={4} style={{ margin: '15px auto' }}>Educator Profile</Title>
        </Col>
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        />
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CheckCircleOutlined style={{ fontSize: 30, color: 'purple', margin: '15px auto' }} />
          </div>
        </Col>
        <Col
          xl={4}
          style={{ width: '100%', height: '100%', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CheckCircleOutlined style={{ fontSize: 30, color: 'purple', margin: '15px auto' }} />
          </div>
        </Col>
        {/* <Col xl={4} style={{width: '100%', height: '100%',borderBottom: '1px solid #ccc'}}> */}
        {/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}> */}
        {/* <CheckCircleOutlined style={{fontSize: 30, color: 'purple', margin: '15px auto'}} /> */}
        {/* </div> */}
        {/* </Col> */}
      </Row>
      {/* GET STARTED BUTTONS */}
      <Row align='middle' justify='center' style={{ margin: '20px auto', marginBottom: '40px' }}>
        <Col xl={6} style={{ width: '100%', height: '100%' }} />
        <Col xl={4} style={{ width: '100%', textAlign: 'center' }}>
          <Link to='/sign-up'>
            <PurpleButton>
              GET STARTED <RightOutlined />
            </PurpleButton>
          </Link>
        </Col>
        <Col xl={4} style={{ width: '100%', textAlign: 'center' }}>
          <Link to='/contact'>
            <PurpleButton>
              CONTACT US <RightOutlined />
            </PurpleButton>
          </Link>
        </Col>
        <Col xl={4} style={{ width: '100%', textAlign: 'center' }}>
          <Link to='/contact'>
            <PurpleButton>
              CONTACT US <RightOutlined />
            </PurpleButton>
          </Link>
        </Col>
        {/* <Col xl={4} style={{width: '100%', textAlign: 'center'}}> */}
        {/* <PurpleButton>CONTACT SALES</PurpleButton> */}
        {/* </Col> */}
      </Row>
    </FeatureTableContainer>
  );
};
