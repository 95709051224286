import React from 'react';
import styled from 'styled-components';
import { Avatar, Button, List as AList, Row } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { StyledTitle as Title } from '../../../fields/Title';

const StyledActionButton = styled(Button)`
  background: transparent;
  padding: 0;
  border: none;
  color: #8053d7;
  outline: none;
  box-shadow: none;

  &:hover,
  &:focus,
  &:active {
    color: #9874df;
    background: transparent;
    outline: none;
    box-shadow: none;
  }
`;

export const TutorGroupStudentsListItem = ({
  student,
  onClick,
  index,
  icon,
  maxCount = 25,
  count = 0
}) => {
  // THis will be updated after the response from the backend is updated
  const firstName = student?.firstName ? student?.firstName : student?.profile?.firstName;
  const lastName = student?.lastName ? student?.lastName : student?.profile?.lastName;

  return (
    <AList.Item
      key={`${firstName}-${lastName}`}
      actions={[
        <StyledActionButton
          icon={icon}
          type='text'
          disabled={count === maxCount}
          onClick={(event) => {
            console.log('TutorGroupStudentsListItem, event: ', event);
            onClick({ event, student, index });
          }}
        />
      ]}
    >
      <AList.Item.Meta
        avatar={<Avatar size={50} icon={<UserOutlined />} />}
        title={
          <div style={{ marginBottom: -10 }}>
            <Title level={5}>{`${firstName} ${lastName}`}</Title>
          </div>
        }
        description={
          <Row>
            <div style={{ color: '#8053D7' }}>{`${student?.learningStyles?.join(
              ', '
            )}`}
            </div>
          </Row>
        }
      />
    </AList.Item>
  );
};
