import { all, fork, take, takeLatest } from 'redux-saga/effects';

import {
  SIGNUP_REQUEST,
  CONTACT_US_REQUEST,
  SIGNUP_FAILURE,
  VERIFY_EMAIL_REQUEST,
  RESEND_EMAIL_REQUEST,
  onHandleSignUpRequest,
  onHandleResendVerifyEmail,
  onHandleVerifyEmail,
  onHandleContactUsRequest
} from './redux';

import { ONBOARDING_REQUEST, onHandleOnBoardingRequest } from './redux/onboarding';
import { SET_STUDENT_PHONE_NUMBER_REQUEST, onHandleSetStudentPhoneNumberRequest } from './redux/set-student-phone-number';
import { VERIFY_STUDENT_PHONE_NUMBER_REQUEST, onHandleVerifyStudentPhoneNumberRequest } from './redux/verify-student-phone-number';
import { IDENTIFY_STUDENT_LEARNING_STYLE_REQUEST, onHandleIdentifyStudentLearningStyleRequest } from './redux/identify-student-learning-style';
import {
  ENROLLMENT_REQUEST,
  VERIFY_STUDENT_EMAIL_REQUEST,
  onHandleEnrollmentRequest,
  onHandleVerifyStudentEmail
} from './redux/enrollment';

function * watchSetStudentPhoneNumber () {
  console.log('#watchSetStudentPhoneNumber');
  yield takeLatest(SET_STUDENT_PHONE_NUMBER_REQUEST, onHandleSetStudentPhoneNumberRequest);
}

function * watchVerifyStudentPhoneNumber () {
  console.log('#watchSetStudentPhoneNumber');
  yield takeLatest(VERIFY_STUDENT_PHONE_NUMBER_REQUEST, onHandleVerifyStudentPhoneNumberRequest);
}

function * watchIdentifyStudentLearningStyle () {
  console.log('#watchIdentifyStudentLearningStyle');
  yield takeLatest(IDENTIFY_STUDENT_LEARNING_STYLE_REQUEST, onHandleIdentifyStudentLearningStyleRequest);
}

function * watchEnrollment () {
  console.log('#watchEnrollment');
  yield takeLatest(ENROLLMENT_REQUEST, onHandleEnrollmentRequest);
}

function * watchOnBoarding () {
  console.log('#watchOnBoarding');
  yield takeLatest(ONBOARDING_REQUEST, onHandleOnBoardingRequest);
}

function * watchVerifyEmail () {
  console.log('#watchVerifyEmail');
  yield takeLatest(VERIFY_EMAIL_REQUEST, onHandleVerifyEmail);
}

function * watchVerifyStudentEmail () {
  console.log('#watchVerifyStudentEmail');
  yield takeLatest(VERIFY_STUDENT_EMAIL_REQUEST, onHandleVerifyStudentEmail);
}

function * watchResendVerifyEmail () {
  console.log('#watchVerifyEmail');
  yield takeLatest(RESEND_EMAIL_REQUEST, onHandleResendVerifyEmail);
}

function * watchContactUs () {
  console.log('#watchContactUs');
  yield takeLatest(CONTACT_US_REQUEST, onHandleContactUsRequest);
}

function * watchUserSignUp () {
  console.log('#watchUserSignUp');

  while (true) {
    console.log('#watchUserSignUp while loop');
    const { payload } = yield take(SIGNUP_REQUEST);
    yield fork(onHandleSignUpRequest, payload);
    const action = yield take(SIGNUP_FAILURE);

    console.log('maybe a sign up failure happened, not sure... action: ', action);
  }
}

export default function * sagas () {
  yield all([
    watchUserSignUp(),
    watchContactUs(),
    watchResendVerifyEmail(),
    watchOnBoarding(),
    watchEnrollment(),
    watchVerifyStudentEmail(),
    watchSetStudentPhoneNumber(),
    watchVerifyStudentPhoneNumber(),
    watchIdentifyStudentLearningStyle(),
    watchVerifyEmail()
  ]);
}
