import { all, call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import request from '../../../lib/request';
import { FORM_ERROR } from 'final-form';
import { setAuthToken } from '../../auth/redux';
import { parseFormErrors } from '../../../utils/parseFormErrors';

export const VERIFY_STUDENT_PHONE_NUMBER_REQUEST = 'sign-up/enrollment/VERIFY_STUDENT_PHONE_NUMBER_REQUEST';
export const VERIFY_STUDENT_PHONE_NUMBER_SUCCESS = 'sign-up/enrollment/VERIFY_STUDENT_PHONE_NUMBER_SUCCESS';
export const VERIFY_STUDENT_PHONE_NUMBER_FAILURE = 'sign-up/enrollment/VERIFY_STUDENT_PHONE_NUMBER_FAILURE';

export const handleVerifyStudentPhoneNumberRequest = payload => ({
  type: VERIFY_STUDENT_PHONE_NUMBER_REQUEST,
  payload
});

export const handleVerifyStudentPhoneNumberSuccess = payload => ({
  type: VERIFY_STUDENT_PHONE_NUMBER_SUCCESS,
  payload,
  loading: false
});

export const handleVerifyStudentPhoneNumberFailure = payload => ({
  type: VERIFY_STUDENT_PHONE_NUMBER_FAILURE,
  payload,
  isLoading: false
});

export function * onHandleVerifyStudentPhoneNumberRequest ({ payload: { smsVerifyCode } }) {
  console.log('#onHandleVerifyStudentPhoneNumberRequest, smsVerifyCode: ', smsVerifyCode);

  try {
    console.log('#onHandleVerifyStudentPhoneNumberRequest, try block');
    const response = yield call(request.post, '/accounts/verify/student/sms', { smsVerifyCode });

    const { user, token } = response.data;
    localStorage.setItem('token', JSON.stringify(token));
    localStorage.setItem('user', JSON.stringify(user));

    yield all([
      put(handleVerifyStudentPhoneNumberSuccess(user)),
      put(setAuthToken(token)),
      put(push('/student/identify-learning-styles'))
    ]);
  } catch (err) {
    console.error('Error, #onHandleVerifyStudentPhoneNumberRequest, err: ', err);
    const { status, message, errors } = err.response.data;

    if (message === 'Network Error') {
      return yield put({
        type: VERIFY_STUDENT_PHONE_NUMBER_SUCCESS,
        payload: { [FORM_ERROR]: 'Network Error? Check your connection and please try again later...' }
      });
    }

    if (status === 409) {
      return yield put({
        type: VERIFY_STUDENT_PHONE_NUMBER_SUCCESS,
        payload: {
          [FORM_ERROR]: 'Already Verified...',
          ...parseFormErrors(errors)
        }
      });
    }

    if (status === 422 || status === 400) {
      return yield put({
        type: VERIFY_STUDENT_PHONE_NUMBER_SUCCESS,
        payload: {
          [FORM_ERROR]: message,
          ...parseFormErrors(errors)
        }
      });
    }

    if (err.response.status === 500) {
      return yield put({
        type: VERIFY_STUDENT_PHONE_NUMBER_SUCCESS,
        payload: { [FORM_ERROR]: 'Its not you, its us....... Please try again later.' }
      });
    }
  }
}

const INITIAL_STATE = {
  isLoading: false,
  error: null
};

export default function verifyStudentPhoneNumber (state = INITIAL_STATE, action) {
  switch (action.type) {
    case VERIFY_STUDENT_PHONE_NUMBER_REQUEST:
      return { ...state, isLoading: true };
    case VERIFY_STUDENT_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload.user
      };
    case VERIFY_STUDENT_PHONE_NUMBER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
}
