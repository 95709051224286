import React from 'react';
import { Link } from 'react-router-dom';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { Field as FField, Form as FForm } from 'react-final-form';
import styled from 'styled-components';
import { Row, Col, Button, Form as AForm, Divider } from 'antd';

import { dispatch, promiseListener } from '../../store';
import { SIGNUP_REQUEST, SIGNUP_SUCCESS, SIGNUP_FAILURE } from '../../modules/sign-up/redux';
import { capitalize } from '../fields/renderFields';
import { InputControl, EmailInputControl, PasswordControl, CustomCheckboxInputControl } from '../fields/Fields';
import { StyledButton, StyledGoogleButton } from '../fields/Button';
import * as validations from '../../utils/validations';
import { handleShowToast } from '../../modules/notification/redux';
import LoadingSpinner from '../../lib/components/loadingSpinner';
import { FORM_ERROR } from 'final-form';
import GoogleIcon from '../../assets/google.png';

const StyledOptInInput = styled.div`
  & .opt-in-field__checkbox {
    display: flex;
    float: left;
    margin-right: .25rem;
  }
`;

export const SignUpForm = ({ isLoading, initialValues }) => (

  <MakeAsyncFunction
    listener={promiseListener}
    start={SIGNUP_REQUEST}
    resolve={SIGNUP_SUCCESS}
    reject={SIGNUP_FAILURE}
    getError={(action) => {
      const payload = action.payload;
      const error = payload.email ? payload.email : payload.orgName ? payload.orgName
        : payload[FORM_ERROR] ? payload[FORM_ERROR] : 'something went wrong';
      dispatch(handleShowToast({ message: error, type: 'error' }));
    }}
  >
    {onSubmit => (
      <FForm
        name='sign-up-form'
        style={{ textAlign: 'center' }}
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({
          handleSubmit,
          submitting,
          hasValidationErrors,
          hasSubmitErrors,
          dirtySinceLastSubmit,
          values
        }) => (
          <div>
            <LoadingSpinner spinning={isLoading}>
              <AForm onFinish={handleSubmit}>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col lg={12} sm={20} xs={24}>
                    <StyledGoogleButton>
                      <img src={GoogleIcon} height={20} width={20} alt='' />
                      Sign Up with Google
                    </StyledGoogleButton>
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col lg={12} sm={20} xs={24}>
                    <Divider orientation='center'>
                      <small>or Sign Up with email</small>
                    </Divider>
                    <small style={{ display: 'flex', justifyContent: 'left' }}>First Name*</small>
                    <FField
                      name='firstName'
                      component={InputControl}
                      type='text'
                      placeholder='First Name*'
                      format={capitalize}
                      validate={validations.composeValidators(
                        validations.required,
                        validations.minLength(2),
                        validations.maxLength(30)
                      )}
                    />
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col lg={12} sm={20} xs={24}>
                    <small style={{ display: 'flex', justifyContent: 'left' }}>Last Name*</small>
                    <FField
                      name='lastName'
                      component={InputControl}
                      type='text'
                      placeholder='Last Name*'
                      format={capitalize}
                      validate={validations.composeValidators(
                        validations.required,
                        validations.minLength(2),
                        validations.maxLength(30)
                      )}
                    />
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col lg={12} sm={20} xs={24}>
                    <small style={{ display: 'flex', justifyContent: 'left' }}>Email*</small>
                    <FField
                      name='email'
                      component={EmailInputControl}
                      type='text'
                      placeholder='Email*'
                      disabled={initialValues.email !== ''}
                      validate={validations.composeValidators(
                        validations.required,
                        validations.email
                      )}
                    />
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col lg={12} sm={20} xs={24}>
                    <small style={{ display: 'flex', justifyContent: 'left' }}>School*</small>
                    <FField
                      name='orgName'
                      component={InputControl}
                      type='text'
                      placeholder='School/School District/Tutor Center Name*'
                      disabled={initialValues.orgName !== ''}
                      format={capitalize}
                      validate={validations.composeValidators(
                        validations.required,
                        validations.minLength(4),
                        validations.maxLength(50)
                      )}
                    />
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col lg={12} sm={20} xs={24}>
                    <small style={{ display: 'flex', justifyContent: 'left' }}>Password*</small>
                    <FField
                      name='password'
                      component={PasswordControl}
                      type='text'
                      placeholder='Password*'
                      validate={validations.composeValidators(
                        validations.required,
                        validations.minLength(6)
                      )}
                    />
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col lg={12} sm={20} xs={24}>
                    <small style={{ display: 'flex', justifyContent: 'left', marginBottom: 5 }}>Already have an account? Please login <Link to='/sign-in' style={{ marginLeft: 2, textDecoration: 'underline' }}>here</Link></small>
                    <small style={{ display: 'block', justifyContent: 'left', marginBottom: 5 }}>
                      By clicking Sign Up, you agree to our
                      <Link to={{ pathname: 'https://app.termly.io/document/terms-of-service/ec2ee45b-62dd-4816-80d0-f7e574c19bf0' }} target='_blank' style={{ textDecoration: 'underline' }}>Terms and Conditions,</Link>
                      <Link to={{ pathname: 'https://app.termly.io/document/privacy-policy/438a711d-5cdf-45ae-8df4-0cd0e71e0f23' }} target='_blank' style={{ textDecoration: 'underline' }}> Privacy Policy,</Link>
                      <Link to={{ pathname: 'https://app.termly.io/document/acceptable-use/0d414270-e7d5-4b9b-bcaa-8891f2841711' }} target='_blank' style={{ textDecoration: 'underline' }}> Acceptable Use Policy,</Link> and
                      <Link to={{ pathname: 'https://app.termly.io/document/cookie-policy/5ea13866-93d0-4d6f-82e7-82553407e4a0' }} target='_blank' style={{ marginLeft: 2, textDecoration: 'underline' }}>Cookies Policy.</Link>
                      You may receive SMS Notifications from us and can opt out any time.
                    </small>
                    <small style={{ display: 'block', justifyContent: 'left', marginBottom: 20 }}>
                      <StyledOptInInput>
                        <FField
                          name='marketingOptIn'
                          component={CustomCheckboxInputControl}
                          type='checkbox'
                          className='opt-in-field__checkbox'
                          initialValue
                        />

                      By selecting this box you want to learn more about what we are doing to make an impact in the learning experience and our communities
                      via our <Link to='/#' style={{ textDecoration: 'underline' }}>newsletter.</Link>
                      </StyledOptInInput>
                    </small>

                    {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                    <StyledButton
                      size='large'
                      type='primary'
                      htmlType='submit'
                      disabled={submitting ||
                      ((hasSubmitErrors || hasValidationErrors) && !dirtySinceLastSubmit)}
                      loading={isLoading || submitting}
                      block
                    >
                      Sign Up
                    </StyledButton>
                  </Col>
                </Row>
              </AForm>
            </LoadingSpinner>
          </div>
        )}
      />
    )}
  </MakeAsyncFunction>
);
