import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button, List as AList, Rate } from 'antd';
import { FireOutlined } from '@ant-design/icons';

import { StyledContainer } from './TutorGroupQuestionSetList';

const StyledQuestionSetsItem = styled(StyledContainer)`
  width: 100%;
  min-height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 20px 0 30px;
  border-left: ${({ isActive }) =>
    `15px solid ${isActive ? '#5BBCBD' : '#D7753E'}`};

  & .qs-item__content {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
  }

  & .ant-list-item-meta-title {
    width: 100%;
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: all 0.2s linear;
    margin-bottom: 0;
  }

  & .ant-list-item-action {
    margin-left: 20px;
    & > li {
      padding-right: 0px;
    }
  }

  & .qs-item__link {
    color: #000000;
    transition: all 0.2s linear;
  }

  & .qs-item__link:hover {
    color: #8053d7;
    transition: all 0.2s linear;
  }

  & .qs-item-difficulty {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d7753e;
    border-radius: 8px;
    padding: 5px;
    @media (max-width: 500px) {
        display: none;
      };

    &__title {
      font-weight: bold;
      font-size: 13px;
      color: #000000;
      padding: 2px 7px;
      background-color: #fff;
      position: absolute;
      white-space: nowrap;
      left: 10px;
      top: 0;
      transform: translateY(-50%);
    }
  }
`;

const StyledActionButton = styled(Button)`
  background: transparent;
  padding: 0;
  border: none;
  color: #8053d7;
  outline: none;
  box-shadow: none;

  &:hover,
  &:focus,
  &:active {
    color: #9874df;
    background: transparent;
    outline: none;
    box-shadow: none;
  }
`;

// ICONS FOR DIFFICULTY
const customIcons = {
  1: <FireOutlined />,
  2: <FireOutlined />,
  3: <FireOutlined />,
  4: <FireOutlined />,
  5: <FireOutlined />
};

export const TutorGroupQuestionSetItem = ({
  qSet,
  maxCount = 3,
  count,
  onClick,
  index,
  icon
}) => (
  <StyledQuestionSetsItem>
    <AList.Item
      key={`${qSet.title}-${qSet.id}`}
      style={{ width: '100%' }}
      actions={[
        <StyledActionButton
          icon={icon}
          type='text'
          disabled={count === maxCount}
          onClick={(event) => {
            console.log('TutorGroupQuestionSetItem, event: ', event);
            onClick({ event, qSet, index });
          }}
        />
      ]}
    >
      <AList.Item.Meta
        title={
          <div
            className='qs-item__content'
            onClick={(event) => {
              console.log('TutorGroupQuestionSetItem, event: ', event);
              onClick({ event, qSet, index });
            }}
          >
            <div className='qs-item__link'>
              {qSet.title}
            </div>
            <div className='qs-item__difficulty qs-item-difficulty'>
              <span className='qs-item-difficulty__title'>Difficulty</span>
              <Rate
                disabled
                value={qSet.questionSetDifficulty}
                character={({ index }) => {
                  return customIcons[index + 1];
                }}
              />
            </div>
          </div>
        }
      />
    </AList.Item>
  </StyledQuestionSetsItem>
);
