import { all, call, put } from 'redux-saga/effects';

import request from '../../../lib/request';
import { push } from 'connected-react-router';
import { FORM_ERROR } from 'final-form';
import { parseFormErrors } from '../../../utils/parseFormErrors';
import { mockedExtraTutorGroupsData } from './mocks';

export const TUTOR_GROUP_REQUEST = 'teacher/tutor-groups/TUTOR_GROUP_REQUEST';
export const TUTOR_GROUP_SUCCESS = 'teacher/tutor-groups/TUTOR_GROUP_SUCCESS';
export const TUTOR_GROUP_FAILURE = 'teacher/tutor-groups/TUTOR_GROUP_FAILURE';

export const CREATE_TUTOR_GROUP_REQUEST =
  'teacher/tutor-groups/CREATE_TUTOR_GROUP_REQUEST';
export const CREATE_TUTOR_GROUP_SUCCESS =
  'teacher/tutor-groups/CREATE_TUTOR_GROUP_SUCCESS';
export const CREATE_TUTOR_GROUP_FAILURE =
  'teacher/tutor-groups/CREATE_TUTOR_GROUP_FAILURE';

export const EDIT_TUTOR_GROUP_REQUEST =
  'teacher/tutor-groups/EDIT_TUTOR_GROUP_REQUEST';
export const EDIT_TUTOR_GROUP_SUCCESS =
  'teacher/tutor-groups/EDIT_TUTOR_GROUP_SUCCESS';
export const EDIT_TUTOR_GROUP_FAILURE =
  'teacher/tutor-groups/EDIT_TUTOR_GROUP_FAILURE';

export const DELETE_TUTOR_GROUP_REQUEST =
  'teacher/tutor-groups/DELETE_TUTOR_GROUP_REQUEST';
export const DELETE_TUTOR_GROUP_SUCCESS =
  'teacher/tutor-groups/DELETE_TUTOR_GROUP_SUCCESS';
export const DELETE_TUTOR_GROUP_FAILURE =
  'teacher/tutor-groups/DELETE_TUTOR_GROUP_FAILURE';

// Get Tutor Group by Id
export const handleTutorGroupRequest = (payload) => ({
  type: TUTOR_GROUP_REQUEST,
  payload
});

export const handleTutorGroupSuccess = (payload) => ({
  type: TUTOR_GROUP_SUCCESS,
  payload
});

export const handleTutorGroupFailure = (payload) => ({
  type: TUTOR_GROUP_FAILURE,
  payload
});

export function * onHandleTutorGroupRequest ({ payload }) {
  console.log('#onHandleTutorGroupRequest, payload: ', payload);

  try {
    console.log('#onHandleTutorGroupRequest, try block');
    const response = yield call(
      request.get,
      `/classes/${payload.classId}/tutor-groups/${payload.tutorGroupId}`
    );
    console.log('response: ', response);
    const { tutorGroup, students } = response.data;

    return yield all([
      put(handleTutorGroupSuccess({ tutorGroup, students }))
    ]);
  } catch (err) {
    console.error('Error, #onHandleTutorGroupRequest, err: ', err);
  }
}

// Create Tutor Group
export const handleCreateTutorGroupRequest = (payload) => ({
  type: CREATE_TUTOR_GROUP_REQUEST,
  payload
});

export const handleCreateTutorGroupSuccess = (payload) => ({
  type: CREATE_TUTOR_GROUP_SUCCESS,
  payload
});

export const handleCreateTutorGroupFailure = (payload) => ({
  type: CREATE_TUTOR_GROUP_FAILURE,
  payload
});

export function * onHandleCreateTutorGroup ({ payload }) {
  console.log('#onHandleCreateTutorGroup, payload: ', payload);

  const body = {
    title: payload.title,
    dispatchInterval: payload.dispatchInterval,
    questionSets: payload.tutorGroupQuestionSets.map((qSet) => qSet.id),
    students: payload.tutorGroupStudents.map((student) => student.id)
  };

  try {
    console.log('#onHandleCreateTutorGroup, try block');
    const response = yield call(
      request.post,
      `/classes/${payload.classId}/tutor-groups`,
      body
    );
    console.log('#onHandleCreateTutorGroup, response: ', response);
    const { tutorGroup, tutorGroupSession } = response.data;

    console.log(response);

    return yield all([
      put(handleCreateTutorGroupSuccess({ tutorGroup, tutorGroupSession })),
      put(push(`/teacher/classes/${payload.classId}`))
    ]);
  } catch (err) {
    console.error('Error, #onHandleCreateTutorGroup, err: ', err);
    const { status, message, errors } = err.response.data;

    if (message === 'Network Error') {
      return yield put({
        type: CREATE_TUTOR_GROUP_SUCCESS,
        payload: {
          [FORM_ERROR]:
            'Network Error? Check your connection and please try again later...'
        }
      });
    }

    if (status === 409) {
      return yield put({
        type: CREATE_TUTOR_GROUP_SUCCESS,
        payload: {
          [FORM_ERROR]: 'Already exists...',
          ...parseFormErrors(errors)
        }
      });
    }

    if (status === 422 || status === 400) {
      return yield put({
        type: CREATE_TUTOR_GROUP_SUCCESS,
        payload: {
          [FORM_ERROR]: message,
          ...parseFormErrors(errors)
        }
      });
    }

    if (err.response.status === 500) {
      return yield put({
        type: CREATE_TUTOR_GROUP_SUCCESS,
        payload: {
          [FORM_ERROR]: 'Its not you, its us....... Please try again later.'
        }
      });
    }
  }
}

// Edit Tutor Group
export const handleEditTutorGroupRequest = (payload) => ({
  type: EDIT_TUTOR_GROUP_REQUEST,
  payload
});

export const handleEditTutorGroupSuccess = (payload) => ({
  type: EDIT_TUTOR_GROUP_SUCCESS,
  payload
});

export const handleEditTutorGroupFailure = (payload) => ({
  type: EDIT_TUTOR_GROUP_FAILURE,
  payload
});

export function * onHandleEditTutorGroup ({ payload }) {
  console.log('#onHandleEditTutorGroup, payload: ', payload);

  const body = {
    title: payload.title,
    dispatchInterval: payload.dispatchInterval,
    questionSets: payload.tutorGroupQuestionSets.map((qSet) => qSet.id),
    students: payload.tutorGroupStudents.map((student) => student.id ? student.id : student._id)
  };

  try {
    console.log('#onHandleEditTutorGroup, try block', payload);
    const response = yield call(
      request.put,
      `/classes/${payload.classId}/tutor-groups/${payload.tutorGroupId}`,
      body
    );
    console.log('#onHandleCreateTutorGroup, response: ', response);

    const { tutorGroup, tutorGroupSession } = response?.data;

    return yield all([
      put(handleEditTutorGroupSuccess({ tutorGroup: tutorGroup }))
    ]);
  } catch (err) {
    console.error('Error, #onHandleEditTutorGroup, err: ', err);
    const { status, message, errors } = err.response.data;

    if (message === 'Network Error') {
      return yield put({
        type: EDIT_TUTOR_GROUP_SUCCESS,
        payload: {
          [FORM_ERROR]:
            'Network Error? Check your connection and please try again later...'
        }
      });
    }

    if (status === 409) {
      return yield put({
        type: EDIT_TUTOR_GROUP_SUCCESS,
        payload: {
          [FORM_ERROR]: 'Already exists...',
          ...parseFormErrors(errors)
        }
      });
    }

    if (status === 422 || status === 400) {
      return yield put({
        type: EDIT_TUTOR_GROUP_SUCCESS,
        payload: {
          [FORM_ERROR]: message,
          ...parseFormErrors(errors)
        }
      });
    }

    if (err.response.status === 500) {
      return yield put({
        type: EDIT_TUTOR_GROUP_SUCCESS,
        payload: {
          [FORM_ERROR]: 'Its not you, its us....... Please try again later.'
        }
      });
    }
  }
}

// Delete Tutor Group
export const handleDeleteTutorGroupRequest = (payload) => ({
  type: DELETE_TUTOR_GROUP_REQUEST,
  payload
});

export const handleDeleteTutorGroupSuccess = (payload) => ({
  type: DELETE_TUTOR_GROUP_SUCCESS,
  payload
});

export const handleDeleteTutorGroupFailure = (payload) => ({
  type: DELETE_TUTOR_GROUP_FAILURE,
  payload
});

export function * onHandleDeleteTutorGroupRequest ({ payload }) {
  console.log('#onHandleDeleteTutorGroupRequest, payload', payload);

  try {
    console.log('#onHandleDeleteTutorGroupRequest, try block');

    const response = yield call(
      request.del,
      `/classes/${payload.classId}/tutor-groups/${payload.tutorGroupId}`
    );
    console.log('#onHandleDeleteTutorGroupRequest, response: ', response);

    return yield all([
      put(
        handleDeleteTutorGroupSuccess({ tutorGroupId: payload.tutorGroupId })
      ),
      put(push(`/teacher/classes/${payload.classId}`))
    ]);
  } catch (err) {
    console.error('Error, #onHandleDeleteClassRequest, err: ', err);

    const { status, message } = err.response.data;
    let errorMessage = '';
    if (message === 'Network Error') {
      errorMessage =
        'Network Error? Check your connection and please try again later...';
    }

    if (status === 422 || status === 400) {
      errorMessage = message;
    }

    if (err.response.status === 500) {
      errorMessage = 'Its not you, its us....... Please try again later.';
    }

    return yield put({
      type: DELETE_TUTOR_GROUP_FAILURE,
      payload: errorMessage
    });
  }
}
