import { all, call, put } from 'redux-saga/effects';

import request from '../../../lib/request';
import { setAuthToken } from '../../auth/redux';

export const DASHBOARD_REQUEST = 'teacher/dashboard/DASHBOARD_REQUEST';
export const DASHBOARD_SUCCESS = 'teacher/dashboard/DASHBOARD_SUCCESS';
export const DASHBOARD_FAILURE = 'teacher/dashboard/DASHBOARD_FAILURE';

export const handleDashboardRequest = (payload) => ({
  type: DASHBOARD_REQUEST,
  payload
});

export const handleDashboardSuccess = (payload) => ({
  type: DASHBOARD_SUCCESS,
  payload
});

export const handleDashboardFailure = (payload) => ({
  type: DASHBOARD_FAILURE,
  payload
});

export function * onHandleTeacherDashboardRequest ({ payload }) {
  console.log('#onHandleTeacherDashboardRequest, payload: ', payload);

  try {
    console.log('#onHandleTeacherDashboardRequest, try block');
    const response = yield call(request.get, '/teacher/dashboard');
    console.log('#onHandleTeacherDashboardRequest, response', response);

    // FIXME:REMOVE MOCK REQUESTS
    // const { data: mockClasses } = yield call(request.mockGet, 'classes');
    // const { data: mockQuestionSets } = yield call(request.mockGet, 'classes');
    // const { data: mockStudents } = yield call(request.mockGet, 'enrollments/students');

    const { data: { user, token, dashboard, myQuestionSets, myStudents } } = response;

    // console.log('#onHandleTeacherDashboardRequest, dashboard', dashboard);

    // console.log('#onHandleTeacherDashboardRequest, myClasses', myClasses);
    // console.log('#onHandleTeacherDashboardRequest, mockClasses', mockClasses);

    // console.log('#onHandleTeacherDashboardRequest, myQuestionSets', myQuestionSets);
    // console.log('#onHandleTeacherDashboardRequest, mockQuestionSets', mockQuestionSets);
    // console.log('#onHandleTeacherDashboardRequest, mockStudents', mockStudents);

    localStorage.setItem('token', JSON.stringify(token));
    localStorage.setItem('user', JSON.stringify(user));

    // FIXME:REMOVE MOCK DATA
    const dashboardWithMocks = {
      myClasses: dashboard,
      myStudents: myStudents,
      myQuestionSets: myQuestionSets
    };

    console.log('#onHandleTeacherDashboardRequest, dashboardWithMocks', dashboardWithMocks);
    return yield all([
      // FIXME: REMOVE MOCK PAYLOAD
      put(handleDashboardSuccess(dashboardWithMocks)),
      put(setAuthToken(token))
    ]);
  } catch (err) {
    console.error('Error, #onHandleTeacherDashboardRequest, err: ', err);
    // TODO: Add Notifications to let user know dashboard failed to get loaded and further instructions
    return yield all([put(handleDashboardFailure(err))]);
  }
}

const INITIAL_STATE = {
  isLoading: false,
  error: null,
  data: []
};

export default function dashboard (state = INITIAL_STATE, action) {
  switch (action.type) {
    case DASHBOARD_REQUEST:
      return { ...state, isLoading: true };
    case DASHBOARD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload
      };
    case DASHBOARD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
}
