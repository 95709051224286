import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Col, Divider, Row, Switch, Typography } from 'antd';

import {
  PurpleButton,
  MainLayout,
  RoundedContainer,
  SecondaryLayout,
  StyledContent
} from '../../../styles/StyledComponents';
import { StyledTitle } from '../../fields/Title';
import usePlans from './hooks/usePlans';
import LoadingSpinner from '../../../lib/components/loadingSpinner';
import SiderNav from '../../template/SiderNav';

export const StyledSwitchInput = styled(Switch)`
  &.ant-switch {
    background-color: #aa82f6;
  }

  &.ant-switch-checked {
    background-color: #8053d7;
  }
`;

// This page is for a new Admin / Owner who is looking to choose a plan, or update their current plan.  If they already have successfully on boarded, we do not
// send them on to the Setup Org Page and simply notify them that they successfully updated their plan
const OrganizationPlans = () => {
  const {
    user,
    account = { stripe: { priceId: '' } },
    accountBillingInfo,
    prices,
    isLoading,
    isMonth,
    handlePlanChange,
    setAccountProductPlan
  } = usePlans();

  const productPricesContainerColors = useMemo(
    () => (isMonth ? ['#8053d7', 'darkorange'] : ['#AA82F6', '#d7753e']),
    [isMonth]
  );

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <MainLayout hasSider>
      {/* SIDE NAV BAR */}
      <SiderNav user={user} />
      <SecondaryLayout>
        <StyledContent>
          <Row justify='center'>
            <Col>Choose A Plan</Col>
          </Row>
          <Row justify='center' style={{ textAlign: 'center' }}>
            <StyledTitle>Pick a plan for your organization</StyledTitle>
          </Row>
          <Row justify='center'>
            <Col>
              <StyledSwitchInput
                onChange={handlePlanChange}
                style={{ display: 'block', marginBottom: '20px' }}
                checkedChildren='Month'
                unCheckedChildren='Annual'
                defaultChecked
                checked={isMonth}
              />
            </Col>
          </Row>
          {/* PRICING */}
          <Row justify='center' style={{ gap: 24 }}>
            {prices?.length > 0 &&
              prices?.map((price, index) => (
                <RoundedContainer
                  key={index}
                  className='hvr-float-shadow'
                  xl={5}
                  md={12}
                  sm={16}
                  xs={24}
                  style={{
                    borderTop: `12px solid ${productPricesContainerColors[index]}`,
                    marginBottom: '1em'
                  }}
                >
                  {accountBillingInfo?.currentPriceId === price.id && (
                    <Row justify='start' style={{ textAlign: 'left' }}>
                      {/* Current Plan */}
                      <Col
                        span={24}
                        style={{
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          padding: 10,
                          paddingTop: 0,
                          paddingBottom: 5,
                          background: `${productPricesContainerColors[index]}`
                        }}
                      >
                        <Typography
                          style={{ color: 'white', fontWeight: 'bold' }}
                        >
                          Current Plan
                        </Typography>
                      </Col>
                    </Row>
                  )}
                  <Row
                    justify='start'
                    style={{ textAlign: 'left', marginBottom: '.5em' }}
                  >
                    {/* PACKAGE TITLE */}
                    <Col span={24}>
                      <span
                        style={{
                          borderBottom: `3px solid ${productPricesContainerColors[index]}`
                        }}
                      >
                        {price?.product?.name?.toUpperCase()}
                      </span>
                    </Col>
                  </Row>
                  {/* MONTHLY PRICING */}
                  <Row justify='start' style={{ textAlign: 'left' }}>
                    <Col span={24}>
                      <StyledTitle level={4}>
                        &#36;
                        {isMonth
                          ? `${price?.amount / 100}`
                          : `${price?.amount / 12 / 100}`}
                        /month
                      </StyledTitle>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <PurpleButton
                        style={{
                          backgroundColor: `${productPricesContainerColors[index]}`
                        }}
                        disabled={
                          accountBillingInfo?.currentPriceId === price.id
                        }
                        onClick={() => {
                          setAccountProductPlan({
                            priceId: price?.id,
                            productId: price?.product?.id,
                            productName: price?.product?.name?.toLowerCase(),
                            isUpdating: Boolean(
                              accountBillingInfo?.currentPriceId
                            )
                          });
                        }}
                      >
                        GET STARTED
                      </PurpleButton>
                    </Col>
                    <Divider style={{ color: '#626266' }} />
                    <Row justify='start' style={{ textAlign: 'left' }}>
                      <Col span={24}>
                        <ul>
                          <li>
                            <span style={{ fontWeight: 'bold', fontSize: 16 }}>
                              Includes
                            </span>
                          </li>
                          {price?.product?.description}
                        </ul>
                      </Col>
                    </Row>
                  </Row>
                </RoundedContainer>
              ))}
          </Row>
        </StyledContent>
      </SecondaryLayout>
    </MainLayout>
  );
};

export default OrganizationPlans;
