import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Button, Row } from 'antd';
import { StyledTitle as Title } from '../../fields/Title';
import SiderNav from '../../template/SiderNav';
import { QuestionSetList } from './question-set-list';
import {
  MainLayout,
  SecondaryLayout,
  StyledContent
} from '../../../styles/StyledComponents';
import { Link } from 'react-router-dom';
import useQuestionSetsPage from './hooks/useQuestionSetsPage';
import SearchInput from '../../fields/SearchInput';
import LoadingSpinner from '../../../lib/components/loadingSpinner';

const StyledQuestionSetsPageHeader = styled(Row)`
  width: 100%;
  margin-bottom: 35px;
`;

const StyledQuestionSetsPageHeadingsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  @media (min-width: 640px) {
    margin-bottom: 0;
  }

  & .ant-typography {
    margin-bottom: 0;
  }
`;

const StyledLinkButton = styled(Link)`
  background: transparent;
  padding: 0;
  border: none;
  color: #8053d7;
  outline: none;
  box-shadow: none;
  margin-left: 10px;

  &:hover,
  &:focus,
  &:active {
    color: #9874df;
    background: transparent;
    outline: none;
    box-shadow: none;
  }
`;

const StyledAddButton = styled(Button)`
  background: transparent;
  padding: 0;
  border: none;
  color: #8053d7;
  outline: none;
  box-shadow: none;
  margin-left: 10px;

  &:hover,
  &:focus,
  &:active {
    color: #9874df;
    background: transparent;
    outline: none;
    box-shadow: none;
  }
`;

const StyledEmptyDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const QuestionSetsPage = () => {
  const { questionSets, isLoading, searchInputConfig, searchedValue } =
    useQuestionSetsPage();

  // EMPTY STATE DESCRIPTION
  const renderedQuestionSetsEmptyDescription = useMemo(() => {
    if (!!searchedValue && searchedValue.length > 0) {
      return (
        <StyledEmptyDescription>
          <span>
            Sorry, the question set with the searched name wasn`t found!
          </span>
        </StyledEmptyDescription>
      );
    }

    return (
      <StyledEmptyDescription>
        <span>Sorry, you don't have any question sets!</span>
        <span>Please add the first question set</span>
      </StyledEmptyDescription>
    );
  }, [searchedValue]);

  return (
    <MainLayout hasSider>
      {/* SIDE NAV BAR */}
      <SiderNav />
      <SecondaryLayout>
        <StyledContent>
          <LoadingSpinner spinning={isLoading}>
            <StyledQuestionSetsPageHeader justify='space-between'>
              <StyledQuestionSetsPageHeadingsContainer>
                <Title level={2}>Question Sets</Title>
                <StyledLinkButton to='/teacher/question-sets/create-question-set'>
                  + Create new set
                </StyledLinkButton>
              </StyledQuestionSetsPageHeadingsContainer>
              <SearchInput
                {...searchInputConfig}
                placeholder='Search the question sets
              '
              />
            </StyledQuestionSetsPageHeader>
            {/* QUESTION SET LIST */}
            <QuestionSetList
              questionSets={questionSets}
              isLoading={isLoading}
              emptyDescription={renderedQuestionSetsEmptyDescription}
            />
          </LoadingSpinner>
        </StyledContent>
      </SecondaryLayout>
    </MainLayout>
  );
};

export default QuestionSetsPage;
