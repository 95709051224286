import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Button, Drawer, Row, Col } from 'antd';
import { AddStudentForm } from './AddStudentForm';

const StyledAddButton = styled(Button)`
  background: transparent;
  padding: 0;
  border: none;
  color: #8053d7;
  outline: none;
  box-shadow: none;
  margin-left: 10px;

  &:hover,
  &:focus,
  &:active {
    color: #9874df;
    background: transparent;
    outline: none;
    box-shadow: none;
  }
`;

export const AddStudentDrawer = ({ classes = [] }) => {
  console.log('AddStudentDrawer');
  const [visible, setVisible] = useState(false);

  const showDrawer = useCallback(() => {
    console.log('AddStudentDrawer#showDrawer clicked');
    setVisible(true);
  }, []);

  const onClose = useCallback(() => {
    console.log('AddStudentDrawer#onClose clicked');
    setVisible(false);
  }, []);

  return (
    <>
      <StyledAddButton onClick={showDrawer}>+ New Student</StyledAddButton>
      <Drawer
        title='Add Student'
        placement='right'
        width={window.innerWidth > 700 ? 700 : window.innerWidth}
        onClose={onClose}
        visible={visible}
      >
        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 16]}>
          <Col xl={24}>
            <AddStudentForm onClose={onClose} classes={classes} isActive={visible} />
          </Col>
        </Row>
      </Drawer>
    </>
  );
};
