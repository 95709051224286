import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Radio,
  Avatar,
  List as AList,
  Dropdown,
  Menu
} from 'antd';
import SiderNav from '../../template/SiderNav';
import { MainLayout, SecondaryLayout, StyledContainer, StyledContent, SaveButton, OrangeStrokeContainer } from '../../../styles/StyledComponents';
import { DownOutlined, RightOutlined, UserOutlined } from '@ant-design/icons';
import { StyledInput } from '../../fields/renderFields';

import { EditClassesHeader } from './EditClassesHeader';
import { userSelector } from '../../../modules/auth/redux/selectors';

const menu = (
  <Menu>
    <Menu.Item key='0'>
      <p>Everyday</p>
    </Menu.Item>
  </Menu>
);

class EditClassesPage extends Component {
  render () {
    const { myQuestionSets, isLoading, user } = this.props;
    return (
      <MainLayout hasSider>
        {/* SIDE NAV BAR */}
        <SiderNav user={user} />
        <SecondaryLayout>
          <StyledContent>

            {/* EDIT CLASSES HEADER AND SUBJECTS */}
            <EditClassesHeader />
            <Row gutter={[16, 16]} justify='center' style={{ marginTop: 20 }}>
              <Col xl={10}>
                <small>Class Name</small>
                <StyledInput style={{ marginBottom: 20 }} />
                <br />
                <small>Students</small>
                <br />
                <Avatar.Group style={{ marginBottom: 20 }}>
                  <Avatar
                    style={{ background: 'dodgerblue' }}
                    icon={<UserOutlined />}
                  />
                  <Avatar
                    style={{ background: 'green' }}
                    icon={<UserOutlined />}
                  />
                  <Avatar
                    style={{ background: 'red' }}
                    icon={<UserOutlined />}
                  />
                  <Avatar
                    style={{ background: 'pink' }}
                    icon={<UserOutlined />}
                  />
                  <Avatar style={{ background: 'purple' }}>+2</Avatar>
                </Avatar.Group>
                <br />
                <small>Tutor Group</small>
                <OrangeStrokeContainer style={{ marginBottom: 20 }}>
                  <AList
                    itemLayout='horizontal'
                    dataSource={myQuestionSets}
                    renderItem={(item, index) => {
                      return (
                        <StyledContainer style={{ padding: 15, borderLeft: '20px solid #5BBCBD' }}>
                          <AList.Item
                            key={`${item.title}-${item.id}`}
                            style={{ width: '100%' }}
                          >
                            <AList.Item.Meta
                              title={
                                <Link
                                  to={`/teacher/classes/${item.id}`}
                                >
                                  {item.title}
                                </Link>
                              }
                            />
                            <Link to={`/teacher/question-sets/${item.id}`}>
                              <RightOutlined styles={{ float: 'right' }} />
                            </Link>
                          </AList.Item>
                        </StyledContainer>
                      );
                    }}
                  />
                </OrangeStrokeContainer>
                <small>Dispatch Interval:</small>
                <br />
                <Radio.Group>
                  <Radio value='1'>After School Practice</Radio>
                  <br />
                  <Radio value='2'>In Class Quizlet</Radio>
                </Radio.Group>
                <br />
                <br />
                <Row justify='center' gutter={[16, 16]}>
                  <Col xl={8}>
                    <small>Day</small>
                    <OrangeStrokeContainer>
                      <Dropdown overlay={menu} trigger={['click']}>
                        <a
                          className='ant-dropdown-link'
                          onClick={(e) => e.preventDefault()}
                          style={{ color: '#333' }}
                        >
                          Select a Day <DownOutlined />
                        </a>
                      </Dropdown>
                    </OrangeStrokeContainer>
                  </Col>
                  <Col xl={8}>
                    <small>Time</small>
                    <OrangeStrokeContainer />
                  </Col>
                  <Col xl={8}>
                    <small>Interval</small>
                    <OrangeStrokeContainer />
                  </Col>
                </Row>
                <SaveButton>
                  SAVE
                </SaveButton>
              </Col>
              <Col xl={14} />
            </Row>
          </StyledContent>
        </SecondaryLayout>
      </MainLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: userSelector(state),
    myQuestionSets: state.app.questionSets.data,
    isLoading: state.app.questionSets.isLoading
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EditClassesPage);
