import React from 'react';
import { Layout } from 'antd';
import Header from '../template/Header';
import Footer from '../template/Footer';
import { StudentsJoinBeta } from './sections/StudentsJoinBeta';
import { StudentsWhy } from './sections/StudentsWhy';

const { Content } = Layout;

const Students = () => (
  <>
    <Layout style={{ width: '100%', backgroundColor: '#e5e5e5' }}>
      <Header />
      <Content className='site-layout' style={{ margin: '30px 0' }}>
        {/* WHAT IS TUTORD CONTAINER */}
        <br />
        <br />
        <br />
        <StudentsWhy />
        {/* JOIN BETA */}
        <StudentsJoinBeta />
      </Content>
      <Footer />
    </Layout>
  </>
);

export default Students;
