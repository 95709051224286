import React from 'react';
import { Link } from 'react-router-dom';
import { Radio } from 'antd';
import { StyledTitle as Title } from '../../fields/Title';
import { LeftOutlined } from '@ant-design/icons';
import { StyledRadio, NewButton } from '../../../styles/StyledComponents';
import { StyledButton } from '../../fields/Button';

const onChange = (e) => {
  console.log(`radio checked:${e.target.value}`);
};

export const EditClassesHeader = () => {
  return (
    <>
      <Link to='/teacher/new-tutor-group'>
        <NewButton>
                NEW TUTOR GROUP
        </NewButton>
      </Link>
      <Title level={3} style={{ fontWeight: 'bolder' }}>
        <Link to='/teacher/classes' style={{ color: '#333' }}>
          <LeftOutlined /> Edit Class
        </Link>
      </Title>

      <StyledRadio
        onChange={onChange}
        buttonStyle='solid'
        //   style={{marginBottom: 16}}
      >
        <Radio.Button
          value='chemistry'
          style={{ borderRadius: 6, border: 'none', marginRight: 10 }}
        >
                Chemistry
        </Radio.Button>
        <Radio.Button
          value='math'
          style={{ borderRadius: 6, border: 'none', marginRight: 10 }}
        >
                Math
        </Radio.Button>
        <Radio.Button
          value='literature'
          style={{ borderRadius: 6, border: 'none', marginRight: 10 }}
        >
                Literature
        </Radio.Button>
        <Link to='/teacher/new-question-set'>
          <StyledButton style={{ float: 'right', background: 'none', color: '#8053D7' }}>
                  + Add Subject
          </StyledButton>
        </Link>
      </StyledRadio>
    </>
  );
};
