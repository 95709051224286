import { all, call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import request from '../../../lib/request';
import { FORM_ERROR } from 'final-form';
import { parseFormErrors } from '../../../utils/parseFormErrors';

export const ONBOARDING_REQUEST = 'sign-up/on-boarding/ONBOARDING_REQUEST';
export const ONBOARDING_FAILURE = 'sign-up/on-boarding/ONBOARDING_FAILURE';
export const ONBOARDING_SUCCESS = 'sign-up/on-boarding/ONBOARDING_SUCCESS';

export const handleOnBoardingRequest = (payload) => ({
  type: ONBOARDING_REQUEST,
  payload,
  isLoading: true
});

export const handleOnBoardingSuccess = (payload) => ({
  type: ONBOARDING_SUCCESS,
  payload,
  isLoading: false
});

export const handleOnBoardingFailed = (payload) => ({
  type: ONBOARDING_FAILURE,
  payload,
  isLoading: false
});

export function * onHandleOnBoardingRequest ({ payload }) {
  console.log('#onHandleOnBoardingRequest, payload: ', payload);

  try {
    console.log('#onHandleOnBoardingRequest, try block');
    const response = yield call(request.post, '/accounts/on-boarding', payload);
    console.log('response: ', response);

    localStorage.setItem('user', JSON.stringify(response.data.user));

    yield all([put(handleOnBoardingSuccess(response.data.user)), put(push('/teacher/dashboard'))]);
  } catch (err) {
    console.error('#onHandleOnBoardingRequest, err: ', err);
    const { status, message, errors } = err.response.data;

    if (status === 422 || status === 400) {
      console.error('Form Errors, err.message: ', err.message);
      yield put(
        handleOnBoardingFailed({
          [FORM_ERROR]: message,
          ...parseFormErrors(errors)
        })
      );
    }
  }
}

const INITIAL_STATE = {
  isLoading: false,
  error: null
};

export default function onBoarding (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ONBOARDING_REQUEST:
      return { ...state, isLoading: true };
    case ONBOARDING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload
      };
    case ONBOARDING_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
}
