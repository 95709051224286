import { all, call, put } from 'redux-saga/effects';
import request from '../../../lib/request';
import { setAuthToken } from '../../auth/redux';
import { push } from 'connected-react-router';
import { handleVerifyEmailSuccess } from '../../sign-up/redux';
import { FORM_ERROR } from 'final-form';
import { parseFormErrors } from '../../../utils/parseFormErrors';

export const STUDENT_ENROLLMENTS_DASHBOARD_REQUEST =
  'teacher/enrollment/STUDENT_ENROLLMENTS_DASHBOARD_REQUEST';
export const STUDENT_ENROLLMENTS_DASHBOARD_SUCCESS =
  'teacher/enrollment/STUDENT_ENROLLMENTS_DASHBOARD_SUCCESS';
export const STUDENT_ENROLLMENTS_DASHBOARD_FAILURE =
  'teacher/enrollment/STUDENT_ENROLLMENTS_DASHBOARD_FAILURE';

export const STUDENT_PROFILE_REQUEST =
  'teacher/enrollment/STUDENT_PROFILE_REQUEST';
export const STUDENT_PROFILE_SUCCESS =
  'teacher/enrollment/STUDENT_PROFILE_SUCCESS';
export const STUDENT_PROFILE_FAILURE =
  'teacher/enrollment/STUDENT_PROFILE_FAILURE';

export const STUDENT_PROFILE_GRAPH_REQUEST =
  'teacher/enrollment/STUDENT_PROFILE_GRAPH_REQUEST';
export const STUDENT_PROFILE_GRAPH_SUCCESS =
  'teacher/enrollment/STUDENT_PROFILE_GRAPH_SUCCESS';
export const STUDENT_PROFILE_GRAPH_FAILURE =
  'teacher/enrollment/STUDENT_PROFILE_GRAPH_FAILURE';

export const CREATE_STUDENT_REQUEST =
  'teacher/enrollment/CREATE_STUDENT_REQUEST';
export const CREATE_STUDENT_SUCCESS =
  'teacher/enrollment/CREATE_STUDENT_SUCCESS';
export const CREATE_STUDENT_FAILURE =
  'teacher/enrollment/CREATE_STUDENT_FAILURE';

export const EDIT_STUDENT_REQUEST = 'teacher/enrollment/EDIT_STUDENT_REQUEST';
export const EDIT_STUDENT_SUCCESS = 'teacher/enrollment/EDIT_STUDENT_SUCCESS';
export const EDIT_STUDENT_FAILURE = 'teacher/enrollment/EDIT_STUDENT_FAILURE';

// handleStudentsDashboardRequest
export const handleStudentsDashboardRequest = (payload) => ({
  type: STUDENT_ENROLLMENTS_DASHBOARD_REQUEST,
  payload
});

export const handleStudentsDashboardSuccess = (payload) => ({
  type: STUDENT_ENROLLMENTS_DASHBOARD_SUCCESS,
  payload
});

export const handleStudentsDashboardFailure = (payload) => ({
  type: STUDENT_ENROLLMENTS_DASHBOARD_FAILURE,
  payload
});

// Get Student profile by Id

export const handleStudentProfileRequest = (payload) => ({
  type: STUDENT_PROFILE_REQUEST,
  payload
});

export const handleStudentProfileSuccess = (payload) => ({
  type: STUDENT_PROFILE_SUCCESS,
  payload
});

export const handleStudentProfileFailure = (payload) => ({
  type: STUDENT_PROFILE_FAILURE,
  payload
});

// Get Student profile graph by Id

export const handleStudentProfileGraphRequest = (payload) => ({
  type: STUDENT_PROFILE_GRAPH_REQUEST,
  payload
});

export const handleStudentProfileGraphSuccess = (payload) => ({
  type: STUDENT_PROFILE_GRAPH_SUCCESS,
  payload
});

export const handleStudentProfileGraphFailure = (payload) => ({
  type: STUDENT_PROFILE_GRAPH_FAILURE,
  payload
});

// Create new student

export const handleCreateStudentRequest = (payload) => ({
  type: CREATE_STUDENT_REQUEST,
  payload
});

export const handleCreateStudentSuccess = (payload) => ({
  type: CREATE_STUDENT_SUCCESS,
  payload
});

export const handleCreateStudentFailure = (payload) => ({
  type: CREATE_STUDENT_FAILURE,
  payload
});

// edit students
export const handleEditStudentProfileRequest = (payload) => ({
  type: EDIT_STUDENT_REQUEST,
  payload
});

export const handleEditStudentProfileSuccess = (payload) => ({
  type: EDIT_STUDENT_SUCCESS,
  payload
});

export const handleEditStudentProfileFailure = (payload) => ({
  type: EDIT_STUDENT_FAILURE,
  payload
});

export function * onHandleStudentsDashboardRequest () {
  console.log('#onHandleStudentsDashboardRequest');

  try {
    console.log('#onHandleStudentsDashboardRequest, try block');
    const response = yield call(request.get, '/enrollments/students');
    console.log('#onHandleStudentsDashboardRequest, response: ', response);

    const { user, token, students, enrollments } = response.data;

    localStorage.setItem('token', JSON.stringify(token));
    localStorage.setItem('user', JSON.stringify(user));

    yield all([
      put(handleStudentsDashboardSuccess({ students, enrollments })),
      put(setAuthToken(token))
    ]);
  } catch (err) {
    console.error('Error, #onHandleStudentsDashboardRequest, err: ', err);
  }
}

export function * onHandleStudentProfileRequest ({ payload: studentId }) {
  console.log('#onHandleStudentProfileRequest, studentId: ', studentId);

  try {
    console.log('#onHandleStudentProfileRequest, try block');
    const response = yield call(
      request.get,
      `/enrollments/students/${studentId}`
    );
    console.log('#onHandlestudentProfileRequest, response: ', response);

    const { student, classWithTutorGroup, tutorGroupSessions } = response.data;

    console.log('#onHandleStudentProfileRequest, student: ', student);
    console.log('#onHandleStudentProfileRequest, classWithTutorGroup: ', classWithTutorGroup);

    yield all([
      put(
        handleStudentProfileSuccess({
          enrollments: [],
          classWithTutorGroup,
          tutorGroupSessions,
          student
        })
      )
    ]);
  } catch (err) {
    console.error('Error, #onHandleStudentProfileRequest, err: ', err);
    yield put(handleStudentProfileFailure(err.message));
  }
}

export function * onHandleStudentProfileGraphRequest ({
  payload: { studentId, sessionId, tutorGroupId }
}) {
  console.log('#onHandleStudentProfileGraphRequest, studentId: ', studentId);

  try {
    console.log('#onHandleStudentProfileGraphRequest, try block');
    const response = yield call(
      request.post,
      'teacher/student/graphAndTimeline',
      { studentId, sessionId, tutorGroupId }
    );

    const { timelineData, graphData } = response.data;

    yield all([
      put(
        handleStudentProfileGraphSuccess({
          timelineData,
          graphData
        })
      )
    ]);
  } catch (err) {
    console.error('Error, #onHandleStudentProfileGraphRequest, err: ', err);
    yield put(handleStudentProfileGraphFailure(err.message));
  }
}

export function * onHandleEditStudentProfileRequest ({ payload }) {
  console.log('#onHandleEditStudentProfileRequest, payload: ', payload);

  try {
    console.log('#onHandleEditStudentProfileRequest, try block');
    const response = yield call(
      request.put,
      `/enrollments/students/${payload.id}`,
      payload
    );
    console.log('#onHandleEditStudentProfileRequest, response: ', response);

    const { student } = response.data;

    console.log('#onHandleEditStudentProfileRequest, student: ', student);

    yield all([put(handleEditStudentProfileSuccess(student))]);
  } catch (err) {
    console.error('Error, #onHandleEditStudentProfileRequest, err: ', err);
    const { message } = err;
    yield put(
      handleEditStudentProfileFailure({ message, ...parseFormErrors(err) })
    );
  }
}

export function * onHandleCreateStudentRequest ({ payload }) {
  console.log('#onHandleCreateStudentRequest, payload: ', payload);

  try {
    const response = yield call(
      request.post,
      '/accounts/create/student',
      payload
    );
    const { student, enrollments } = response.data;

    yield all([
      put(handleCreateStudentSuccess({ student, enrollments })),
      put(push('/teacher/students'))
    ]);
  } catch (err) {
    console.error('Error, #onHandleStudentProfileRequest, err: ', err);
    const { status, message, errors } = err.response.data;
    if (message === 'Network Error') {
      return yield put({
        type: CREATE_STUDENT_SUCCESS,
        payload: {
          [FORM_ERROR]:
            'Network Error? Check your connection and please try again later...'
        }
      });
    }

    if (status === 409) {
      return yield put({
        type: CREATE_STUDENT_SUCCESS,
        payload: {
          [FORM_ERROR]: 'Already exists...',
          ...parseFormErrors(errors)
        }
      });
    }

    if (status === 422 || status === 400) {
      return yield put({
        type: CREATE_STUDENT_SUCCESS,
        payload: {
          [FORM_ERROR]: message,
          ...parseFormErrors(errors)
        }
      });
    }

    if (err.response.status === 500) {
      return yield put({
        type: CREATE_STUDENT_SUCCESS,
        payload: {
          [FORM_ERROR]: 'Its not you, its us....... Please try again later.'
        }
      });
    }
  }
}

const INITIAL_STATE = {
  isLoading: false,
  data: [],
  error: null,
  emailExists: false
};

export default function students (state = INITIAL_STATE, action) {
  switch (action.type) {
    case CREATE_STUDENT_REQUEST:
    case EDIT_STUDENT_REQUEST:
    case STUDENT_ENROLLMENTS_DASHBOARD_REQUEST:
      return { ...state, isLoading: true };
    case STUDENT_ENROLLMENTS_DASHBOARD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.students,
        enrollments: action.payload.enrollments
      };
    case STUDENT_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        profile: action.payload.student,
        enrollments: action.payload.enrollments,
        classWithTutorGroup: action.payload.classWithTutorGroup,
        tutorGroupSessions: action.payload.tutorGroupSessions
      };

    case STUDENT_PROFILE_GRAPH_REQUEST:
      return {
        ...state,
        isGraphLoading: true
      };
    case STUDENT_PROFILE_GRAPH_SUCCESS:
      return {
        ...state,
        isGraphLoading: false,
        timelineData: action.payload.timelineData,
        graphData: action.payload.graphData
      };
    case STUDENT_PROFILE_GRAPH_FAILURE:
      return {
        ...state,
        isGraphLoading: false
      };
    case EDIT_STUDENT_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    case CREATE_STUDENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: [...state.data, action.payload.student],
        enrollments: [...state.enrollments, ...action.payload.enrollments]
      };
    case STUDENT_ENROLLMENTS_DASHBOARD_FAILURE:
    case STUDENT_PROFILE_FAILURE:
    case CREATE_STUDENT_FAILURE:
    case EDIT_STUDENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
}
