import React from 'react';
import { Link } from 'react-router-dom';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { Field, Form as FForm } from 'react-final-form';
import { Row, Col, Button, Form as AForm, Divider } from 'antd';
import styled from 'styled-components';

import { dispatch, promiseListener } from '../../store';
import {
  SIGNIN_REQUEST,
  SIGNIN_SUCCESS,
  SIGNIN_FAILURE
} from '../../modules/auth/redux';
import { EmailInputControl, PasswordControl } from '../fields/Fields';
import { StyledButton, StyledGoogleButton } from '../fields/Button';
import * as validations from '../../utils/validations';
import GoogleIcon from '../../assets/google.png';
import { FORM_ERROR } from 'final-form';
import { handleShowToast } from '../../modules/notification/redux';

const StyledSignInFormContainer = styled.div`
  width: 100%;
  text-align: left;

  & .error {
    width: max-content;
    text-align: left;
    display: inline-block;
    margin-bottom: 15px;
  }
`;

export const SignInForm = ({ isLoading }) => (
  <MakeAsyncFunction
    listener={promiseListener}
    start={SIGNIN_REQUEST}
    resolve={SIGNIN_SUCCESS}
    reject={SIGNIN_FAILURE}
    getError={(action) => {
      console.log('SignInForm#getError, action: ', action);
      const payload = action.payload;
      const error = payload.email ? payload.email : payload.orgName ? payload.orgName
        : payload[FORM_ERROR] ? payload[FORM_ERROR] : 'something went wrong';
      dispatch(handleShowToast({ message: error, type: 'error' }));
    }}
  >
    {(onSubmit) => (
      <FForm
        name='sign-in-form'
        style={{ textAlign: 'center' }}
        onSubmit={onSubmit}
        render={({
          submitError,
          handleSubmit,
          submitting,
          hasValidationErrors,
          hasSubmitErrors,
          dirtySinceLastSubmit
        }) => (
          <StyledSignInFormContainer>
            {isLoading && <div>Loading...</div>}
            {submitError && <span className='error'>{submitError}</span>}
            <AForm onFinish={handleSubmit}>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col lg={12} sm={20} xs={24}>
                  <StyledGoogleButton>
                    <img src={GoogleIcon} height={20} width={20} alt='' />
                    Sign in with Google
                  </StyledGoogleButton>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col lg={12} sm={20} xs={24}>
                  <Divider orientation='center'>
                    <small>or Sign in with Email</small>
                  </Divider>
                  <small style={{ display: 'flex', justifyContent: 'left' }}>Email*</small>
                  <Field
                    name='email'
                    component={EmailInputControl}
                    type='text'
                    placeholder='Email*'
                    autoFocus
                    validate={validations.composeValidators(
                      validations.required,
                      validations.email
                    )}
                  />
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col lg={12} sm={20} xs={24}>
                  <small style={{ display: 'flex', justifyContent: 'left' }}>Password*</small>
                  <Field
                    name='password'
                    component={PasswordControl}
                    type='text'
                    placeholder='Password*'
                    validate={validations.required}
                  />
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col lg={12} sm={20} xs={24}>
                  <small
                    style={{
                      display: 'flex',
                      justifyContent: 'left',
                      marginBottom: 20
                    }}
                  >
                    No account? Please sign-up{' '}
                    <Link
                      to='/sign-up'
                      style={{ marginLeft: 5, textDecoration: 'underline' }}
                    >
                      here
                    </Link>
                  </small>
                  <StyledButton
                    size='large'
                    type='primary'
                    htmlType='submit'
                    disabled={
                      submitting ||
                      ((hasSubmitErrors || hasValidationErrors) &&
                        !dirtySinceLastSubmit)
                    }
                    loading={isLoading || submitting}
                    block
                  >
                    Sign In
                  </StyledButton>
                </Col>
              </Row>
            </AForm>
          </StyledSignInFormContainer>
        )}
      />
    )}
  </MakeAsyncFunction>
);
