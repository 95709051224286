import React from 'react';
import { Col, List, Row, Spin, Rate, Empty } from 'antd';
import { Link } from 'react-router-dom';
import loadingSpinner from '../../../lib/components/loadingSpinner';
import {
  OrangeStrokeContainer,
  StyledContainer
} from '../../../styles/StyledComponents';
import { FireOutlined, RightOutlined } from '@ant-design/icons';
import { Field as FField } from 'react-final-form';
import { RatingControl } from '../../fields/Fields';
import { StyledTitle, StyledTitle as Title } from '../../fields/Title';

const { SpinProps } = Spin;
const { Item } = List;

// ICONS FOR DIFFICULTY
const customIcons = {
  1: <FireOutlined />,
  2: <FireOutlined />,
  3: <FireOutlined />,
  4: <FireOutlined />,
  5: <FireOutlined />
};

export const QuestionSetList = ({
  questionSets,
  isLoading,
  emptyDescription
}) => {
  console.log('QuestionSetList, questionSets: ', questionSets);
  return (
    <List
      loading={isLoading}
      dataSource={questionSets}
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={emptyDescription}
          />
        )
      }}
      renderItem={(qSet) => (
        <Row>
          <Col xs={22} xl={14}>
            <StyledContainer
              style={{ borderLeft: '20px solid #D7753E', padding: 10 }}
            >
              <Link to={`/teacher/edit-question-set/${qSet.id}`}>
                <Row gutter={16} align='middle' style={{ margin: 0 }}>
                  <Col flex={1}>
                    <Item>
                      <Title level={5}>{qSet.title}</Title>
                    </Item>
                  </Col>
                  <Col style={{ marginTop: -10 }}>
                    <div
                      style={{
                        background: '#fff',
                        marginBottom: -10,
                        marginLeft: 10,
                        position: 'relative',
                        width: 50
                      }}
                    >
                      <small>Difficulty</small>
                    </div>
                    <OrangeStrokeContainer style={{ padding: 5 }}>
                      <Rate
                        disabled
                        value={qSet.questionSetDifficulty}
                        character={({ index }) => {
                          return customIcons[index + 1];
                        }}
                      />
                    </OrangeStrokeContainer>
                  </Col>
                  <Col>
                    <RightOutlined style={{ float: 'right' }} />
                  </Col>
                </Row>
              </Link>
            </StyledContainer>
          </Col>
        </Row>
      )}
    />
  );
};
