import React, { useEffect, useState } from 'react';
import arrayMutators from 'final-form-arrays';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { Field as FField, Form as FForm } from 'react-final-form';
import { FieldArray as FFieldArray } from 'react-final-form-arrays';
import { Row, Col, Empty, Space, Form as AForm } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { dispatch, promiseListener } from '../../../../store';
import { EditableTitleControl } from '../../../fields/Fields';

import {
  CREATE_QUESTION_SET_FAILURE,
  CREATE_QUESTION_SET_REQUEST,
  CREATE_QUESTION_SET_SUCCESS
} from '../../../../modules/question-set/redux';
import QuestionSetIcon from '../../../../assets/question-set-image.svg';
import { QuestionItem } from './QuestionItem';
import {
  StyledContainer,
  AddQuestionButton,
  DoneButton,
  StyledButton
} from '../../../../styles/StyledComponents';
import LoadingSpinner from '../../../../lib/components/loadingSpinner';
import { handleShowToast } from '../../../../modules/notification/redux';
import { FORM_ERROR } from 'final-form';

export const CreateQuestionSetForm = ({ isLoading }) => {
  return (
    <MakeAsyncFunction
      listener={promiseListener}
      start={CREATE_QUESTION_SET_REQUEST}
      resolve={CREATE_QUESTION_SET_SUCCESS}
      reject={CREATE_QUESTION_SET_FAILURE}
      getError={(action) => {
        const payload = action.payload;
        const error = payload[FORM_ERROR] ? payload[FORM_ERROR] : 'something went wrong';
        dispatch(handleShowToast({ message: error, type: 'error' }));
      }}
    >
      {(onSubmit) => (
        <FForm
          name='create-question-set'
          initialValues={{
            title: 'New Question Set',
            questions: [
              {
                questionFormat: 'multiple-choice',
                questionDifficulty: 3
              }
            ]
          }}
          mutators={{
            ...arrayMutators
          }}
          onSubmit={onSubmit}
          render={({
            submitError,
            handleSubmit,
            form: {
              mutators: { push, pop, removeBatch }
            },
            submitting,
            hasValidationErrors,
            hasSubmitErrors,
            dirtySinceLastSubmit,
            values
          }) => (
            <div>
              <LoadingSpinner spinning={isLoading}>
                <AForm onFinish={handleSubmit}>
                  <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 16]}>
                    <Col style={{ width: '100%' }}>
                      <FField
                        name='title'
                        component={EditableTitleControl}
                        tooltip='What is a Molecule?'
                        level={3}
                      // validate={validations.required}
                      />
                      {values.questions.length}/15 questions added
                    </Col>
                  </Row>
                  <Space direction='vertical' style={{ width: '100%' }}>
                    <FFieldArray name='questions'>
                      {({ fields, meta }) =>
                        fields.map((name, index) => (
                          <StyledContainer
                            key={name}
                            style={{
                              marginTop: 20,
                              width: '100%',
                              padding: '40px 50px '
                            }}
                          >
                            <QuestionItem
                              name={name}
                              index={index}
                              push={push}
                              numOfQuestions={fields.length}
                              remove={fields.remove}
                              removeBatch={removeBatch}
                            />
                          </StyledContainer>
                        ))}
                    </FFieldArray>
                    {values.questions.length > 0 &&
                    values.questions.length < 15 && (
                      <Row gutter={[16, 16]} justify='center'>
                        <Col span={12}>
                          <AddQuestionButton
                            block
                            type='dashed'
                            style={{}}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              push('questions', null);
                            }}
                          >
                            <PlusOutlined /> Question
                          </AddQuestionButton>
                        </Col>
                        <Col span={12}>
                          <DoneButton
                            type='primary'
                            htmlType='submit'
                            disabled={
                              submitting ||
                              ((hasSubmitErrors || hasValidationErrors) &&
                                !dirtySinceLastSubmit)
                            }
                            loading={isLoading || submitting}
                            block
                          >
                            Done!
                          </DoneButton>
                        </Col>
                      </Row>
                    )}
                    {values.questions.length === 15 && (
                      <div>
                        <DoneButton
                          type='primary'
                          htmlType='submit'
                          disabled={
                            submitting ||
                          ((hasSubmitErrors || hasValidationErrors) &&
                            !dirtySinceLastSubmit)
                          }
                          loading={isLoading || submitting}
                          block
                        >
                        Done!
                        </DoneButton>
                      </div>
                    )}
                  </Space>
                  <div>
                    {values.questions.length === 0 && (
                      <Empty
                        image={QuestionSetIcon}
                        imageStyle={{ height: 90 }}
                        description=''
                      >
                        <AForm.Item>
                          <StyledButton
                            type='primary'
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              push('questions', null);
                            }}
                          >
                          Create my first question!
                          </StyledButton>
                        </AForm.Item>
                      </Empty>
                    )}
                  </div>
                  {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                </AForm>
              </LoadingSpinner>
            </div>
          )}
        />
      )}
    </MakeAsyncFunction>
  );
};
