import React from 'react';
import {
  VerticalTimeline,
  VerticalTimelineElement
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {
  BulbFilled,
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons';
import { MessageContexts } from '../../../../utils/messageContexts';
import { getFormattedDateFromTimestamp } from '../../../../utils/date';
import HandsOn from '../../../../assets/learningStyleIcons/handsOn.svg';
import Auditory from '../../../../assets/learningStyleIcons/audio.svg';
import Notsure from '../../../../assets/learningStyleIcons/notsure.svg';
import Visual from '../../../../assets/learningStyleIcons/visual.svg';
import Reading from '../../../../assets/learningStyleIcons/reading.svg';
import { Img } from '../../../../styles/StyledComponents';

const StudentTimelineGraph = ({ timelineData }) => {
  const getIcon = (messageContext) => {
    switch (messageContext) {
      case MessageContexts.QUESTION_STATEMENT:
        return <QuestionCircleOutlined style={{ color: 'goldenrod' }} />;
      case MessageContexts.CORRECT_ANSWER_RESPONSE:
        return <CheckCircleOutlined style={{ color: 'green' }} />;
      case MessageContexts.INCORRECT_ANSWER_RESPONSE:
        return <CloseCircleOutlined style={{ color: 'red' }} />;
      case MessageContexts.ANSWER_ATTEMPT:
        return <InfoCircleOutlined style={{ color: 'goldenrod' }} src="" />;
      case MessageContexts.HANDS_ON_HINT:
        return (
          <Img
            src={HandsOn}
            style={{ position: 'absolute', height: 25, width: 25 }}
          />
        );
      case MessageContexts.AUDITORY_HINT:
        return (
          <Img
            src={Auditory}
            style={{ position: 'absolute', height: 25, width: 25 }}
          />
        );

      case MessageContexts.READING_HINT:
        return (
          <Img
            src={Reading}
            style={{ position: 'absolute', height: 25, width: 25 }}
          />
        );

      case MessageContexts.VISUAL_HINT:
        return (
          <Img
            src={Visual}
            style={{ position: 'absolute', height: 25, width: 25 }}
          />
        );

      default:
        return <BulbFilled style={{ color: 'gold' }} />;
    }
  };
  const timelineIcon = (messageContext) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          width: '0%',
          height: '100%'
        }}
      >
        {getIcon(messageContext)}
      </div>
    );
  };
  const iconStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: 'none'
  };
  const contentStyle = {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'end'
  };
  const contentArrowStyle = { display: 'none' };

  return (
    <div
      style={{
        maxHeight: '100vh',
        overflowY: 'scroll',
        width: '90%',
        overflowX: 'clip',
        '::-webkit-scrollbar>*': {
          width: 0,
          height: 0
        }
      }}
    >
      <VerticalTimeline animate>
        {timelineData?.map((data, index) => (
          <VerticalTimelineElement
            key={index}
            className={
              data?.messageType !== MessageContexts.SYSTEM_MESSAGE
                ? 'leftVerticalTimelineElement'
                : 'rightVerticalTimelineElement'
            }
            contentStyle={contentStyle}
            contentArrowStyle={contentArrowStyle}
            visible
            iconStyle={iconStyle}
            icon={timelineIcon(data?.messageContext)}
          >
            {
              <div
                className={`${
                  data?.messageType !== MessageContexts.SYSTEM_MESSAGE
                    ? 'leftAlignedText'
                    : 'rightAlignedText'
                }`}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  width: '100%'
                }}
              >
                <span style={{ fontSize: 12 }}>{data?.messageBody} </span>
                <span style={{ fontSize: 10 }}>
                  {data?.createdAt &&
                    getFormattedDateFromTimestamp(Number(data?.createdAt))}
                </span>
              </div>
            }
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
    </div>
  );
};
export default StudentTimelineGraph;
