import emailValidator from 'email-validator';
import validator from 'validator';

export const phoneNumber = (value) => {
  return value && !validator.isMobilePhone(value)
    ? 'Invalid phone number'
    : undefined;
};

export const email = (value) => {
  return value && !emailValidator.validate(value)
    ? 'Invalid email address'
    : undefined;
};

export const requiredArray = (value) =>
  value && value.length > 0 ? undefined : 'Required';

export const emailArray = (values) => {
  let validationResults;
  values.every((value) => {
    if (!emailValidator.validate(value)) {
      validationResults = 'Invalid email address';
      return false;
    }
    return true;
  });

  return values && validationResults;
};

export const edu = (value) => {
  return value && !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.+-]+\.edu$/.test(value)
    ? 'You must be actively enrolled in a College or University.'
    : undefined;
};

export const validHttpsLink = (value) => {
  return value &&
    !/^https:\/\/(?:www\.)?[a-zA-Z0-9_\-~!$&'()*+,;=.]+(?:\.[a-zA-Z]{2,})+(?::\d+)?(?:\/[^\s?]*)?(?:\?[^\s]*)?$/.test(
      value.url
    )
    ? 'Only valid https links are allowed'
    : undefined;
};

export const yahoo = (value) => {
  return value && /.+@yahoo\.com/.test(value)
    ? 'Really? You still Yahoo? Ok...'
    : undefined;
};

export const mustBeNumber = (value) =>
  isNaN(value) ? 'Must be a number' : undefined;

export const required = (value) => {
  return value || typeof value === 'number' ? undefined : 'Required';
};

export const requiredWithCustomErrorText = (errorText) => (value) => {
  const renderedErrorText = errorText ?? 'Required';

  return value || typeof value === 'number' ? undefined : renderedErrorText;
};

export const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;
export const maxLength15 = maxLength(15);
export const minLength = (min) => (value) =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;

export const minLength2 = minLength(2);

export const minLength6 = minLength(6);
export const maxLength6 = maxLength(6);

export const minLength10 = minLength(10);
export const maxLength10 = maxLength(10);

export const number = (value) => {
  return value && isNaN(Number(value)) ? 'Must be a number' : undefined;
};

export const minValue = (min) => (value) =>
  value && value < min ? `Must be at least ${min}` : undefined;

export const minValue13 = minValue(13);

export const alphaNumeric = (value) => {
  return value && /[^a-zA-Z0-9 ]/i.test(value)
    ? 'Only alphanumeric characters'
    : undefined;
};

export const passwordsMustMatch = (value, allValues) => {
  console.log('value: ', value);
  console.log('allValues: ', allValues);
  return value !== allValues.password ? 'Passwords do not match' : undefined;
};

export const getValidator = (isRequired) =>
  isRequired ? (value) => (value ? undefined : 'Required') : () => {};

export const composeValidators =
  (...validators) =>
    (value, allValues, fieldState) => {
      return validators.reduce(
        (error, validator) =>
          error ||
        (validator !== null && validator(value, allValues, fieldState)),
        undefined
      );
    };

export const simpleMemoize = (fn) => {
  let lastArg;
  let lastResult;
  return (arg) => {
    if (arg !== lastArg) {
      lastArg = arg;
      lastResult = fn(arg);
    }
    return lastResult;
  };
};
