import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Button, Row, Col, Typography } from 'antd';
import { Field as FField } from 'react-final-form';
import { FieldArray as FFieldArray } from 'react-final-form-arrays';
import {
  RatingControl,
  FieldsControl,
  InputControlWithExtraButton,
  SwitchInputControl,
  CustomSimpleSelectControl
} from '../../../fields/Fields';
import { FireOutlined } from '@ant-design/icons';

import { requiredWithCustomErrorText } from '../../../../utils/validations';

import { Conditional } from '../../../fields/Conditional';
import { EditQuestionHintItem } from './EditQuestionHintItem';
import { EditAnswerItem } from './EditAnswerItem';

const { Title } = Typography;

// ICONS FOR DIFFICULTY
const customIcons = {
  1: <FireOutlined />,
  2: <FireOutlined />,
  3: <FireOutlined />,
  4: <FireOutlined />,
  5: <FireOutlined />
};

const questionTypeOptions = [
  {
    label: 'Multiple Choice',
    value: 'multiple-choice'
  },
  {
    label: 'True or False',
    value: 'true-false'
  }
];

const StyledButton = styled(Button)`
  background-color: #8053d7;
  border: none;
  border-radius: 20px;
  font-weight: bolder;
  color: #fff;
  height: 40px;
  min-width: 78px;

  &:hover {
    background-color: #9874df;
    border: none;
  }

  &:focus {
    background-color: #8053d7;
  }
`;

const StyledAddHintButton = styled(Button)`
  color: #8053d7;
  padding: 0 7px 0 0;
  background: transparent;
  height: auto;

  &:hover,
  &:active,
  &:focus {
    color: #9874df;
    background: transparent;
  }
`;
const StyledAnswerButton = styled(Button)`
  padding: 0;
  text-transform: uppercase;
  background: transparent;
  font-weight: 600;

  color: ${({ accent }) => (accent ? '#EB5757' : '#9874df')};

  &:focus {
    color: ${({ accent }) => (accent ? '#EB5757' : '#9874df')};
    background: transparent;
  }

  &:hover {
    color: ${({ accent }) => (accent ? '#EB5757' : '#9874df')};
    background: transparent;
  }
`;

const StyledTextButton = styled(Button)`
  padding: 0;
  text-transform: uppercase;
  background: transparent;
  font-weight: 600;

  color: ${({ accent }) => (accent ? '#9C9696' : '#cccbca')};

  &:focus {
    color: ${({ accent }) => (accent ? '#9C9696' : '#cccbca')};
    background: transparent;
  }

  &:hover {
    color: ${({ accent }) => (accent ? '#EB5757' : '#9874df')};
    background: transparent;
  }
`;

const StyledQuestionContentContainer = styled.div`
  display: block;
  width: 100%%;

  padding-bottom: ${({ withBorder }) => (withBorder ? '46px' : '0px')};
  border-bottom: ${({ withBorder }) =>
    withBorder ? '1px dashed #BDBDBD' : 'none'};
`;

const StyledQuestionInfoContainer = styled(Row)`
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin: 0 !important;
`;

export const EditQuestionItem = ({
  name,
  index,
  push,
  remove,
  onSubmit,
  numOfQuestions,
  removeBatch
}) => {
  const [answersVisible, setVisible] = useState(false);

  const toggleAnswers = useCallback(() => {
    setVisible((prev) => !prev);
  }, []);

  const hintCountButtonText = useCallback((condition, count) => {
    let buttonTxt = '+ Add Hint';

    if (condition && count > 0) {
      buttonTxt = count > 1 ? `(${count} Hints)` : `(${count} Hint)`;
    }

    return buttonTxt;
  }, []);

  const handleButtonDone = useCallback(
    (condition) => () => {
      if (condition) {
        // FIXME: ADD the real submit for true/false question
        onSubmit?.();
        console.log('DumbOnSubmit');

        return;
      }

      toggleAnswers();
    },
    [onSubmit, toggleAnswers]
  );

  const removeEntities = useCallback(
    (name, indxs) => (value) => {
      if (value === 'true-false') {
        removeBatch(name, indxs);
        setVisible(false);
      }
    },
    [removeBatch]
  );

  return (
    <FieldsControl
      names={[
        `${name}.possibleAnswers`,
        `${name}.questionFormat`,
        `${name}.hints`
      ]}
    >
      {(fieldsState) => {
        const possibleAnswers =
          fieldsState[`${name}.possibleAnswers`].input.value;
        const questionFormat =
          fieldsState[`${name}.questionFormat`].input.value;

        const hints = fieldsState[`${name}.hints`].input.value;

        const answerLimit = 5;

        const hintsLimit = 5;

        let selectedHintTypes = [];

        let possibleAnswersIndexes = [];

        if (Array.isArray(hints)) {
          hints.forEach((item) => {
            selectedHintTypes = [...selectedHintTypes, item?.learnerType];
          });
        }

        if (Array.isArray(possibleAnswers)) {
          possibleAnswers.forEach((_, index) => {
            possibleAnswersIndexes = [...possibleAnswersIndexes, index];
          });
        }

        return (
          <>
            {' '}
            <StyledQuestionContentContainer
              withBorder={
                (possibleAnswers.length > 0 && answersVisible) ||
                questionFormat === 'true-false'
              }
            >
              <Row
                style={{ width: '100%' }}
                align='start'
                justify='space-between'
              >
                <Row style={{ flex: 1 }}>
                  <StyledQuestionInfoContainer
                    gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 16]}
                  >
                    <Col xl={8} style={{ paddingLeft: 0 }}>
                      <Title level={5} style={{ fontWeight: 'bolder' }}>
                        Question {index + 1}
                      </Title>
                      <FField
                        name={`${name}.questionStatement`}
                        type='text'
                        placeholder='EX: What is a Molecule?'
                        validate={requiredWithCustomErrorText(
                          'Please add question'
                        )}
                      >
                        {(props) => (
                          <InputControlWithExtraButton
                            {...props}
                            button={
                              <StyledAddHintButton
                                type='text'
                                disabled={
                                  (questionFormat === 'true-false' &&
                                    hints.length === hintsLimit) ||
                                  (questionFormat !== 'true-false' &&
                                    (hints.length === hintsLimit ||
                                      !answersVisible))
                                }
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  push(`${name}.hints`, null);
                                }}
                              >
                                {hintCountButtonText(
                                  (questionFormat === 'true-false' &&
                                    hints.length === hintsLimit) ||
                                    (questionFormat !== 'true-false' &&
                                      (hints.length === hintsLimit ||
                                        !answersVisible)),
                                  hints.length
                                )}
                              </StyledAddHintButton>
                            }
                            marginBottom={0}
                          />
                        )}
                      </FField>
                    </Col>
                    <Col xl={6}>
                      <Title level={5} style={{ fontWeight: 'bolder' }}>
                        Format
                      </Title>
                      <FField
                        name={`${name}.questionFormat`}
                        defaultValue='multiple-choice'
                        component={CustomSimpleSelectControl}
                        options={questionTypeOptions}
                        removeBatch={removeEntities(
                          `${name}.possibleAnswers`,
                          possibleAnswersIndexes
                        )}
                        validate={requiredWithCustomErrorText(
                          'Question format is required'
                        )}
                      />
                    </Col>
                    <Col xl={4}>
                      <Title level={5} style={{ fontWeight: 'bolder' }}>
                        Difficulty
                      </Title>
                      <FField
                        name={`${name}.questionDifficulty`}
                        defaultValue={3}
                        customIcons={customIcons}
                        component={RatingControl}
                      />
                    </Col>
                    <Conditional conditional={questionFormat === 'true-false'}>
                      <Col xl={4}>
                        <Title level={5} style={{ fontWeight: 'bolder' }}>
                          True/False
                        </Title>
                        <FField
                          name={`${name}.possibleAnswers[0].isCorrect`}
                          component={SwitchInputControl}
                        />
                      </Col>
                    </Conditional>
                  </StyledQuestionInfoContainer>
                  <Conditional
                    conditional={
                      hints.length > 0 &&
                      (questionFormat === 'true-false' || answersVisible)
                    }
                  >
                    <StyledQuestionInfoContainer
                      gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 16]}
                    >
                      <Col xl={24} xs={20} style={{ marginBottom: 20 }}>
                        <FFieldArray name={`${name}.hints`}>
                          {({ fields, meta }) =>
                            fields.map((name, index) => (
                              <div key={name}>
                                <EditQuestionHintItem
                                  name={name}
                                  index={index}
                                  remove={fields.remove}
                                  selectedHintTypes={selectedHintTypes}
                                />
                              </div>
                            ))}
                        </FFieldArray>
                      </Col>
                    </StyledQuestionInfoContainer>
                  </Conditional>
                </Row>
                <Conditional conditional={questionFormat !== 'true-false'}>
                  <Col style={{ paddingTop: 37 }}>
                    <StyledAnswerButton type='text' onClick={toggleAnswers}>
                      {answersVisible === false ? 'Show' : 'Hide'} Answers (
                      {possibleAnswers.length})
                    </StyledAnswerButton>
                  </Col>
                </Conditional>
              </Row>
            </StyledQuestionContentContainer>
            <Conditional
              conditional={answersVisible || questionFormat === 'true-false'}
            >
              <Conditional
                conditional={
                  possibleAnswers.length > 0 && questionFormat !== 'true-false'
                }
              >
                <Row
                  gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 16]}
                  style={{ paddingTop: 34 }}
                >
                  <Col xl={24}>
                    <FFieldArray name={`${name}.possibleAnswers`}>
                      {({ fields, meta }) =>
                        fields.map((name, index) => (
                          <div key={name}>
                            <EditAnswerItem
                              name={name}
                              index={index}
                              remove={fields.remove}
                            />
                          </div>
                        ))}
                    </FFieldArray>
                  </Col>
                </Row>
              </Conditional>
              <Row
                gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 16]}
                style={{
                  paddingTop: 55,
                  display: 'flex',
                  flexDirection:
                    questionFormat === 'true-false' ? 'row-reverse' : 'row',
                  justifyContent: 'space-between'
                }}
                align='middle'
              >
                <Conditional
                  conditional={
                    possibleAnswers.length < answerLimit &&
                    questionFormat !== 'true-false'
                  }
                >
                  <Col span={6}>
                    <StyledButton
                      shape='round'
                      type='primary'
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        push(`${name}.possibleAnswers`, null);
                      }}
                    >
                      Add Answer
                    </StyledButton>
                  </Col>
                </Conditional>
                <Conditional
                  conditional={possibleAnswers.length === answerLimit}
                >
                  <Col xl={6}>
                    <StyledButton
                      shape='round'
                      type='primary'
                      onClick={handleButtonDone(
                        questionFormat === 'true-false'
                      )}
                    >
                      Done
                    </StyledButton>
                  </Col>
                </Conditional>
                <Col>
                  <StyledTextButton
                    type='text'
                    onClick={() => {
                      remove(index);
                    }}
                    disabled={numOfQuestions === 1}
                    accent={1}
                  >
                    Delete
                  </StyledTextButton>
                </Col>
              </Row>
            </Conditional>
          </>
        );
      }}
    </FieldsControl>
  );
};
