import 'react-bulma-components/dist/react-bulma-components.min.css';
import { Layout, Switch } from 'antd';
import Header from '../template/Header';
import Footer from '../template/Footer';
import { FeatureTable } from './sections/FeatureTable';
import MonthlyPricing from './sections/MonthlyPricing';
import useToggle from '../../hooks/useToggle';
import AnnualPricing from './sections/AnnualPricing';

const { Content } = Layout;

const Pricing = () => {
  const [price, togglePrice] = useToggle(true);
  return (
    <>
      <Layout style={{ width: '100%', backgroundColor: '#e5e5e5' }}>
        <Header />
        <Content className='site-layout' style={{ margin: '100px 0' }}>
          <Switch onClick={togglePrice} style={{ display: 'block', margin: '0 auto', marginBottom: '-100px' }} checkedChildren='Month' unCheckedChildren='Annual' defaultChecked />
          {/* PRICING */}
          {price ? <MonthlyPricing /> : <AnnualPricing />}
          {/* <PricingTable /> */}
          {/* <Divider /> */}
          <br />
          <FeatureTable />
        </Content>
        <Footer />
      </Layout>
    </>
  );
};

export default Pricing;
