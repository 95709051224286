import React, { Component } from 'react';
import { connect } from 'react-redux';
import SiderNav from '../../template/SiderNav';
import { BlueButton, MainLayout, OrangeButton, PurpleButton, RoundedContainer, SecondaryLayout, StyledContent } from '../../../styles/StyledComponents';
import OrganizationForm from './OrganizationForm';
import { Col, Divider, Row } from 'antd';
import { StyledTitle } from '../../fields/Title';

class SetupOrganization extends Component {
  render () {
    const { isLoading, account = { subscriptionPlan: '', stripe: { interval: '' } }, user = {} } = this.props;

    return (
      <MainLayout hasSider>
        {/* SIDE NAV BAR */}
        <SiderNav />

        <SecondaryLayout>
          <StyledContent>
            <Row justify='center'>
              <Col>
                  Tell us about your organization
              </Col>
            </Row>
            <br />
            <Row justify='center' style={{ textAlign: 'center' }}>
              <StyledTitle>
                  Setup your organization
              </StyledTitle>
            </Row>
            <br />
            <Row justify='center'>
              <Col>
                <OrganizationForm user={user} isLoading={isLoading} />
              </Col>
            </Row>
          </StyledContent>
        </SecondaryLayout>
      </MainLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    account: state.account.data,
    isLoading: state.auth.isLoading
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SetupOrganization);
