import { all, takeLatest } from 'redux-saga/effects';
import {
  CLASS_REQUEST,
  CREATE_CLASS_REQUEST,
  DELETE_CLASS_REQUEST,
  CLASSES_DASHBOARD_REQUEST,
  onHandleClassRequest,
  onHandleCreateClassRequest,
  onHandleClassesDashboardRequest,
  onHandleDeleteClassRequest,
  EDIT_CLASS_REQUEST,
  onHandleEditClassRequest,
  CLEAR_NEW_CLASS,
  onHandleClearNewClass,
  GET_CLASS_USING_TOKEN_REQUEST,
  onHandleGetClassWithToken,
  GET_ORGANIZATION_USING_TOKEN_REQUEST,
  onHandleGetOrganizationWithToken
} from './redux';
import {
  CREATE_TUTOR_GROUP_REQUEST,
  DELETE_TUTOR_GROUP_REQUEST,
  EDIT_TUTOR_GROUP_REQUEST,
  TUTOR_GROUP_REQUEST,
  onHandleCreateTutorGroup,
  onHandleDeleteTutorGroupRequest,
  onHandleEditTutorGroup,
  onHandleTutorGroupRequest
} from './redux/tutor-groups';

function * watchClassesDashboard () {
  console.log('#watchClassesDashboard');
  yield takeLatest(CLASSES_DASHBOARD_REQUEST, onHandleClassesDashboardRequest);
}

function * watchClass () {
  console.log('#watchClass');
  yield takeLatest(CLASS_REQUEST, onHandleClassRequest);
}

function * watchCreateClass () {
  console.log('#watchCreateClass');
  yield takeLatest(CREATE_CLASS_REQUEST, onHandleCreateClassRequest);
}

function * watchEditClass () {
  console.log('#watchEditClass');
  yield takeLatest(EDIT_CLASS_REQUEST, onHandleEditClassRequest);
}

function * watchDeleteClass () {
  console.log('#watchDeleteClass');
  yield takeLatest(DELETE_CLASS_REQUEST, onHandleDeleteClassRequest);
}

function * watchTutorGroup () {
  console.log('#watchTutorGroup');
  yield takeLatest(TUTOR_GROUP_REQUEST, onHandleTutorGroupRequest);
}

function * watchCreateTutorGroup () {
  console.log('#watchCreateTutorGroup');
  yield takeLatest(CREATE_TUTOR_GROUP_REQUEST, onHandleCreateTutorGroup);
}

function * watchEditTutorGroup () {
  console.log('#watchEditTutorGroup');
  yield takeLatest(EDIT_TUTOR_GROUP_REQUEST, onHandleEditTutorGroup);
}

function * watchDeleteTutorGroup () {
  console.log('#watchDeleteTutorGroup');
  yield takeLatest(DELETE_TUTOR_GROUP_REQUEST, onHandleDeleteTutorGroupRequest);
}

function * watchClearNewClass () {
  yield takeLatest(CLEAR_NEW_CLASS, onHandleClearNewClass);
}

function * watchGetClassWithToken () {
  yield takeLatest(GET_CLASS_USING_TOKEN_REQUEST, onHandleGetClassWithToken);
}

function * watchGetTeacherWithToken () {
  yield takeLatest(GET_ORGANIZATION_USING_TOKEN_REQUEST, onHandleGetOrganizationWithToken);
}

export default function * sagas () {
  yield all([
    watchClass(),
    watchTutorGroup(),
    watchCreateClass(),
    watchEditClass(),
    watchDeleteClass(),
    watchEditTutorGroup(),
    watchCreateTutorGroup(),
    watchDeleteTutorGroup(),
    watchClassesDashboard(),
    watchClearNewClass(),
    watchGetClassWithToken(),
    watchGetTeacherWithToken()
  ]);
}
