import React from 'react';
import 'react-bulma-components/dist/react-bulma-components.min.css';
import { Layout } from 'antd';
import Header from '../template/Header';
import Footer from '../template/Footer';
import { JoinBeta } from './sections/JoinBeta';
import { EducatorsWhy } from './sections/EducatorsWhy';

const { Content } = Layout;

const Educators = () => (
  <>
    <Layout style={{ width: '100%', backgroundColor: '#e5e5e5' }}>
      <Header />
      <Content className='site-layout' style={{ margin: '30px 0' }}>
        {/* WHAT IS TUTORD CONTAINER */}
        <br />
        <br />
        <br />
        <EducatorsWhy />
        {/* JOIN BETA */}
        <JoinBeta />

      </Content>
      <Footer />
    </Layout>
  </>
);

export default Educators;
