import { createSelector } from 'reselect';

// QUESTION SETS SELECTOR
export const questionSetsSelector = (state) => state.questionSets;

// GET QUESTION SETS SELECTOR
export const getQuestionSetsSelectorSelector = createSelector(
  [questionSetsSelector],
  ({ data }) => data
);
