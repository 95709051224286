import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'antd';

import { CenteredContainer as Container } from '../fields/Container';
import EmailSent from '../../assets/email-sent.svg';
import { VerifyEmailSentForm } from './VerifyEmailSentForm';
import Header from '../template/Header';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { MainCol, YellowUnderline, NextPage, BackPage, BigTitle, WhiteText } from '../../styles/StyledComponents';
import { Link } from 'react-router-dom';

class VerifyEmailSentPage extends Component {
  render () {
    const { isLoading, user: { email = 'admin+ghost@tutord.io' } } = this.props;

    return (
      <Container style={{ width: '100%' }}>
        <Row justify='center'>
          <Col xl={10} xs={24} style={{ background: '#5CC9D3', paddingBottom: 30 }}>
            <Header />
            <div style={{ padding: 24, textAlign: 'center' }}>
              <BigTitle>Verify</BigTitle>
              {/* <Title level={1}>
              <FormattedMessage id="verify-email-sent.title" />
            </Title> */}
              <img src={EmailSent} alt='Email Sent' style={{ marginBottom: 30 }} />
              <WhiteText style={{ fontSize: '1.5em' }}>
                <FormattedMessage id='verify-email-sent.subTitle' />
              </WhiteText>
            </div>
          </Col>
          <MainCol xl={14} xs={24}>
            {/* <Title level={1}> */}
            {/* <FormattedMessage id="verify-email-sent.email-address-form.title" /> */}
            <YellowUnderline level={3}>
                Verification Email Sent
            </YellowUnderline>
            {/* </Title> */}
            {/* <Title level={3}> */}
            {/* <FormattedMessage id="verify-email-sent.email-address-form.subTitle" /> */}
            <small style={{ display: 'flex' }}>Please check your email and click the link provided.</small>
            {/* </Title> */}
            <br />
            <small style={{ display: 'flex' }}>Email</small>
            <VerifyEmailSentForm isLoading={isLoading} email={email} />
          </MainCol>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.signUp.isLoading,
    user: state.signUp.user
  };
};

export default connect(mapStateToProps, {})(VerifyEmailSentPage);
