import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleStudentsDashboardRequest } from '../../../../../modules/students/redux';
import { handleClassesDashboardRequest } from '../../../../../modules/classes/redux';
import { classesSelector } from '../../../../../modules/classes/redux/selectors';
import { studentsSelector } from '../../../../../modules/students/redux/selectors';

const useStudentsPage = () => {
  const [searchedValue, setSearchedValue] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(handleStudentsDashboardRequest());
    dispatch(handleClassesDashboardRequest());
  }, [dispatch]);

  const {
    isLoading: classesLoading,
    data: allClasses,
    error
  } = useSelector(classesSelector);
  const { isLoading: studentsLoading, data: students } =
    useSelector(studentsSelector);

  const isLoading = useMemo(
    () => studentsLoading || classesLoading,
    [classesLoading, studentsLoading]
  );

  // SEARCHED STUDENT BY FIRST AND LAST NAMES
  const searchedStudents = useMemo(() => {
    if (!!searchedValue && searchedValue.length > 0) {
      return students.filter(
        ({ firstName, lastName }) =>
          firstName
            .toLocaleLowerCase()
            .startsWith(searchedValue.toLocaleLowerCase()) ||
          lastName
            .toLocaleLowerCase()
            .startsWith(searchedValue.toLocaleLowerCase())
      );
    }

    return students;
  }, [searchedValue, students]);

  // SEARCH STUDENTS ONCHANGE HANDLER
  const handleStudentsSearch = useCallback(
    (e) => {
      const { value } = e.target;

      if (!searchedValue && value === ' ') {
        return;
      }

      setSearchedValue(value);
    },
    [searchedValue]
  );

  const searchInputConfig = useMemo(
    () => ({
      value: searchedValue,
      onChange: handleStudentsSearch,
      disabled: students?.length === 0 || !students
    }),
    [handleStudentsSearch, searchedValue, students]
  );

  const user = JSON.parse(localStorage.getItem('user') ?? '');

  return {
    classes: allClasses,
    students: searchedStudents,
    isLoading,
    error,
    handleStudentsSearch,
    searchedValue,
    searchInputConfig,
    user
  };
};

export default useStudentsPage;
