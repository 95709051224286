import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import _ from 'lodash';
import { StyledTitle as Title } from '../../fields/Title';
import {
  Tag,
  Col,
  Row,
  Drawer,
  Button,
  List as AList,
  Form as AForm,
  Select as ASelect,
  Input as AInput,
  Avatar,
  AutoComplete
} from 'antd';
import { Field as FField, Form as FForm } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { FieldArray as FFieldArray } from 'react-final-form-arrays';
import {
  UserOutlined,
  CloseCircleOutlined,
  PlusOutlined
} from '@ant-design/icons';

import { capitalize } from '../../fields/renderFields';
import {
  InputControl,
  EmailInputControl,
  SelectControl,
  FieldsControl
} from '../../fields/Fields';
import * as validations from '../../../utils/validations';
import {
  StyledContainer,
  YellowUnderline
} from '../../../styles/StyledComponents';
import { StyledButton } from '../../fields/Button';
import { dispatch } from '../../../store';
import { handleShowToast } from '../../../modules/notification/redux';
import request from '../../../lib/request';

const { Option } = ASelect;

export const options = [
  {
    value: 'Brandon Bailey',
    label: 'bailey1.brandon@gmail.com'
  },
  {
    value: 'Reggie Alleyne',
    label: 'reggiealleyne89@gmail.com'
  }
];

const OPTIONS = [
  'Algebra 1',
  'Geometry',
  'Chemistry',
  'Biology',
  'Literature',
  'History',
  'Science',
  'English'
];

const OPTIONS_OBJ = {
  'Algebra 1': 'gold',
  Geometry: 'yellow',
  Chemistry: 'lime',
  Biology: 'cyan',
  Literature: 'geekblue',
  History: 'green',
  Science: 'volcano',
  English: 'lime'
};

const tagRender = (props) => {
  const { label, value, closable, onClose } = props;
  return (
    <Tag
      color={OPTIONS_OBJ[value]}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3 }}
    >
      {label}
    </Tag>
  );
};

const EnrollmentsForm = (props) => {
  console.log('OnBoardingPage#EnrollmentsForm, props: ', props);
  const { match, onBoardingData } = props;
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const handleSearch = (value) => {
    console.log('EnrollmentsForm#handleSearch, value: ', value);
  };

  const onSelect = (value) => {
    console.log('EnrollmentsForm#onSelect, value: ', value);
  };

  const verifyEmailInOrg = async (email) => {
    try {
      const res = await request.post('/users/verifyEmailByOrg', { email });

      return res;
    } catch (err) {
      console.error('validate email', err);
      return null;
    }
  };

  return (
    <>
      <Row justify='center' align='middle' style={{ height: '70vh' }}>
        <Col xl={22} xs={22}>
          <YellowUnderline>
            <Title level={3}>
              <FormattedMessage
                id={`on-boarding.${match.params.step}.main.title`}
              />
            </Title>
          </YellowUnderline>
          <p style={{ maxWidth: 450 }}>
            We can set up your classes and students so that you don’t have to do
            it manually, you will still be able to edit them aftwewards.{' '}
          </p>
        </Col>
        <Col xl={22} xs={22}>
          <FField
            name='email'
            type='text'
            placeholder='Search for Student by Email Address'
            label='Find Student'
          >
            {({ input, meta }) => (
              <AutoComplete
                onSelect={onSelect}
                onSearch={handleSearch}
                options={options}
                disabled
              >
                <AInput.Search
                  placeholder='Search for students by name or email'
                  enterButton
                  disabled
                />
              </AutoComplete>
            )}
          </FField>
          <div onClick={showDrawer}>
            <StyledButton
              style={{
                color: '#fff',
                marginLeft: 10,
                transform: 'translateY(5px)'
              }}
            >
              <PlusOutlined /> Create Student
            </StyledButton>
          </div>
        </Col>
        <Col xl={22} xs={22}>
          <FFieldArray name='studentEnrollments'>
            {({ fields, meta }) => {
              let submit, _form, _submitting, _invalid;

              const onSubmit = async ({ student }) => {
                // checking if the student email already exists both on backend and on the list of new students
                if (fields.value.filter(val => val.email === student.email).length) {
                  dispatch(
                    handleShowToast({
                      message: 'Email already added',
                      type: 'error'
                    })
                  );
                } else {
                  const result = await verifyEmailInOrg(student.email);
                  if (!(result?.data?.emailExist)) {
                    onClose();
                    fields.push(student);
                    _form.restart();
                  } else if (result?.data?.emailExist) {
                    dispatch(
                      handleShowToast({
                        message: 'Email already exist',
                        type: 'error'
                      })
                    );
                  } else {
                    dispatch(
                      handleShowToast({
                        message: 'Something Went wrong',
                        type: 'error'
                      })
                    );
                  }
                }
              };

              const onClose = () => {
                setVisible(false);
              };

              return (
                <>
                  <AList
                    itemLayout='horizontal'
                    dataSource={fields.value}
                    renderItem={(item, index) => {
                      return (
                        <StyledContainer
                          style={{
                            padding: 5,
                            marginTop: 20,
                            width: 'clamp(250px , 50%, 380px)'
                          }}
                        >
                          <AList.Item
                            key={`${item.firstName}-${item.lastName}-${index}`}
                            actions={[
                              <Button
                                icon={<CloseCircleOutlined />}
                                type='link'
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  fields.remove(index);
                                }}
                              />
                            ]}
                          >
                            <AList.Item.Meta
                              avatar={
                                <Avatar
                                  size={50}
                                  style={{ marginLeft: 10 }}
                                  icon={<UserOutlined />}
                                />
                              }
                              title={
                                <a href='#'>{`${_.capitalize(
                                  item.firstName
                                )} ${_.capitalize(item.lastName)}`}
                                </a>
                              }
                              description={
                                item.classes ? item.classes.join(', ') : 'N/A'
                              }
                            />
                          </AList.Item>
                        </StyledContainer>
                      );
                    }}
                  />
                  <FForm
                    name='ff-student-enrollment-form'
                    onSubmit={onSubmit}
                    render={({
                      handleSubmit,
                      form,
                      invalid,
                      submitting,
                      pristine,
                      values
                    }) => {
                      submit = handleSubmit;
                      _form = form;
                      _invalid = invalid;
                      _submitting = submitting;
                      return (
                        <FieldsControl names={['classes']}>
                          {(fieldsState) => {
                            return (
                              <>
                                <Drawer
                                  title='Add Student'
                                  placement='right'
                                  onClose={() => {
                                    _form.restart();
                                    onClose();
                                  }}
                                  visible={visible}
                                  bodyStyle={{ paddingBottom: 80 }}
                                  width={window.innerWidth > 900 ? 600 : window.innerWidth}
                                  footer={
                                    <div
                                      style={{
                                        textAlign: 'right'
                                      }}
                                    >
                                      <Button
                                        style={{ marginRight: 8 }}
                                        onClick={() => {
                                          _form.restart();
                                          onClose();
                                        }}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        type='submit'
                                        htmlType='submit'
                                        disabled={_submitting || _invalid}
                                        onClick={(e) => {
                                          submit(e);
                                        }}
                                      >
                                        Create Student
                                      </Button>
                                    </div>
                                  }
                                >
                                  <Row
                                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                                  >
                                    <Col xl={12} sm={20} xs={24}>
                                      <small
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'left'
                                        }}
                                      >
                                        First Name
                                      </small>
                                      <FField
                                        name='student.firstName'
                                        component={InputControl}
                                        type='text'
                                        placeholder='First Name*'
                                        parse={(value) =>
                                          value && value.toLowerCase()}
                                        format={capitalize}
                                        validate={validations.composeValidators(
                                          validations.required,
                                          validations.minLength(2),
                                          validations.maxLength(30)
                                        )}
                                      />
                                    </Col>
                                  </Row>
                                  <Row
                                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                                  >
                                    <Col xl={12} sm={20} xs={24}>
                                      <small
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'left'
                                        }}
                                      >
                                        Last Name
                                      </small>
                                      <FField
                                        name='student.lastName'
                                        component={InputControl}
                                        type='text'
                                        placeholder='Last Name*'
                                        parse={(value) =>
                                          value && value.toLowerCase()}
                                        format={capitalize}
                                        validate={validations.composeValidators(
                                          validations.required,
                                          validations.minLength(2),
                                          validations.maxLength(30)
                                        )}
                                      />
                                    </Col>
                                  </Row>
                                  <Row
                                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                                  >
                                    <Col xl={12} sm={20} xs={24}>
                                      <small
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'left'
                                        }}
                                      >
                                        Email
                                      </small>
                                      <FField
                                        name='student.email'
                                        component={EmailInputControl}
                                        type='text'
                                        placeholder='Email*'
                                        validate={validations.composeValidators(
                                          validations.required,
                                          validations.email,
                                          validations.minLength(4)
                                        )}
                                      />
                                    </Col>
                                  </Row>
                                  <Row
                                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                                  >
                                    <Col xl={12} sm={20} xs={24}>
                                      <small
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'left'
                                        }}
                                      >
                                        Enrolled Courses
                                      </small>
                                      <FField
                                        name='student.classes'
                                        component={SelectControl}
                                        tagRender={tagRender}
                                        options={onBoardingData.classes}
                                        mode='multiple'
                                        size='large'
                                        value={[]}
                                        format={(value) => value || []}
                                        type='text'
                                        placeholder='Classes student is currently enrolled'
                                      />
                                    </Col>
                                  </Row>
                                </Drawer>
                              </>
                            );
                          }}
                        </FieldsControl>
                      );
                    }}
                  />
                </>
              );
            }}
          </FFieldArray>
        </Col>
      </Row>
    </>
  );
};

export default withRouter(EnrollmentsForm);
