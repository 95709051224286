import React, {
  Component,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Row, Col, Typography, Avatar, Divider, List, Modal } from 'antd';
import { connect } from 'react-redux';
import { dispatch } from '../../../store';
import TutorDLogoOwl from '../../../assets/owl.svg';
import SiderNav from '../../template/SiderNav';
import {
  MainLayout,
  SecondaryLayout,
  StyledButton,
  RoundedCard,
  StyledContent
} from '../../../styles/StyledComponents';
import {
  ArrowRightOutlined,
  ExclamationCircleOutlined,
  RightCircleFilled,
  UserOutlined
} from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import {
  handleAccountBillingInfoRequest,
  handleAccountPlanSwitchRequest
} from '../../../modules/account/redux';
import LoadingSpinner from '../../../lib/components/loadingSpinner';
import { convertStripeDate } from '../../../lib/utils';
import styled from 'styled-components';

const DisabledLink = styled(Link)`
  cursor: not-allowed;
  color: gray;
`;

const { Title } = Typography;

const BillingPage = (props) => {
  const {
    user = { firstName: '', lastName: '', email: '' },
    billingInfo,
    complementaryPrice,
    isLoading,
    handleAccountBillingInfoRequest,
    handleAccountPlanSwitchRequest
  } = props;
  const { confirm } = Modal;
  const router = useHistory();
  const [refetch, setRefetch] = useState(false);

  function showPromiseConfirm() {
    confirm({
      title: `Are you sure you want to switch to ${
        billingInfo.interval === 'month' ? 'Yearly' : 'Monthly'
      } plan ?`,
      icon: <ExclamationCircleOutlined />,
      content: `Your current subscription will be changed and your new recurring payment will be ${
        complementaryPrice.unit_amount / 100
      } per ${complementaryPrice.recurring?.interval}`,
      onOk() {
        complementaryPrice &&
          dispatch(handleAccountPlanSwitchRequest({priceId:complementaryPrice.id}));
        setRefetch(!refetch);
      },
      onCancel() {}
    });
  }

  useEffect(() => {
    dispatch(handleAccountBillingInfoRequest());
  }, [refetch]);

  return (
    <MainLayout hasSider>
      <SiderNav user={user} />
      <SecondaryLayout>
        <LoadingSpinner spinning={isLoading}>
          <StyledContent>
            <Row gutter={[16, 16]}>
              <Col>
                <Avatar icon={<UserOutlined />} size={60} />
              </Col>
              <Col>
                <Title level={3} style={{ marginBottom: 0 }}>
                  {user && user.firstName} {user && user.lastName}
                </Title>
                {user && user.email && <small>{user.email}</small>}
              </Col>
            </Row>
            <Col lg={20} xs={24}>
              <Row gutter={[16, 16]} justify="center" style={{ marginTop: 25 }}>
                <Col md={8} xs={24}>
                  <RoundedCard style={{ height: '100%', gap: 10 }}>
                    <span style={{ fontWeight: 500 }}>
                      {billingInfo?.interval === 'month'
                        ? 'Current monthly bill'
                        : 'Current yearly bill'}
                    </span>
                    <span>{billingInfo?.complementaryIntervalLookupKey}</span>
                    <Title level={3} style={{ fontWeight: 'bolder' }}>
                      ${billingInfo && billingInfo?.currentAmount / 100}
                    </Title>
                    <div
                      onClick={() => {
                        showPromiseConfirm();
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <small style={{ color: '#1890ff' }}>
                        {billingInfo?.interval === 'month'
                          ? 'Switch to yearly billing'
                          : 'Switch to monthly billing'}
                      </small>
                    </div>
                  </RoundedCard>
                </Col>
                <Col md={8} xs={24}>
                  <RoundedCard style={{ height: '100%' }}>
                    <span style={{ fontWeight: 500 }}>Next payment due</span>
                    <Title level={3} style={{ fontWeight: 'bolder' }}>
                      {billingInfo &&
                        convertStripeDate(billingInfo.nextDueDate)}
                    </Title>
                    <Link to="/teacher/billing/history">
                      <small>View payment history</small>
                    </Link>
                  </RoundedCard>
                </Col>
                <Col md={8} xs={24}>
                  <RoundedCard style={{ height: '100%' }}>
                    <Row
                      style={{ alignItems: 'center', gap: 10, width: '100%' }}
                    >
                      <span style={{ fontWeight: 500 }}>
                        Payment information
                      </span>
                      <ArrowRightOutlined />
                    </Row>

                    <Link to="/teacher/organizations/payment">
                      <small>Update payment method</small>
                    </Link>
                    <br />

                    <small style={{ cursor: 'not-allowed' }}>
                      Share with scholarship provider
                    </small>

                    <br />

                    <small style={{ cursor: 'not-allowed' }}>
                      Redeem coupon
                    </small>
                    <br />
                    <br />
                  </RoundedCard>
                </Col>
              </Row>
            </Col>

            <Col xs={24} style={{ marginTop: 60 }}>
              <Col lg={20} xs={24}>
                <Title
                  level={4}
                  style={{
                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                    paddingBottom: 10
                  }}
                >
                  CURRENT PLAN
                </Title>
              </Col>

              <Col lg={20} xs={24} style={{ marginTop: 30 }}>
                <RoundedCard style={{ borderRadius: 15 }}>
                  <Row
                    style={{
                      width: '100%',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                      paddingBottom: 15
                    }}
                  >
                    <Row style={{ gap: 10, alignItems: 'center' }}>
                      <Avatar
                        src={TutorDLogoOwl}
                        alt=""
                        style={{ width: 40, height: 40 }}
                      />
                      <span
                        style={{
                          marginBottom: 0,
                          fontWeight: 'bold',
                          fontSize: window.innerWidth > 630 ? '30px' : '20px'
                        }}
                      >
                        {billingInfo &&
                          `${billingInfo.planName} / ${
                            billingInfo.interval === 'month'
                              ? 'Monthly'
                              : 'Yearly'
                          }`}
                      </span>
                    </Row>

                    <StyledButton
                      onClick={() =>
                        router.push('/teacher/organizations/plans')
                      }
                      style={{
                        background: '#8053D7',
                        marginTop: window.innerWidth > 424 ? 0 : '10px'
                      }}
                    >
                      Edit
                    </StyledButton>
                  </Row>
                  <Row style={{ minHeight: 300, alignItems: 'center' }}>
                    <Col lg={14} xs={24}>
                      <span>{billingInfo?.description}</span>
                      <ul style={{ padding: 10, marginTop: 10 }}>
                        {billingInfo?.features?.map((item, index) => {
                          return (
                            <li
                              key={index}
                              style={{
                                marginTop: '5px',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: '10px'
                              }}
                            >
                              <RightCircleFilled style={{ color: '#8053d7' }} />
                              <span>{item.name}</span>
                            </li>
                          );
                        })}
                      </ul>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      width: '100%',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                      paddingTop: 15
                    }}
                  >
                    <Row style={{ alignItems: 'baseline', gap: 5 }}>
                      <Title level={3} style={{ marginBottom: 0 }}>
                        ${billingInfo && billingInfo?.currentAmount / 100}
                      </Title>
                      <span>per {billingInfo?.interval}</span>
                    </Row>
                    <Title level={3} style={{ marginBottom: 0 }}>
                      ${billingInfo && billingInfo?.currentAmount / 100}.00
                    </Title>
                  </Row>
                </RoundedCard>
              </Col>
            </Col>
          </StyledContent>
        </LoadingSpinner>
      </SecondaryLayout>
    </MainLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    classes: state.classes.data,
    students: state.students.data,
    isLoading: state.account.isLoading,
    user: state.auth.user,
    billingInfo: state.account.accountBillingInfo,
    complementaryPrice: state.account.complementaryPrice
  };
};

const mapDispatchToProps = {
  handleAccountBillingInfoRequest,
  handleAccountPlanSwitchRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingPage);
