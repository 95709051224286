import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Layout } from 'antd';
import { handleQuestionSetRequest } from '../../modules/question-set/redux';
import { dispatch } from '../../store';

class EditQuestionSetsPage extends Component {
  componentDidMount () {
    const questionSetId = this.props.match.params.questionSetId;
    dispatch(handleQuestionSetRequest(questionSetId));
  }

  render () {
    return (
      <div>
        EditQuestionSetsPage
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    questionSet: state.dashboard.data.questionSets[0]
  };
};

export default withRouter(connect(mapStateToProps, {})(EditQuestionSetsPage));
