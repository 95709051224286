import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { handleVerifyEmail } from '../../modules/sign-up/redux';
import { dispatch } from '../../store';

class VerifiedEmailPage extends Component {
  componentWillMount () {
    const emailToken = this.props.match.params.verifyEmailToken;
    dispatch(handleVerifyEmail(emailToken));
  }

  render () {
    return (
      <div>
      Verifying Email...
      </div>
    );
  }
}

export default withRouter(VerifiedEmailPage);
