import { take, all } from 'redux-saga/effects';
import auth from './auth/sagas';
import signUp from './sign-up/sagas';
import classes from './classes/sagas';
import students from './students/sagas';
import dashboard from './dashboard/sagas';
import questionSet from './question-set/sagas';
import account from './account/sagas';
import notification from './notification/sagas';

export function * logActions () {
  while (true) {
    const action = yield take('*');
    console.log(action.type);
  }
}

export default function * rootSaga () {
  yield all([auth(), signUp(), classes(), account(), students(), dashboard(), questionSet(), notification()]);
}
