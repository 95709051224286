import React, { useMemo } from 'react';
import styled from 'styled-components';
import { FORM_ERROR } from 'final-form';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import arrayMutators from 'final-form-arrays';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { Field as FField, Form as FForm } from 'react-final-form';
import {
  Form as AForm,
  List as AList,
  Button,
  Empty,
  Modal,
  notification
} from 'antd';
import {
  ExclamationCircleOutlined,
  LeftOutlined,
  RightOutlined
} from '@ant-design/icons';

import * as validations from '../../../../utils/validations';
import { dispatch, promiseListener } from '../../../../store';
import { EditableTitleControl } from '../../../fields/Fields';
import {
  EDIT_CLASS_FAILURE,
  EDIT_CLASS_REQUEST,
  EDIT_CLASS_SUCCESS,
  RESET_CLASS_ERROR
} from '../../../../modules/classes/redux';
import { StyledContainer } from '../../../../styles/StyledComponents';
import BorderedContainerWithTitle from '../../../fields/BorderedContainerWithTitle';
import { EditClassStudentsList } from './EditClassStudentsList';
import CustomTreeSelect from '../../../fields/TreeSelect';
import { handleShowToast } from '../../../../modules/notification/redux';
import { classesSelector } from '../../../../modules/classes/redux/selectors';

const StyledAddButton = styled(Button)`
  display: flex;
  align-items: center;
  background: transparent;
  color: #8053d7;
  margin-left: 20px;
  line-height: 3em;

  &:hover,
  &:focus {
    color: #8053d7;
  }
`;

const StyledEditClassFormContainer = styled.div`
  width: 100%;
`;

const StyledEditClassFormHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  & .ant-typography,
  & .ant-form-item {
    margin-bottom: 3px;
  }

  & .ant-typography-edit-content {
    margin: 0 0 0 12px;
  }
`;

const StyledStyledEditClassFormInnerContainer = styled.div`
  width: 100%;

  @media (min-width: 1200px) {
    padding-left: 35px;
  }
`;

const StyledGroupItem = styled(StyledContainer)`
  width: 100%;
  min-height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 20px 0 30px;
  border-left: ${({ isActive }) =>
    `15px solid ${isActive ? '#5BBCBD' : '#D7753E'}`};

  & > .group-item__link {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & .group-item__marker {
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${({ isActive }) => (isActive ? '#58F467' : '#BFC0BF')};
  }

  & > .group-item__link .group-item__arrow {
    transition: all 0.2s linear;
  }

  & > .group-item__link .ant-list-item-meta-title {
    width: 100%;
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: all 0.2s linear;
    margin-bottom: 0;
  }

  & > .group-item__link:hover .ant-list-item-meta-title,
  & > .group-item__link:hover .group-item__arrow {
    color: #8053d7;
    transition: all 0.2s linear;
  }

  & > .group-item__link:hover .group-item__arrow {
    transform: translateX(5px);
  }
`;

const StyledEditClassFormButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  justify-content: flex-end;
  margin-top: 40px;

  @media (min-width: 1200px) {
    gap: 50px;
  }
`;

const StyledSaveButton = styled(Button)`
  background-color: #5bbcbd;
  border: none;
  border-radius: 10px;
  font-weight: bolder;
  color: #fff;
  height: 50px;
  padding: 0 17px;
  min-width: 100px;

  @media (min-width: 1200px) {
    min-width: 200px;
  }

  &:hover,
  &:focus {
    background-color: #60e4e5;
    border: none;
    color: #fff;
  }
`;

const StyledDeleteButton = styled(Button)`
  background: transparent;
  padding: 0;
  border: none;
  color: #e64732;
  outline: none;
  box-shadow: none;

  &:hover,
  &:focus,
  &:active {
    color: #e64732;
    background: transparent;
    outline: none;
    box-shadow: none;
  }
`;

const StyledEmptyGroupDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const EditClassForm = ({
  currentClass,
  isLoading,
  onClassDelete,
  allStudents = [],
  treeSelectConfig,
  tutorGroups,
  showQRCode
}) => {
  const { confirm } = Modal;
  const { error } = useSelector(classesSelector);

  function showPromiseConfirm (_class) {
    confirm({
      title: `Do you want to delete ${_class.title}?`,
      icon: <ExclamationCircleOutlined />,
      content:
        'All Class, Tutor Group, Session and Student data will be deleted if you do delete ',
      onOk () {
        console.log(
          'showPromiseConfirm, attempting to dispatch handleDeleteClassRequest, classId: ',
          _class.id
        );
        onClassDelete();
      },
      onCancel () {}
    });
  }
  // SET POSITION FOR THE CIRCLE MARKER
  const groupItemTitleWidth = useMemo(() => {
    const itemWidths = tutorGroups?.map(({ title }) => title?.length * 5.5);

    const maxItemWidth = Math.max.apply(null, itemWidths);

    const fullMaxWidth = maxItemWidth + 80;

    return fullMaxWidth;
  }, [tutorGroups]);

  // EMPTY STATE DESCRIPTION
  const renderedGroupsEmptyDescription = useMemo(() => {
    if (treeSelectConfig?.selectedFilter === 'active-session') {
      return (
        <StyledEmptyGroupDescription>
          <span>
            Sorry, in this class no tutor groups with the active sessions!
          </span>
        </StyledEmptyGroupDescription>
      );
    }

    if (treeSelectConfig?.selectedFilter === 'completed-session') {
      return (
        <StyledEmptyGroupDescription>
          <span>
            Sorry, in this class no tutor groups with the completed sessions!
          </span>
        </StyledEmptyGroupDescription>
      );
    }

    return (
      <StyledEmptyGroupDescription>
        <span>Sorry, in this class no tutor groups!</span>
        <span>Please add the first tutor group to this class</span>
      </StyledEmptyGroupDescription>
    );
  }, [treeSelectConfig?.selectedFilter]);

  return (
    <>
      <MakeAsyncFunction
        listener={promiseListener}
        start={EDIT_CLASS_REQUEST}
        resolve={EDIT_CLASS_SUCCESS}
        reject={EDIT_CLASS_FAILURE}
        getError={(action) => {
          const payload = action.payload;
          const errorMessage = payload[FORM_ERROR]
            ? payload[FORM_ERROR]
            : 'something went wrong';
          dispatch(handleShowToast({ message: errorMessage, type: 'error' }));
        }}
      >
        {(onSubmit) => (
          <FForm
            name='edit-class'
            initialValues={{
              id: currentClass?.id,
              title: currentClass?.title,
              students: currentClass?.students || []
            }}
            onSubmit={onSubmit}
            mutators={{
              ...arrayMutators
            }}
            render={({
              handleSubmit,
              submitting,
              submitError,
              hasValidationErrors,
              hasSubmitErrors,
              dirtySinceLastSubmit,
              dirty,
              values
            }) => {
              return (
                <StyledEditClassFormContainer>
                  <AForm onFinish={handleSubmit} className='edit-class-form'>
                    {submitError && <div className='error'>{submitError}</div>}
                    <StyledEditClassFormHeader>
                      <Link to='/teacher/classes' style={{ marginRight: 12 }}>
                        <LeftOutlined
                          style={{ color: '#000000', fontSize: 20 }}
                        />
                      </Link>
                      <FField
                        name='title'
                        component={EditableTitleControl}
                        level={3}
                        validate={validations.composeValidators(
                          validations.required,
                          validations.minLength(4),
                          validations.maxLength(30)
                        )}
                      />
                      <StyledAddButton onClick={showQRCode}>
                        Show QR Code
                      </StyledAddButton>
                    </StyledEditClassFormHeader>
                    <StyledStyledEditClassFormInnerContainer>
                      <EditClassStudentsList
                        allStudents={allStudents}
                        currentClassStudents={values?.students}
                        withMarginBottom={
                          !(
                            currentClass?.tutorGroups &&
                            currentClass?.tutorGroups?.length > 0
                          )
                        }
                      />
                      <BorderedContainerWithTitle
                        title='Tutor Groups'
                        extraContent={
                          currentClass?.tutorGroups &&
                          currentClass?.tutorGroups?.length > 0 ? (
                            <CustomTreeSelect
                                treeData={treeSelectConfig?.treeData}
                                value={treeSelectConfig?.selectedFilterValue}
                                onChange={treeSelectConfig?.handleFilterChange}
                              />
                            ) : null
                        }
                      >
                        <AList
                          itemLayout='horizontal'
                          dataSource={tutorGroups}
                          locale={{
                            emptyText: (
                              <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={renderedGroupsEmptyDescription}
                              />
                            )
                          }}
                          renderItem={(tGroup) => (
                            <StyledGroupItem
                              className='group-item'
                              isActive={tGroup.activeSession}
                              left={groupItemTitleWidth}
                            >
                              <Link
                                to={`/teacher/classes/${currentClass.id}/tutor-group/${tGroup.id}`}
                                className='group-item__link'
                              >
                                <AList.Item
                                  key={`${tGroup.title}-${tGroup.id}`}
                                  style={{ width: '100%' }}
                                >
                                  <span className='group-item__marker' />
                                  <AList.Item.Meta title={tGroup.title} />
                                  <RightOutlined className='group-item__arrow' />
                                </AList.Item>
                              </Link>
                            </StyledGroupItem>
                          )}
                        />
                      </BorderedContainerWithTitle>
                      <StyledEditClassFormButtonsContainer>
                        <StyledSaveButton
                          htmlType='submit'
                          disabled={
                            submitting ||
                            ((hasSubmitErrors || hasValidationErrors) &&
                              !dirtySinceLastSubmit) ||
                            !dirty
                          }
                          loading={isLoading || submitting}
                        >
                          SAVE
                        </StyledSaveButton>
                        <StyledDeleteButton
                          htmlType='button'
                          type='text'
                          onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            showPromiseConfirm(currentClass);
                          }}
                          disabled={isLoading || submitting}
                        >
                          Delete Class
                        </StyledDeleteButton>
                      </StyledEditClassFormButtonsContainer>
                    </StyledStyledEditClassFormInnerContainer>
                  </AForm>
                </StyledEditClassFormContainer>
              );
            }}
          />
        )}
      </MakeAsyncFunction>
      {error &&
        notification.error({
          key: error,
          message: 'Class error',
          description: error,
          onClose: dispatch({ type: RESET_CLASS_ERROR })
        })}
    </>
  );
};
