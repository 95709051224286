import React from 'react';
import { withRouter } from 'react-router-dom';
import { Steps } from 'antd';
import styled from 'styled-components';

const { Step } = Steps;

const steps = [
  {
    title: 'Classes'
  },
  {
    title: 'Enrollments'
  },
  {
    title: 'Goals'
  }
];

const StyledSteps = styled(Steps)`
.ant-steps-item-icon {
  background: #E5A84F;
  border-color: #E5A84F;
}
`;

const OnBoardingSteps = ({ match }) => {
  return (
    <StyledSteps size='small' labelPlacement='vertical' current={parseInt(match.params.step, 10)}>
      {steps.map(item => (
        <Step key={item.title} title={item.title} />
      ))}
    </StyledSteps>
  );
};

export default withRouter(OnBoardingSteps);
