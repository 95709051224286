import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';

import { userSelector } from '../../../modules/auth/redux/selectors';

import SiderNav from '../../template/SiderNav';
import { MainLayout, SecondaryLayout, StyledContent } from '../../../styles/StyledComponents';
import ProfileForm from './ProfileForm';
import LoadingSpinner from '../../../lib/components/loadingSpinner';

class ProfilePage extends Component {
  render () {
    const { isLoading, user = {} } = this.props;

    return (
      <MainLayout hasSider>
        {/* SIDE NAV BAR */}
        <SiderNav user={user} />

        <SecondaryLayout>
          <StyledContent>
            <LoadingSpinner spinning={isLoading} fullscreen>
              <ProfileForm user={user} isLoading={isLoading} />
              <Row style={{ margin: '15px auto' }} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xs={24} sm={20} xl={8}>
                  <Link to={{ pathname: 'https://app.termly.io/notify/438a711d-5cdf-45ae-8df4-0cd0e71e0f23' }} target='_blank' style={{ textDecoration: 'underline' }}> Do Not Sell or Share My Personal information</Link>
                </Col>
                <Col xs={24} sm={20} xl={8}>
                  <Link to={{ pathname: 'https://app.termly.io/notify/438a711d-5cdf-45ae-8df4-0cd0e71e0f23' }} target='_blank' style={{ textDecoration: 'underline' }}> Limit the Use Of My Sensitive Personal Information</Link>
                </Col>
              </Row>
            </LoadingSpinner>
          </StyledContent>
        </SecondaryLayout>
      </MainLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: userSelector(state),
    isLoading: state.auth.isLoading
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
