import React from 'react';
import { Col, Row } from 'antd';

export const JoinBeta = () => {
  return (
    <>
      <Row justify='center' style={{ margin: '40px auto' }}>
        <Col
          xl={20}
          style={{
            background: '#8053D7',
            borderRadius: 6,
            filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
            padding: 40
          }}
        >
          <p
            style={{
              fontSize: '2.5em',
              fontFamily: 'Montserrat Bold',
              color: '#fff',
              textAlign: 'center'
            }}
          >
              Dont let your students become {' '}
            <a href='https://www.tampabay.com/news/education/2021/02/03/90000-florida-kids-didnt-show-up-for-school-this-year-where-are-they/' target='_blank' rel='noreferrer'>
              <span style={{ color: '#FFDC54' }}>Another Statistic.</span>
            </a>
          </p>
          <p style={{ color: '#fff', textAlign: 'center' }}>
              Students self identifying their learning language can provide
              educators with the information to make for a more engaging
              experience.
          </p>
        </Col>
      </Row>
    </>
  );
};
