import styled from 'styled-components';
import { TreeSelect } from 'antd';

export const StyledTreeSelect = styled(TreeSelect)`
  min-width: 100px;
  padding: 0;

  & .ant-select-selection-item {
    color: #8053d7;
    font-size: 15px;
    line-height: 20px;
    font-weight: 700;
  }

  & .ant-select-arrow {
    color: #000000;
  }
`;

const CustomTreeSelect = ({
  value,
  treeData,
  onChange,
  bordered = false,
  placeholder = 'Please select filter'
}) => (
  <StyledTreeSelect
    value={value}
    treeData={treeData}
    onChange={onChange}
    bordered={bordered}
    placeholder={placeholder}
    dropdownMatchSelectWidth={false}
  />
);

export default CustomTreeSelect;
