import React from 'react';
import { RightOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';
import { StyledTitle as Title } from '../../fields/Title';
import LaptopImage from '../../../assets/Laptop.png';
import { StyledButton } from '../../../styles/StyledComponents';

export const EducatorsWhy = () => {
  return (
    <>
      <Row justify='center'>
        <Col
          xl={20}
          sm={22}
          style={{
            background: '#fff',
            padding: 40,
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
          }}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 40 }}>
            {/* LEFT COLUMN */}
            <Col xl={12}>
              <img src={LaptopImage} alt='' />
              {/* <img src={PhoneImage} style={{width: 400, margin: '0 auto', display: 'block'}} alt="" /> */}
            </Col>
            {/* RIGHT COLUMN */}
            <Col xl={12}>
              <Title level={2} style={{ color: '#333', fontWeight: 'bolder' }}>
                Why TutorD?
              </Title>
              <br />
              <strong style={{ color: '#333' }}>
                Lets talk about it, do you know if your students are engaged?
                Barely hanging on? Or disappeared from their learning
                experience?
              </strong>
              <br />
              <br />
              <p>
                We provide products that increases engagement between student and teacher in a safe and trackable way.
                TutorD also increases engagement based on the communication methods utilized to facilitate
                interactions between students and class content.
              </p>
              <p>
                We meet the students where they are, and deliver the entire curriculum via mobile phone to keep your
                students engaged if they don't have access to a computer.
              </p>
            </Col>
          </Row>
          {/* PURPLE CONTAINER */}
          <Row justify='center' style={{ margin: '40px 0' }}>
            <Col
              xl={20}
              sm={22}
              style={{
                background: '#5BBCBD',
                borderRadius: 6,
                filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                padding: 40
              }}
            >
              {/* ROW FOR TWO COLUMNS */}
              <Row justify='center' style={{ textAlign: 'center' }}>
                <Col>
                  <p style={{ color: '#fff', fontFamily: 'Montserrat Bold', fontSize: '3em' }}>Join our Private Beta</p>
                  <p style={{ color: '#fff' }}>
                    We are looking for teachers interested in going on the journey with us, limited spaces available.
                  </p>
                  <br />
                  <StyledButton style={{ borderRadius: 6, height: 40, width: 150 }}>
                    Schedule Demo <RightOutlined />
                  </StyledButton>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
