// NPM IMPORTS
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row } from 'antd';
import { ElementsConsumer } from '@stripe/react-stripe-js';
// COMPONENTS
import SiderNav from '../../template/SiderNav';
import { MainLayout, SecondaryLayout, StyledContent } from '../../../styles/StyledComponents';
import { StyledTitle } from '../../fields/Title';
// FORMS
import { dispatch } from '../../../store';
import { handleAccountRequest } from '../../../modules/account/redux';
import { PaymentDetailsForm } from './PaymentDetailsForm';

class PaymentDetailsPage extends Component {
  componentWillMount () {
    console.log('PaymentDetailsPage#componentWillMount');
    dispatch(handleAccountRequest());
  }

  componentDidMount () {
    console.log('PaymentDetailsPage#componentDidMount');
    // dispatch(handleAccountRequest());
  }

  render () {
    const { isLoading, user = { firstName: '', lastName: '', email: '' }, account = { subscriptionPlan: '', stripe: { priceAmount: '0' } } } = this.props;

    return (
      <MainLayout hasSider>
        {/* SIDE NAV BAR */}
        <SiderNav />

        <SecondaryLayout>
          <StyledContent>
            <Row justify='center' style={{ textAlign: 'center' }}>
              <StyledTitle>
                Payment Details Page
              </StyledTitle>
            </Row>
            <br />
            <ElementsConsumer>
              {({ stripe, elements }) => (
                <PaymentDetailsForm
                  isLoading={isLoading}
                  user={user}
                  email=''
                  account={account}
                  interval={account?.stripe?.interval}
                  subscriptionPlan=''
                  priceAmount=''
                  stripe={stripe}
                  elements={elements}
                />
              )}
            </ElementsConsumer>
          </StyledContent>
        </SecondaryLayout>
      </MainLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    account: state.account.data,
    isLoading: state.account.isLoading
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetailsPage);
