import React from 'react';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { withRouter } from 'react-router-dom';
import { Field, Field as FField, Form as FForm } from 'react-final-form';
import { Row, Col, Form as AForm, Upload, message, Switch, Button } from 'antd';
import { CheckCircleTwoTone, CloseCircleTwoTone, PlusCircleFilled, UploadOutlined } from '@ant-design/icons';
import { promiseListener } from '../../../store';
import { CodeInputControl, EmailInputControl, InputControl, PasswordControl, StyledInput } from '../../fields/Fields';
import { StyledTitle as Title } from '../../fields/Title';
import { StyledButton } from '../../fields/Button';
import * as validations from '../../../utils/validations';
import { YellowUnderline, NewButton } from '../../../styles/StyledComponents';
import { FormattedMessage } from 'react-intl';

import Auditory from '../../../assets/enrollment/auditory.svg';
import ReadingIcon from '../../../assets/enrollment/reading.svg';
import HandsOn from '../../../assets/enrollment/hands-on.svg';
import VisualIcon from '../../../assets/enrollment/visual.svg';
import { capitalize } from '../../fields/renderFields';

function onSwitch (checked) {
  console.log(`switch to ${checked}`);
}

const data = {
  name: 'file',
  action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  headers: {
    authorization: 'authorization-text'
  },
  onChange (info) {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  }
};

const ProfileForm = ({ isLoading, user }) => (
  <MakeAsyncFunction
    listener={promiseListener}
    // start={UPDATE_TEACHER_PROFILE}
    // resolve={UPDATE_TEACHER_PROFILE_SUCCESS}
    // reject={UPDATE_TEACHER_PROFILE_FAILURE}
  >
    {onSubmit => (
      <FForm
        onSubmit={onSubmit}
        render={({
          submitError,
          handleSubmit,
          submitting,
          hasValidationErrors,
          hasSubmitErrors,
          dirtySinceLastSubmit,
          values
        }) => (
          <div>
            {isLoading && <div>Loading...</div>}
            {submitError && <div className='error'>{submitError}</div>}
            <AForm onFinish={handleSubmit}>
              <Row gutter={16} justify='center' style={{ width: '100%' }}>
                <Col>
                  <Title level={3}>
                    My Profile
                  </Title>
                </Col>
                <Col flex={1}>
                  <NewButton>
                    SHARE
                  </NewButton>
                </Col>
              </Row>
              <br />
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col sm={20} xl={8}>
                  <small>Profile Image</small>
                  <br />
                  <Upload {...data} disabled>
                    <StyledButton icon={<UploadOutlined />} disabled>Click to Upload</StyledButton>
                  </Upload>
                  <Row gutter={[16, 16]}>
                    <Col>
                      {user.emailVerified ? <CheckCircleTwoTone twoToneColor='#52c41a' />
                        : <CloseCircleTwoTone twoToneColor='#eb2f96' />}
                      <small>email verified</small>
                    </Col>
                    <Col>
                      {user.isOnboarded ? <CheckCircleTwoTone twoToneColor='#52c41a' />
                        : <CloseCircleTwoTone twoToneColor='#eb2f96' />}
                      <small>onboarded</small>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                {/* EMAIL INPUT */}
                <Col sm={20} xl={8}>
                  <small style={{ display: 'flex', justifyContent: 'left' }}>First Name</small>
                  <FField
                    name='firstName'
                    component={InputControl}
                    type='text'
                    initialValue={user.firstName}
                    disabled
                    parse={value => value && value.toLowerCase()}
                    format={capitalize}
                    validate={validations.composeValidators(
                      validations.required,
                      validations.minLength(2),
                      validations.maxLength(20)
                    )}
                  />
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col sm={20} xl={8}>
                  <small style={{ display: 'flex', justifyContent: 'left' }}>First Name</small>
                  <FField
                    name='lastName'
                    component={InputControl}
                    type='text'
                    initialValue={user.lastName}
                    disabled
                    parse={value => value && value.toLowerCase()}
                    format={capitalize}
                    validate={validations.composeValidators(
                      validations.required,
                      validations.minLength(2),
                      validations.maxLength(20)
                    )}
                  />
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                {/* EMAIL INPUT */}
                <Col sm={20} xl={8}>
                  <small>Email</small>
                  <Field
                    name='email'
                    component={EmailInputControl}
                    type='text'
                    initialValue={user.email}
                    disabled
                    validate={validations.composeValidators(
                      validations.required,
                      validations.email
                    )}
                  />
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                {/* Password INPUT */}
                <Col sm={20} xl={8}>
                  <small>Password</small>
                  <Field
                    name='password'
                    component={PasswordControl}
                    type='text'
                    initialValue='********'
                    disabled
                    validate={validations.required}
                  />
                </Col>
              </Row>
              <br />
              {/* LEARNER TYPES SECTION */}
              <Row>
                <Col sm={20} xl={8}>
                  <strong>Manage Learner Types:</strong>
                </Col>
                <Col xl={16} />
                <Col>
                  <p style={{ color: '#8053D7' }}>
                    <a href=''>
                      <PlusCircleFilled style={{ color: '#8053D7', marginRight: 5 }} />
                    </a>
                    Add Learner Type
                  </p>
                </Col>
                <Col xl={16} />
                <Col xl={8}>
                  <Row gutter={[16, 16]}>
                    <Col>
                      <img src={Auditory} width='150px' style={{ display: 'block', margin: '0 auto' }} alt='' />
                    </Col>
                    <Col>
                      <img src={ReadingIcon} width='150px' style={{ display: 'block', margin: '0 auto' }} alt='' />
                    </Col>
                    <Col>
                      <img src={HandsOn} width='150px' style={{ display: 'block', margin: '0 auto' }} alt='' />
                    </Col>
                    <Col>
                      <img src={VisualIcon} width='150px' style={{ display: 'block', margin: '0 auto' }} alt='' />
                    </Col>
                  </Row>
                </Col>
                <Col xl={16} />
              </Row>
              <Row>
                <Col>
                  <strong>Public Profile</strong>
                  <p style={{ color: '#777' }}>(this will allow other teachers to see your question sets)</p>
                </Col>
                <Col>
                  <Switch defaultChecked={false} onChange={onSwitch} disabled />
                </Col>
              </Row>
              <br />
              {/* values: <pre>{JSON.stringify(values, 0, 2)}</pre> */}
              {/* DELETE ACCOUNT */}
              <Row>
                <Col>
                  <Button danger disabled>
                    Delete Account
                  </Button>
                </Col>
              </Row>
            </AForm>
          </div>
        )}
      />
    )}
  </MakeAsyncFunction>
);

export default withRouter(ProfileForm);
