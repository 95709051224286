import { all, takeLatest } from 'redux-saga/effects';

import {
  DASHBOARD_REQUEST,
  onHandleTeacherDashboardRequest
} from './redux';

// The Teacher Dashboard is going to be where we display all the teacher at a glance information
// the initial tab will be displaying your enrolled students primary learning styles

function * watchTeacherDashboard () {
  console.log('#watchTeacherDashboard');
  yield takeLatest(DASHBOARD_REQUEST, onHandleTeacherDashboardRequest);
}

export default function * sagas () {
  yield all([
    watchTeacherDashboard()
  ]);
}
