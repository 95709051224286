import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { handleClassRequest } from '../../../../../modules/classes/redux';
import { handleQuestionSetsDashboardRequest } from '../../../../../modules/question-set/redux';
import { classesSelector } from '../../../../../modules/classes/redux/selectors';
import { questionSetsSelector } from '../../../../../modules/question-set/redux/selectors';

const useCreateTutorGroupPage = () => {
  const { classId } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    if (classId) {
      dispatch(handleClassRequest(classId));
    }

    dispatch(handleQuestionSetsDashboardRequest());
  }, [classId, dispatch]);

  const { isLoading: classLoading, _class: currentClass } =
    useSelector(classesSelector);

  const { isLoading: questionSetsLoading, data: questionSets } =
    useSelector(questionSetsSelector);

  const isLoading = useMemo(
    () => classLoading || questionSetsLoading,
    [classLoading, questionSetsLoading]
  );

  return { classId, currentClass, questionSets, isLoading };
};

export default useCreateTutorGroupPage;
