// MOCKS FOR EXTRA FIELDS OF RESPONSE
// FIXME: DELETE THIS FILE WHEN THE REAL RESPONSE DATA WILL BE DONE WITH ACTUAL SCHEMA
export const mockedExtraTutorGroupsData = [
  {
    activeSession: true,
    totalScore: '0',
    totalScaledScore: '0.000',
    maxPossiblePerQuestionScore: '2',
    maxPossibleScore: '240.0',
    totalEngagementScore: '0.20',
    tutorSessions: [
      {
        id: '6209486e3b66231af0e46441',
        status: 'active',
        totalScore: '0.0',
        totalScaledScore: '0.000',
        maxPossiblePerQuestionScore: '2',
        averageEngagementScore: '0.336',
        studentPerformance: {
          '604134913725d40062624fd8': {
            totalScore: '0.0',
            totalEngagementScore: '0.770'
          },
          '635b374c88ea29f7c2f6f0bb': {
            totalScore: '14.5',
            totalEngagementScore: '0.450'
          }
        },
        questionPerformance: {
          totalScore: '0.0',
          totalEngagementScore: '0.000',
          '604137653725d40062624fdc': {
            totalScore: '0.0',
            totalEngagementScore: '0.000'
          },
          '604137653725d40062624fe0': {
            totalScore: '0.0',
            totalEngagementScore: '0.000'
          },
          '604137653725d40062624fe6': {
            totalScore: '0.0',
            totalEngagementScore: '0.000'
          },
          '604137653725d40062624fea': {
            totalScore: '0.0',
            totalEngagementScore: '0.000'
          },
          '604137653725d40062624fef': {
            totalScore: '0.0',
            totalEngagementScore: '0.000'
          }
        }
      }
    ]
  },
  {
    activeSession: false,
    totalScore: '87',
    totalScaledScore: '0.414',
    maxPossibleScore: '240.0',
    totalEngagementScore: '0.486',
    tutorSessions: [
      {
        id: '62098d7d88ea291739f6efc3',
        status: 'completed',
        totalScore: '87',
        totalScaledScore: '0.414',
        maxPossibleIndividualScore: '60.0',
        maxPossiblePerQuestionScore: '30',
        averageEngagementScore: '0.486',
        studentPerformance: {
          '6206cdf13b6623b6cae463c2': {
            totalScore: '0.0',
            totalEngagementScore: '0.000'
          },
          '6206cd8e3b6623b430e463b8': {
            totalScore: '0.0',
            totalEngagementScore: '0.000'
          },
          '6206cc983b6623928be463b0': {
            totalScore: '7',
            totalEngagementScore: '0.714'
          },
          '6206cc7a3b66232649e463ae': {
            totalScore: '14',
            totalEngagementScore: '0.900'
          },
          '604134913725d40062624fd8': {
            totalScore: '18',
            totalEngagementScore: '0.6600'
          },
          '6206ccaf3b66232c8fe463b2': {
            totalScore: '0.0',
            totalEngagementScore: '0.000'
          },
          '635b374c88ea29f7c2f6f0bb': {
            totalScore: '14.5',
            totalEngagementScore: '1.000'
          },
          '6206cd6a3b66232206e463b6': {
            totalScore: '3.5',
            totalEngagementScore: '0.357'
          },
          '62e06baf88ea293e3df6f096': {
            totalScore: '3.5',
            totalEngagementScore: '0.357'
          },
          '6206cdac3b66230966e463ba': {
            totalScore: '0.0',
            totalEngagementScore: '0.000'
          },
          '6206cdbf3b6623eb72e463bc': {
            totalScore: '0.0',
            totalEngagementScore: '0.000'
          },
          '6346b32488ea29db17f6f0b0': {
            totalScore: '4',
            totalEngagementScore: '0.286'
          },
          '6206ce123b66231be9e463c6': {
            totalScore: '5',
            totalEngagementScore: '1.000'
          },
          '6206ce223b6623fb9fe463c8': {
            totalScore: '10.5',
            totalEngagementScore: '0.857'
          },
          '6206cea73b6623a8a6e463ca': {
            totalScore: '7',
            totalEngagementScore: '0.714'
          }
        },
        questionPerformance: {
          totalScore: '62.5',
          totalEngagementScore: '0.000',
          totalScaledScore: '0.000',
          maxPossiblePerQuestionScore: '30',
          maxPossibleScore: '210',
          '6206d3803b662390b3e463ed': {
            totalScore: '6.5',
            totalScaledScore: '0.217',
            totalEngagementScore: '0.400'
          },
          '6206d3803b6623c7c7e463f2': {
            totalScore: '7.0',
            totalScaledScore: '0.233',
            totalEngagementScore: '0.500'
          },
          '6206d3803b66236499e463f6': {
            totalScore: '8.5',
            totalScaledScore: '0.283',
            totalEngagementScore: '0.500'
          },
          '6206d3803b66237cd0e463fa': {
            totalScore: '7.5',
            totalScaledScore: '0.250',
            totalEngagementScore: '0.600'
          },
          '6206d3803b662314cce463fe': {
            totalScore: '6.5',
            totalScaledScore: '0.217',
            totalEngagementScore: '0.400'
          },
          '6206d3803b6623099ee46402': {
            totalScore: '11.5',
            totalScaledScore: '0.383',
            totalEngagementScore: '0.600'
          },
          '6206d3803b662382dbe46406': {
            totalScore: '15',
            totalScaledScore: '0.500',
            totalEngagementScore: '0.700'
          }
        }
      }
    ]
  },
  {
    activeSession: false,
    totalScore: '87.5',
    totalScaledScore: '0.417',
    maxPossibleScore: '240.0',
    totalEngagementScore: '0.523',
    tutorSessions: [
      {
        id: '62098dbe88ea293cb7f6efc9',
        status: 'completed',
        totalScore: '87.5',
        totalScaledScore: '0.417',
        maxPossibleIndividualScore: '60.0',
        averageEngagementScore: '0.336',
        studentPerformance: {
          '6206cfa43b6623d6f6e463dc': {
            totalScore: '0.0',
            totalEngagementScore: '0.000'
          },
          '6206cfe13b6623b27fe463e0': {
            totalScore: '0.0',
            totalEngagementScore: '0.000'
          },
          '6206cf933b66238927e463da': {
            totalScore: '0.5',
            totalEngagementScore: '0.142'
          },
          '604134913725d40062624fd8': {
            totalScore: '0.5',
            totalEngagementScore: '0.142'
          },
          '6206ced63b66234fd4e463ce': {
            totalScore: '1.0',
            totalEngagementScore: '0.285'
          },
          '6206cf543b6623dd7ae463d4': {
            totalScore: '4.0',
            totalEngagementScore: '0.285'
          },
          '6206cea73b6623a8a6e463ca': {
            totalScore: '5.5',
            totalEngagementScore: '0.571'
          },
          '6206cf6c3b66231dc4e463d6': {
            totalScore: '5.5',
            totalEngagementScore: '0.714'
          },
          '6206a2183b66237c7ee463a2': {
            totalScore: '7.0',
            totalEngagementScore: '0.714'
          },
          '6206cff03b662370fbe463e2': {
            totalScore: '8.0',
            totalEngagementScore: '1.000'
          },
          '6206cf2e3b662342dee463d0': {
            totalScore: '10.0',
            totalEngagementScore: '0.714'
          },
          '6206cfca3b66231a7ee463de': {
            totalScore: '10.0',
            totalEngagementScore: '0.714'
          },
          '6206cf813b66232cd0e463d8': {
            totalScore: '10.5',
            totalEngagementScore: '0.571'
          },
          '6206cebd3b66232f55e463cc': {
            totalScore: '11.0',
            totalEngagementScore: '1.000'
          },
          '6206cf413b66236a04e463d2': {
            totalScore: '14.0',
            totalEngagementScore: '1.000'
          }
        },
        questionPerformance: {
          totalScore: '87.5',
          totalEngagementScore: '0.000',
          totalScaledScore: '0.000',
          maxPossiblePerQuestionScore: '30',
          maxPossibleScore: '210',
          '6206d3803b662390b3e463ed': {
            totalScore: '14',
            totalScaledScore: '0.467',
            totalEngagementScore: '0.667'
          },
          '6206d3803b6623c7c7e463f2': {
            totalScore: '12.5',
            totalScaledScore: '0.417',
            totalEngagementScore: '0.467'
          },
          '6206d3803b66236499e463f6': {
            totalScore: '13.5',
            totalScaledScore: '0.450',
            totalEngagementScore: '0.600'
          },
          '6206d3803b66237cd0e463fa': {
            totalScore: '15',
            totalScaledScore: '0.500',
            totalEngagementScore: '0.600'
          },
          '6206d3803b662314cce463fe': {
            totalScore: '15.5',
            totalScaledScore: '0.517',
            totalEngagementScore: '0.533'
          },
          '6206d3803b6623099ee46402': {
            totalScore: '10.5',
            totalScaledScore: '0.350',
            totalEngagementScore: '0.400'
          },
          '6206d3803b662382dbe46406': {
            totalScore: '6.5',
            totalScaledScore: '0.217',
            totalEngagementScore: '0.467'
          }
        }
      }
    ]
  },
  {
    activeSession: true,
    totalScore: '0',
    totalScaledScore: '0.000',
    maxPossiblePerQuestionScore: '4',
    maxPossibleScore: '240.0',
    totalEngagementScore: '0.390',
    tutorSessions: [
      {
        id: '6209486e3b66231af0e46441s',
        status: 'active',
        totalScore: '0.0',
        totalScaledScore: '0.000',
        maxPossiblePerQuestionScore: '40',
        averageEngagementScore: '0.336',
        studentPerformance: {
          '635b374c88ea29f7c2f6f0bb': {
            totalScore: '0.0',
            totalEngagementScore: '0.240'
          }
        },
        questionPerformance: {
          totalScore: '0.0',
          totalEngagementScore: '0.000',
          '604137653725d40062624fdcs': {
            totalScore: '10.0',
            totalEngagementScore: '0.000'
          },
          '604137653725d40062624fe0a': {
            totalScore: '20.0',
            totalEngagementScore: '0.000'
          },
          '604137653725d40062624fe6f': {
            totalScore: '11.0',
            totalEngagementScore: '0.000'
          },
          '604137653725d40062624feaas': {
            totalScore: '4.0',
            totalEngagementScore: '0.000'
          },
          '604137653725d40062624fefs': {
            totalScore: '43.0',
            totalEngagementScore: '0.000'
          }
        }
      }
    ]
  }
];
