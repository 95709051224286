import { all, call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import * as braze from '@braze/web-sdk';

import request from '../../../lib/request';
import { FORM_ERROR } from 'final-form';
import { handleSignInSuccess, setAuthToken } from '../../auth/redux';
import { parseFormErrors } from '../../../utils/parseFormErrors';
import { ROLE_ADMIN, ROLE_TEACHER } from '../../../utils/roles';
import { handleAccountSuccess } from '../../account/redux';

export const SIGNUP_REQUEST = 'sign-up/sign-up/SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'sign-up/sign-up/SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'sign-up/sign-up/SIGNUP_FAILURE';
export const SIGNUP_CANCELLED = 'sign-up/sign-up/SIGNUP_CANCELLED';

export const CONTACT_US_REQUEST = 'sign-up/sign-up/CONTACT_US_REQUEST';
export const CONTACT_US_SUCCESS = 'sign-up/sign-up/CONTACT_US_SUCCESS';
export const CONTACT_US_FAILURE = 'sign-up/sign-up/CONTACT_US_FAILURE';

export const VERIFY_EMAIL_REQUEST = 'sign-up/sign-up/VERIFY_EMAIL_REQUEST';
export const VERIFY_EMAIL_SUCCESS = 'sign-up/sign-up/VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_FAILURE = 'sign-up/sign-up/VERIFY_EMAIL_FAILURE';

export const RESEND_EMAIL_REQUEST = 'sign-up/sign-up/RESEND_EMAIL_REQUEST';
export const RESEND_EMAIL_SUCCESS = 'sign-up/sign-up/RESEND_EMAIL_SUCCESS';
export const RESEND_EMAIL_FAILURE = 'sign-up/sign-up/RESEND_EMAIL_FAILURE';

export const handleSignUpSuccess = payload => ({
  type: SIGNUP_SUCCESS,
  payload
});

export const handleContactUsSuccess = payload => ({
  type: CONTACT_US_SUCCESS,
  payload
});

export const handleVerifyEmail = payload => ({
  type: VERIFY_EMAIL_REQUEST,
  payload
});

export const handleVerifyEmailSuccess = payload => ({
  type: VERIFY_EMAIL_SUCCESS,
  payload
});

export const handleResendEmailSuccess = () => ({
  type: RESEND_EMAIL_SUCCESS
});

export function * onHandleSignUpRequest ({ uid, firstName, lastName, email, orgId, orgName = '', password, marketingOptIn }) {
  console.log('#onHandleSignUpRequest');
  try {
    console.log('#onHandleSignUpRequest, try block');
    let payload, endpoint, redirectURL;

    if (orgId && orgName && uid) {
      endpoint = '/accounts/create/teacher';
    } else {
      endpoint = '/accounts/create/admin';
      redirectURL = '/verify/email-sent';
    }

    payload = { firstName, lastName, email, orgName, password, marketingOptIn };

    // console.log('#onHandleSignUpRequest, endpoint: ', endpoint);
    // console.log('#onHandleSignUpRequest, payload: ', payload);

    const { data: { user, token = '' } } = yield call(request.post, endpoint, payload);

    // console.log('#onHandleSignUpRequest, user: ', user);
    // console.log('#onHandleSignUpRequest, token: ', token);

    const sagaEffectsArr = [put(handleSignUpSuccess(user)), put(push(redirectURL))];

    if (user.emailVerified && !user.isOnboarded) {
      // console.log('#onHandleVerifyEmail,role teacher, user.emailVerified && !user.isOnboarded');
      redirectURL = '/teacher/on-boarding/0';

      localStorage.setItem('token', JSON.stringify(token));
      localStorage.setItem('user', JSON.stringify(user));

      sagaEffectsArr.splice(-1, 1, put(handleSignInSuccess(user)), put(setAuthToken(token)), put(push(redirectURL)));
    }

    yield all(sagaEffectsArr);
  } catch (err) {
    console.error('#onHandleSignUpRequest, err: ', err);

    // TODO: fix error handling when the error object is malformed, for example attempting to sign up from a
    // TODO: non whitelisted domain will respond with a CORS error but it is not handled at all. and the user just sits
    // TODO: Please reference https://final-form.org/docs/react-final-form/examples/submission-errors

    if (err.message === 'Network Error' || err?.response?.message === 'Network Error') {
      return yield put({
        type: SIGNUP_FAILURE,
        payload: { [FORM_ERROR]: 'Check your connection and please try again later.' }
      });
    }

    const { status, message, errors } = err?.response?.data;

    if (status === 409) {
      return yield put({
        type: SIGNUP_FAILURE,
        payload: {
          [FORM_ERROR]: message,
          ...parseFormErrors(errors)
        }
      });
    }

    if (status === 422) {
      return yield put({
        type: SIGNUP_FAILURE,
        payload: {
          [FORM_ERROR]: message,
          ...parseFormErrors(errors)
        }
      });
    }

    if (err.response.status === 500 || status === 400) {
      return yield put({
        type: SIGNUP_FAILURE,
        payload: { [FORM_ERROR]: 'Its not you, its us....... Please try again later.' }
      });
    }
  }
}

export function * onHandleContactUsRequest ({ payload: { firstName, lastName, email, jobTitle, phoneNumber, subject, messageBody } }) {
  console.log('#onHandleContactUsRequest');

  try {
    console.log('#onHandleContactUsRequest, try block');

    const brazePayload = {
      first_name: firstName,
      last_name: lastName,
      email,
      jobTitle,
      phone: phoneNumber,
      subject,
      messageBody
    };

    // console.log('#onHandleContactUsRequest, braze.getUser().addAlias()');
    const addAliasReq = braze.getUser().addAlias(email, 'contact_us_form');
    console.log('#onHandleContactUsRequest, addAliasReq: ', addAliasReq);

    // console.log(`#onHandleContactUsRequest, braze.getUser().setEmail(${email})`);
    const setEmailReq = braze.getUser().setEmail(email);
    console.log('#onHandleContactUsRequest, setEmailReq: ', setEmailReq);

    // console.log(`#onHandleContactUsRequest, braze.getUser().setFirstName(${firstName})`);
    const setFirstNameReq = braze.getUser().setFirstName(firstName);
    console.log('#onHandleContactUsRequest, setFirstNameReq: ', setFirstNameReq);

    // console.log(`#onHandleContactUsRequest, braze.getUser().setLastName(${lastName})`);
    const setLastNameReq = braze.getUser().setLastName(lastName);
    console.log('#onHandleContactUsRequest, setLastNameReq: ', setLastNameReq);

    // console.log(`#onHandleContactUsRequest, braze.getUser().phoneNumber(${phoneNumber})`);
    const setPhoneNumberReq = braze.getUser().setPhoneNumber(phoneNumber);
    console.log('#onHandleContactUsRequest, setPhoneNumberReq: ', setPhoneNumberReq);

    // console.log(`#onHandleContactUsRequest, braze.getUser()?.setCustomUserAttribute(\`contact_us_form\`, ${brazePayload})`);
    const setContactUsCustomAttrsReq = braze.getUser()?.setCustomUserAttribute('contact_us_form', brazePayload);
    console.log('#onHandleContactUsRequest, setContactUsCustomAttrsReq: ', setContactUsCustomAttrsReq);

    const subjectMessagePayload = { subject, messageBody };
    // console.log(`#onHandleContactUsRequest, braze.getUser()?.setCustomUserAttribute(\`contact_us_form\`, ${subjectMessagePayload})`);
    const setContactUsCustomAttrsSubAndBodyReq = braze.getUser()?.setCustomUserAttribute('contact_us_form_subject_message_body', subjectMessagePayload);
    console.log('#onHandleContactUsRequest, setContactUsCustomAttrsSubAndBodyReq: ', setContactUsCustomAttrsSubAndBodyReq);

    // console.log('#onHandleContactUsRequest, braze.logCustomEvent, brazePayload: ', brazePayload);
    const logCustomEventReq = braze.logCustomEvent('contact_us_form', brazePayload);
    console.log('#onHandleContactUsRequest, logCustomEventReq: ', logCustomEventReq);

    yield all([
      put(handleContactUsSuccess({ firstName, lastName, email, phoneNumber, subject, messageBody })),
      put(push('/contact/email-sent'))
    ]);
  } catch (err) {
    console.error('#onHandleContactUsRequest, catch block , err: ', err);
  }
}

export function * onHandleResendVerifyEmail ({ payload }) {
  console.log('#onHandleResendVerifyEmail, verifyEmailToken: ', payload);

  try {
    console.log('#onHandleResendVerifyEmail, try block');
    const response = yield call(request.post, '/accounts/verify/resend/email', { emailToken: payload });

    console.log('response: ', response);
    yield put(handleResendEmailSuccess());
  } catch (err) {
    console.error('#onHandleResendVerifyEmail, catch block , err: ', err);

    if (err.message === 'Network Error') {
      return yield put({
        type: RESEND_EMAIL_FAILURE,
        payload: { [FORM_ERROR]: 'Check your connection and please try again later.' }
      });
    }
  }
}

export function * onHandleVerifyEmail ({ payload }) {
  console.log('#onHandleVerifyEmail, verifyEmailToken: ', payload);
  try {
    console.log('#onHandleVerifyEmail, try block');
    const { data: { user, token, account, org } } = yield call(request.post, '/accounts/verify/email', { emailToken: payload });

    localStorage.setItem('token', JSON.stringify(token));
    localStorage.setItem('user', JSON.stringify(user));

    braze.changeUser(user.id);

    let redirectURL = '';

    if (user.role === ROLE_ADMIN) {
      if (user.isOwner) {
        redirectURL = '/teacher/organizations/plans';
      } else {
        redirectURL = '/admin/dashboard/';
      }
    } else if (user.role === ROLE_TEACHER) {
      if (user.emailVerified && !user.isOnboarded) {
        redirectURL = '/teacher/on-boarding/0';
      } else {
        redirectURL = `/sign-up?orgId=${org.id.toString()}&orgName=${encodeURIComponent(org.name)}&uid=${user.id}&email=${encodeURIComponent(user.email)}`;
      }
    }

    yield all([
      put(handleSignInSuccess(user)),
      put(handleVerifyEmailSuccess(user)),
      put(handleAccountSuccess(account)),
      put(setAuthToken(token)),
      put(push(redirectURL))
    ]);
  } catch (err) {
    console.error('#onHandleVerifyEmail, catch block, err: ', err);
    put(push('/sign-up'));
  }
}

const user = localStorage.getItem('user') !== 'undefined'
  ? JSON.parse(localStorage.getItem('user'))
  : '';

const INITIAL_STATE = {
  isLoading: false,
  user: user,
  error: null,
  emailToken: null
};

export default function signUp (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SIGNUP_REQUEST:
    case CONTACT_US_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case VERIFY_EMAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
        emailToken: action.payload
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload
      };
    case VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload
      };
    case SIGNUP_FAILURE:
    case VERIFY_EMAIL_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: action.payload.errors
      };
    case RESEND_EMAIL_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case RESEND_EMAIL_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    case CONTACT_US_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload
      };
    case RESEND_EMAIL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case CONTACT_US_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: action.payload
      };
    default:
      return state;
  }
}
