import React from 'react';
import styled from 'styled-components';
import { CardElement } from '@stripe/react-stripe-js';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { Field as FField, Form as FForm } from 'react-final-form';
import { Row, Col, Form, Divider } from 'antd';

import { promiseListener } from '../../../store';
import {
  CREATE_SUBSCRIPTION_REQUEST,
  CREATE_SUBSCRIPTION_SUCCESS,
  CREATE_SUBSCRIPTION_FAILURE
} from '../../../modules/account/redux';
import { capitalize } from '../../fields/renderFields';
import {
  InputControl,
  EmailInputControl,
  CardInputControl
} from '../../fields/Fields';
import { StyledButton } from '../../fields/Button';
import * as validations from '../../../utils/validations';

const CardPaymentCol = styled(Col)`
  @media (max-width: 1199px) {
    min-width: 75vw;
  }
`;

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#8053D7',
      color: '#000',
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#fce883'
      }
    }
  }
};

export const PaymentDetailsForm = ({
  isLoading,
  interval,
  stripe,
  elements,
  user,
  account = {
    subscriptionPlan: '',
    stripe: { priceAmount: '0' }
  }
}) => {
  return (
    <MakeAsyncFunction
      listener={promiseListener}
      start={CREATE_SUBSCRIPTION_REQUEST}
      resolve={CREATE_SUBSCRIPTION_SUCCESS}
      reject={CREATE_SUBSCRIPTION_FAILURE}
    >
      {onSubmit => (
        <>
          <FForm
            initialValues={{
              firstName: user.firstName,
              lastName: user.lastName,
              billingEmail: user.email,
              stripe: stripe,
              cardElement: ''
            }}
            style={{ textAlign: 'center' }}
            onSubmit={onSubmit}
            render={({
              submitError,
              handleSubmit,
              submitting,
              hasValidationErrors,
              hasSubmitErrors,
              dirtySinceLastSubmit,
              values
            }) => {
              const submit = handleSubmit;

              return (
                <>
                  {isLoading && <div>Loading...</div>}
                  {submitError && <div className='error'>{submitError}</div>}
                  {submitError && <>{submitError.message}</>}
                  <Form onFinish={handleSubmit}>
                    <fieldset className='FormGroup'>
                      {/* MONTHLY SUBSCRIPTION */}
                      {!isLoading &&
                        <>
                          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify='center'>
                            <Col xl={10} sm={20} xs={24}>
                              <strong>{capitalize(interval)}ly Subscription</strong>
                              <small style={{ float: 'right' }}>Pay {capitalize(interval)}ly</small>
                              <Divider style={{ margin: '10px auto' }} />
                            </Col>
                          </Row>
                          {/* PLAN TYPE */}
                          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify='center'>
                            <Col xl={10} sm={20} xs={24}>
                              <small>PLAN</small>
                              <Divider style={{ margin: 5 }} />
                              <strong>{account?.subscriptionPlan?.toUpperCase()}</strong>
                            </Col>
                          </Row>
                          <br />
                          {/* PRICE/MONTH TOTAL */}
                          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify='center'>
                            <Col xl={7} sm={20} xs={24}>
                              <small>PRICE / {interval?.toUpperCase()}</small>
                            </Col>
                            <Col xl={3} sm={20} xs={24}>
                              <small>TOTAL</small>
                            </Col>
                          </Row>
                          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify='center'>
                            <Col xl={10} sm={20} xs={24}>
                              <Divider style={{ margin: '10px auto' }} />
                            </Col>
                          </Row>
                          {/* PRICE OF THE PLAN */}
                          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify='center'>
                            <Col xl={7} sm={20} xs={24}>
                              <strong>${account?.stripe?.priceAmount / 100}</strong>
                            </Col>
                            <Col xl={3} sm={20} xs={24}>
                              <small />
                            </Col>
                          </Row>
                        </>}
                      <br />
                      <br />
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify='center'>
                        <Col xl={10} sm={20} xs={24}>
                          <strong>Payment Details</strong>
                          <Divider style={{ margin: '10px auto' }} />
                        </Col>
                      </Row>
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify='center'>
                        {/* FIRST NAME */}
                        <Col xl={5} sm={20} xs={24}>
                          <small style={{ display: 'flex', justifyContent: 'left' }}>First Name</small>
                          <FField
                            name='firstName'
                            component={InputControl}
                            type='text'
                            placeholder='First Name*'
                            parse={value => value && value.toLowerCase()}
                            format={capitalize}
                            validate={validations.composeValidators(
                              validations.required,
                              validations.minLength(2),
                              validations.maxLength(20)
                            )}
                          />
                        </Col>
                        {/* LAST NAME */}
                        <Col xl={5} sm={20} xs={24}>
                          <small style={{ display: 'flex', justifyContent: 'left' }}>Last Name</small>
                          <FField
                            name='lastName'
                            component={InputControl}
                            parse={value => value && value.toLowerCase()}
                            format={capitalize}
                            validate={validations.composeValidators(
                              validations.required,
                              validations.minLength(2),
                              validations.maxLength(20)
                            )}
                            type='text'
                            placeholder='Last Name*'
                          />
                        </Col>
                      </Row>
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify='center'>
                        {/* BILLING EMAIL */}
                        <Col xl={10} sm={20} xs={24}>
                          <small style={{ display: 'flex', justifyContent: 'left' }}>Billing Email</small>
                          <FField
                            name='billingEmail'
                            component={EmailInputControl}
                            type='text'
                            placeholder='Billing Email*'
                            validate={validations.composeValidators(
                              validations.required,
                              validations.email
                            )}
                          />
                        </Col>
                      </Row>
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify='center'>
                        {/* FIRST NAME */}
                        <Col xl={10} sm={20} xs={24}>
                          <small style={{ display: 'flex', justifyContent: 'left' }}>Promo Code</small>
                          <FField
                            name='promoCode'
                            component={InputControl}
                            type='text'
                            placeholder='PromoCode2024!'
                            parse={value => value && value.toUpperCase()}
                            validate={validations.composeValidators(
                              validations.minLength(6),
                              validations.maxLength(20)
                            )}
                          />
                        </Col>
                      </Row>
                    </fieldset>
                    <fieldset className='FormGroup'>
                      <Row justify='center'>
                        <CardPaymentCol xl={10}>
                          <FField
                            name='cardNumberElement'
                            component={CardInputControl}
                            options={CARD_OPTIONS}
                          />
                          <br />
                        </CardPaymentCol>
                      </Row>
                    </fieldset>
                    {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                    <Row justify='center'>
                      <Col xl={6} sm={20} xs={24}>
                        <StyledButton
                          style={{ width: '100%', color: '#fff', height: 40 }}
                          error={submitError || hasValidationErrors || isLoading}
                          disabled={!stripe || !elements || submitError || hasValidationErrors || isLoading || submitting}
                          onClick={(event) => {
                            event.preventDefault();

                            // console.log('pay button clicked');
                            const card = elements.getElement(CardElement);
                            console.log('card element: ', card);
                            values.cardElement = card;
                            // here we will call both the strip handle click stuff
                            submit();
                          }}
                        >
                          Save Payment Method
                        </StyledButton>
                      </Col>
                    </Row>
                  </Form>
                </>
              );
            }}
          />
        </>
      )}
    </MakeAsyncFunction>
  );
};
