import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, List as AList, Empty } from 'antd';
import { StyledTitle as Title } from '../../../fields/Title';
import { RightOutlined } from '@ant-design/icons';
import { StyledContainer } from '../../../../styles/StyledComponents';
import BorderedContainerWithTitle from '../../../fields/BorderedContainerWithTitle';

export const RecentSection = ({ myQuestionSets, myClasses }) => {
  return (
    <>
      <Title level={2}>Recent</Title>
      <Row gutter={[30, 30]} style={{ paddingTop: 20 }}>
        <Col xs={24} xl={12} style={{ marginBottom: 20 }}>
          <BorderedContainerWithTitle title='My Recent Question Sets'>
            <AList
              itemLayout='horizontal'
              dataSource={myQuestionSets}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="Sorry, you don't have any question sets!"
                  />
                )
              }}
              renderItem={(item, index) => {
                return (
                  <>
                    <StyledContainer
                      style={{ padding: 15, borderLeft: '20px solid #5BBCBD' }}
                    >
                      <AList.Item
                        key={`${item.title}-${item.id}`}
                        style={{ width: '100%' }}
                      >
                        <AList.Item.Meta
                          title={
                            <Link to={`/teacher/edit-question-set/${item.id}`}>
                              {item.title}
                            </Link>
                          }
                        />
                        <Link to={`/teacher/edit-question-set/${item.id}`}>
                          <RightOutlined styles={{ float: 'right' }} />
                        </Link>
                      </AList.Item>
                    </StyledContainer>
                  </>
                );
              }}
            />
          </BorderedContainerWithTitle>
        </Col>
        <Col xs={24} xl={12}>
          <BorderedContainerWithTitle title='My Classes'>
            <AList
              itemLayout='horizontal'
              dataSource={myClasses}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="Sorry, you don't have any classes!"
                  />
                )
              }}
              renderItem={(item, index) => {
                return (
                  <>
                    <StyledContainer
                      style={{ padding: 15, borderLeft: '20px solid #9E7CDF' }}
                    >
                      <AList.Item
                        key={`${item.title}-${item.id}`}
                        style={{ width: '100%' }}
                      >
                        <AList.Item.Meta
                          title={
                            <Link to={`/teacher/classes/${item.id}`}>
                              {item.title}
                            </Link>
                          }
                        />
                        <Link to={`/teacher/classes/${item.id}`}>
                          <RightOutlined styles={{ float: 'right' }} />
                        </Link>
                      </AList.Item>
                    </StyledContainer>
                  </>
                );
              }}
            />
          </BorderedContainerWithTitle>
        </Col>
      </Row>
    </>
  );
};
