import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

import { CenteredContainer as Container } from '../fields/Container';
import TutorDOwl from '../../assets/tutord-signin-page.svg';
import { SignUpForm } from './SignUpForm';
import { BackPage, SignInUpCol, YellowUnderline, Img, BigTitle, WhiteText } from '../../styles/StyledComponents';
import Header from '../template/Header';

class SignUpPage extends Component {
  constructor (props) {
    super(props);

    this.state = {
      email: '',
      orgName: '',
      orgId: '',
      uid: ''
    };
  }

  componentWillMount () {
    const values = new URLSearchParams(this.props.location.search);

    if (values.get('email') && values.get('orgName') && values.get('orgId') && values.get('uid')) {
      this.setState(prevState => ({
        email: decodeURIComponent(values.get('email')),
        uid: values.get('uid'),
        orgName: decodeURIComponent(values.get('orgName')),
        orgId: values.get('orgId')
      }));
    }
  }

  render () {
    const { isLoading } = this.props;

    return (
      <Container style={{ width: '100%' }}>
        <Row justify='center'>
          <Col xl={10} xs={24} style={{ paddingBottom: 30 }}>
            <Header />
            <div style={{ padding: '0px 24px', textAlign: 'center' }}>
              <BigTitle>TutorD</BigTitle>
              <Img src={TutorDOwl} alt='TutorD Owl' />
              <WhiteText>
                <FormattedMessage id='sign-up.title' />
              </WhiteText>
              <WhiteText style={{ fontSize: '1.5em' }}>
                <FormattedMessage id='sign-up.subTitle' />
              </WhiteText>
              <Link to='/'>
                <BackPage>
                  <ArrowLeftOutlined style={{ marginRight: 5 }} />
                  Back
                </BackPage>
              </Link>
            </div>
          </Col>
          <SignInUpCol xl={14} xs={24}>
            <YellowUnderline level={3}>
              Sign Up
            </YellowUnderline>
            <SignUpForm
              isLoading={isLoading}
              initialValues={{
                uid: this.state.uid || '',
                email: this.state.email || '',
                orgId: this.state.orgId || '',
                orgName: this.state.orgName || ''
              }}
            />
          </SignInUpCol>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.signUp.isLoading
  };
};

export default connect(mapStateToProps, {})(SignUpPage);
