import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import app from './app/redux';
import auth from './auth/redux';
import signUp from './sign-up/redux';
import account from './account/redux';
import classes from './classes/redux';
import students from './students/redux';
import dashboard from './dashboard/redux';
import onBoarding from './sign-up/redux/onboarding';
import questionSets from './question-set/redux';
import enrollment from './sign-up/redux/enrollment';
import notification from './notification/redux';
import setStudentPhoneNumber from './sign-up/redux/set-student-phone-number';
import verifyStudentPhoneNumber from './sign-up/redux/verify-student-phone-number';
import identifyStudentLearningStyle from './sign-up/redux/identify-student-learning-style';

const rootReducer = (history) =>
  combineReducers({
    app,
    auth,
    signUp,
    account,
    classes,
    students,
    dashboard,
    onBoarding,
    enrollment,
    notification,
    questionSets,
    setStudentPhoneNumber,
    verifyStudentPhoneNumber,
    identifyStudentLearningStyle,
    router: connectRouter(history)
  });

export default rootReducer;
