import React from 'react';
import { withRouter } from 'react-router-dom';
import { Field as FField } from 'react-final-form';
import { FieldArray as FFieldArray } from 'react-final-form-arrays';
import { FormattedMessage } from 'react-intl';
import { Col, Row, Tag } from 'antd';
import { StyledTitle as Title } from '../../fields/Title';
import { CheckboxInputControl, InputControl } from '../../fields/Fields';

import NotSure from '../../../assets/onboarding/goals/not-sure.svg';
import ImproveGrades from '../../../assets/onboarding/goals/improve-grades.svg';
import PerformanceTracking from '../../../assets/onboarding/goals/performance-tracking.svg';
import IncreaseEngagement from '../../../assets/onboarding/goals/increase-engagement.svg';
import IdentifyLearningStyles from '../../../assets/onboarding/goals/identify-learning-styles.svg';

import Icon from '@ant-design/icons';
import Auditory from '../../../assets/enrollment/auditory.svg';
import { Img, YellowUnderline } from '../../../styles/StyledComponents';

const { CheckableTag } = Tag;

const GOALS = [
  'Increase engagement',
  'Performance tracking',
  'Identify learning styles',
  'Improve grades',
  'Other'
];

// eslint-disable-next-line
const OPTIONS_OBJ = {
  'Increase engagement': '#D7753E',
  'Performance tracking': '#5BBCBD',
  'Identify learning styles': '#FF8BA7',
  'Improve grades': '#8053D7'
};

const CheckableTagArrayControl = ({ fields, options }) => {
  const onCheckToggle = (option, checked) => {
    if (checked) {
      if (fields.value && !fields.value.indexOf(option) > -1) {
        fields.push(option);
      } else if (GOALS.includes(option)) {
        fields.push(option);
      }
    } else if (!checked && fields.value && fields.value.indexOf(option) > -1) {
      fields.remove(fields.value.indexOf(option));
    }
  };

  return (
    <div>
      {options.map((option, index) => {
        return (
          <CheckableTag
            key={option}
            checked={fields.value && fields.value.indexOf(option) > -1}
            onChange={(clicked) => onCheckToggle(option, clicked)}
            style={{ padding: 10, backgroundColor: '#eee' }}
          >
            {option}
          </CheckableTag>
        );
      })}
    </div>
  );
};

const GoalsForm = ({ match }) => {
  return (
    <Row justify='center' align='middle' style={{ padding: '50px 10px' }}>
      <Col xl={22} xs={22}>
        <YellowUnderline>
          <Title level={3}>
            <FormattedMessage
              id={`on-boarding.${match.params.step}.main.title`}
            />
          </Title>
        </YellowUnderline>
        <small style={{ textAlign: 'center' }}>Please select your goals:</small>
        <Row gutter={[16, 16]}>
          <Col xl={8} xs={20} style={{ paddingBottom: 20 }}>
            <FField
              name='goals'
              value='increase-engagement'
              type='checkbox'
              component={CheckboxInputControl}
              icon={
                <Icon
                  component={() => <Img src={IncreaseEngagement} width={150} />}
                />
              }
            />
          </Col>
          <Col xl={8} xs={20} style={{ paddingBottom: 20 }}>
            <FField
              name='goals'
              value='improve-grades'
              type='checkbox'
              component={CheckboxInputControl}
              icon={
                <Icon
                  component={() => <Img src={ImproveGrades} width={150} />}
                />
              }
            />
          </Col>
          <Col xl={8} xs={20} style={{ paddingBottom: 20 }}>
            <FField
              name='goals'
              value='identify-learning-styles'
              type='checkbox'
              component={CheckboxInputControl}
              icon={
                <Icon
                  component={() => (
                    <Img src={IdentifyLearningStyles} width={150} />
                  )}
                />
              }
            />
          </Col>
          <Col xl={8} xs={20} style={{ paddingBottom: 20 }}>
            <FField
              name='goals'
              value='performance-tracking'
              type='checkbox'
              component={CheckboxInputControl}
              icon={
                <Icon
                  component={() => (
                    <Img src={PerformanceTracking} width={150} />
                  )}
                />
              }
            />
          </Col>
          <Col xl={8} xs={20} style={{ paddingBottom: 20 }}>
            <FField
              name='goals'
              value='not-sure'
              type='checkbox'
              component={CheckboxInputControl}
              icon={
                <Icon component={() => <Img src={NotSure} width={150} />} />
              }
            />
          </Col>
        </Row>
        <Col xl={22} xs={22}>
          <YellowUnderline>
            <Title level={3}>How many students do you teach?</Title>
          </YellowUnderline>
          <FField
            name='avgClassSize'
            component={InputControl}
            style={{ maxWidth: 300 }}
            type='number'
            placeholder='21'
          />
        </Col>
        <br />
        <br />
      </Col>
    </Row>
  );
};

export default withRouter(GoalsForm);
