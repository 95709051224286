import React from 'react';
import styled from 'styled-components';
import { Field as FField, Form as FForm, useForm } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { Input, Form as AForm } from 'antd';
import PhoneInput, { getCountryCallingCode, isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

export const PhoneInputControl = ({
  name,
  label,
  input,
  style,
  placeholder,
  disabled = false,
  callingCode,
  countryCode,
  meta,
  ...rest
}) => {
  const { change } = useForm();

  return (
    <>
      <AForm.Item
        name={name}
        labels={label}
        style={style}
        hasFeedback
        validateStatus={((meta.error || meta.submitError) && meta.submitFailed) ? 'error'
          : (meta.valid && meta.submitSucceeded) ? 'success'
            : (meta.data.warning) ? 'warning' : ''}
        help={(meta.data.warning || meta.error || meta.submitError) && meta.touched && (
          <span>{meta.data.warning || meta.error || meta.submitError}</span>
        )}
      >
        <PhoneInput
          {...input}
          {...rest}
          placeholder={placeholder}
          defaultCountry='US'
          initialValueFormat='national'
          countryCallingCodeEditable
          onCountryChange={(_countryCode) => {
            change(countryCode, _countryCode);
            change(callingCode, `+${getCountryCallingCode(_countryCode)}`);
          }}
          error={(meta.data.warning || meta.error || meta.submitError) && meta.touched && (
            <span>{meta.data.warning || meta.error || meta.submitError}</span>
          )}
        />
      </AForm.Item>
      {/* <pre>{JSON.stringify(meta, 0, 2)}</pre> */}
    </>
  );
};
