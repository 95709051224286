import { all, call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import request from '../../../lib/request';
import { FORM_ERROR } from 'final-form';
import * as braze from '@braze/web-sdk';

import { parseFormErrors } from '../../../utils/parseFormErrors';
import { ROLE_ADMIN, ROLE_TEACHER } from '../../../utils/roles';
import { handleAccountSuccess } from '../../account/redux';

export const SIGNIN_REQUEST = 'auth/signin/SIGNIN_REQUEST';
export const SIGNIN_SUCCESS = 'auth/signin/SIGNIN_SUCCESS';
export const SIGNIN_FAILURE = 'auth/signin/SIGNIN_FAILURE';
export const SIGNIN_CANCELLED = 'auth/signin/SIGNIN_CANCELLED';

export const SIGNOUT_REQUEST = 'auth/signout/SIGNOUT_REQUEST';
export const SIGNOUT_SUCCESS = 'auth/signout/SIGNOUT_SUCCESS';

export const AUTHENTICATED = 'auth/signin/AUTHENTICATED';
export const UNAUTHENTICATED = 'auth/signin/UNAUTHENTICATED';

export const SET_TOKEN = 'auth/login/SET_TOKEN';
export const UNSET_TOKEN = 'auth/login/UNSET_TOKEN';

const user =
  localStorage.getItem('user') !== 'undefined'
    ? JSON.parse(localStorage.getItem('user'))
    : '';

const INITIAL_STATE = user
  ? {
    authenticated: true,
    user: user,
    token: null,
    isLoading: false,
    error: null
  }
  : {
    user: null,
    token: null,
    authenticated: false,
    isLoading: false,
    error: null
  };

export const handleSignInSuccess = (payload) => ({
  type: SIGNIN_SUCCESS,
  payload
});

export const handleSignOutRequest = () => ({
  type: SIGNOUT_REQUEST,
  user: null,
  token: null
});

export const handleSignOutSuccess = () => ({
  type: SIGNOUT_SUCCESS
});

export const setAuthToken = (payload) => ({
  type: SET_TOKEN,
  payload
});

export const unsetAuthToken = () => ({
  type: UNSET_TOKEN
});

export function * onHandleSignInRequest ({ payload }) {
  console.log('#onHandleSignInRequest');
  try {
    const response = yield call(request.post, '/auth/login', {
      email: payload.email,
      password: payload.password
    });

    const {
      user,
      token,
      account
    } = response.data;

    localStorage.setItem('token', JSON.stringify(token));
    localStorage.setItem('user', JSON.stringify(user));

    braze.changeUser(user.id);

    // if admin and account already onboarded and user onboarded then go to admin dashboard
    // otherwise check if account:
    // - subscriptionPlanSelected
    // - orgDetailsVerified
    // - paymentDetailsVerified
    // check if admin:
    // - isOwner
    // - isAdmin
    // - isOnboarded
    // if any missing then we redirect them to complete this step
    // if teacher and onboarded then go to teacher dashboard
    // otherwise send teacher to onboarding flow

    let redirectPath = '';

    if (
      user.role === ROLE_ADMIN &&
      user.isAdmin &&
      user.isOwner &&
      !user.isOnboarded
    ) {
      if (account) {
        if (!account.subscriptionPlanSelected) {
          redirectPath = '/teacher/organizations/plans';
        } else if (!account.orgDetailsVerified) {
          redirectPath = '/teacher/organizations/setup';
        } else if (!account.paymentDetailsVerified) {
          redirectPath = '/teacher/organizations/payment';
        } else {
          redirectPath = '/admin/dashboard';
        }
      } else {
        redirectPath = '/teacher/organizations/plans';
      }
    } else if (user.role === ROLE_ADMIN &&
      user.isAdmin &&
      user.isOwner &&
      user.isOnboarded) {
      if (account) {
        if (account.subscriptionPlan === 'basic') {
          redirectPath = '/teacher/dashboard';
        }
      }
    } else if (user.role === ROLE_TEACHER) {
      redirectPath = user.isOnboarded ? '/teacher/dashboard' : '/teacher/on-boarding/0';
    }

    yield all([
      put(handleSignInSuccess(user)),
      put(handleAccountSuccess(account)),
      put(setAuthToken(token)),
      put(push(redirectPath))
    ]);
  } catch (err) {
    console.error('#onHandleSignInRequest, catch block, err: ', err);

    if (err.message === 'Network Error' || err?.response?.message === 'Network Error') {
      return yield put({
        type: SIGNIN_SUCCESS,
        payload: { [FORM_ERROR]: 'Check your connection and please try again later.' }
      });
    }

    if (err.response.status === 500) {
      return yield put({
        type: SIGNIN_FAILURE,
        payload: { [FORM_ERROR]: 'Its not you, its us....... Please try again later.' }
      });
    }

    // TODO: Make this show an error in the toast notifaction bar but still have a SIGNIN_SUCCESS as
    // TODO: as the system did make a successful req/res call to th backend, maybe send the proper error message
    if (err.response.status === 401) {
      return yield put({
        type: SIGNIN_FAILURE,
        payload: { [FORM_ERROR]: 'Login Failed, please check username/password' }
      });
    }
  }
}

export function * onHandleSignOutRequest () {
  // console.log('#onHandleSignOutRequest');
  try {
    // console.log('#onHandleSignOutRequest, try block');
    localStorage.removeItem('token');
    localStorage.removeItem('user');

    yield all([
      put(unsetAuthToken()),
      put(handleSignOutSuccess()),
      put(push('/'))
    ]);
  } catch (err) {
    console.error('#onHandleSignOutRequest, err: ', err);
  }
}

export default function auth (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SIGNIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        authenticated: false
      };
    case SIGNIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        authenticated: true,
        user: action.payload
      };
    case SIGNOUT_REQUEST:
      return {
        ...state,
        isLoading: true,
        authenticated: false
      };
    case SIGNOUT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        authenticated: false,
        user: null
      };
    case SIGNIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        authenticated: false,
        error: action.payload
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload
      };
    case UNSET_TOKEN:
      return {
        ...state,
        token: action.payload
      };
    case AUTHENTICATED:
      return {
        ...state,
        authenticated: true
      };
    case UNAUTHENTICATED:
      return {
        ...state,
        authenticated: false
      };
    default:
      return state;
  }
}
