import React from 'react';
import 'react-bulma-components/dist/react-bulma-components.min.css';
import { Layout } from 'antd';
import Header from '../template/Header';
import Footer from '../template/Footer';
import { LandingPageHeader } from './sections/LandingPageHeader';
import { HowDoesItWork } from './sections/HowDoesItWork';
import { Benefits } from './sections/Benefits';
import { ScheduleDemo } from './sections/ScheduleDemo';
import { LearningStyles } from './sections/LearningStyles';
import { WhatIsTutorD } from './sections/WhatIsTutorD';

const { Content } = Layout;

const LandingPage = () => (
  <>
    <Layout style={{ width: '100%', backgroundColor: '#e5e5e5' }}>
      <Header />
      {/* LANDING PAGE HEADER */}
      <LandingPageHeader />
      <Content className='site-layout' style={{ margin: '30px 0' }}>
        {/* WHAT IS TUTORD CONTAINER */}
        <WhatIsTutorD />
        {/* HOW DOES IT WORK SECTION */}
        <HowDoesItWork />
        {/* TEAL CONTAINER w/ BENEFITS */}
        <Benefits />
        {/* YELLOW CONTAINER w/ SCHEDULE DEMO */}
        <ScheduleDemo />
        {/* BLANK CONTAINER w/ LEARNING STYLES */}
        <LearningStyles />
      </Content>
      <Footer />
    </Layout>
  </>
);

export default LandingPage;
