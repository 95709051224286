import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga4';
import { ConnectedRouter as Router } from 'connected-react-router';
import { ThemeProvider } from 'styled-components';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import * as braze from '@braze/web-sdk';
import 'antd/dist/antd.css';

import env from './lib/env';
import store, { history } from './store';

import Index from './components';
import GlobalStyles, { getTheme } from './styles';
import './global.css';

import messages from './i18n/messages';
import { flattenMessages } from './lib/utils';
import * as serviceWorker from './serviceWorker';

const theme = getTheme();

let locale =
  (navigator.language && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage ||
  'en-US';

if (locale === 'en' || locale === 'en-us') { locale = 'en-US'; }

// Load stripePromise to init Stripe Elements for all children components to have access to
const stripePromise = loadStripe(env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

ReactGA.initialize(env.REACT_APP_GA_ID, {
  testMode: (env.REACT_APP_ENVIRONMENT === 'dev')
});

braze.initialize(env.REACT_APP_BRAZE_API_KEY, {
  baseUrl: env.REACT_APP_BRAZE_SDK_ENDPOINT,
  enableLogging: (env.REACT_APP_ENVIRONMENT === 'dev')
});

// optionally show all in-app messages without custom handling
braze.automaticallyShowInAppMessages();
braze.openSession();
braze.getUser()?.setCustomUserAttribute(`visited TutorD env: ${env.REACT_APP_ENVIRONMENT}`, new Date());

ReactDOM.render(
  <React.StrictMode>
    <Elements stripe={stripePromise}>
      <Provider store={store}>
        <Router history={history}>
          <IntlProvider defaultLocale='en-US' locale={locale} messages={flattenMessages(messages['en-US'])}>
            <ThemeProvider theme={theme}>
              <Index />
              <GlobalStyles />
            </ThemeProvider>
          </IntlProvider>
        </Router>
      </Provider>
    </Elements>
  </React.StrictMode>,
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
