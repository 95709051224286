import React from 'react';
import { Row, Col, Tag } from 'antd';
import OwlIcon from '../../../assets/owl-icon.svg';
import IncreaseLearningIcon from '../../../assets/increase-learning.svg';

export const Benefits = () => {
  return (
    <>
      <Row justify='center' style={{ margin: '40px auto' }}>
        <Col
          xl={20}
          sm={22}
          style={{
            background: '#5BBCBD',
            padding: 40,
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
          }}
        >
          {/* BENEFITS TAG */}
          <div style={{ marginBottom: 40 }}>
            <Tag
              style={{
                background: '#E5A84F',
                color: '#fff',
                border: 'none',
                fontWeight: 'bolder'
              }}
            >
                BENEFITS
            </Tag>
          </div>
          {/* ROW FOR TWO COLUMNS */}
          <Row justify='space-around'>
            {/* LEFT COLUMN */}
            <Col xl={10}>
              <img src={OwlIcon} alt='' />
              <br />
              <br />
              <p style={{ color: '#fff', fontFamily: 'Montserrat Bold', fontSize: '2em' }}>
                  A tool for Educators to keep track of student progress.
              </p>
              <p style={{ color: '#fff' }}>
                  We at TutorD have a philosophy of fast positive feedback loops, with as little noise
                  as possible.  We measure any and all levels of student engagement; with this approach
                  Educators can really pin point a disconnect in the learning experience.
              </p>
            </Col>
            {/* RIGHT COLUMN */}
            <Col xl={10}>
              <img src={IncreaseLearningIcon} alt='' />
              <br />
              <br />
              <p style={{ color: '#fff', fontFamily: 'Montserrat Bold', fontSize: '2em' }}>
                  Achieving a higher level of understanding through practice.
              </p>
              <p style={{ color: '#fff' }}>
                  By reducing the tech toolchain to the bare minimum we aim to provide a low barrier of
                  entry for student engagement. Couple that with allowing students to self identify how
                  they think they learn provides the tools and insights to accelerate and increase active engagement.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
