import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { FieldArray as FFieldArray } from 'react-final-form-arrays';
import { Button, Col, Typography } from 'antd';
import * as validations from '../../../../utils/validations';
import { StudentEnrollmentsDrawer } from './StudentEnrollmentsDrawer';
import { TutorGroupStudentsListItem } from '../create-tutor-group/TutorGroupStudentsListItem';

const StyledStudentEnrollmentsListContainer = styled(Col)`
  width: 100%;
  display: flex;
  flex-direction: column;

  & .students-list__title {
    display: inline-block;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #262626;
    margin-bottom: 15px;
  }

  & .students-list__placeholder {
    color: #bfbfbf;
    user-select: none;
  }
`;

const StyledSubtitle = styled.span`
  display: inline-block;
  width: 100%;
  font-size: 14px;
  color: #000000;
  margin-bottom: 15px;
`;

const StyledButton = styled(Button)`
  background-color: #5bbcbd;
  border: none;
  border-radius: 10px;
  font-weight: bolder;
  color: #fff;
  height: 40px;
  padding: 0 17px;
  min-width: 100px;
  margin-top: 20px;
  width: max-content;

  &:hover,
  &:focus {
    background-color: #60e4e5;
    border: none;
    color: #fff;
  }
`;

const { Title } = Typography;

export const StudentEnrollmentsList = ({
  enrolledStudents = [],
  unEnrolledStudents = [],
  handleFieldReset
}) => {
  const [childrenDrawer, setChildrenDrawer] = useState(false);

  const showChildrenDrawer = useCallback(() => {
    console.log('StudentEnrollmentsList#showDrawer clicked');
    handleFieldReset('title');
    setChildrenDrawer(true);
  }, [handleFieldReset]);

  const onChildrenDrawerClose = useCallback(() => {
    console.log('StudentEnrollmentsList#onChildrenDrawerClose clicked');
    setChildrenDrawer(false);
  }, []);

  const renderedStudentsButtonText = useMemo(
    () =>
      enrolledStudents && enrolledStudents?.length > 0
        ? '+/- STUDENTS'
        : 'ADD STUDENTS +',
    [enrolledStudents]
  );

  return (
    <StyledStudentEnrollmentsListContainer xl={24}>
      <FFieldArray name='enrolledStudents' validate={validations.composeValidators(validations.minLength(0))}>
        {({ fields }) => (
          <>
            <Title level={5}>Enrolled Students</Title>
            <StyledSubtitle>
              {fields?.value?.length ?? '0'} / 25 Students Enrolled
            </StyledSubtitle>
            {fields?.value?.map((student, index) => (
              <TutorGroupStudentsListItem
                key={index}
                student={student}
                index={index}
              />
            ))}
            {(!fields?.value || fields?.value?.length === 0) && (
              <span className='students-list__placeholder'>
                Please add students to the class
              </span>
            )}
            <StudentEnrollmentsDrawer
              studentEnrollments={fields.value}
              unEnrolledStudents={unEnrolledStudents}
              onClose={onChildrenDrawerClose}
              visible={childrenDrawer}
              push={fields.push}
              remove={fields.remove}
            />
          </>
        )}
      </FFieldArray>
      <StyledButton onClick={showChildrenDrawer}>
        {renderedStudentsButtonText}
      </StyledButton>
    </StyledStudentEnrollmentsListContainer>
  );
};
