import _ from 'lodash';

export function authHeader () {
  // return authorization header with jwt token
  let token, user, orgId;
  try {
    token = JSON.parse(localStorage.getItem('token'));
    user = JSON.parse(localStorage.getItem('user'));

    let _orgId;
    if (user && user.orgId) {
      _orgId = user.orgId;

      if (_.isObject(_orgId)) {
        orgId = user.orgId._id.toString();
      } else if (_.isString(_orgId)) {
        orgId = user.orgId;
      } else {
        console.warn('authHeader, please investigate orgId type');
        debugger;
      }
    }
  } catch (err) {
    console.error('Error, authHeader, err: ', err);
    return err;
  }

  const headersObj = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
    withCredentials: true
  };

  if (token && user) {
    return Object.assign(headersObj, {
      Authorization: ' ' + token,
      uid: ' ' + user.id,
      org_id: ' ' + orgId
    });
  }

  return headersObj;
}
