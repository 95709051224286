import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'antd';

import { CenteredContainer as Container } from '../../../fields/Container';
import Header from '../../../template/Header';
import IdentifyStudentLearningStyleForm from './IdentifyStudentLearningStyleForm';
import TutorDOwl from '../../../../assets/tutord-signin-page.svg';
import { Img, MainCol, BigTitle, WhiteText } from '../../../../styles/StyledComponents';

class IdentifyStudentLearningStylePage extends Component {
  render () {
    const { isLoading } = this.props;
    return (
      <Container style={{ width: '100%' }}>
        <Row justify='center'>
          <Col xl={11} xs={24}>
            <Header />
            <Col xl={23} xs={22} style={{ textAlign: 'center', paddingLeft: 20 }}>
              <BigTitle style={{ fontSize: '4em' }}>
                <FormattedMessage id='enrollment.2.aside.title' />
              </BigTitle>
              <br />
              <Img src={TutorDOwl} alt='TutorD Owl' style={{ marginBottom: 20 }} />
              <WhiteText>
                <FormattedMessage id='enrollment.2.aside.welcome' />
              </WhiteText>
              <WhiteText style={{ fontWeight: 100, fontFamily: 'Montserrat Light', fontSize: '1.5em' }}>
                <FormattedMessage id='enrollment.2.aside.subTitle' />
              </WhiteText>
            </Col>
          </Col>
          <MainCol xl={13} xs={24}>
            <IdentifyStudentLearningStyleForm isLoading={isLoading} />
          </MainCol>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.identifyStudentLearningStyle.isLoading
  };
};

export default connect(mapStateToProps, {})(IdentifyStudentLearningStylePage);
