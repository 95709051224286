import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import arrayMutators from 'final-form-arrays';
import { Row, Col, Tag, Menu, Form as AForm, Select } from 'antd';
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  EditFilled,
  LeftOutlined
} from '@ant-design/icons';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { StyledLink } from '../../../fields/renderFields';
import { dispatch, promiseListener } from '../../../../store';
import Auditory from '../../../../assets/enrollment/auditory.svg';
import HandsOn from '../../../../assets/enrollment/hands-on.svg';
import ReadingIcon from '../../../../assets/enrollment/reading.svg';
import VisualIcon from '../../../../assets/enrollment/visual.svg';
import NotSureIcon from '../../../../assets/enrollment/not-sure.svg';
import {
  EDIT_STUDENT_FAILURE,
  EDIT_STUDENT_REQUEST,
  EDIT_STUDENT_SUCCESS,
  handleStudentProfileGraphRequest,
  handleStudentProfileRequest
} from '../../../../modules/students/redux';
import {
  DoneButton,
  MainLayout,
  SecondaryLayout,
  StyledContent
} from '../../../../styles/StyledComponents';
import { StyledTitle as Title } from '../../../fields/Title';
import { Form as FForm, Field } from 'react-final-form';

import {
  EditableNameControl,
  EmailInputControl,
  SelectControl
} from '../../../fields/Fields';
import * as validations from '../../../../utils/validations';
import { FORM_ERROR } from 'final-form';
import { handleShowToast } from '../../../../modules/notification/redux';
import StudentTimelineGraph from './StudentTimelineGraph';
import CustomTreeSelect from '../../../fields/TreeSelect';
import LineChartTimeline from './LineChart';
import SiderNav from '../../../template/SiderNav';
import LoadingSpinner from '../../../../lib/components/loadingSpinner';

const chartData = [];
for (let i = 0; i < 20; i += 1) {
  chartData.push({
    x: new Date().getTime() + 1000 * 60 * 30 * i,
    y1: Math.floor(Math.random() * 100) + 1000,
    y2: Math.floor(Math.random() * 100) + 10
  });
}

const learningStylesMapByName = {
  visual: VisualIcon,
  auditory: Auditory,
  'hands-on': HandsOn,
  reading: ReadingIcon,
  'not-sure': NotSureIcon
};

const StudentProfilePage = (props) => {
  const {
    student = { firstName: '', lastName: '', learningStyles: [] },
    classWithTutorGroup = [],
    timelineData = [],
    tutorGroupSessions = [],
    graphData = [],
    isLoading,
    isGraphLoading
  } = props;

  const studentId = props.match.params.studentId;
  const [isEditing, setIsEditing] = useState(false);
  const [selectedFilterValue, setSelectedFilterValue] = useState('all');
  const [selectedSession, setSelectedSession] = useState('all session');

  const treeData = useMemo(() => {
    const tree = [
      {
        children: [],
        title: 'All Tutor',
        value: 'all'
      }
    ];
    classWithTutorGroup.forEach((item) => {
      const { title: className, id: classId } = item.classData;

      const classNode = {
        title: className,
        value: classId,
        children: [],
        disabled: true
      };

      if (item.tutorGroup?.length > 0) {
        item.tutorGroup?.forEach((item) => {
          const tutorNode = {
            title: item.title,
            value: item.id,
            children: []
          };
          classNode.children.push(tutorNode);
        });
      }

      tree[0].children.push(classNode);
    });
    return tree;
  }, [classWithTutorGroup]);

  const tutorSessions = useMemo(() => {
    const sessions = [
      {
        children: [],
        title: 'All session',
        value: 'all session'
      }
    ];
    tutorGroupSessions.forEach((item) => {
      if (item.tutorGroup === selectedFilterValue) {
        item.sessions.map((session) => {
          sessions.push({
            children: [],
            title: session.name,
            value: session.id
          });
        });
      }
    });
    return sessions;
  }, [tutorGroupSessions, selectedFilterValue]);

  useEffect(() => {
    dispatch(handleStudentProfileRequest(studentId));
    dispatch(handleStudentProfileGraphRequest({ studentId }));
  }, []);

  useEffect(() => {
    if (selectedSession || selectedFilterValue) {
      dispatch(
        handleStudentProfileGraphRequest({
          studentId,
          sessionId:
            selectedSession !== 'all session' ? selectedSession : undefined,
          tutorGroupId:
            selectedFilterValue !== 'all' ? selectedFilterValue : undefined
        })
      );
    }
  }, [selectedSession, selectedFilterValue]);

  // TREE SELECT FILTER ONCHANGE HANDLER
  const handleFilterChange = useCallback((value) => {
    if (value === 'all') {
      setSelectedFilterValue(value);
      setSelectedSession('all session');
    } else {
      setSelectedFilterValue(value);
      setSelectedSession('all session');
    }
  }, []);

  const menuItems = (
    <Menu>
      <Menu.Item key='auditory'>
        <img
          src={Auditory}
          width='150px'
          style={{ display: 'block', margin: '0 auto' }}
          alt=''
        />
      </Menu.Item>
      <Menu.Item key='hands-on'>
        <img
          src={HandsOn}
          width='150px'
          style={{ display: 'block', margin: '0 auto' }}
          alt=''
        />
      </Menu.Item>
      <Menu.Item key='reading'>
        <img
          src={ReadingIcon}
          width='150px'
          style={{ display: 'block', margin: '0 auto' }}
          alt=''
        />
      </Menu.Item>
      <Menu.Item key='visual'>
        <img
          src={VisualIcon}
          width='150px'
          style={{ display: 'block', margin: '0 auto' }}
          alt=''
        />
      </Menu.Item>
      <Menu.Item key='not-sure'>
        <img
          src={NotSureIcon}
          width='150px'
          style={{ display: 'block', margin: '0 auto' }}
          alt=''
        />
      </Menu.Item>
    </Menu>
  );

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    return (
      <Tag
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
          width: '110px',
          background: 'transparent',
          border: 'none',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <img
          src={learningStylesMapByName[value]}
          style={{ display: 'block', margin: '0 auto', width: '100px' }}
          alt={value}
        />
      </Tag>
    );
  };

  const OPTIONS = ['visual', 'auditory', 'hands-on', 'reading', 'not-sure'];

  return (
    <MainLayout hasSider>
      <SiderNav />
      <SecondaryLayout>
        <LoadingSpinner spinning={isLoading}>
          <MakeAsyncFunction
            listener={promiseListener}
            start={EDIT_STUDENT_REQUEST}
            resolve={EDIT_STUDENT_SUCCESS}
            reject={EDIT_STUDENT_FAILURE}
            getError={(action) => {
              console.log('SignInForm#getError, action: ', action);
              const payload = action.payload;
              const error = payload.email
                ? payload.email
                : payload.orgName
                  ? payload.orgName
                  : payload[FORM_ERROR]
                    ? payload[FORM_ERROR]
                    : 'something went wrong';
              dispatch(handleShowToast({ message: error, type: 'error' }));
            }}
          >
            {(onSubmit) => (
              <FForm
                name='edit-student-profile'
                initialValues={student}
                onSubmit={onSubmit}
                mutators={{
                  ...arrayMutators
                }}
                render={({
                  handleSubmit,
                  submitting,
                  hasValidationErrors,
                  hasSubmitErrors,
                  dirtySinceLastSubmit,
                  values,
                  submitError
                }) => {
                  return (
                    <AForm onFinish={handleSubmit}>
                      <StyledContent>
                        <Row gutter={[16, 16]}>
                          <Col xl={12} style={{ width: '100%' }}>
                            <Row
                              style={{
                                rowGap: 10,
                                flexDirection: 'row',
                                alignItems: 'baseline'
                              }}
                            >
                              <Title level={3}>
                                <StyledLink
                                  style={{ color: '#333', marginRight: 10 }}
                                  to='/teacher/students'
                                >
                                  <LeftOutlined />
                                </StyledLink>
                              </Title>
                              <Field
                                name='firstName'
                                component={EditableNameControl}
                                editing={isEditing}
                                onEnd={() => setIsEditing(false)}
                                tooltip='First Name'
                                level={3}
                                validate={validations.required}
                              />
                              <Field
                                name='lastName'
                                component={EditableNameControl}
                                tooltip='Last Name'
                                editing={isEditing}
                                onEnd={() => setIsEditing(false)}
                                level={3}
                                validate={validations.required}
                              />

                              {!isEditing && (
                                <Title level={3}>
                                  <EditFilled
                                    onClick={() => setIsEditing(!isEditing)}
                                    style={{
                                      height: '50%',
                                      marginLeft: '5px',
                                      color: '#1890ff'
                                    }}
                                  />
                                </Title>
                              )}
                            </Row>

                            <Col xl={12} style={{ margin: '10px 0' }}>
                              <Row style={{ gap: 5, marginBottom: 5 }}>
                                {student.emailVerified ? (
                                  <CheckCircleTwoTone twoToneColor='#52c41a' />
                                ) : (
                                  <CloseCircleTwoTone twoToneColor='#eb2f96' />
                                )}
                                <small>Email verified</small>
                              </Row>
                              <Row style={{ gap: 5, marginBottom: 5 }}>
                                {student.phoneVerified ? (
                                  <CheckCircleTwoTone twoToneColor='#52c41a' />
                                ) : (
                                  <CloseCircleTwoTone twoToneColor='#eb2f96' />
                                )}
                                <small>Phone verified</small>
                              </Row>
                              <Row style={{ gap: 5, marginBottom: 5 }}>
                                {student.isOnboarded ? (
                                  <CheckCircleTwoTone twoToneColor='#52c41a' />
                                ) : (
                                  <CloseCircleTwoTone twoToneColor='#eb2f96' />
                                )}
                                <small>Onboarded</small>
                              </Row>
                            </Col>

                            {/* EMAIL INPUT */}
                            <Col xl={12} style={{ margin: '5px 0' }}>
                              <small>Email Address</small>
                              <Field
                                name='email'
                                component={EmailInputControl}
                                type='text'
                                submitError={submitError}
                                placeholder='Email*'
                                tooltip='Email*'
                                label='Email*'
                                validate={validations.composeValidators(
                                  validations.required,
                                  validations.email
                                )}
                              />
                            </Col>
                            {/* LEARNER TYPE INPUT */}
                            <Col xl={24} style={{ margin: '10px 0' }}>
                              <small>Learner Type</small>

                              <Col style={{ maxWidth: 400, marginTop: 5 }}>
                                <Field
                                  name='learningStyles'
                                  component={SelectControl}
                                  tagRender={tagRender}
                                  options={OPTIONS}
                                  mode='multiple'
                                  value={[]}
                                  format={(value) => value || []}
                                  type='text'
                                  placeholder='Add students learning style'
                                />
                              </Col>
                            </Col>
                            <Col style={{ width: '100px' }}>
                              <DoneButton
                                htmlType='submit'
                                disabled={
                                  submitting ||
                                  ((hasSubmitErrors || hasValidationErrors) &&
                                    !dirtySinceLastSubmit) ||
                                  JSON.stringify(values) ===
                                    JSON.stringify(student)
                                }
                                loading={isLoading || submitting}
                                block
                              >
                                Save
                              </DoneButton>
                            </Col>
                            <Col style={{ width: '100%', marginTop: 10 }}>
                              <Row style={{ justifyContent: 'space-between' }}>
                                <strong>{values.firstName}’s progress:</strong>
                                <Row>
                                  <CustomTreeSelect
                                    treeData={treeData}
                                    value={selectedFilterValue}
                                    onChange={handleFilterChange}
                                  />
                                  <CustomTreeSelect
                                    treeData={tutorSessions}
                                    value={selectedSession}
                                    onChange={(value) => {
                                      setSelectedSession(value);
                                    }}
                                  />
                                </Row>
                              </Row>
                              <LoadingSpinner spinning={isGraphLoading}>
                                <LineChartTimeline graphData={graphData} />
                              </LoadingSpinner>
                            </Col>
                          </Col>
                          <Col xl={12} style={{ width: '100%' }}>
                            {/* STUDENT PROGRESS */}
                            <Col xl={24} sm={24} style={{ margin: '10px 0' }}>
                              <LoadingSpinner spinning={isGraphLoading}>
                                <StudentTimelineGraph
                                  timelineData={timelineData}
                                />
                              </LoadingSpinner>
                            </Col>
                          </Col>
                        </Row>
                      </StyledContent>
                    </AForm>
                  );
                }}
              />
            )}
          </MakeAsyncFunction>
        </LoadingSpinner>
      </SecondaryLayout>
    </MainLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    student: state.students.profile,
    classWithTutorGroup: state.students.classWithTutorGroup,
    timelineData: state.students.timelineData,
    tutorGroupSessions: state.students.tutorGroupSessions,
    graphData: state.students.graphData,
    isGraphLoading: state.students.isGraphLoading,
    isLoading: state.students.isLoading,
    dashboard: state.dashboard.data
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(StudentProfilePage);
