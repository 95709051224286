import { message } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectToast } from '../../modules/notification/redux/selectors';

const Notification = () => {
  const lastToast = useSelector(selectToast);

  useEffect(() => {
    // Triggers message.open when a new toast is available
    // it don't trigger if the toast id is 0 since the state intialized as 0
    if (lastToast && lastToast.id !== 0) {
      message.open({
        content: lastToast.message,
        type: lastToast.type,
        duration: 6,
        style: {
          position: 'fixed',
          top: 20,
          left: '40%'
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastToast?.id]);

  return null; // Render nothing here, as the message is triggered in useEffect
};

export default Notification;
