import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Drawer, List as AList, Row, Col, Empty } from 'antd';

import { StyledTitle as Title } from '../../../fields/Title';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

import { TutorGroupQuestionSetItem } from '../create-tutor-group/TutorGroupQuestionSetItem';

export const StyledContainer = styled.div`
  display: block;
  margin-bottom: 20px;
  padding: 30px;
  width: 100%;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.31);
`;

const StyledDivider = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0;
`;

const StyledEmptyDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const StyledSubtitle = styled.span`
  display: inline-block;
  width: 100%;
  font-size: 14px;
  color: #000000;
  margin-bottom: 20px;
`;

export const EditTutorGroupQuestionSetsListDrawer = ({
  tutorGroupQuestionSets = [],
  questionSets = [],
  onClose,
  visible,
  remove,
  push
}) => {
  const availableQuestionSets = useMemo(() => {
    let filteredQuestionSets = [];

    questionSets?.forEach((qSet) => {
      const foundQuestionSet = tutorGroupQuestionSets?.find(
        ({ id }) => id === qSet.id
      );

      if (foundQuestionSet) {
        filteredQuestionSets = [...filteredQuestionSets];
      } else {
        filteredQuestionSets = [...filteredQuestionSets, qSet];
      }
    });

    return filteredQuestionSets;
  }, [questionSets, tutorGroupQuestionSets]);

  const mappedTutorGroupQuestionSets = useMemo(() => {
    let filteredQuestionSets = [];

    questionSets?.forEach((qSet) => {
      const foundQuestionSet = tutorGroupQuestionSets?.find(
        ({ id }) => id === qSet.id
      );

      if (!foundQuestionSet) {
        filteredQuestionSets = [...filteredQuestionSets];
      } else {
        filteredQuestionSets = [...filteredQuestionSets, qSet];
      }
    });

    return filteredQuestionSets;
  }, [questionSets, tutorGroupQuestionSets]);

  const removeQuestionSetOnClick = useCallback(
    ({ event, qSet, index }) => {
      console.log('removeStudentOnClick, event: ', event);
      console.log('removeStudentOnClick, qSet: ', qSet);

      event.preventDefault();
      event.stopPropagation();

      remove(index);
    },
    [remove]
  );

  const addQuestionSetOnClick = useCallback(
    ({ event, qSet, index }) => {
      console.log('addQuestionSetOnClick, event: ', event);
      event.preventDefault();
      event.stopPropagation();

      push(qSet);
    },
    [push]
  );

  return (
    <Drawer
      title='Question Sets'
      placement='right'
      width={window.innerWidth > 600 ? 600 : window.innerWidth}
      onClose={onClose}
      visible={visible}
    >
      <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 16]}>
        <Col style={{ width: '100%' }}>
          <Title level={5}>Tutor Group Question Sets</Title>
          <StyledSubtitle>
            {mappedTutorGroupQuestionSets?.length ?? '0'} / 3 Question Sets
            added
          </StyledSubtitle>
          <AList
            itemLayout='horizontal'
            dataSource={mappedTutorGroupQuestionSets}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={
                    <StyledEmptyDescription>
                      <span>Sorry, in this tutor group no question sets!</span>
                      <span>
                        Please add the first question set to this tutor group
                      </span>
                    </StyledEmptyDescription>
                  }
                />
              )
            }}
            renderItem={(qSet, index) => (
              <TutorGroupQuestionSetItem
                qSet={qSet}
                index={index}
                onClick={removeQuestionSetOnClick}
                icon={<MinusOutlined />}
              />
            )}
          />
        </Col>
      </Row>
      <StyledDivider />
      <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 16]}>
        <Col style={{ width: '100%' }}>
          <Title level={5}>My Question Sets</Title>
          <StyledSubtitle>
            {availableQuestionSets?.length ?? '0'} Question Sets Available
          </StyledSubtitle>
          <AList
            itemLayout='horizontal'
            dataSource={availableQuestionSets}
            renderItem={(qSet, index) => (
              <TutorGroupQuestionSetItem
                qSet={qSet}
                count={tutorGroupQuestionSets?.length}
                maxCount={3}
                index={index}
                onClick={addQuestionSetOnClick}
                icon={<PlusOutlined />}
              />
            )}
          />
        </Col>
      </Row>
    </Drawer>
  );
};
