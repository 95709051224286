import React from 'react';
import { Pie } from 'ant-design-pro/lib/Charts';

const LearningStylesChart = ({
  data,
  totalCount,
  currentCount,
  chartBarColors,
  height = 475
}) => (
  <Pie
    hasLegend
    title='learning-styles'
    colors={chartBarColors}
    total={() => (
      <span
        dangerouslySetInnerHTML={{
          __html: `
            <div class="pie-chart-total">
            <span class="pie-chart-total__title">My Students</span>
            <span class="pie-chart-total__count">${currentCount}/${totalCount}</span>
            </div>
            `
        }}
      />
    )}
    data={data}
    valueFormat={(val) => <span dangerouslySetInnerHTML={{ __html: val }} />}
    height={height}
  />
);

export default LearningStylesChart;
