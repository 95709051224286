import React from 'react';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { Form as FForm, Field as FField } from 'react-final-form';
import { Row, Col, Typography, Form as AForm, Select as ASelect } from 'antd';

import {
  EmailInputControl,
  formatPhone,
  InputControl,
  parsePhone,
  TextAreaControl
} from '../../fields/Fields';
import { StyledButton } from '../../../styles/StyledComponents';
import {
  CONTACT_US_REQUEST,
  CONTACT_US_SUCCESS,
  CONTACT_US_FAILURE
} from '../../../modules/sign-up/redux';
import { promiseListener } from '../../../store';
import * as validations from '../../../utils/validations';

const { Option } = ASelect;

const prefixSelector = (
  <AForm.Item name='countryCode' noStyle initialValue='+1'>
    <ASelect
      style={{
        width: 70
      }}
    >
      <Option value='+1'>+1</Option>
    </ASelect>
  </AForm.Item>
);

export const ContactUsForm = ({ isLoading = false }) => (
  <MakeAsyncFunction
    listener={promiseListener}
    start={CONTACT_US_REQUEST}
    resolve={CONTACT_US_SUCCESS}
    reject={CONTACT_US_FAILURE}
  >
    {(onSubmit) => (
      <FForm
        name='contact-us'
        onSubmit={onSubmit}
        render={({
          submitError,
          handleSubmit,
          submitting,
          hasValidationErrors,
          hasSubmitErrors,
          dirtySinceLastSubmit,
          values
        }) => (
          <>
            {isLoading && <div>Loading...</div>}
            {submitError && <div className='error'>{submitError}</div>}
            <AForm onFinish={handleSubmit}>
              <Row gutter={[{ xs: 12, sm: 16, md: 24, lg: 32 }, 16]}>
                <Col span={12}>
                  <small style={{ display: 'flex', justifyContent: 'left' }}>First Name*</small>
                  <FField
                    name='firstName'
                    component={InputControl}
                    type='text'
                    placeholder='First Name*'
                    tooltip='First Name*'
                    label='First Name'
                    validate={validations.required}
                  />
                </Col>
              </Row>
              <Row gutter={[{ xs: 12, sm: 16, md: 24, lg: 32 }, 16]}>
                <Col span={12}>
                  <small style={{ display: 'flex', justifyContent: 'left' }}>Last Name*</small>
                  <FField
                    name='lastName'
                    component={InputControl}
                    type='text'
                    placeholder='Last Name*'
                    tooltip='Last Name*'
                    label='Last Name*'
                    validate={validations.required}
                  />
                </Col>
              </Row>
              <Row gutter={[{ xs: 12, sm: 16, md: 24, lg: 32 }, 16]}>
                <Col span={12}>
                  <small style={{ display: 'flex', justifyContent: 'left' }}>Email*</small>
                  <FField
                    name='email'
                    component={EmailInputControl}
                    type='text'
                    placeholder='Email*'
                    tooltip='Email*'
                    label='Email*'
                    validate={validations.composeValidators(
                      validations.required,
                      validations.email
                    )}
                  />
                </Col>
              </Row>
              <Row gutter={[{ xs: 12, sm: 16, md: 24, lg: 32 }, 16]}>
                <Col span={12}>
                  <small style={{ display: 'flex', justifyContent: 'left' }}>Job Title*</small>
                  <FField
                    name='jobTitle'
                    component={InputControl}
                    type='text'
                    placeholder='Job Title*'
                    tooltip='Job Title*'
                    label='Job Title*'
                    validate={validations.required}
                  />
                </Col>
              </Row>
              <Row gutter={[{ xs: 12, sm: 16, md: 24, lg: 32 }, 16]}>
                <Col span={12}>
                  <small style={{ display: 'flex', justifyContent: 'left' }}>Phone Number*</small>
                  <FField
                    name='phoneNumber'
                    component={InputControl}
                    type='text'
                    placeholder='(555) 555-5555*'
                    label='Phone Number*'
                    parse={parsePhone}
                    format={formatPhone}
                    addonBefore={prefixSelector}
                    validate={validations.required}
                  />
                </Col>
              </Row>
              <Row gutter={[{ xs: 12, sm: 16, md: 24, lg: 32 }, 16]}>
                <Col span={12}>
                  <small style={{ display: 'flex', justifyContent: 'left' }}>Subject*</small>
                  <FField
                    name='subject'
                    component={InputControl}
                    type='text'
                    placeholder='Subject*'
                    tooltip='Subject*'
                    label='Subject*'
                    validate={validations.required}
                  />
                </Col>
              </Row>
              <Row gutter={[{ xs: 12, sm: 16, md: 24, lg: 32 }, 16]}>
                <Col span={12}>
                  <small style={{ display: 'flex', justifyContent: 'left' }}>Message Body*</small>
                  <FField
                    name='messageBody'
                    component={TextAreaControl}
                    type='text'
                    placeholder='Let us know a bit more about who you are and what you are interested learning more about.'
                    tooltip='Message Body*'
                    label='Message Body*'
                    validate={validations.required}
                  />
                </Col>
              </Row>
              {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
              <AForm.Item>
                <StyledButton
                  size='large'
                  type='primary'
                  htmlType='submit'
                  loading={isLoading || submitting}
                  disabled={
                    isLoading || submitting ||
                    ((hasSubmitErrors || hasValidationErrors) &&
                      !dirtySinceLastSubmit)
                  }
                >
                  SEND
                </StyledButton>
              </AForm.Item>
            </AForm>
          </>
        )}
      />
    )}
  </MakeAsyncFunction>
);
