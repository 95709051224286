import { Col, Divider, Row } from 'antd';
import React from 'react';
import { OrangeButton, PurpleButton, RoundedContainer } from '../../../styles/StyledComponents';
import { StyledTitle } from '../../fields/Title';
import { Link } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';

const AnnualPricing = () => {
  return (
    <Row
      // gutter={[24, 24]}
      justify='center'
      //   align="middle"
      style={{ gap: 24, marginTop: 120 }}
    >
      <RoundedContainer
        className='hvr-float-shadow'
        xl={5}
        md={12}
        sm={16}
        xs={24}
        style={{ borderTop: '12px solid #8053d7' }}
      >
        <Row justify='start' style={{ textAlign: 'left' }}>
          {/* PACKAGE TITLE */}
          <Col span={24}>
            <h6>BASIC</h6>
          </Col>
          <Col span={24}>
            <p style={{ color: '#626266' }}>Solo educators looking to reach more learners</p>
          </Col>
        </Row>
        {/* ANNUAL PRICING */}
        <Row justify='start' style={{ textAlign: 'left' }}>
          <Col span={24}>
            <StyledTitle level={3}>$40/month</StyledTitle>
          </Col>
          <Col>
            <small>Unlimited students, classes, curriculum and messages.</small>
          </Col>
        </Row>
        <br />
        {/* BUILD YOUR PLAN */}
        <Row>
          <Col>
            <Link to='/sign-up'>
              <PurpleButton>
                GET STARTED <RightOutlined />
              </PurpleButton>
            </Link>
          </Col>
        </Row>
        <Divider style={{ color: '#626266' }} />
        {/* LIST OF FEATURES */}
        <Row justify='start' style={{ textAlign: 'left' }}>
          <Col span={24}>
            <ul>
              <li><span style={{ fontWeight: 'bold', fontSize: 16 }}>Access to</span></li>
              <li>Unlimited data history & student seats</li>
              <li>Unlimited curriculum and messages sent to learners.</li>
              <li>Monitoring & reports</li>
              <li>Legendary email support</li>
            </ul>
          </Col>
        </Row>
      </RoundedContainer>
      <RoundedContainer
        className='hvr-float-shadow'
        xl={5}
        md={12}
        sm={16}
        xs={24}
        style={{ borderTop: '12px solid #d7753e' }}
      >
        <Row justify='start' style={{ textAlign: 'left' }}>
          {/* PACKAGE TITLE */}
          <Col span={24}>
            <h6>TEAM</h6>
          </Col>
          <Col span={24}>
            <p style={{ color: '#626266' }}>More answers for more people</p>
          </Col>
        </Row>
        {/* ANNUAL PRICING */}
        <Row justify='start' style={{ textAlign: 'left' }}>
          <Col span={24}>
            <StyledTitle level={3}>Contact Us</StyledTitle>
          </Col>
          <Col>
            <small>Support your small organizations learning needs</small>
          </Col>
        </Row>
        <br />
        {/* BUILD YOUR PLAN */}
        <Row>
          <Col>
            <Link to='/contact'>
              <OrangeButton>
                CONTACT US <RightOutlined />
              </OrangeButton>
            </Link>
          </Col>
        </Row>
        <Divider style={{ color: '#626266' }} />
        {/* LIST OF FEATURES */}
        <Row justify='start' style={{ textAlign: 'left' }}>
          <Col span={24}>
            <ul>
              <li><span style={{ fontWeight: 'bold', fontSize: 16 }}>Access to</span></li>
              <li><span style={{ fontSize: 16 }}>All features in <span style={{ fontWeight: 'bold' }}>Basic,</span> plus</span></li>
              <li>500 Students, with unlimited Teachers, and Admins</li>
              <li>Impact reporting with causal inference to send to stakeholders</li>
              <li>Advanced Reporting and Admin Features</li>
              <li>Customer success partner support</li>
            </ul>
          </Col>
        </Row>
      </RoundedContainer>
      <RoundedContainer
        className='hvr-float-shadow'
        xl={5}
        md={12}
        sm={16}
        xs={24}
        style={{ borderTop: '12px solid darkorange' }}
      >
        <Row justify='start' style={{ textAlign: 'left' }}>
          {/* PACKAGE TITLE */}
          <Col span={24}>
            <h6>TEAM PLUS</h6>
          </Col>
          <Col span={24}>
            <p style={{ color: '#626266' }}>For larger schools and institutions</p>
          </Col>
        </Row>
        {/* ANNUAL PRICING */}
        <Row justify='start' style={{ textAlign: 'left' }}>
          <Col span={24}>
            <StyledTitle level={3}>Contact Us</StyledTitle>
          </Col>
          <Col>
            <small>Unlimited everything!</small>

          </Col>
        </Row>
        <br />
        <br />
        {/* BUILD YOUR PLAN */}
        <Row>
          <Col>
            <Link to='/contact'>
              <OrangeButton style={{ background: 'darkorange' }}>
                CONTACT US <RightOutlined />
              </OrangeButton>
            </Link>
          </Col>
        </Row>
        <Divider style={{ color: '#626266' }} />
        {/* LIST OF FEATURES */}
        <Row justify='start' style={{ textAlign: 'left' }}>
          <Col span={24}>
            <ul>
              <li><span style={{ fontWeight: 'bold', fontSize: 16 }}>Access to</span></li>
              <li><span style={{ fontSize: 16 }}>All features in <span style={{ fontWeight: 'bold' }}>Team,</span> plus</span></li>
              <li>Unlimited saved reports & session performance</li>
              <li>Impact report with causal inference</li>
              <li>Data modeling</li>
              <li>Group Analytics & Data Pipelines add-ons</li>
              <li>Premium support with &lt; 4 hours response time</li>
            </ul>
          </Col>
        </Row>
      </RoundedContainer>
      {/* <RoundedContainer
              className="hvr-float-shadow"
              xl={5}
              md={12}
              sm={16}
              xs={24}
              style={{borderTop: '12px solid dodgerblue'}}
            >
                <Row justify="start" style={{textAlign: 'left'}}>
                <Col span={24}>
                    <h6>ENTERPRISE</h6>
                </Col>
                <Col span={24}>
                    <p style={{color: '#626266'}}>Empower teams in large organizations</p>
                    </Col>
                </Row>
                <Row justify="start" style={{textAlign: 'left'}}>
                    <Col span={24}>
                    <StyledTitle level={3}>Let's chat</StyledTitle>
                    </Col>
                    <Col>
                    <small>Custom plans and pricing to suit your needs</small>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col>
                    <BlueButton>CONTACT SALES</BlueButton>
                    </Col>
                </Row>
                <Divider style={{color: "#626266"}}/>
                <Row justify="start" style={{textAlign: 'left'}}>
                    <Col span={24}>
                    <ul>
                        <li> <span style={{fontSize: 16}}>Everything in <span style={{fontWeight: 'bold'}}>Growth</span>, plus</span></li>
                        <li>Advanced access controls</li>
                        <li>Experiments & Signal reports</li>
                        <li>SSO & automated provisioning</li>
                        <li>Seasonal & events-based pricing</li>
                        <li>Dedicated customer success</li>
                    </ul>
                    </Col>
                </Row>
            </RoundedContainer> */}
    </Row>
  );
};

export default AnnualPricing;
