import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row, Typography } from 'antd';
import {
  MinusCircleOutlined,
  ReadOutlined,
  AudioOutlined,
  ApiOutlined,
  EyeOutlined
} from '@ant-design/icons';
import { Field as FField, useForm } from 'react-final-form';
import { SimpleSelectControl, UploaderControl } from '../../../fields/Fields';
import { createGlobalStyle } from 'styled-components';
import * as validations from '../../../../utils/validations';

const { Title } = Typography;

const learnerTypeOptions = [
  {
    label: 'ALL',
    value: 'not-sure'
  },
  {
    label: <EyeOutlined style={{ fontSize: 20 }} />,
    value: 'visual'
  },
  {
    label: <AudioOutlined style={{ fontSize: 20 }} />,
    value: 'auditory'
  },
  {
    label: <ReadOutlined style={{ fontSize: 20 }} />,
    value: 'reading'
  },
  {
    label: <ApiOutlined style={{ fontSize: 20 }} />,
    value: 'hands-on'
  }
];

const GlobalHintItemStyles = createGlobalStyle`
 .learner-type-selector .ant-select-selection-item {
    color: #8053D7
 }
 
 .ant-select-item-option-active {
    color: #9874DF
 }

 .ant-select-item-option-selected {
    color: #8053D7 !important
 }
`;

export const QuestionHintItem = ({
  name,
  index,
  remove,
  selectedLearnerTypes
}) => {
  const form = useForm();
  const [hintType, setHintType] = useState('all');

  const mappedLearnerTypeOptions = useMemo(
    () =>
      learnerTypeOptions.map((option) => ({
        ...option,
        disabled: selectedLearnerTypes.includes(option.value)
      })),
    [selectedLearnerTypes]
  );

  const addHintType = (type) => {
    switch (type) {
      case 'url':
        return 'link';
      case 'text':
        return 'pdf';
      case 'image':
        return 'jpg/png';
      case 'video':
        return 'mp4/mov';
      default:
        return ' ';
    }
  };

  useEffect(() => {
    // Update the form field value when hintType changes
    form.change(`${name}.type`, addHintType(hintType));
  }, [hintType]);

  return (
    <Row
      gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 16]}
      style={{ padding: 5, paddingTop: 15 }}
    >
      <GlobalHintItemStyles />
      <Col xl={8} style={{ paddingLeft: 0 }}>
        <Title level={5} style={{ fontWeight: 'bolder' }}>
          Hint {index + 1}
        </Title>
        <FField
          name={`${name}.file`}
          component={UploaderControl}
          type='text'
          placeholder={`Select Hint Type #${index + 1}?`}
          validate={validations.composeValidators(
            validations.required,
            hintType === 'url' ? validations.validHttpsLink : null
          )}
          hintType={hintType}
          setHintType={(type) => setHintType(type)}
          edit
        />
      </Col>
      <Col xl={6}>
        <Title level={5} style={{ fontWeight: 'bolder' }}>
          Learner Type {index + 1}
        </Title>
        <FField
          name={`${name}.learnerType`}
          initialValue='not-sure'
          component={SimpleSelectControl}
          options={mappedLearnerTypeOptions}
          className='learner-type-selector'
        />
      </Col>
      <Col flex='none' style={{ paddingTop: 44, paddingLeft: 0 }}>
        <MinusCircleOutlined
          className='dynamic-delete-button'
          style={{ color: '#BDBDBD', fontSize: 20 }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            remove(index);
          }}
        />
      </Col>
    </Row>
  );
};
