import React from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
const { Footer: AFooter } = Layout;

const StyledAFooter = styled(AFooter)`
  text-align: center;
  background-color: #D7753E;
  color: #fff;
`;

const Footer = () => {
  return (
    <StyledAFooter>
      Copyright&copy; 2023 TutorD&trade;. All rights reserved.

      <div onClick={() => { window.displayPreferenceModal(); return false; }} to='#' style={{ marginLeft: 2, textDecoration: 'underline' }}>Consent Preferences</div>

    </StyledAFooter>
  );
};
export default Footer;
