import React, { useMemo } from 'react';
import { Row, notification } from 'antd';
import styled from 'styled-components';
import { dispatch } from '../../../../store';
import SiderNav from '../../../template/SiderNav';
import { RESET_CLASS_ERROR } from '../../../../modules/classes/redux';
import { ClassList } from './class-list';
import { StyledTitle as Title } from '../../../fields/Title';
import {
  MainLayout,
  SecondaryLayout,
  StyledContent
} from '../../../../styles/StyledComponents';

import { AddClassDrawer } from './AddClassDrawer';
import useClassPage from './hooks/useClassPage';
import LoadingSpinner from '../../../../lib/components/loadingSpinner';
import SearchInput from '../../../fields/SearchInput';

const StyledClassPageHeader = styled(Row)`
  width: 100%;
  margin-bottom: 35px;
`;

const StyledClassPageHeadingsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  @media (min-width: 500px) {
    margin-bottom: 0;
  }

  & .ant-typography {
    margin-bottom: 0;
  }
`;

const StyledEmptyDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const ClassesPage = () => {
  const {
    classes,
    students,
    isLoading,
    error,
    searchInputConfig,
    searchedValue
  } = useClassPage();

  // EMPTY STATE DESCRIPTION
  const renderedGroupsEmptyDescription = useMemo(() => {
    if (!!searchedValue && searchedValue.length > 0) {
      return (
        <StyledEmptyDescription>
          <span>Sorry, the class with the searched name wasn`t found!</span>
        </StyledEmptyDescription>
      );
    }

    return (
      <StyledEmptyDescription>
        <span>Sorry, you don't have any classes!</span>
        <span>Please add the first class</span>
      </StyledEmptyDescription>
    );
  }, [searchedValue]);

  return (
    <MainLayout hasSider>
      <SiderNav />
      <SecondaryLayout>
        <StyledContent>
          <LoadingSpinner spinning={isLoading} fullscreen>
            <StyledClassPageHeader justify='space-between'>
              <StyledClassPageHeadingsContainer>
                <Title level={2}>Classes</Title>
                <AddClassDrawer students={students} />
              </StyledClassPageHeadingsContainer>
              <SearchInput
                {...searchInputConfig}
                placeholder='Search the classes'
              />
            </StyledClassPageHeader>
            {error &&
              notification.error({
                key: error,
                message: 'Class error',
                description: error,
                onClose: dispatch({ type: RESET_CLASS_ERROR })
              })}
            <ClassList
              classes={classes}
              emptyDescription={renderedGroupsEmptyDescription}
            />
          </LoadingSpinner>
        </StyledContent>
      </SecondaryLayout>
    </MainLayout>
  );
};

export default ClassesPage;
