import React, { useEffect, useMemo, useState } from 'react';
import { Row, Button } from 'antd';
import styled from 'styled-components';
import {
  MainLayout,
  SecondaryLayout,
  StyledContent
} from '../../../../styles/StyledComponents';
import { StyledTitle as Title } from '../../../fields/Title';
import SiderNav from '../../../template/SiderNav';
import { StudentEnrollmentList } from './student-enrollment-list';
import { AddStudentDrawer } from './AddStudentDrawer';
import useStudentsPage from './hooks/useStudentsPage';
import LoadingSpinner from '../../../../lib/components/loadingSpinner';
import SearchInput from '../../../fields/SearchInput';
import QRCodeModal from '../../../../lib/components/QRCodeModal';

const StyledStudentsPageHeader = styled(Row)`
  width: 100%;
  margin-bottom: 35px;
`;

const StyledStudentsPageHeadingsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  @media (min-width: 540px) {
    margin-bottom: 0;
  }

  & .ant-typography {
    margin-bottom: 0;
  }
`;

const StyledButton = styled.button`
  border: 1px solid #8053d750;
  border-radius:5px;
  color: #8053d7;
  padding: 7px 12px;
  cursor: pointer;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const StyledEmptyDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const StudentsPage = () => {
  const { classes, students, user, isLoading, searchInputConfig, searchedValue } =
    useStudentsPage();
  const [showQRCode, setShowQRCode] = useState(false);
  // EMPTY STATE DESCRIPTION
  const renderedStudentsEmptyDescription = useMemo(() => {
    if (!!searchedValue && searchedValue.length > 0) {
      return (
        <StyledEmptyDescription>
          <span>Sorry, the student with the searched name wasn`t found!</span>
        </StyledEmptyDescription>
      );
    }

    return (
      <StyledEmptyDescription>
        <span>Sorry, you don't have any students!</span>
        <span>Please add the first student</span>
      </StyledEmptyDescription>
    );
  }, [searchedValue]);

  return (
    <MainLayout hasSider>
      <SiderNav />
      <SecondaryLayout>
        <StyledContent>
          <LoadingSpinner spinning={isLoading}>
            <StyledStudentsPageHeader justify='space-between'>
              <StyledStudentsPageHeadingsContainer>
                <Title level={2}>Students</Title>
                <AddStudentDrawer classes={classes} />
              </StyledStudentsPageHeadingsContainer>
              <StyledDiv>
                <QRCodeModal
                  onClose={() => setShowQRCode(false)}
                  qrCodeValue={user?.organizationUrl}
                  visible={showQRCode}
                  teacherName={user?.firstName + ' ' + user?.lastName}
                />
                <StyledButton onClick={() => { setShowQRCode(true); }}>Invite Students</StyledButton>
                <SearchInput
                  {...searchInputConfig}
                  placeholder='Search the students'
                />
              </StyledDiv>
            </StyledStudentsPageHeader>
            <StudentEnrollmentList
              students={students}
              emptyDescription={renderedStudentsEmptyDescription}
            />
          </LoadingSpinner>
        </StyledContent>
      </SecondaryLayout>
    </MainLayout>
  );
};

export default StudentsPage;
