import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import {
  classesSelector,
  sortedAllStudentsByCurrentClassLearningStylesQuantitySelector,
  sortedStudentsByEntityLearningStylesQuantitySelector,
  studentsByCurrentClassSelector,
  studentsByEntitySelector,
  tutorGroupsByClassSelector
} from '../../../../../modules/classes/redux/selectors';
import {
  handleClassRequest,
  handleDeleteClassRequest
} from '../../../../../modules/classes/redux';
import { studentsSelector } from '../../../../../modules/students/redux/selectors';
import { handleStudentsDashboardRequest } from '../../../../../modules/students/redux';
import { userSelector } from '../../../../../modules/auth/redux/selectors';

const useEditClass = () => {
  const [activeTab, setActiveTab] = useState('engagement');
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [selectedFilterValue, setSelectedFilterValue] = useState('all');
  const [selectedEntityId, setSelectedEntityId] = useState('');
  const studentTrendContainerRef = useRef(null);

  const { classId } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    if (classId) {
      dispatch(handleClassRequest(classId));
    }

    dispatch(handleStudentsDashboardRequest());
  }, [classId, dispatch]);

  const { isLoading, _class: currentClass } = useSelector(classesSelector);

  const user = JSON.parse(localStorage.getItem('user') ?? '');

  const { data: allStudents } = useSelector(studentsSelector);

  // ALL STUDENTS BY CURRENT CLASS
  const studentsByCurrentClass = useSelector(studentsByCurrentClassSelector);

  // ALL STUDENTS BY ENTITY
  const studentsByEntity = useSelector((state) =>
    studentsByEntitySelector(state, {
      id: selectedEntityId,
      type: selectedFilter
    })
  );

  // ALL TUTOR GROUPS BY CURRENT CLASS
  const allTutorGroups = useSelector(tutorGroupsByClassSelector);

  // OBJECT WITH QUANTITY OF LEANING STYLES BY ALL STUDENTS

  const sortedAllStudentsLearningTypesQuantity = useSelector(
    sortedAllStudentsByCurrentClassLearningStylesQuantitySelector
  );

  // OBJECT WITH QUANTITY OF LEANING STYLES BY ENTITY
  const sortedStudentsByEntityLearningTypesQuantity = useSelector((state) =>
    sortedStudentsByEntityLearningStylesQuantitySelector(state, {
      id: selectedEntityId,
      type: selectedFilter
    })
  );

  // TUTOR GROUPS WITH THE ACTIVE SESSION BY CURRENT CLASS
  const tutorGroupsWithActiveSession = useMemo(
    () => allTutorGroups?.filter((group) => group?.activeSession),
    [allTutorGroups]
  );

  // TUTOR GROUPS WITH THE COMPLETED SESSION BY CURRENT CLASS
  const tutorGroupsWithCompletedSession = useMemo(
    () =>
      allTutorGroups?.filter((group) =>
        group?.tutorSessions?.some((session) => session?.status === 'completed')
      ),
    [allTutorGroups]
  );

  // TUTOR GROUP BY CHOSEN ID
  const chosenTutorGroup = useMemo(
    () => allTutorGroups?.filter((group) => group?.id === selectedEntityId),
    [allTutorGroups, selectedEntityId]
  );

  // DATA FOR TREE SELECT FILTER
  const treeData = useMemo(
    () => [
      {
        value: 'all',
        title: 'All Groups',
        children: allTutorGroups?.map((item) => ({
          value: `group-${item.id}`,
          title: item.title
        }))
      },
      {
        value: 'active-session',
        title: 'Active Session'
      },
      {
        value: 'completed-session',
        title: 'Completed Session'
      }
    ],
    [allTutorGroups]
  );

  // RENDERED TUTOR GROUPS
  const tutorGroups = useMemo(() => {
    if (selectedFilter === 'active-session') {
      return tutorGroupsWithActiveSession;
    }

    if (selectedFilter === 'completed-session') {
      return tutorGroupsWithCompletedSession;
    }

    if (selectedFilter === 'group') {
      return chosenTutorGroup;
    }

    return allTutorGroups;
  }, [
    allTutorGroups,
    chosenTutorGroup,
    selectedFilter,
    tutorGroupsWithActiveSession,
    tutorGroupsWithCompletedSession
  ]);

  // RENDERED STUDENTS DATA ACCORDING TO FILTER TYPE
  const students = useMemo(
    () =>
      selectedFilter === 'all' ? studentsByCurrentClass : studentsByEntity,
    [selectedFilter, studentsByCurrentClass, studentsByEntity]
  );

  // TREE SELECT FILTER ONCHANGE HANDLER
  const handleFilterChange = useCallback((value) => {
    if (
      value === 'all' ||
      value === 'active-session' ||
      value === 'completed-session'
    ) {
      setSelectedFilter(value);
      setSelectedFilterValue(value);
      setSelectedEntityId('');
    } else {
      const accentStrIndex = value.indexOf('-');
      const type = value.substring(0, accentStrIndex);
      const id = value.substring(accentStrIndex + 1, value.length);

      setSelectedFilter(type);
      setSelectedFilterValue(value);
      setSelectedEntityId(id);
    }
  }, []);

  // DELETE THE CLASS
  const handleClassDelete = useCallback(
    () => dispatch(handleDeleteClassRequest(classId)),
    [classId, dispatch]
  );

  // TAB ONCHANGE HANDLER
  const handleTabChange = useCallback((key) => setActiveTab(key), []);

  // CONFIG FOR TREE SELECT FILTER
  const treeSelectConfig = useMemo(
    () => ({
      treeData,
      selectedFilter,
      selectedFilterValue,
      handleFilterChange
    }),
    [handleFilterChange, selectedFilter, selectedFilterValue, treeData]
  );

  // ENGAGEMENT TAB

  // ENGAGEMENT BY ALL GROUPS
  const engagementDataByAllGroups = useMemo(
    () =>
      allTutorGroups
        ?.map((group) => [
          {
            name: group?.title,
            type: 'correct',
            value: +(+group?.totalEngagementScore * 100).toFixed(1)
          },
          {
            name: group?.title,
            type: 'missed',
            value: +((1 - +group?.totalEngagementScore) * 100).toFixed(1)
          }
        ])
        .flat(),
    [allTutorGroups]
  );

  // ENGAGEMENT BY GROUPS WITH ACTIVE SESSIONS
  const engagementDataByGroupsWithActiveSession = useMemo(
    () =>
      tutorGroupsWithActiveSession
        ?.map((group) => [
          {
            name: group?.title,
            type: 'correct',
            value: +(+group?.totalEngagementScore * 100).toFixed(1)
          },
          {
            name: group?.title,
            type: 'missed',
            value: +((1 - +group?.totalEngagementScore) * 100).toFixed(1)
          }
        ])
        .flat(),
    [tutorGroupsWithActiveSession]
  );

  // ENGAGEMENT BY GROUPS WITH COMPLETED SESSIONS
  const engagementDataByGroupsWithCompletedSession = useMemo(
    () =>
      tutorGroupsWithCompletedSession
        ?.map((group) => [
          {
            name: group?.title,
            type: 'correct',
            value: +(+group?.totalEngagementScore * 100).toFixed(1)
          },
          {
            name: group?.title,
            type: 'missed',
            value: +((1 - +group?.totalEngagementScore) * 100).toFixed(1)
          }
        ])
        .flat(),
    [tutorGroupsWithCompletedSession]
  );

  // STUDENTS PERFORMANCE BY CURRENT GROUP
  const studentsPerformanceDataByCurrentGroup = useMemo(
    () =>
      chosenTutorGroup
        ?.map((group) => {
          const activeSession = group?.tutorSessions?.filter(
            (session) => session?.status === 'active'
          )[0];
          const session = activeSession ?? group?.tutorSessions[0];
          return group?.students?.map((student) => ({
            name: `${student?.firstName} ${student?.lastName}`,
            performance: session?.studentPerformance[student?.id] ?? {}
          }));
        })
        .flat(),

    [chosenTutorGroup]
  );

  // ENGAGEMENT BY CURRENT
  const engagementDataByCurrentGroup = useMemo(
    () =>
      studentsPerformanceDataByCurrentGroup
        ?.map((student) => [
          {
            name: student?.name,
            type: 'correct',
            value: +(+student?.performance?.totalEngagementScore * 100).toFixed(
              1
            )
          },
          {
            name: student?.name,
            type: 'missed',
            value: +(
              (1 - +student?.performance?.totalEngagementScore) *
              100
            ).toFixed(1)
          }
        ])
        .flat(),
    [studentsPerformanceDataByCurrentGroup]
  );

  // DATA FOR ENGAGEMENT CHART
  const engagementChartData = useMemo(() => {
    if (selectedFilter === 'active-session') {
      return engagementDataByGroupsWithActiveSession;
    }

    if (selectedFilter === 'completed-session') {
      return engagementDataByGroupsWithCompletedSession;
    }

    if (selectedFilter === 'group') {
      return engagementDataByCurrentGroup;
    }

    return engagementDataByAllGroups;
  }, [
    engagementDataByAllGroups,
    engagementDataByCurrentGroup,
    engagementDataByGroupsWithActiveSession,
    engagementDataByGroupsWithCompletedSession,
    selectedFilter
  ]);

  // MAX ENGAGEMENT CHART ITEM WIDTH
  const maxEngagementChartItemWidth = useMemo(() => {
    const itemNames = [
      ...new Set(engagementChartData?.map(({ name }) => name))
    ];

    const itemWidths = itemNames.map((name) => name?.length * 8);

    const maxItemWidth = Math.max.apply(null, itemWidths);

    return maxItemWidth;
  }, [engagementChartData]);

  // ENGAGEMENT CHART HEIGHT
  const engagementChartHeight = useMemo(
    () =>
      engagementChartData?.length * 20 + 200 > 485
        ? engagementChartData?.length * 20 + 100
        : 485,
    [engagementChartData?.length]
  );

  // CONFIG FOR ENGAGEMENT CHART
  const engagementChartConfig = useMemo(
    () => ({
      data: engagementChartData,
      maxItemWidth: maxEngagementChartItemWidth,
      height: engagementChartHeight
    }),
    [engagementChartData, engagementChartHeight, maxEngagementChartItemWidth]
  );

  // GROUP TRENDS TAB

  // GET QUESTIONS PERFORMANCE BY GROUPS
  const questionsPerformanceByGroups = useMemo(
    () =>
      tutorGroups?.map((group) => {
        const questionsPerformance = group?.tutorSessions?.find(
          (session) => session?.status === 'active' || group?.tutorSessions?.[0]
        )?.questionPerformance;

        const {
          totalScore,
          totalEngagementScore,
          totalScaledScore,
          maxPossiblePerQuestionScore,
          maxPossibleScore,
          ...rest
        } = questionsPerformance ? questionsPerformance : {};

        return {
          name: group?.title,
          questionsPerformance: Object.values({ ...rest })?.map(
            (value, index) => ({
              name: `Q${index + 1}`,
              performance: value
            })
          )
        };
      }),

    [tutorGroups]
  );

  // DATA FOR STUDENT GROUP TREND CHART
  const studentGroupTrendChartData = useMemo(
    () =>
      currentClass?.tutorGroups?.map((group) => ({
        name: group?.title,
        score: +group?.totalScaledScore * 100
      })),
    [currentClass?.tutorGroups]
  );

  // DATA FOR GROUP TREND CHART
  const groupTrendChartData = useMemo(
    () =>
      questionsPerformanceByGroups
        ?.map(({ name, questionsPerformance }) =>
          questionsPerformance?.map((perf) => ({
            name: perf?.name,
            group: name,
            value: +perf?.performance?.totalScore
          }))
        )
        .flat(),
    [questionsPerformanceByGroups]
  );

  // DESCRIPTION FOR THE EMPTY STATE OF THE GROUP TREND CHART
  const groupTrendTabEmptyDescription = useMemo(() => {
    switch (selectedFilter) {
      case 'active-session':
        return 'Sorry, no data for the tutor groups with the active session';
      case 'completed-session':
        return 'Sorry, no data for the tutor groups with the completed session';
      case 'group':
        return 'Sorry, no data for this tutor group';
      default:
        return 'Sorry, no data cause in this class no tutor groups!';
    }
  }, [selectedFilter]);

  // MAX STUDENT TREND CHART ITEM WIDTH
  const studentTrendChartItemWidth = useMemo(() => {
    const itemWidths = groupTrendChartData?.map(({ group }) => group?.length * 8);

    const maxItemWidth = Math.max.apply(null, itemWidths);

    return maxItemWidth;
  }, [groupTrendChartData]);

  // MAX STUDENT TREND CHART HEIGHT
  const studentTrendChartHeight = useMemo(
    () =>
      studentGroupTrendChartData?.length * 25 + 200 > 485
        ? studentGroupTrendChartData?.length * 25 + 200
        : 485,
    [studentGroupTrendChartData]
  );

  // AXIS FOR STUDENT TREND CHART
  const scale = useMemo(
    () => ({
      score: {
        min: 0,
        max: 100
      }
    }),
    []
  );

  // AXIS LABEL FOR STUDENT TREND CHART
  const label = useMemo(() => {
    return {
      formatter: (val) => `${val}%`
    };
  }, [studentGroupTrendChartData]);

  // CONFIG FOR STUDENT GROUP TREND CHART
  const studentTrendChartConfig = useMemo(
    () => ({
      scale,
      label,
      data: studentGroupTrendChartData,
      ref: studentTrendContainerRef,
      maxItemWidth: studentTrendChartItemWidth,
      height: studentTrendChartHeight
    }),
    [
      label,
      scale,
      studentTrendChartHeight,
      studentTrendChartItemWidth,
      studentGroupTrendChartData
    ]
  );

  // CONFIG FOR GROUP TREND CHART
  const groupTrendChartConfig = useMemo(
    () => ({
      data: groupTrendChartData,
      emptyDescription: groupTrendTabEmptyDescription
    }),
    [groupTrendChartData, groupTrendTabEmptyDescription]
  );

  // LEARNING STYLES TAB

  // STUDENTS WITH SELECTED LEARNING TYPES
  const studentsWithLearningStyles = students?.filter(
    (student) => student.learningStyles?.length > 0
  );

  // SORTED QUANTITY OF THE LEARNING STYLES
  const sortedLearningTypesQuantity = useMemo(
    () =>
      selectedFilter === 'all'
        ? sortedAllStudentsLearningTypesQuantity
        : sortedStudentsByEntityLearningTypesQuantity,
    [
      selectedFilter,
      sortedAllStudentsLearningTypesQuantity,
      sortedStudentsByEntityLearningTypesQuantity
    ]
  );

  // DATA FOR LEARNING TYPES CHART BY STUDENTS
  const learningStylesChartData = Object.entries(
    sortedLearningTypesQuantity ?? {}
  ).map(([key, value]) => ({ x: key.toUpperCase(), y: value }));

  // DESCRIPTION FOR THE EMPTY STATE OF THE LEARNING STYLES CHART
  const learningStylesTabEmptyDescription = useMemo(() => {
    switch (selectedFilter) {
      case 'active-session':
        return 'Sorry, in tutor groups with active session no students with chosen learning style';
      case 'completed-session':
        return 'Sorry, in tutor groups with completed session no students with chosen learning style';
      case 'group':
        return 'Sorry, in this tutor group no students with chosen learning style';
      default:
        return 'Sorry, there are no students with chosen learning style';
    }
  }, [selectedFilter]);

  // CONFIG FOR LEARNING STYLES CHART
  const learningStylesChartConfig = useMemo(
    () => ({
      data: learningStylesChartData ?? [],
      emptyDescription: learningStylesTabEmptyDescription
    }),
    [learningStylesChartData, learningStylesTabEmptyDescription]
  );

  return {
    user,
    activeTab,
    classId,
    isLoading,
    currentClass,
    allStudents,
    studentsByEntity: students,
    tutorGroups,
    treeSelectConfig,
    engagementChartConfig,
    groupTrendChartConfig,
    studentTrendChartConfig,
    studentsWithLearningStyles,
    learningStylesChartConfig,
    handleClassDelete,
    handleTabChange
  };
};

export default useEditClass;
