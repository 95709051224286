import _ from 'lodash';
import axios from 'axios';
import { FORM_ERROR } from 'final-form';
import { all, call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import request from '../../../lib/request';
import { parseFormErrors } from '../../../utils/parseFormErrors';

import { handleDashboardFailure } from '../../dashboard/redux';
import { handleSignInSuccess, setAuthToken } from '../../auth/redux';

export const QUESTION_SET_REQUEST = 'teacher/question-set/QUESTION_SET_REQUEST';
export const QUESTION_SET_SUCCESS = 'teacher/question-set/QUESTION_SET_SUCCESS';
export const QUESTION_SET_FAILURE = 'teacher/question-set/QUESTION_SET_FAILURE';

export const CREATE_QUESTION_SET_REQUEST = 'teacher/question-set/CREATE_QUESTION_SET_REQUEST';
export const CREATE_QUESTION_SET_SUCCESS = 'teacher/question-set/CREATE_QUESTION_SET_SUCCESS';
export const CREATE_QUESTION_SET_FAILURE = 'teacher/question-set/CREATE_QUESTION_SET_FAILURE';

export const EDIT_QUESTION_SET_REQUEST = 'teacher/question-set/EDIT_QUESTION_SET_REQUEST';
export const EDIT_QUESTION_SET_SUCCESS = 'teacher/question-set/EDIT_QUESTION_SET_SUCCESS';
export const EDIT_QUESTION_SET_FAILURE = 'teacher/question-set/EDIT_QUESTION_SET_FAILURE';

export const DELETE_QUESTION_SET_REQUEST = 'teacher/question-set/DELETE_QUESTION_SET_REQUEST';
export const DELETE_QUESTION_SET_SUCCESS = 'teacher/question-set/DELETE_QUESTION_SET_SUCCESS';
export const DELETE_QUESTION_SET_FAILURE = 'teacher/question-set/DELETE_QUESTION_SET_FAILURE';

export const QUESTION_SET_DASHBOARD_REQUEST = 'teacher/question-set/QUESTION_SET_DASHBOARD_REQUEST';
export const QUESTION_SET_DASHBOARD_SUCCESS = 'teacher/question-set/QUESTION_SET_DASHBOARD_SUCCESS';
export const QUESTION_SET_DASHBOARD_FAILURE = 'teacher/question-set/QUESTION_SET_DASHBOARD_FAILURE';

// Get Question Set by Id
export const handleQuestionSetRequest = (payload) => ({
  type: QUESTION_SET_REQUEST,
  payload
});

export const handleQuestionSetSuccess = (payload) => ({
  type: QUESTION_SET_SUCCESS,
  payload
});

export const handleQuestionSetFailure = (payload) => ({
  type: QUESTION_SET_FAILURE,
  payload
});

// Create New Question Set
export const handleCreateQuestionSetRequest = (payload) => ({
  type: CREATE_QUESTION_SET_REQUEST,
  payload
});

export const handleCreateQuestionSetSuccess = (payload) => ({
  type: CREATE_QUESTION_SET_SUCCESS,
  payload
});

export const handleCreateQuestionSetFailure = (payload) => ({
  type: CREATE_QUESTION_SET_FAILURE,
  payload
});

// Edit Question Set
export const handleEditQuestionSetRequest = (payload) => ({
  type: EDIT_QUESTION_SET_REQUEST,
  payload
});

export const handleEditQuestionSetSuccess = (payload) => ({
  type: EDIT_QUESTION_SET_SUCCESS,
  payload
});

export const handleEditQuestionSetFailure = (payload) => ({
  type: EDIT_QUESTION_SET_FAILURE,
  payload
});

// delete questionset
export const handleDeleteQuestionSetRequest = (payload) => ({
  type: DELETE_QUESTION_SET_REQUEST,
  payload
});

export const handleDeleteQuestionSetSuccess = (payload) => ({
  type: DELETE_QUESTION_SET_SUCCESS,
  payload
});

export const handleDeleteQuestionSetFailure = (payload) => ({
  type: DELETE_QUESTION_SET_FAILURE,
  payload
});

// Question Set Dashboard
export const handleQuestionSetsDashboardRequest = (payload) => ({
  type: QUESTION_SET_DASHBOARD_REQUEST,
  payload
});

export const handleQuestionSetsDashboardSuccess = (payload) => ({
  type: QUESTION_SET_DASHBOARD_SUCCESS,
  payload
});

export const handleQuestionSetsDashboardFailure = (payload) => ({
  type: QUESTION_SET_DASHBOARD_FAILURE,
  payload
});

export function * onHandleQuestionSetsDashboardRequest () {
  console.log('#onHandleQuestionSetsDashboardRequest');

  try {
    console.log('#onHandleQuestionSetsDashboardRequest, try block');
    const response = yield call(request.get, '/question-sets');
    console.log('#onHandleQuestionSetsDashboardRequest, response: ', response);

    const {
      data: { user, token, questionSets }
    } = response;

    return yield all([
      put(handleQuestionSetsDashboardSuccess(questionSets)),
      put(handleSignInSuccess(user)),
      put(setAuthToken(token))
    ]);
  } catch (err) {
    console.error('Error, #onHandleQuestionSetsDashboardRequest, err: ', err);
    // TODO: Add Notifications to let user know QuestionSetDashboard failed to get loaded and further instructions
    return yield all([put(handleDashboardFailure(err))]);
  }
}

export function * onHandleQuestionSetRequest ({ payload: questionSetId }) {
  console.log('#onHandleQuestionSetRequest, questionSetId: ', questionSetId);

  try {
    console.log('#onHandleQuestionSetRequest, try block');
    const response = yield call(request.get, `/question-sets/${questionSetId}`);
    console.log('#onHandleQuestionSetRequest, response: ', response);
    const {
      data: { user, token, questionSets }
    } = response;
    console.log('#onHandleQuestionSetRequest, questionSets: ', questionSets);

    return yield all([put(handleQuestionSetSuccess(questionSets[0]))]);
  } catch (err) {
    console.error('Error, #onHandleQuestionSetRequest, err: ', err);
  }
}

// we check if there is a file and upload it and return the updated data with the url.
const uploadHintFiles = async (questionSet) => {
  console.log('#uploadHintFiles', questionSet);
  const formdata = new FormData();

  console.log('#uploadHintFiles, formdata: ', formdata);

  for (let i = 0; i < questionSet.questions?.length; i++) {
    const question = questionSet.questions[i];
    console.log(`#uploadHintFiles, questions[${i}]: `, question);

    // since each question has an array of hints
    for (let j = 0; j < question.hints?.length; j++) {
      const hint = question.hints[j];
      console.log(`#uploadHintFiles, hint[${j}]: `, hint);

      // we need this to check if the hint is a file and it actually exists
      if (hint.type !== 'link' && hint.file.originFileObj) {
        console.log('#uploadHintFiles, calling /aws/getPreSignedUrl');
        const response = await request.post('/aws/getPreSignedUrl', {
          fileName: hint.file.name,
          mimeType: hint.file.type,
          fileUid: hint.file.uid
        });

        console.log('#uploadHintFiles, response: ', response);

        const { data: { signedUrlData } } = response;

        console.log('#uploadHintFiles, signedUrlData: ', signedUrlData);

        // the key will be used to set the filename in s3 bucket
        formdata.append('key', signedUrlData.fields.key);
        formdata.append('file', hint.file.originFileObj);

        console.log(`#uploadHintFiles, key: ${signedUrlData.fields.key}`);
        console.log(`#uploadHintFiles, file: ${hint.file.originFileObj}`);

        try {
          console.log('#uploadHintFiles, attempting to upload file object with preSignedUrl');
          await axios.put(signedUrlData.signedUrl, hint.file.originFileObj, {
            headers: { 'Content-Type': hint.file.originFileObj.type }
          });

          // update the hint url using the result
          console.log('#uploadHintFiles, update the hint url using the result');
          const updatedFile = {
            ...questionSet.questions[i].hints[j].file,
            url: signedUrlData.fields.path,
            keyPath: signedUrlData.fields.keyPath,
            originFileObj: undefined
          };

          console.log('#uploadHintFiles, updatedFile: ', updatedFile);
          questionSet.questions[i].hints[j].file = updatedFile;
        } catch (err) {
          console.error('#uploadHintFiles, Error, err:');
          console.error(err);
          console.error(err.stackTrace.toString());
        }
      }
    }
  }

  return questionSet;
};

const checkForUploadableHintFiles = (questionSet) => {
  // We will check through at most n=15 questions in the new / updated question set
  // If we find at least one hint that is other than a hyperlink (link) hint type
  // We will call the uploadHintFiles, otherwise we can assume that there are no
  // hints on this question set or the only hints are hyperlinks(links)
  console.log('#checkForUploadableHintFiles, questionSet: ', questionSet);
  let hintFileFound = false;

  try {
    console.log('#checkForUploadableHintFiles, try block');
    for (const questionObj of questionSet.questions) {
      console.log('#checkForUploadableHintFiles, question: ', questionObj);

      if (hintFileFound === true) { break; }

      if (!_.isEmpty(questionObj.hints)) {
        for (const hint of questionObj.hints) {
          console.log('#checkForUploadableHintFiles, hints: ', hint);
          if (hint.type !== 'link' && hint.file.originFileObj) {
            console.log('#checkForUploadableHintFiles, hint with file to upload found, setting flag and breaking from loop');
            hintFileFound = true;
            break;
          }
        }
      }
    }
    console.log(`#checkForUploadableHintFiles, hintFileFound flag: ${hintFileFound} `);
    return hintFileFound;
  } catch (err) {
    console.error('#checkForUploadableHintFiles, Error: ', err);
    throw err;
  }
};

export function * onHandleCreateQuestionSetRequest ({ payload: reqQuestionSet }) {
  console.log('#onHandleCreateQuestionSetRequest', reqQuestionSet);

  try {
    console.log('#onHandleCreateQuestionSetRequest, try block');
    const response = yield call(request.post, '/question-sets', { questionSet: reqQuestionSet });

    const { data: { questionSet: resQuestionSet } } = response;

    console.log('questionSet: ', resQuestionSet);

    if (checkForUploadableHintFiles(reqQuestionSet)) {
      // we will handle the whole process of adding hint files
      console.log('#onHandleCreateQuestionSetRequest, we have hint files to process');
      console.log('#onHandleCreateQuestionSetRequest, calling uploadHintFiles');
      reqQuestionSet = yield uploadHintFiles(reqQuestionSet);
      // after the hint file is uploaded we update the question set with the files url
      // the reason is we have already created the questionSet so we just need to update the hint urls.
      const updatedQuestionSet = {
        ...resQuestionSet,
        questions: resQuestionSet.questions.map((question, index) => {
          // since the payload question has no id, we use questionStatment for comparison
          if (question.questionStatement === reqQuestionSet.questions[index].questionStatement) {
            return { ...question, hints: reqQuestionSet.questions[index].hints };
          }

          return question;
        })
      };

      // we sent the updated question set to edit api to update the database with the upload url
      return yield all([put(handleEditQuestionSetRequest(updatedQuestionSet))]);
    } else {
      // we will save as is because there are no hint files
      console.log('#onHandleCreateQuestionSetRequest, we do not have any hint files to process and can have a single req/res');
      return yield all([
        put(handleCreateQuestionSetSuccess(resQuestionSet)),
        put(push('/teacher/question-sets'))
      ]);
    }
  } catch (err) {
    console.error('Error, #onHandleCreateQuestionSetRequest, err: ', err);
    const { status, message, errors } = err.response.data;

    if (message === 'Network Error') {
      return yield put({
        type: CREATE_QUESTION_SET_FAILURE,
        payload: { [FORM_ERROR]: 'Network Error? Check your connection and please try again later...' }
      });
    }

    if (status === 409) {
      return yield put({
        type: CREATE_QUESTION_SET_SUCCESS,
        payload: {
          [FORM_ERROR]: message,
          ...parseFormErrors(errors)
        }
      });
    }

    if (status === 422 || status === 400) {
      return yield put({
        type: CREATE_QUESTION_SET_SUCCESS,
        payload: {
          [FORM_ERROR]: message,
          ...parseFormErrors(errors)
        }
      });
    }

    if (err.response.status === 500) {
      return yield put({
        type: CREATE_QUESTION_SET_FAILURE,
        payload: { [FORM_ERROR]: 'Its not you, its us....... Please try again later.' }
      });
    }
  }
}

export function * onHandleEditQuestionSetRequest ({ payload }) {
  console.log('onHandleEditQuestionSetRequest, payload: ', payload);

  try {
    console.log('#onHandleEditQuestionSetRequest, try block');

    // checking if there is a new file and uploading the file if there is a new file
    // otherwise it will just check and skip
    payload = yield uploadHintFiles(payload);

    const response = yield call(request.put, `/question-sets/${payload.id}`, {
      questionSet: payload
    });

    console.log('#onHandleEditQuestionSetRequest, response: ', response);
    const {
      data: { questionSet }
    } = response;
    console.log('questionSet: ', questionSet);
    return yield all([
      put(handleEditQuestionSetSuccess(questionSet)),
      put(push('/teacher/question-sets'))
    ]);
  } catch (err) {
    console.error('Error, #onHandleEditQuestionSetRequest, err: ', err);

    const { status, message, errors } = err.response.data;
    if (message === 'Network Error') {
      return yield put({
        type: EDIT_QUESTION_SET_FAILURE,
        payload: {
          [FORM_ERROR]:
            'Network Error? Check your connection and please try again later...'
        }
      });
    }

    if (status === 409) {
      return yield put({
        type: EDIT_QUESTION_SET_FAILURE,
        payload: {
          [FORM_ERROR]: 'Already exists...',
          ...parseFormErrors(errors)
        }
      });
    }

    if (status === 422 || status === 400) {
      return yield put({
        type: EDIT_QUESTION_SET_FAILURE,
        payload: {
          [FORM_ERROR]: message,
          ...parseFormErrors(errors)
        }
      });
    }

    if (err.response.status === 500) {
      return yield put({
        type: EDIT_QUESTION_SET_FAILURE,
        payload: {
          [FORM_ERROR]: 'Its not you, its us....... Please try again later.'
        }
      });
    }
  }
}

export function * onHandleDeleteQuestionSetRequest ({ payload }) {
  console.log('onHandleDeleteQuestionSetRequest, payload: ', payload);

  try {
    console.log('#onHandleDeleteQuestionSetRequest, try block');

    const response = yield call(request.del, `/question-sets/${payload}`);
    console.log('#onHandleDeleteQuestionSetRequest, response: ', response);

    return yield all([
      put(handleDeleteQuestionSetSuccess()),
      put(push('/teacher/question-sets'))
    ]);
  } catch (err) {
    console.error('Error, #onHandleDeleteQuestionSetRequest, err: ', err);

    const { message } = err.response.data;
    if (message === 'Network Error') {
      return yield put({
        type: DELETE_QUESTION_SET_FAILURE,
        payload: {
          [FORM_ERROR]:
            'Network Error? Check your connection and please try again later...'
        }
      });
    }

    if (err.response.status === 500) {
      return yield put({
        type: DELETE_QUESTION_SET_FAILURE,
        payload: {
          [FORM_ERROR]: 'Its not you, its us....... Please try again later.'
        }
      });
    }
  }
}

const INITIAL_STATE = {
  isLoading: false,
  data: [],
  error: null
};

export default function questionSets (state = INITIAL_STATE, action) {
  switch (action.type) {
    case EDIT_QUESTION_SET_REQUEST:
    case CREATE_QUESTION_SET_REQUEST:
    case QUESTION_SET_DASHBOARD_REQUEST:
    case DELETE_QUESTION_SET_REQUEST:
      return { ...state, isLoading: true };
    case CREATE_QUESTION_SET_SUCCESS:
    case EDIT_QUESTION_SET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        questionSet: action.payload
      };
    case QUESTION_SET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        questionSet: action.payload
      };
    case DELETE_QUESTION_SET_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    case DELETE_QUESTION_SET_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case QUESTION_SET_DASHBOARD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload
      };
    case EDIT_QUESTION_SET_FAILURE:
    case CREATE_QUESTION_SET_FAILURE:
    case QUESTION_SET_DASHBOARD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
}
