import 'dotenv/config';

let env;

env = {
  REACT_APP_ENVIRONMENT: checkEnvVar('REACT_APP_ENVIRONMENT'),
  REACT_APP_BUILD_ENV: checkEnvVar('REACT_APP_BUILD_ENV'),
  REACT_APP_TUTORD_API_ORIGIN: checkEnvVar('REACT_APP_TUTORD_API_ORIGIN'),
  REACT_APP_STRIPE_PUBLISHABLE_KEY: checkEnvVar('REACT_APP_STRIPE_PUBLISHABLE_KEY'),
  REACT_APP_GA_ID: checkEnvVar('REACT_APP_GA_ID'),
  REACT_APP_BRAZE_API_KEY: checkEnvVar('REACT_APP_BRAZE_API_KEY'),
  REACT_APP_BRAZE_SDK_ENDPOINT: checkEnvVar('REACT_APP_BRAZE_SDK_ENDPOINT'),
  REACT_APP_TERMLY_UUID: checkEnvVar('REACT_APP_TERMLY_UUID')
};

function checkEnvVar (name) {
  if (!process.env[name]) {
    console.error(`Error: ${name} is not set`);
    throw new Error(`ERROR: Environment variable not specified ${name}`);
  }

  return process.env[name];
}

export default env;
