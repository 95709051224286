import { BellOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';
import { Col, Row, Typography } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { handleDashboardRequest } from '../../../modules/dashboard/redux';
import { dispatch } from '../../../store';
import {
  MainLayout,
  SecondaryLayout,
  StyledContainer,
  StyledContent
} from '../../../styles/StyledComponents';
import SiderNav from '../../template/SiderNav';

const { Title } = Typography;

class AdminDashboardPage extends Component {
  componentDidMount () {
    dispatch(handleDashboardRequest());
  }

  render () {
    const { user } = this.props;
    return (
      <MainLayout hasSider>
        <SiderNav user={user} />
        <SecondaryLayout>
          <StyledContent>
            <Row gutter={[16, 16]}>
              {/* LEFT SIDE */}
              <Col lg={18}>
                <StyledContainer>
                  <Row gutter={[8, 16]}>
                    <Col>
                      <Title level={2} style={{ fontWeight: 'lighter' }}>
                        Welcome Back,
                      </Title>
                    </Col>
                    <Col flex='auto'>
                      <Title level={2}>Rick!</Title>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col>
                      <p>
                        Your students completed{' '}
                        <span style={{ fontWeight: 'bolder' }}>80%</span> of the
                        task. Progress is{' '}
                        <span style={{ fontWeight: 'bolder' }}>very good!</span>
                      </p>
                    </Col>
                  </Row>
                </StyledContainer>
                <Row gutter={[16, 16]}>
                  <Col lg={12} sm={20} xs={24}>
                    <StyledContainer />
                  </Col>
                  <Col lg={12} sm={20} xs={24}>
                    <StyledContainer />
                  </Col>
                </Row>
              </Col>
              {/* RIGHT SIDE */}
              <Col lg={6}>
                <Row gutter={[16, 16]}>
                  <Col lg={3}>
                    <SearchOutlined style={{ fontSize: 20 }} />
                  </Col>
                  <Col span={3}>
                    <BellOutlined style={{ fontSize: 20 }} />
                  </Col>
                  <Col span={16}>
                    <div style={{ textAlign: 'right' }}>
                      <Title level={5} style={{ lineHeight: 0.3 }}>
                        Rick James
                      </Title>
                      <small>superfreak@gmail.com</small>
                    </div>
                  </Col>
                  <Col span={2}>
                    <UserOutlined style={{ fontSize: 20 }} />
                  </Col>
                </Row>
                <div style={{ margin: 40 }} />
                <Row gutter={[16, 16]}>
                  <Col flex={1}>
                    <StyledContainer />
                  </Col>
                </Row>
              </Col>
            </Row>
          </StyledContent>
        </SecondaryLayout>
      </MainLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    dashboard: state.dashboard.data,
    isLoading: state.dashboard.isLoading
  };
};

export default connect(mapStateToProps, {})(AdminDashboardPage);
