import React from 'react';
import { RightOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';
import { StyledTitle as Title } from '../../fields/Title';
import LaptopImage from '../../../assets/Laptop.png';
import LearningImage from '../../../assets/learning.svg';
import AccessibleImage from '../../../assets/accessible.svg';
import GlobeImage from '../../../assets/globe.svg';
import { StyledButton } from '../../../styles/StyledComponents';

export const WhyParents = () => {
  return (
    <>
      <Row justify='center'>
        <Col
          xl={20}
          sm={22}
          style={{
            background: '#fff',
            padding: 40,
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
          }}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 40 }}>
            {/* LEFT COLUMN */}
            <Col xl={12}>
              <img src={LaptopImage} alt='' />
              {/* <img src={PhoneImage} style={{width: 400, margin: '0 auto', display: 'block'}} alt="" /> */}
            </Col>
            {/* RIGHT COLUMN */}
            <Col xl={12}>
              <Title level={2} style={{ color: '#333', fontWeight: 'bolder' }}>
                Why TutorD?
              </Title>
              <br />
              <strong style={{ color: '#333' }}>
                Lets talk about it, do you know if your scholars are engaged?
                Barely hanging on? Or disconnected from their learning
                experience?
              </strong>
              <br />
              <br />
              <p>
                We know times are hard and this pandemic has created a lot of challenges for families.
                Our goal is to ease those challenges by providing easier access to your child's classroom
                and their curriculum.  We would love your child's institution to join our journey.
              </p>
              <br />
              <StyledButton style={{ borderRadius: 6, height: 40, width: 150 }}>
                ABOUT US <RightOutlined />
              </StyledButton>
            </Col>
          </Row>
          {/* PURPLE CONTAINER */}
          <Row justify='center' style={{ margin: '40px 0' }}>
            <Col
              xl={24}
              style={{
                background: '#8053D7',
                borderRadius: 6,
                filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                padding: 40
              }}
            >
              <p
                style={{
                  fontSize: '2.5em',
                  fontFamily: 'Montserrat Bold',
                  color: '#fff',
                  textAlign: 'center'
                }}
              >
                Allowing you to address the{' '}
                <span style={{ color: '#FFDC54' }}>Whole Student.</span>
              </p>
              <p style={{ color: '#fff', textAlign: 'center' }}>
                Students self identifying their learning language can provide
                educators with the information to make for a more engaging
                experience.
              </p>
            </Col>
          </Row>
          {/* 3 ROW COLUMN */}
          <Row justify='center' gutter={[40, 40]} style={{ textAlign: 'center' }}>
            <Col xl={8}>
              <img src={AccessibleImage} alt='' style={{ height: 200 }} />
              <br />
              <br />
              <Title level={3} style={{ color: '#333' }}>
                A NEW APPROACH
              </Title>
              <p style={{ color: '#333' }}>
                Education always has to keep up with technology and culture - it
                is an evolving practice and we are taking an approach that is
                adaptive and engaging.
              </p>
            </Col>
            <Col xl={8}>
              <img src={GlobeImage} alt='' style={{ height: 200 }} />
              <br />
              <br />
              <Title level={3} style={{ color: '#333' }}>
                ACCESSIBLE ANYWHERE
              </Title>
              <p style={{ color: '#333' }}>
                Our approach is mobile first which gives your students the
                ability to engage with your educational materials anywhere,
                becuase everyone has a phone.
              </p>
            </Col>
            <Col xl={8}>
              <img src={LearningImage} alt='' style={{ height: 200 }} />
              <br />
              <br />
              <Title level={3} style={{ color: '#333' }}>
                PRACTICE LEARNING
              </Title>
              <p style={{ color: '#333' }}>
                With a text message thread students can now have a study guide
                at hand to review and gain confidence and mastery.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
