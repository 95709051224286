import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { handleVerifyStudentEmail } from '../../modules/sign-up/redux/enrollment';
import { dispatch } from '../../store';

class VerifiedStudentEmailPage extends Component {
  componentWillMount () {
    const emailToken = this.props.match.params.verifyEmailToken;
    console.log('studentVerifiedEmailPage, emailToken: ', emailToken);
    dispatch(handleVerifyStudentEmail(emailToken));
  }

  render () {
    return (
      <div>
        Verifying Email...
      </div>
    );
  }
}

export default withRouter(VerifiedStudentEmailPage);
