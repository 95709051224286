import { all, call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import request from '../../../lib/request';
import { FORM_ERROR } from 'final-form';
import { parseFormErrors } from '../../../utils/parseFormErrors';

// ACTION TYPES
export const IDENTIFY_STUDENT_LEARNING_STYLE_REQUEST = 'sign-up/enrollment/IDENTIFY_STUDENT_LEARNING_STYLE_REQUEST';
export const IDENTIFY_STUDENT_LEARNING_STYLE_SUCCESS = 'sign-up/enrollment/IDENTIFY_STUDENT_LEARNING_STYLE_SUCCESS';
export const IDENTIFY_STUDENT_LEARNING_STYLE_FAILURE = 'sign-up/enrollment/IDENTIFY_STUDENT_LEARNING_STYLE_FAILURE';

// ACTION CREATORS
export const handleIdentifyStudentLearningStyleRequest = payload => ({
  type: IDENTIFY_STUDENT_LEARNING_STYLE_REQUEST,
  payload
});

export const handleIdentifyStudentLearningStyleSuccess = payload => ({
  type: IDENTIFY_STUDENT_LEARNING_STYLE_SUCCESS,
  payload,
  loading: false
});

export const handleIdentifyStudentLearningStyleFailure = payload => ({
  type: IDENTIFY_STUDENT_LEARNING_STYLE_FAILURE,
  payload,
  isLoading: false
});

export function * onHandleIdentifyStudentLearningStyleRequest ({ payload: { learningStyles = [] } }) {
  console.log('#onHandleIdentifyStudentLearningStyleRequest, learningStyles: ', learningStyles);

  try {
    console.log('#onHandleIdentifyStudentLearningStyleRequest, try block');
    const response = yield call(request.put, '/accounts/student/enrollment', { learningStyles });

    const { user } = response.data;
    localStorage.setItem('user', JSON.stringify(user));

    yield all([
      put(handleIdentifyStudentLearningStyleSuccess(user)),
      put(push('/student/enrollment-complete'))
    ]);
  } catch (err) {
    console.error('Error, #onHandleIdentifyStudentLearningStyleRequest, err: ', err);
    const { status, message, errors } = err.response.data;

    if (message === 'Network Error') {
      return yield put({
        type: IDENTIFY_STUDENT_LEARNING_STYLE_SUCCESS,
        payload: { [FORM_ERROR]: 'Network Error? Check your connection and please try again later...' }
      });
    }

    if (status === 409) {
      return yield put({
        type: IDENTIFY_STUDENT_LEARNING_STYLE_SUCCESS,
        payload: {
          [FORM_ERROR]: 'Already exists...',
          ...parseFormErrors(errors)
        }
      });
    }

    if (status === 422 || status === 400) {
      return yield put({
        type: IDENTIFY_STUDENT_LEARNING_STYLE_SUCCESS,
        payload: {
          [FORM_ERROR]: message,
          ...parseFormErrors(errors)
        }
      });
    }

    if (err.response.status === 500) {
      return yield put({
        type: IDENTIFY_STUDENT_LEARNING_STYLE_SUCCESS,
        payload: { [FORM_ERROR]: 'Its not you, its us....... Please try again later.' }
      });
    }
  }
}

const INITIAL_STATE = {
  isLoading: false,
  error: null
};

export default function setStudentPhoneNumber (state = INITIAL_STATE, action) {
  switch (action.type) {
    case IDENTIFY_STUDENT_LEARNING_STYLE_REQUEST:
      return { ...state, isLoading: true };
    case IDENTIFY_STUDENT_LEARNING_STYLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload.user
      };
    case IDENTIFY_STUDENT_LEARNING_STYLE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
}
