import React, { Component } from 'react';
import { connect } from 'react-redux';
import SiderNav from '../../template/SiderNav';
import { MainLayout, NewButton, SecondaryLayout, StyledButton, StyledContainer, StyledContent } from '../../../styles/StyledComponents';
import OrganizationForm from './OrganizationForm';
import { Avatar, Col, Divider, Row } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import { Link } from 'react-router-dom';

class OrganizationPage extends Component {
  render () {
    const { isLoading, user = {} } = this.props;
    const onChange = (e) => {
      console.log(`radio checked:${e.target.value}`);
    };
    return (
      <MainLayout hasSider>
        {/* SIDE NAV BAR */}
        <SiderNav />

        <SecondaryLayout>
          <StyledContent>
            <Row gutter={16} justify='center' style={{ width: '100%' }}>
              <Col>
                <Title level={3}>
                    Organizations
                </Title>
              </Col>
              <Col flex={1}>
                <NewButton>
                    NEW ORGANIZATION
                </NewButton>
              </Col>
            </Row>
            <br />
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col sm={20} xl={10}>
                <StyledContainer style={{ padding: 10 }}>
                  <Row gutter={[16, 0]} align='middle'>
                    <Col>
                      <Avatar size={40} icon={<UserOutlined />} />
                    </Col>
                    <Col flex={1}>
                      <strong style={{ fontSize: 16, marginRight: 10 }}>{user.firstName}{' '}{user.lastName}</strong>
                      <small>Owner</small>
                    </Col>
                    <Col>
                      <StyledButton style={{ background: '#8053D7', color: '#fff' }}>Settings</StyledButton>
                    </Col>
                    <Col>
                      <StyledButton style={{ background: 'darkgray', color: '#fff' }}>Leave</StyledButton>
                    </Col>
                  </Row>
                </StyledContainer>
              </Col>
            </Row>
            <br />
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col sm={20} xl={10}>
                <Title level={4}>Transform accounts</Title>
                <Divider />
                <Link to='/teacher/organizations/plans'>
                  <StyledButton style={{ background: '#8053D7', color: '#fff' }}>Turn theTutorB into an organization</StyledButton>
                </Link>
              </Col>
            </Row>
          </StyledContent>
        </SecondaryLayout>
      </MainLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    isLoading: state.auth.isLoading
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationPage);
