import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleStudentsDashboardRequest } from '../../../../../modules/students/redux';
import { handleClassesDashboardRequest } from '../../../../../modules/classes/redux';
import { classesSelector } from '../../../../../modules/classes/redux/selectors';
import { studentsSelector } from '../../../../../modules/students/redux/selectors';

const useClassPage = () => {
  const [searchedValue, setSearchedValue] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(handleStudentsDashboardRequest());
    dispatch(handleClassesDashboardRequest());
  }, [dispatch]);

  const {
    isLoading: classesLoading,
    data: allClasses,
    error
  } = useSelector(classesSelector);
  const { isLoading: studentsLoading, data: students } =
    useSelector(studentsSelector);

  const isLoading = useMemo(
    () => studentsLoading || classesLoading,
    [classesLoading, studentsLoading]
  );

  // SEARCHED CLASSES BY TITLE
  const searchedClasses = useMemo(() => {
    if (!!searchedValue && searchedValue.length > 0) {
      return allClasses.filter(({ title }) =>
        title.toLocaleLowerCase().startsWith(searchedValue.toLocaleLowerCase())
      );
    }

    return allClasses;
  }, [allClasses, searchedValue]);

  // SEARCH CLASSES ONCHANGE HANDLER
  const handleClassesSearch = useCallback(
    (e) => {
      const { value } = e.target;

      if (!searchedValue && value === ' ') {
        return;
      }

      setSearchedValue(value);
    },
    [searchedValue]
  );

  const searchInputConfig = useMemo(
    () => ({
      value: searchedValue,
      onChange: handleClassesSearch,
      disabled: allClasses?.length === 0 || !allClasses
    }),
    [allClasses, handleClassesSearch, searchedValue]
  );

  return {
    classes: searchedClasses,
    students,
    isLoading,
    error,
    handleClassesSearch,
    searchedValue,
    searchInputConfig
  };
};

export default useClassPage;
