import { createSelector } from 'reselect';

// DASHBOARD SELECTOR
export const dashboardSelector = (state) => state.dashboard;

// CUSTOM ENTITY SELECTOR
export const getEntityFromProps = (state, props) => props;

// DASHBOARD ALL DATA SELECTOR
export const dashboardDataSelector = createSelector(
  [dashboardSelector],
  (dashboard) => dashboard?.data
);

// DASHBOARD ALL STUDENTS SELECTOR
export const dashboardStudentsSelector = createSelector(
  [dashboardDataSelector],
  (data) => data?.myStudents
);

// DASHBOARD ALL CLASSES SELECTOR
export const dashboardClassesSelector = createSelector(
  [dashboardDataSelector],
  (data) => data?.myClasses
);

// DASHBOARD ALL TUTOR GROUPS SELECTOR
export const dashboardTutorGroupsSelector = createSelector(
  [dashboardClassesSelector],
  (classes) => classes?.map((currentClass) => currentClass?.tutorGroups)?.flat()
);

// DASHBOARD CURRENT CLASS BY ID SELECTOR
export const dashboardClassByIdSelector = createSelector(
  [dashboardClassesSelector, getEntityFromProps],
  (classes, props) =>
    classes?.find((currentClass) => currentClass?.id === props?.id)
);

// DASHBOARD CURRENT TUTOR GROUP BY ID SELECTOR
export const dashboardTutorGroupByIdSelector = createSelector(
  [dashboardTutorGroupsSelector, getEntityFromProps],
  (groups, props) =>
    groups?.find((currentGroup) => currentGroup?.id === props?.id)
);

// DASHBOARD ALL STUDENT IDS BY CLASS SELECTOR
export const dashboardStudentsIdsByClassSelector = createSelector(
  [dashboardClassByIdSelector],
  (currentClass) => currentClass?.studentEnrollments
);

// DASHBOARD ALL STUDENT IDS BY TUTOR GROUP SELECTOR
export const dashboardStudentsIdsByTutorGroupSelector = createSelector(
  [dashboardTutorGroupByIdSelector],
  (currentGroup) => currentGroup?.students
);

// DASHBOARD ALL STUDENT IDS BY CLASS OR TUTOR GROUP SELECTOR

export const dashboardStudentsIdsByEntitySelector = createSelector(
  [
    dashboardClassByIdSelector,
    dashboardTutorGroupByIdSelector,
    getEntityFromProps
  ],
  (currentClass, currentGroup, props) => {
    if (props?.type === 'class') {
      return currentClass?.studentEnrollments;
    }

    if (props?.type === 'group') {
      return currentGroup?.students;
    }

    return [];
  }
);

// DASHBOARD ALL STUDENTS BY CLASS OR TUTOR GROUP SELECTOR
export const dashboardStudentsByEntitySelector = createSelector(
  [dashboardStudentsIdsByEntitySelector, dashboardStudentsSelector],
  (studentsIds, students) => {
    let studentsByClass = [];

    studentsIds?.forEach((id) => {
      const student = students?.find((student) => student?.id === id);

      if (student) {
        studentsByClass = [...studentsByClass, student];
      }
    });
    return studentsByClass;
  }
);

// EACH STUDENT PERFORMANCE IN TUTOR GROUP BY FIRST TUTOR SESSION
export const dashboardStudentsByEntityPerformanceSelector = createSelector(
  [dashboardStudentsByEntitySelector, dashboardTutorGroupByIdSelector],
  (students, group) =>
    students?.map((student) => {
      return ({
        name: `${student?.firstName} ${student?.lastName}`,
        // FIXME: ADD FUNCTIONALITY FOR MULTIPLE SESSIONS
        performance: group?.tutorSessions[0]?.studentPerformance[student?.id]
      });
    })
);

// EACH STUDENT PERFORMANCE IN TUTOR GROUP BY ALL TUTOR SESSIONS
export const dashboardStudentsByTutorSessionsPerformanceSelector =
  createSelector(
    [dashboardStudentsByEntitySelector, dashboardTutorGroupByIdSelector],
    (students, group) => students
      ?.map((student) => group?.tutorSessions?.map((session, index) => ({
        title: `Tutor Session ${index + 1}`,
        studentName: `${student?.firstName} ${student?.lastName}`,
        performance: session?.studentPerformance[student?.id]
      })))
      .flat()

  );

// GET THE LEARNING TYPES QUANTITY BY ALL STUDENTS
export const studentsLearningStylesSelector = createSelector(
  [dashboardStudentsSelector],
  (students) => students?.map((student) => student?.learningStyles[0]).flat()
);

// GET THE LEARNING TYPES QUANTITY BY ALL STUDENTS IN CLASS OR TUTOR GROUP
export const studentsLearningStylesByEntitySelector = createSelector(
  [dashboardStudentsByEntitySelector],
  (students) => students?.map((student) => student?.learningStyles[0]).flat()
);

// SET THE LEARNING TYPES QUANTITY BY ALL STUDENTS
export const sortedAllStudentsLearningStylesByQuantitySelector = createSelector(
  [studentsLearningStylesSelector],
  (types) =>
    types?.reduce(
      (acc, type) => {
        if (type) {
          acc[type] = (acc[type] || 0) + 1;
        }

        return acc;
      },
      {},
      null,
      2
    )
);

// SET THE LEARNING TYPES QUANTITY BY ALL STUDENTS IN CURRENT TUTOR GROUP
export const sortedStudentsLearningStylesQuantityByEntitySelector =
  createSelector([studentsLearningStylesByEntitySelector], (types) =>
    types?.reduce(
      (acc, type) => {
        if (type) {
          acc[type] = (acc[type] || 0) + 1;
        }

        return acc;
      },
      {},
      null,
      2
    )
  );
