import React, { useState } from 'react';
import { Field as FField, Form } from 'react-final-form';
import { InputControl, EmailInputControl, parsePhone, formatPhone, SelectControl } from '../../../fields/Fields';
import { Col, Row } from 'antd';
import { capitalize } from '../../../fields/renderFields';
import * as validations from '../../../../utils/validations';
import Title from 'antd/lib/typography/Title';

// We need to capture Class data such as:
//  Title: "Basic Chemistry"
//  ClassCode: "Chem 101-Mr Rogers"
//  Location: "Online"
//  Grades: [9, 10, 11, 12]
//  Subject: ""

export const AddClassForm = () => {
  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col lg={12} sm={20} xs={24}>
          <Title level={5}>Class Name</Title>
          <FField
            name='title'
            component={InputControl}
            type='text'
            placeholder='Basic Chemistry'
            format={capitalize}
            validate={validations.composeValidators(validations.required, validations.minLength(4), validations.maxLength(25))}
          />
        </Col>
      </Row>
    </>
  );
};
