import { all, takeLatest } from 'redux-saga/effects';
import {
  CREATE_STUDENT_REQUEST,
  STUDENT_PROFILE_REQUEST,
  STUDENT_ENROLLMENTS_DASHBOARD_REQUEST,
  onHandleStudentsDashboardRequest,
  onHandleStudentProfileRequest,
  onHandleCreateStudentRequest,
  EDIT_STUDENT_REQUEST,
  onHandleEditStudentProfileRequest,
  STUDENT_PROFILE_GRAPH_REQUEST,
  onHandleStudentProfileGraphRequest
} from './redux';

function * watchStudentsDashboard () {
  console.log('#watchStudentsDashboard');
  yield takeLatest(
    STUDENT_ENROLLMENTS_DASHBOARD_REQUEST,
    onHandleStudentsDashboardRequest
  );
}

function * watchStudentProfile () {
  console.log('#watchStudentProfile');
  yield takeLatest(STUDENT_PROFILE_REQUEST, onHandleStudentProfileRequest);
}

function * watchCreateStudent () {
  console.log('#watchCreateStudent');
  yield takeLatest(CREATE_STUDENT_REQUEST, onHandleCreateStudentRequest);
}

function * watchEditStudent () {
  console.log('#watchEditStudent');
  yield takeLatest(EDIT_STUDENT_REQUEST, onHandleEditStudentProfileRequest);
}
function * watchStudentProfileGraph () {
  console.log('#watchStudentProfileGraph');
  yield takeLatest(
    STUDENT_PROFILE_GRAPH_REQUEST,
    onHandleStudentProfileGraphRequest
  );
}
export default function * sagas () {
  yield all([
    watchCreateStudent(),
    watchStudentProfile(),
    watchStudentsDashboard(),
    watchEditStudent(),
    watchStudentProfileGraph()
  ]);
}
