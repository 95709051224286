import React, { useEffect, useState } from 'react';
import RegisterForm from './RegistrationForm';
import TutorDOwl from '../../../../assets/tutord-signin-page.svg';
import { Row, Col } from 'antd';
import {
  MainCol,
  BigTitle,
  Img,
  WhiteText
} from '../../../../styles/StyledComponents';
import Header from '../../../template/Header';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  validatedOrganizationSelector,
  organizationNotFoundSelector
} from '../../../../modules/classes/redux/selectors';
import { dispatch, promiseListener } from '../../../../store';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { handleGetOrganizationWithTokenRequest } from '../../../../modules/classes/redux';
import {
  registerStudentLoadingSelector,
  registeredStudentSelector
} from '../../../../modules/account/redux/selectors';
import Title from 'antd/lib/typography/Title';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { handleShowToast } from '../../../../modules/notification/redux';
import {
  CREATE_STUDENT_WITH_ORG_TOKEN_FAILURE,
  CREATE_STUDENT_WITH_ORG_TOKEN_REQUEST,
  CREATE_STUDENT_WITH_ORG_TOKEN_SUCCESS
} from '../../../../modules/account/redux';

const RegisterStudentWithOrgToken = () => {
  const { token: organizationToken } = useParams();
  const [showSuccess, setShowSuccess] = useState(false);
  const validatedOrganization = useSelector(validatedOrganizationSelector);
  const organizationNotFound = useSelector(organizationNotFoundSelector);
  const registeredStudent = useSelector(registeredStudentSelector);
  const isLoading = useSelector(registerStudentLoadingSelector);

  useEffect(() => {
    if (organizationToken) {
      dispatch(handleGetOrganizationWithTokenRequest(organizationToken));
    }
  }, [organizationToken]);

  useEffect(() => {
    if (registeredStudent) {
      setShowSuccess(true);
    }
  }, [registeredStudent]);

  return (
    <Row justify='center'>
      <Col xl={11} sm={24} xs={24}>
        <Header />
        <div style={{ padding: 30, textAlign: 'center' }}>
          <BigTitle>
            <FormattedMessage id='studentRegistration.register-student-with.aside.title' />
          </BigTitle>
          <Img src={TutorDOwl} alt='TutorD Owl' style={{ marginBottom: 20 }} />
          <WhiteText
            style={{
              fontWeight: 10,
              fontSize: '2em',
              fontFamily: 'Montserrat Light'
            }}
          >
            <FormattedMessage id='studentRegistration.register-student-using-org-token.aside.subTitle' />
            {organizationNotFound ? ' other ' : (validatedOrganization?.organization?.name && (
              <span>
                {' '}
                {validatedOrganization?.organization?.name}
                {' '}
              </span>
            ))}
            <FormattedMessage id='studentRegistration.register-student-using-org-token.aside.subTitleTwo' />
          </WhiteText>
        </div>
      </Col>
      <MainCol xl={13} sm={20} xs={24}>
        {!organizationNotFound ? (
          showSuccess ? (
            <div>
              <Title>Successfully Registered</Title>
              <Title style={{ fontWeight: 'normal', fontSize: '20px' }}>
                A confirmation email has been sent to your email. Please verify
                your email to finish your onboarding process.
              </Title>
            </div>
          ) : (
            <MakeAsyncFunction
              listener={promiseListener}
              start={CREATE_STUDENT_WITH_ORG_TOKEN_REQUEST}
              resolve={CREATE_STUDENT_WITH_ORG_TOKEN_SUCCESS}
              reject={CREATE_STUDENT_WITH_ORG_TOKEN_FAILURE}
              getError={(action) => {
                dispatch(
                  handleShowToast({
                    message: action.payload['FINAL_FORM/form-error'],
                    type: 'error'
                  })
                );
              }}
            >
              {(onSubmit) => (
                <RegisterForm
                  registrationToken={organizationToken}
                  isLoading={isLoading}
                  onSubmit={onSubmit}
                />
              )}
            </MakeAsyncFunction>
          )
        ) : (
          <div>
            <Title>Invalid Class Registration Link</Title>
            <Title style={{ fontWeight: 'normal', fontSize: '20px' }}>
              Please contact your instructor for further assistance.
            </Title>
          </div>
        )}
      </MainCol>
    </Row>
  );
};

export default RegisterStudentWithOrgToken;
