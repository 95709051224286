import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleQuestionSetsDashboardRequest } from '../../../../modules/question-set/redux';
import { questionSetsSelector } from '../../../../modules/question-set/redux/selectors';

const useQuestionSetsPage = () => {
  const [searchedValue, setSearchedValue] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(handleQuestionSetsDashboardRequest());
  }, [dispatch]);

  const { isLoading, data: allQuestionSets } =
    useSelector(questionSetsSelector);

  // SEARCHED QUESTION SETS BY TITLE
  const searchedQuestionSets = useMemo(() => {
    if (!!searchedValue && searchedValue.length > 0) {
      return allQuestionSets.filter(({ title }) =>
        title.toLocaleLowerCase().startsWith(searchedValue.toLocaleLowerCase())
      );
    }

    return allQuestionSets;
  }, [allQuestionSets, searchedValue]);

  // SEARCH QUESTION SETS ONCHANGE HANDLER
  const handleQuestionSetsSearch = useCallback(
    (e) => {
      const { value } = e.target;

      if (!searchedValue && value === ' ') {
        return;
      }

      setSearchedValue(value);
    },
    [searchedValue]
  );

  const searchInputConfig = useMemo(
    () => ({
      value: searchedValue,
      onChange: handleQuestionSetsSearch,
      disabled: allQuestionSets?.length === 0 || !allQuestionSets
    }),
    [allQuestionSets, handleQuestionSetsSearch, searchedValue]
  );

  return {
    questionSets: searchedQuestionSets,
    isLoading,
    handleQuestionSetsSearch,
    searchedValue,
    searchInputConfig
  };
};

export default useQuestionSetsPage;
