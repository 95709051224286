import { createGlobalStyle } from 'styled-components';
import NotoSansBold from './fonts/noto-sans/NotoSans-Bold.ttf';
import NotoSans from './fonts/noto-sans/NotoSans-Regular.ttf';
import Montserrat from './fonts/montserrat/Montserrat-Regular.ttf';
import MontserratBold from './fonts/montserrat/Montserrat-Bold.ttf';
import MontserratLight from './fonts/montserrat/Montserrat-Light.ttf';

const GlobalStyles = createGlobalStyle`
    @font-face {
      font-family: 'Noto Sans Bold';
      src: local('Noto Sans Bold'), local('NotoSans Bold'), local('NotoSansBold'), url(${NotoSansBold}) format('truetype');
      font-weight: bold;
      font-style: normal;
    }
    
    @font-face {
      font-family: 'Noto Sans';
      src: local('Noto Sans'), local('Noto Sans Regular'), local('NotoSans-Regular'), url(${NotoSans}) format('truetype');
      font-weight: normal;
      font-style: normal;
    }

    @font-face {
      font-family: 'Montserrat';
      src: local('Montserrat'), local('Montserrat Regular'), local('Montserrat-Regular'), url(${Montserrat}) format('truetype');
      font-weight: normal;
      font-style: normal;
    }

    @font-face {
      font-family: 'Montserrat Bold';
      src: local('Montserrat'), local('Montserrat Bold'), local('Montserrat-Bold'), url(${MontserratBold}) format('truetype');
      font-weight: bold;
      font-style: normal;
    }

    @font-face {
      font-family: 'Montserrat Light';
      src: local('Montserrat'), local('Montserrat Light'), local('Montserrat-Light'), url(${MontserratLight}) format('truetype');
      font-style: normal;
    }
    
    html,
    body {
      margin: 0;
      padding: 0;
      background-color: ${(props) => {
    return props.theme.background;
  }};
      
      --antd-wave-shadow-color: ${(props) => {
    return props.theme.background;
  }};
    }
    
    & .ant-layout {
      background-color: ${(props) => {
    return props.theme.primaryPurple;
  }};
    }
    
    .error {
      color: #FF0000;
    }
    .hvr-float-shadow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.hvr-float-shadow:before {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  content: '';
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 0;
  background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
  /* W3C */
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform, opacity;
  transition-property: transform, opacity;
}
.hvr-float-shadow:hover, .hvr-float-shadow:focus, .hvr-float-shadow:active {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  /* move the element up by 5px */
}
.hvr-float-shadow:hover:before, .hvr-float-shadow:focus:before, .hvr-float-shadow:active:before {
  opacity: 1;
  -webkit-transform: translateY(5px);
  transform: translateY(5px);
  /* move the element down by 5px (it will stay in place because it's attached to the element that also moves up 5px) */
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub{
  background: #6440AA;
}
.ant-picker-panel-container {
  border-radius: 8px;
  border: 1px solid #D9D9D9;

  & .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
    transition: all 0.2s linear;
  }

  & .ant-picker-time-panel-column > li.ant-picker-time-panel-cell:hover .ant-picker-time-panel-cell-inner {
    background: transparent;
    transition: all 0.2s linear;
    color: #9874DF;
  }

  & .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background: transparent;
    color: #8053D7;
    font-weight: 600;
  }

  & .ant-picker-now-btn {
    transition: all 0.2s linear;
    color: #8053D7;
    font-weight: 600;
  }

  & .ant-picker-now-btn:hover {
    transition: all 0.2s linear;
    color: #9874DF;
  }

  & .ant-picker-ok > .ant-btn {
    border-radius: 6px;
    border: none;
    outline: none;
    background-color: #8053D7;
    font-weight: 600;
  }

  & .ant-picker-ok:hover > .ant-btn {
    background-color: #9874DF;
  }
}

.ant-select-dropdown {
  border-radius: 8px;
  border: 1px solid #D9D9D9;
  & .ant-select-item-option-selected, 
  & .ant-select-item-option-active {
    background: transparent;
  }
  & .ant-select-item-option-active {
    color: #9874df;
  }
  & .ant-select-item-option-selected {
    color: #8053d7 !important;
    font-weight: 400
  }
}
.ant-tree-select-dropdown  {
  min-width: 130px !important;
  padding: 8px 15px 0px 8px;
  border-radius: 6px;
}
.ant-select-tree-node-selected {
  background-color: transparent !important;
  color: #8053D7;
}

.ant-select-tree-node-selected > .ant-select-tree-title {
  color: #8053D7;
  font-weight: 700;
}


.ant-select-tree-node-content-wrapper:hover {
  background-color: transparent !important;
  color: #9874DF;
}
    #components-layout-demo-fixed .logo {
      width: 120px;
      height: 31px;
      background: rgba(255, 255, 255, 0.2);
      margin: 16px 24px 16px 0;
      float: left;
    }
    
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    
    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
`;

export { getTheme } from './theme';

export default GlobalStyles;
