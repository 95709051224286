import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Row, Tag, Drawer, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Field as FField, Form as FForm } from 'react-final-form';
import { SelectControl } from '../../../fields/Fields';
import { StyledButton } from '../../../fields/Button';
import { FormattedMessage } from 'react-intl';
import * as validations from '../../../../utils/validations';
import { AddClassForm } from './AddClassForm';
import { FieldArray as FFieldArray } from 'react-final-form-arrays';
import { YellowUnderline } from '../../../../styles/StyledComponents';

const OPTIONS = [
  'Art',
  'Math',
  'Music',
  'Science',
  'Biology',
  'Geometry',
  'Chemistry',
  'Algebra 1',
  'Career Technical Education (CTE)',
  'Technology and Computer Science Orientation',
  'Other'
];

const OPTIONS_OBJ = {
  'Algebra 1': 'gold',
  Geometry: 'orange',
  Chemistry: 'green',
  Biology: 'cyan',
  Literature: 'geekblue',
  History: 'green',
  Science: 'volcano',
  English: 'red',
  Art: 'green',
  Math: 'cyan',
  Music: 'gold'
};

const tagRender = (props) => {
  const { label, value, closable, onClose } = props;
  return (
    <Tag color={OPTIONS_OBJ[value]} closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
      {label}
    </Tag>
  );
};

const ClassForm = (props) => {
  const { match } = props;
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <>
      <Row justify='center' align='middle' gutter={[16, 16]} style={{ height: '70vh' }}>
        <FFieldArray name='classes' validate={validations.composeValidators(validations.maxLength(3))}>
          {({ fields, meta }) => {
            return (
              <Col xl={14} xs={16}>
                <YellowUnderline level={3}>
                  <FormattedMessage id={`on-boarding.${match.params.step}.main.title`} />
                </YellowUnderline>
                <FField
                  name='classes'
                  component={SelectControl}
                  tagRender={tagRender}
                  options={OPTIONS}
                  mode='multiple'
                  size='large'
                  value={[]}
                  format={(value) => value || []}
                  type='text'
                  placeholder='Add your classes'
                  disabled={!(fields.length < 3)}
                />
                <StyledButton disabled={fields.length > 2} style={{ borderRadius: 6, color: '#fff' }} onClick={showDrawer}>
                  <PlusOutlined /> Class
                </StyledButton>{' '}
                <FForm
                  onSubmit={(value) => {
                    if (value.title) {
                      fields.push(value.title);
                    }
                    onClose();
                  }}
                  render={({ handleSubmit, form, invalid, submitting, pristine, values }) => {
                    return (
                      visible && (
                        <Drawer
                          title='Add Class'
                          width={window.innerWidth > 900 ? 600 : window.innerWidth}
                          placement='right'
                          onClose={onClose}
                          visible
                          bodyStyle={{ paddingBottom: 80 }}
                          footer={
                            <div
                              style={{
                                textAlign: 'right'
                              }}
                            >
                              <Button
                                style={{ marginRight: 8 }}
                                onClick={() => {
                                  onClose();
                                  form.restart();
                                }}
                              >
                                 Cancel
                              </Button>
                              <Button
                                type='submit'
                                htmlType='submit'
                                disabled={invalid}
                                onClick={(e) => {
                                  handleSubmit(e);
                                  form.restart();
                                }}
                              >
                                 Create Class
                              </Button>
                            </div>
                          }
                        >
                          <AddClassForm />
                        </Drawer>
                      )
                    );
                  }}
                />
              </Col>
            );
          }}
        </FFieldArray>
      </Row>
    </>
  );
};

export default withRouter(ClassForm);
