import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Card, Divider, Modal, List, Empty } from 'antd';
import { StyledTitle as Title } from '../../../fields/Title';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { dispatch } from '../../../../store';
import { handleDeleteClassRequest } from '../../../../modules/classes/redux';

const { Item } = List;
const { confirm } = Modal;

const StyledCard = styled(Card)`
  background: #fff;
  // padding: 20px;
  border-bottom-left-radius: 15px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);

  &.ant-card-bordered {
    border: none;
    border-top: 10px solid #d7753e;
  }
`;

function showPromiseConfirm (_class) {
  confirm({
    title: `Do you want to delete ${_class.title}?`,
    icon: <ExclamationCircleOutlined />,
    content:
      'All Class, Tutor Group, Session and Student data will be deleted if you do delete ',
    onOk () {
      console.log(
        'showPromiseConfirm, attempting to dispatch handleDeleteClassRequest, classId: ',
        _class.id
      );
      dispatch(handleDeleteClassRequest(_class.id));
    },
    onCancel () {}
  });
}

export const ClassList = ({ classes, isLoading, emptyDescription }) => {
  return (
    <List
      grid={{
        gutter: 16,
        xs: 1,
        sm: 2,
        md: 3,
        lg: 4,
        xl: 4,
        xxl: 4
      }}
      loading={isLoading}
      dataSource={classes}
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={emptyDescription}
          />
        )
      }}
      renderItem={(_class) => (
        <Item>
          <StyledCard>
            <Link to={`/teacher/classes/${_class.id}`}>
              <Title level={4}>{_class.title}</Title>
            </Link>
            <br />
            <small style={{ fontWeight: 'bolder', float: 'right' }}>
              <Link to={`/teacher/classes/${_class.id}`}>
                <Button size='small' type='link'>
                  Edit
                </Button>
              </Link>
              <Divider type='vertical' />
              <Button
                size='small'
                type='text'
                danger
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();

                  showPromiseConfirm(_class);
                }}
              >
                Delete
              </Button>
            </small>
          </StyledCard>
        </Item>
      )}
    />
  );
};
