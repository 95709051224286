import { createSelector } from 'reselect';

export const signUpSelector = state => state.signUp;

export const signUpUserSelector = createSelector(
  [signUpSelector],
  (state) => state.user
);
export const contactUsSelector = (state) => state.signUp;
export const enrollmentUserSelector = (state) => state.enrollment.user;
export const enrollmentTeacherSelector = (state) => state.enrollment.teacher;
export const enrollmentClassSelector = (state) => state.enrollment.classes;
