import { all, call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import request from '../../../lib/request';
import { FORM_ERROR } from 'final-form';
import { setAuthToken } from '../../auth/redux';
import { handleVerifyEmailSuccess } from './index';
import {
  handleVerifyStudentPhoneNumberSuccess,
  VERIFY_STUDENT_PHONE_NUMBER_SUCCESS
} from './verify-student-phone-number';
import { parseFormErrors } from '../../../utils/parseFormErrors';

export const VERIFY_STUDENT_EMAIL_REQUEST = 'sign-up/sign-up/VERIFY_STUDENT_EMAIL_REQUEST';
export const VERIFY_STUDENT_EMAIL_SUCCESS = 'sign-up/sign-up/VERIFY_STUDENT_EMAIL_SUCCESS';
export const VERIFY_STUDENT_EMAIL_FAILURE = 'sign-up/sign-up/VERIFY_STUDENT_EMAIL_FAILURE';

export const ENROLLMENT_REQUEST = 'sign-up/enrollment/ENROLLMENT_REQUEST';
export const ENROLLMENT_FAILURE = 'sign-up/enrollment/ENROLLMENT_FAILURE';
export const ENROLLMENT_SUCCESS = 'sign-up/enrollment/ENROLLMENT_SUCCESS';

export const SET_TEACHER_PROFILE_REQUEST = 'student/enrollment/TEACHER_PROFILE_REQUEST';

// TODO: Create handlers for
// TODO: onHandleSetStudentPhoneNumber
// TODO: onHandleVerifyStudentPhoneNumber
// TODO: onHandleStudentEnrollmentWizard
// TODO: each needs Page, Form, Redux-Sagas and Redux Middleware

export const handleEnrollmentRequest = payload => ({
  type: ENROLLMENT_REQUEST,
  payload,
  isLoading: true
});

export const handleEnrollmentSuccess = payload => ({
  type: ENROLLMENT_SUCCESS,
  payload,
  isLoading: false
});

export const handleEnrollmentFailed = payload => ({
  type: ENROLLMENT_FAILURE,
  payload,
  isLoading: false
});

export const handleVerifyStudentEmail = payload => ({
  type: VERIFY_STUDENT_EMAIL_REQUEST,
  payload
});

export const handleVerifyStudentEmailSuccess = payload => ({
  type: VERIFY_STUDENT_EMAIL_SUCCESS,
  payload,
  loading: false
});

export const handleTeacherProfileRequest = payload => ({
  type: SET_TEACHER_PROFILE_REQUEST,
  payload
});

export function * onHandleVerifyStudentEmail ({ payload }) {
  console.log('#onHandleVerifyStudentEmail, emailToken: ', payload);
  try {
    console.log('#onHandleVerifyStudentEmail, try block');
    const { data: { user, teacher, classes, token } } = yield call(request.post, '/accounts/verify/student/email', { emailToken: payload });

    localStorage.setItem('token', JSON.stringify(token));
    localStorage.setItem('user', JSON.stringify(user));

    yield all([
      put(handleVerifyStudentEmailSuccess({ user, teacher, classes })),
      // put(handleVerifyEmailSuccess(user)),
      put(setAuthToken(token)),
      put(push('/student/set-phone-number'))

    ]);
  } catch (err) {
    console.error('#onHandleVerifyStudentEmail, catch block, err: ', err);
    // TODO: Implement proper error handling for this page
  }
}

export function * onHandleEnrollmentRequest ({ payload: { learningStyles } }) {
  console.log('#onHandleEnrollmentRequest, learningStyles', learningStyles);

  try {
    console.log('#onHandleEnrollmentRequest, try block');
    const response = yield call(request.post, '/accounts/student/enrollment');

    const { user, token } = response.data;
    localStorage.setItem('token', JSON.stringify(token));
    localStorage.setItem('user', JSON.stringify(user));

    return yield all([
      put(handleVerifyStudentPhoneNumberSuccess(user)),
      put(setAuthToken(token))
    ]);
  } catch (err) {
    console.error('#onHandleEnrollmentRequest, err: ', err);
    const { status, message, errors } = err.response.data;

    if (message === 'Network Error') {
      return yield put({
        type: VERIFY_STUDENT_PHONE_NUMBER_SUCCESS,
        payload: { [FORM_ERROR]: 'Network Error? Check your connection and please try again later...' }
      });
    }

    if (status === 409) {
      return yield put({
        type: VERIFY_STUDENT_PHONE_NUMBER_SUCCESS,
        payload: {
          [FORM_ERROR]: 'Already Verified...',
          ...parseFormErrors(errors)
        }
      });
    }

    if (status === 422 || status === 400) {
      return yield put({
        type: VERIFY_STUDENT_PHONE_NUMBER_SUCCESS,
        payload: {
          [FORM_ERROR]: message,
          ...parseFormErrors(errors)
        }
      });
    }

    if (err.response.status === 500) {
      return yield put({
        type: VERIFY_STUDENT_PHONE_NUMBER_SUCCESS,
        payload: { [FORM_ERROR]: 'Its not you, its us....... Please try again later.' }
      });
    }
  }
}

const INITIAL_STATE = {
  isLoading: false,
  error: null
};

export default function enrollment (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_TEACHER_PROFILE_REQUEST:
      return { ...state, teacher: action.payload.teacher };
    case ENROLLMENT_REQUEST:
    case VERIFY_STUDENT_EMAIL_REQUEST:
      return { ...state, isLoading: true };
    case ENROLLMENT_SUCCESS:
      return { ...state, isLoading: false };
    case VERIFY_STUDENT_EMAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload.user,
        teacher: action.payload.teacher,
        classes: action.payload.classes
      };
    case ENROLLMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
}
