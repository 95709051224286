import { all, put } from 'redux-saga/effects';

export const SHOW_TOAST = 'show_toast_notification';

// Request Account information
export const handleShowToast = (payload) => ({
  type: SHOW_TOAST,
  payload
});

export function * onHandleShowToast (payload) {
  try {
    return yield all([put(handleShowToast(payload))]);
  } catch (err) {
    console.error('Error, #onHandleShowToast, err: ', err);
    return console.error('Error, #onHandleShowToast, err: ', err);
  }
}

const INITIAL_STATE = {
  currentToast: {
    id: 0
  }
};

export default function notification (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SHOW_TOAST:
      return {
        ...state,
        currentToast: { id: state.currentToast.id + 1, ...action.payload }
      };
    default:
      return state;
  }
}
