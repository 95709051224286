import React from 'react';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { Link, withRouter } from 'react-router-dom';
import { Field, Field as FField, Form as FForm } from 'react-final-form';

import { Row, Col, Form as AForm, Upload, message, Switch, Button, Divider, Radio, Input } from 'antd';
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  PlusCircleFilled,
  UploadOutlined,
  UserOutlined
} from '@ant-design/icons';
import { dispatch, promiseListener } from '../../../store';
import { ConditionalRender, EmailInputControl, InputControl, PasswordControl, RadioControl } from '../../fields/Fields';
import { StyledTitle as Title } from '../../fields/Title';
import { StyledButton } from '../../fields/Button';
import * as validations from '../../../utils/validations';
import { NewButton, PurpleButton, StyledContainer } from '../../../styles/StyledComponents';
import Auditory from '../../../assets/enrollment/auditory.svg';
import ReadingIcon from '../../../assets/enrollment/reading.svg';
import HandsOn from '../../../assets/enrollment/hands-on.svg';
import VisualIcon from '../../../assets/enrollment/visual.svg';
import { capitalize } from '../../fields/renderFields';
import Avatar from 'antd/lib/avatar/avatar';
import {
  CREATE_ORG_ACCOUNT_FAILURE,
  CREATE_ORG_ACCOUNT_REQUEST,
  CREATE_ORG_ACCOUNT_SUCCESS
} from '../../../modules/account/redux';
import LoadingSpinner from '../../../lib/components/loadingSpinner';
import { handleShowToast } from '../../../modules/notification/redux';

const OrganizationForm = ({ isLoading, user }) => (
  <MakeAsyncFunction
    listener={promiseListener}
    start={CREATE_ORG_ACCOUNT_REQUEST}
    resolve={CREATE_ORG_ACCOUNT_SUCCESS}
    reject={CREATE_ORG_ACCOUNT_FAILURE}
    getError={(action) => {
      const payload = action.payload;
      dispatch(handleShowToast({ message: payload.orgName ?? 'Something went wrong', type: 'error' }));
    }}
  >
    {onSubmit => (
      <FForm
        name='create-organization-form'
        onSubmit={onSubmit}
        render={({
          handleSubmit,
          submitting,
          hasValidationErrors,
          hasSubmitErrors,
          dirtySinceLastSubmit,
          values
        }) => (
          <div>
            <LoadingSpinner spinning={isLoading} fullwidth>
              <AForm onFinish={handleSubmit}>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col lg={12} sm={20} xs={24}>
                    <strong>Organization account name <span style={{ color: 'red' }}>*</span></strong>
                    <FField
                      name='accountName'
                      component={InputControl}
                      type='text'
                      placeholder='Account Name*'
                      // parse={value => value && value.toLowerCase()}
                      parse={value => value}
                      format={capitalize}
                      className='orgInput'
                      validate={validations.composeValidators(
                        validations.required,
                        validations.minLength(2),
                        validations.maxLength(30)
                      )}
                    />
                    {/* <small>This will be the name of your account on TutorD</small> */}
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col lg={12} sm={20} xs={24}>
                    <strong>This organization belongs to: <span style={{ color: 'red' }}>*</span></strong>
                    <FField
                      name='accountType'
                      component={RadioControl}
                      type='radio'
                      validate={validations.required}
                      parse={value => value}
                      format={capitalize}
                    >
                      {({ name, input, disabled = false, meta: { error, submitError, touched, pristine, dirtySinceLastSubmit, submitting, ...meta }, ...rest }) => (
                        <AForm.Item
                          hasFeedback
                          validateStatus={(error || submitError) && touched ? 'error' : ''}
                          help={(error || submitError) && touched &&
                          (<span className='error'>{error || submitError}</span>)}
                        >
                          <Radio.Group>
                            <Radio {...input} {...rest} value='personal' disabled={submitting}>
                              My personal account
                            </Radio>
                            <Radio {...input} {...rest} value='business-institution' disabled={submitting}>
                              A business or institution
                            </Radio>
                            {(rest && rest.icon)}
                          </Radio.Group>
                          {/* <pre>{JSON.stringify(meta, 0, 2)}</pre> */}
                        </AForm.Item>
                      )}
                    </FField>
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  {/* ORGANIZATION BELONGS TO */}
                  <Col>
                    <ConditionalRender when='accountType' is='business-institution'>
                      <strong>Name of business or institution this organization belongs to </strong>
                      <FField
                        name='parentOrg'
                        component={InputControl}
                        type='text'
                        placeholder='Belongs To*'
                        parse={value => value}
                        format={capitalize}
                        style={{ width: '30rem' }}
                        validate={validations.composeValidators(
                          validations.required,
                          validations.minLength(2),
                          validations.maxLength(30)
                        )}
                      />
                    </ConditionalRender>
                  </Col>
                </Row>
                {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                <Row justify='end' gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col flex={1}>
                    <StyledButton
                      size='large'
                      type='primary'
                      htmlType='submit'
                      disabled={submitting || ((hasSubmitErrors || hasValidationErrors) && !dirtySinceLastSubmit)}
                      loading={isLoading || submitting}
                      block
                    >
                      Next Payment Details
                    </StyledButton>
                  </Col>
                </Row>
              </AForm>
            </LoadingSpinner>
          </div>
        )}
      />
    )}
  </MakeAsyncFunction>
);

export default withRouter(OrganizationForm);
