import React from 'react';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { withRouter } from 'react-router-dom';
import { Field as FField, Form as FForm } from 'react-final-form';
import { Row, Col, Form as AForm } from 'antd';
import { promiseListener } from '../../../../store';
import { CodeInputControl } from '../../../fields/Fields';
import { StyledTitle as Title } from '../../../fields/Title';
import { StyledButton } from '../../../fields/Button';
import {
  VERIFY_STUDENT_PHONE_NUMBER_REQUEST,
  VERIFY_STUDENT_PHONE_NUMBER_SUCCESS,
  VERIFY_STUDENT_PHONE_NUMBER_FAILURE
} from '../../../../modules/sign-up/redux/verify-student-phone-number';
import * as validations from '../../../../utils/validations';
import { YellowUnderline } from '../../../../styles/StyledComponents';
import { FormattedMessage } from 'react-intl';

const VerifyPhoneNumberForm = ({ isLoading, match }) => (
  <MakeAsyncFunction
    listener={promiseListener}
    start={VERIFY_STUDENT_PHONE_NUMBER_REQUEST}
    resolve={VERIFY_STUDENT_PHONE_NUMBER_SUCCESS}
    reject={VERIFY_STUDENT_PHONE_NUMBER_FAILURE}
  >
    {onSubmit => (
      <FForm
        onSubmit={onSubmit}
        render={({
          submitError,
          handleSubmit,
          submitting,
          hasValidationErrors,
          hasSubmitErrors,
          dirtySinceLastSubmit,
          values
        }) => (
          <div>
            {isLoading && <div>Loading...</div>}
            {submitError && <div className='error'>{submitError}</div>}
            <AForm onFinish={handleSubmit}>
              <Row justify='center' align='middle' style={{ minHeight: '70vh' }}>
                <Col xl={14} sm={20} xs={24}>
                  <YellowUnderline>
                    <Title level={2}>
                      <FormattedMessage id='enrollment.verify-student-phone-number.sign-up.title' />
                    </Title>
                  </YellowUnderline>
                  <FField
                    name='smsVerifyCode'
                    type='text'
                    component={CodeInputControl}
                    validate={validations.composeValidators(
                      validations.required,
                      validations.minLength(6)
                    )}
                  />
                  {/* TODO */}
                  {/* <p
                    style={{ cursor: 'pointer', display: 'inline-block', textDecoration: 'underline', color: '#4285f4' }}
                    size='large'
                    type='secondary'
                    block
                    onclick={() => { console.log('resend code clicked'); }}
                  >
                    Resend code
                  </p> */}
                  <br />
                  <StyledButton
                    style={{ width: 150, float: 'left' }}
                    size='large'
                    type='primary'
                    htmlType='submit'
                    disabled={submitting ||
                    ((hasSubmitErrors || hasValidationErrors) && !dirtySinceLastSubmit)}
                    loading={isLoading || submitting}
                    block
                  >
                    Verify
                  </StyledButton>
                </Col>
              </Row>
              {/* values: <pre>{JSON.stringify(values, 0, 2)}</pre> */}
            </AForm>
          </div>
        )}
      />
    )}
  </MakeAsyncFunction>
);

export default withRouter(VerifyPhoneNumberForm);
