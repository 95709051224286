import React, { useEffect } from 'react';
import styled from 'styled-components';
import arrayMutators from 'final-form-arrays';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { Field as FField, Form as FForm } from 'react-final-form';
import { Button, Col, Form as AForm, Row, Typography } from 'antd';

import { promiseListener } from '../../../../store';
import { CREATE_CLASS_FAILURE, CREATE_CLASS_REQUEST, CREATE_CLASS_SUCCESS } from '../../../../modules/classes/redux';
import { InputControl } from '../../../fields/Fields';
import { StudentEnrollmentsList } from './StudentEnrollmentsList';
import * as validations from '../../../../utils/validations';

const StyledSubmitButton = styled(Button)`
    background-color: #5bbcbd;
    border: none;
    border-radius: 10px;
    font-weight: bolder;
    color: #fff;
    height: 50px;
    padding: 0 17px;
    min-width: 100px;
    margin-top: 30px;

    @media (min-width: 1200px) {
        min-width: 200px;
    }

    &:hover,
    &:focus {
        background-color: #60e4e5;
        border: none;
        color: #fff;
    }
`;

const { Title } = Typography;

export const AddClassForm = ({ unEnrolledStudents = [], isLoading = false, onClose, isActive }) => {
  // CUSTOM VARIABLE TO RESET FORM FIELDS STATE
  let handleFieldReset;

  // RESET FORM FIELDS STATE WHEN THE DRAWER IS CLOSED
  useEffect(() => {
    if (!isActive && handleFieldReset) {
      handleFieldReset('title');
    }
  }, [handleFieldReset, isActive]);

  return (
    <MakeAsyncFunction
      listener={promiseListener}
      start={CREATE_CLASS_REQUEST}
      resolve={CREATE_CLASS_SUCCESS}
      reject={CREATE_CLASS_FAILURE}
    >
      {(onSubmit) => (
        <FForm
          name='create-class-drawer-form'
          initialValues={{
            title: '',
            enrolledStudents: []
          }}
          mutators={{
            ...arrayMutators
          }}
          onSubmit={onSubmit}
          render={({
            pristine,
            submitError,
            handleSubmit,
            values,
            submitting,
            hasValidationErrors,
            hasSubmitErrors,
            dirtySinceLastSubmit,
            form,
            ...rest
          }) => {
            // VARIABLE TO RESET THE FORM STATE OUTSIDE THE FORM COMPONENT
            handleFieldReset = form?.resetFieldState;

            return (
              <div>
                <AForm
                  onFinish={(event) => {
                    console.log('onFinish, event: ', event);
                    handleSubmit();
                    onClose();
                  }}
                >
                  {isLoading && <div>Loading...</div>}
                  {submitError && <div className='error'>{submitError}</div>}
                  <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 16]}>
                    <Col xl={12}>
                      <Title level={5}>Class Name</Title>
                      <FField
                        name='title'
                        component={InputControl}
                        type='text'
                        placeholder='New Class Name'
                        validate={validations.required}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 16]}>
                    <StudentEnrollmentsList
                      unEnrolledStudents={unEnrolledStudents}
                      handleFieldReset={handleFieldReset}
                    />
                  </Row>
                  <StyledSubmitButton
                    htmlType='submit'
                    disabled={
                      pristine ||
                      submitting ||
                      ((hasSubmitErrors || hasValidationErrors) &&
                        !dirtySinceLastSubmit)
                    }
                    loading={isLoading || submitting}
                  >
                    Submit
                  </StyledSubmitButton>
                </AForm>
              </div>
            );
          }}
        />
      )}
    </MakeAsyncFunction>
  );
};
