import React from 'react';
import { Col, Input, List as AList, Row, Empty } from 'antd';
import { StyledContainer } from '../../../../styles/StyledComponents';
import { StyledTitle as Title } from '../../../fields/Title';
import Avatar from 'antd/lib/avatar/avatar';
import { RightOutlined, UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

const StyledStudentsSectionContainer = styled.div`
  width: 100%;

  & .ant-typography {
    margin-bottom: 18px;
  }
`;

const StyledStudentsListContainer = styled.div`
  max-height: 550px;
  overflow: auto;
`;

export const StudentsSection = ({ myStudents, emptyDescription }) => {
  return (
    <StyledStudentsSectionContainer>
      {/* MY STUDENTS SECTION */}
      <Title level={5}>My Students</Title>
      <StyledStudentsListContainer>
        <AList
          itemLayout='horizontal'
          dataSource={myStudents}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={emptyDescription}
              />
            )
          }}
          renderItem={(item, index) => {
            return (
              <StyledContainer
                style={{
                  borderLeft: '20px solid #D7753E',
                  padding: 5,
                  width: '100%'
                }}
              >
                <Row>
                  <Col span={23}>
                    <AList.Item
                      key={`${item.firstName}-${item.lastName}-${index}`}
                    >
                      <AList.Item.Meta
                        avatar={<Avatar size={50} icon={<UserOutlined />} />}
                        title={
                          <div style={{ marginBottom: -5 }}>
                            <Title level={5}>
                              <Link to={`/student/profile/${item.id}`}>
                                {`${item.firstName} ${item.lastName}`}
                              </Link>
                            </Title>
                          </div>
                        }
                        description={
                          item.learningStyles.length > 0 ? item.learningStyles.join(', ') : 'N/A'
                        }
                      />
                      <Col span={1}>
                        <Link to={`/student/profile/${item.id}`}>
                          <RightOutlined
                            style={{ float: 'right', fontSize: 20 }}
                          />
                        </Link>
                      </Col>
                    </AList.Item>
                  </Col>
                  {/* <Col span={8}>
                  <AList.Item
                      key={`${item.firstName}-${item.lastName}-${index}`}
                    >
                      <Link to={`/profile/${item.id}`}>
                        <Title level={5}>
                          <div style={{marginBottom: -5}}>
                            {item.firstName} {item.lastName}
                          </div>
                        </Title>
                        <small>{item.email}</small>
                      </Link>
                    </AList.Item>
                  </Col> */}
                </Row>
              </StyledContainer>
            );
          }}
        />
      </StyledStudentsListContainer>
    </StyledStudentsSectionContainer>
  );
};
