import React from 'react';
import styled from 'styled-components';
import arrayMutators from 'final-form-arrays';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { Field as FField, Form as FForm } from 'react-final-form';
import { Button, Form as AForm, Typography, Modal } from 'antd';
import { ClockCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { promiseListener } from '../../../../store';
import {
  CustomSimpleSelectControl,
  InputControl
} from '../../../fields/Fields';
import * as validations from '../../../../utils/validations';
import { EditTutorGroupStudentsList } from './EditTutorGroupStudentsList';
import { EditTutorGroupQuestionSetList } from './EditTutorGroupQuestionSetsList';
import {
  EDIT_TUTOR_GROUP_FAILURE,
  EDIT_TUTOR_GROUP_REQUEST,
  EDIT_TUTOR_GROUP_SUCCESS
} from '../../../../modules/classes/redux/tutor-groups';

// OPTIONS FOR DAYS INTERVAL SELECTOR
const intervalDaysOptions = [
  {
    label: 'Everyday',
    value: 'everyday'
  },
  {
    label: 'Daily, Mon - Fri',
    value: 'daily'
  },
  {
    label: 'Mon',
    value: 'monday'
  },
  {
    label: 'Tues',
    value: 'tuesday'
  },
  {
    label: 'Wed',
    value: 'wednesday'
  },
  {
    label: 'Thurs',
    value: 'thursday'
  },
  {
    label: 'Fri',
    value: 'friday'
  },
  {
    label: 'Weekend',
    value: 'weekend'
  }
];

// CREATE 12H TIME ARRAY
const getTimeArray = () => {
  const times = [];
  const periods = ['AM', 'PM'];
  const hours = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  let prop = null;
  let hour = null;
  let min = null;

  for (prop in periods) {
    for (hour in hours) {
      for (min = 0; min < 60; min += 15) {
        times.push(
          ('0' + hours[hour]).slice(-2) +
          ':' +
          ('0' + min).slice(-2) +
          ' ' +
          periods[prop]
        );
      }
    }
  }

  return ['Immediate', ...times];
};

// OPTIONS FOR TIME INTERVAL SELECTOR
const intervalTimeOptions = getTimeArray().map((el) => ({
  label: el,
  value: el.toLocaleLowerCase().split(' ').join('')
}));

// OPTIONS FOR INTERVAL SELECTOR
const intervalOptions = [
  {
    label: '1 Question per week',
    value: '1 question per week'
  },
  {
    label: '1 Question per day',
    value: '1 question per day'
  },
  {
    label: '1 Question per hour',
    value: '1 question per hour'
  },
  {
    label: '1 Question per minute',
    value: '1 question per minute'
  }
];

const StyledEditTutorGroupFormContainer = styled.div`
  width: 100%;

  @media (min-width: 1200px) {
    padding-left: 35px;
    max-width: 650px;
  }

  & .edit-tutor-group-form__name {
    width: 100%;
    max-width: 400px;
    margin-bottom: 20px;
  }

  & .edit-tutor-group-form__subtitle {
    display: inline-block;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #262626;
    margin-bottom: 5px;
    user-select: none;
  }

  & .ant-form-item {
    margin-bottom: 0px;
  }
`;

const StyledEditTutorGroupFormIntervalContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 13px;
  flex-wrap: wrap;
  margin-bottom: 30px;

  & .edit-tutor-group-form__field {
    width: 100%;
  }

  & .edit-tutor-group-form__day {
    max-width: 200px;
  }

  & .edit-tutor-group-form__time {
    max-width: 130px;
  }

  & .edit-tutor-group-form__interval {
    max-width: 255px;
  }
`;

const StyledEditTutorGroupFormButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 30px;

  @media (min-width: 1200px) {
    gap: 50px;
  }
`;

const StyledSaveButton = styled(Button)`
  background-color: #5bbcbd;
  border: none;
  border-radius: 10px;
  font-weight: bolder;
  color: #fff;
  height: 50px;
  padding: 0 17px;
  min-width: 100px;

  @media (min-width: 1200px) {
    min-width: 200px;
  }

  &:hover,
  &:focus {
    background-color: #60e4e5;
    border: none;
    color: #fff;
  }
`;

const StyledDeleteButton = styled(Button)`
  background: transparent;
  padding: 0;
  border: none;
  color: #e64732;
  outline: none;
  box-shadow: none;

  &:hover,
  &:focus,
  &:active {
    color: #e64732;
    background: transparent;
    outline: none;
    box-shadow: none;
  }
`;

const { Title } = Typography;

export const EditTutorGroupForm = ({
  classId = '',
  currentClassStudents = [],
  questionSets = [],
  tutorGroup = { students: [], questionSets: [] },
  isLoading = false,
  onGroupDelete
}) => {
  console.log(
    'EditTutorGroupForm, currentClassStudents: ',
    currentClassStudents
  );

  const { confirm } = Modal;

  function showPromiseConfirm () {
    confirm({
      title: `Do you want to delete ${tutorGroup?.title}?`,
      icon: <ExclamationCircleOutlined />,
      content:
        'The Tutor Group, it\'s Sessions and Student data will be deleted if you do delete.',
      onOk () {
        onGroupDelete();
      },
      onCancel () { }
    });
  }
  return (
    <MakeAsyncFunction
      listener={promiseListener}
      start={EDIT_TUTOR_GROUP_REQUEST}
      resolve={EDIT_TUTOR_GROUP_SUCCESS}
      reject={EDIT_TUTOR_GROUP_FAILURE}
    >
      {(onSubmit) => (
        <FForm
          name='edit-tutor-group-form'
          initialValues={{
            classId: classId,
            tutorGroupId: tutorGroup?.id,
            title: tutorGroup?.title,
            tutorGroupStudents: tutorGroup?.students || [],
            tutorGroupQuestionSets: tutorGroup?.questionSets || [],
            dispatchInterval: tutorGroup?.dispatchInterval?.days
              ? tutorGroup.dispatchInterval
              : {
                days: 'everyday',
                startDateTime: tutorGroup?.scheduledStartDateTime,
                interval: tutorGroup?.dispatchInterval
              }
          }}
          mutators={{
            ...arrayMutators
          }}
          onSubmit={onSubmit}
          render={({
            pristine,
            submitError,
            handleSubmit,
            form: {
              mutators: { push, pop }
            },
            submitting,
            hasValidationErrors,
            hasSubmitErrors,
            dirtySinceLastSubmit,
            values
          }) => (
            <StyledEditTutorGroupFormContainer>
              {submitError && <div className='error'>{submitError}</div>}
              <AForm onFinish={handleSubmit}>
                <div className='edit-tutor-group-form__name'>
                  <small className='edit-tutor-group-form__subtitle'>
                    Group Name
                  </small>
                  <FField
                    name='title'
                    component={InputControl}
                    type='text'
                    placeholder='New Tutor Group'
                    validate={validations.required}
                  />
                </div>
                <EditTutorGroupStudentsList
                  tutorGroupStudents={values?.tutorGroupStudents}
                  currentClassStudents={currentClassStudents}
                />
                <EditTutorGroupQuestionSetList
                  questionSets={questionSets}
                  tutorGroupQuestionSets={values?.tutorGroupQuestionSets}
                />
                <Title level={4}>Dispatch Interval:</Title>
                <StyledEditTutorGroupFormIntervalContainer>
                  <div className='edit-tutor-group-form__field edit-tutor-group-form__day'>
                    <small className='edit-tutor-group-form__subtitle'>
                      Day
                    </small>
                    <FField
                      name='dispatchInterval.days'
                      component={CustomSimpleSelectControl}
                      options={intervalDaysOptions}
                      disabled
                    />
                  </div>
                  <div className='edit-tutor-group-form__field edit-tutor-group-form__time'>
                    <small className='edit-tutor-group-form__subtitle'>
                      Time
                    </small>
                    <FField
                      name='dispatchInterval.startDateTime'
                      component={CustomSimpleSelectControl}
                      options={intervalTimeOptions}
                      animatedArrow={false}
                      arrowWidth={50}
                      arrowHeight={50}
                      suffixIcon={
                        <ClockCircleOutlined style={{ height:'100%' }} />
                      }
                    />
                  </div>
                  <div className='edit-tutor-group-form__field edit-tutor-group-form__interval'>
                    <small className='edit-tutor-group-form__subtitle'>
                      Interval
                    </small>
                    <FField
                      name='dispatchInterval.interval'
                      component={CustomSimpleSelectControl}
                      options={intervalOptions}
                    />
                  </div>
                </StyledEditTutorGroupFormIntervalContainer>
                <StyledEditTutorGroupFormButtonsContainer>
                  <StyledSaveButton
                    htmlType='submit'
                    disabled={
                      pristine ||
                      submitting ||
                      ((hasSubmitErrors || hasValidationErrors) &&
                        !dirtySinceLastSubmit) ||
                      !values?.tutorGroupStudents?.length ||
                      !values?.tutorGroupQuestionSets?.length
                    }
                    loading={isLoading || submitting}
                  >
                    Save
                  </StyledSaveButton>
                  <StyledDeleteButton
                    htmlType='button'
                    type='text'
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      showPromiseConfirm();
                    }}
                    disabled={isLoading || submitting}
                  >
                    Delete Group
                  </StyledDeleteButton>
                </StyledEditTutorGroupFormButtonsContainer>
              </AForm>
            </StyledEditTutorGroupFormContainer>
          )}
        />
      )}
    </MakeAsyncFunction>
  );
};
