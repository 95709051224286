import React from 'react';
import { RightOutlined } from '@ant-design/icons';
import { Row, Col, Tag, Divider } from 'antd';

export const HowDoesItWork = () => {
  return (
    <>
      <Row justify='center' style={{ margin: '40px auto' }}>
        <Col
          xl={20}
          sm={22}
          style={{
            background: '#fff',
            padding: 40,
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
          }}
        >
          <div style={{ marginBottom: 40 }}>
            <Tag
              style={{
                background: '#5BBCBD',
                color: '#fff',
                border: 'none',
                fontWeight: 'bolder'
              }}
            >
                HOW DOES IT WORK?
            </Tag>
          </div>
          {/* 5 STEP PROCESS */}
          <Row justify='space-between' gutter={[24, 24]}>
            {/* ENROLL */}
            <Col
              xl={4}
              sm={24}
              style={{
                color: '#fff',
                padding: 20,
                borderRadius: 18,
                height: 260,
                background: '#D7753E',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
              }}
            >
              <strong style={{ fontSize: 18, color: '#fff' }}>1. ENROLL</strong>
              <br />
              <br />
              <p>
                  Set Up dispatch intervals, where students will receive up to
                  15 questions daily or weekly.{' '}
              </p>
              <RightOutlined
                style={{ position: 'absolute', bottom: 15, right: 15 }}
              />
            </Col>
            {/* COHORTS */}
            <Col
              xl={4}
              sm={24}
              style={{
                color: '#fff',
                padding: 20,
                borderRadius: 18,
                height: 260,
                background: '#8053D7',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
              }}
            >
              <strong style={{ fontSize: 18, color: '#fff' }}>
                  2. COHORTS
              </strong>
              <br />
              <br />
              <p>Organize subjects into cohorts of students</p>
              <RightOutlined
                style={{ position: 'absolute', bottom: 15, right: 15 }}
              />
            </Col>
            {/* QUESTIONS */}
            <Col
              xl={4}
              sm={24}
              style={{
                color: '#fff',
                padding: 20,
                borderRadius: 18,
                height: 260,
                background: '#5BBCBD',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
              }}
            >
              <strong style={{ fontSize: 18, color: '#fff' }}>
                  3. QUESTIONS
              </strong>
              <br />
              <br />
              <p>
                  Create Question Sets or Download question sets from other
                  educators who use our platform.
              </p>
              <RightOutlined
                style={{ position: 'absolute', bottom: 15, right: 15 }}
              />
            </Col>
            {/* INTERVALS */}
            <Col
              xl={4}
              sm={24}
              style={{
                color: '#fff',
                padding: 20,
                borderRadius: 18,
                height: 260,
                background: '#E5A84F',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
              }}
            >
              <strong style={{ fontSize: 18, color: '#fff' }}>
                  4. INTERVALS
              </strong>
              <br />
              <br />
              <p>
                  Set Up dispatch intervals, where students will recieve up to
                  15 questions dailly or weekly.{' '}
              </p>
              <RightOutlined
                style={{ position: 'absolute', bottom: 15, right: 15 }}
              />
            </Col>
            {/* PROGRESS */}
            <Col
              xl={4}
              sm={24}
              style={{
                color: '#fff',
                padding: 20,
                borderRadius: 18,
                height: 260,
                background: '#FFDC54',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
              }}
            >
              <strong style={{ fontSize: 18, color: '#333' }}>
                  5. PROGRESS
              </strong>
              <br />
              <br />
              <p style={{ color: '#333' }}>
                  Review students progress through data based on their
                  engagement with the question sets, overall usage, and
                  improvement.
              </p>
              <RightOutlined
                style={{ position: 'absolute', bottom: 15, right: 15, color: '#333' }}
              />
            </Col>
          </Row>
          <Divider dashed style={{ background: '#999' }} />
        </Col>
      </Row>
    </>
  );
};
