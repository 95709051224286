import React from 'react';
import { Col, Row } from 'antd';
import { StyledButton } from '../../../styles/StyledComponents';
import { RightOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

export const ScheduleDemo = () => {
  return (
    <>
      <Row justify='center' style={{ margin: '40px auto' }}>
        <Col
          xl={20}
          sm={22}
          style={{
            background: '#E5A84F',
            padding: 40,
            boxShadow: '0px 30px 40px rgba(0, 0, 0, 0.1)'
          }}
        >
          {/* ROW FOR TWO COLUMNS */}
          <Row justify='center' style={{ textAlign: 'center' }}>
            <Col>
              <p style={{ color: '#fff', fontFamily: 'Montserrat Bold', fontSize: '3em' }}>Schedule a Demo</p>
              <p style={{ color: '#fff' }}>
                  Please contact us at <a href='mailto:admin@tutord.io'>admin@tutord.io</a> for demos.
              </p>
              <br />
              <Link to='/contact'>
                <StyledButton style={{ borderRadius: 6, height: 40, width: 150 }}>
                    CONTACT US <RightOutlined />
                </StyledButton>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
