import { all, takeLatest } from 'redux-saga/effects';

import {
  ACCOUNT_REQUEST,
  ACCOUNT_BILLING_INFO_REQUEST,
  PRODUCTS_PRICES_REQUEST,
  ADD_ORG_MEMBERS_REQUEST,
  CREATE_ORG_ACCOUNT_REQUEST,
  CREATE_SUBSCRIPTION_REQUEST,
  SET_PRODUCT_PRICE_ON_ACCOUNT_REQUEST,
  onHandleAccountProductPricesRequest,
  onHandleCreateSubscriptionRequest,
  onHandleCreateOrgAccountRequest,
  onHandleSetProductPriceOnAccountRequest,
  onHandleAccountRequest,
  onHandleAddOrgMembersRequest,
  CREATE_STUDENT_WITH_CLASS_TOKEN_REQUEST,
  CREATE_STUDENT_WITH_ORG_TOKEN_REQUEST,
  onHandleCreateStudentWithToken,
  onHandleCreateStudentWithOrgToken,
  onHandleAccountBillingInfoReq,
  onHandleAccountPlanSwitchReq,
  ACCOUNT_PLAN_SWITCH_REQUEST,
  ACCOUNT_BILLING_HISTORY_REQUEST,
  onHandleAccountBillingHistoryReq
} from './redux';

// This is where we will be handling making BASIC requests to the backend / stripe api for admins who are
// either completing their onboarding and are at the BASIC details screen or updating their BASIC information

function * watchAccountRequest () {
  console.log('#watchAccountRequest');
  yield takeLatest(ACCOUNT_REQUEST, onHandleAccountRequest);
}

function * watchAccountBillingInfoRequest () {
  console.log('#watchAccountBillingInfoRequest');
  yield takeLatest(ACCOUNT_BILLING_INFO_REQUEST, onHandleAccountBillingInfoReq);
}

function * watchAccountPlan () {
  console.log('#watchAccountPlan');
  yield takeLatest(
    PRODUCTS_PRICES_REQUEST,
    onHandleAccountProductPricesRequest
  );
}

function * watchSetProductPriceOnAccount () {
  console.log('#watchSetProductPriceOnAccount');
  yield takeLatest(
    SET_PRODUCT_PRICE_ON_ACCOUNT_REQUEST,
    onHandleSetProductPriceOnAccountRequest
  );
}

function * watchCreateOrgAccount () {
  console.log('#watchCreateOrgAccount');
  yield takeLatest(CREATE_ORG_ACCOUNT_REQUEST, onHandleCreateOrgAccountRequest);
}

function * watchCreateSubscription () {
  console.log('#watchCreateSubscription');
  yield takeLatest(
    CREATE_SUBSCRIPTION_REQUEST,
    onHandleCreateSubscriptionRequest
  );
}

function * watchAddOrgMembersRequest () {
  console.log('#watchAddOrgMembersRequest');
  yield takeLatest(ADD_ORG_MEMBERS_REQUEST, onHandleAddOrgMembersRequest);
}

function * watchCreateStudentWithToken () {
  yield takeLatest(
    CREATE_STUDENT_WITH_CLASS_TOKEN_REQUEST,
    onHandleCreateStudentWithToken
  );
}
function * watchCreateStudentWithTeacherToken () {
  yield takeLatest(
    CREATE_STUDENT_WITH_ORG_TOKEN_REQUEST,
    onHandleCreateStudentWithOrgToken
  );
}

function * watchAccountPlanSwitch () {
  yield takeLatest(ACCOUNT_PLAN_SWITCH_REQUEST, onHandleAccountPlanSwitchReq);
}

function * watchAccountBillingHistory () {
  yield takeLatest(
    ACCOUNT_BILLING_HISTORY_REQUEST,
    onHandleAccountBillingHistoryReq
  );
}

export default function * sagas () {
  yield all([
    watchCreateStudentWithTeacherToken(),
    watchCreateStudentWithToken(),
    watchAccountRequest(),
    watchAccountPlan(),
    watchCreateOrgAccount(),
    watchCreateSubscription(),
    watchAddOrgMembersRequest(),
    watchSetProductPriceOnAccount(),
    watchAccountBillingInfoRequest(),
    watchAccountPlanSwitch(),
    watchAccountBillingHistory()
  ]);
}
