import { useSelector } from 'react-redux';
import { contactUsSelector, signUpUserSelector } from '../../../../modules/sign-up/redux/selectors';

const useContactUsPage = () => {
  const user = useSelector(signUpUserSelector);

  const { isLoading } = useSelector(contactUsSelector);

  return {
    user,
    isLoading
  };
};

export default useContactUsPage;
