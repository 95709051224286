import React from 'react';
import styled from 'styled-components';
import { FORM_ERROR } from 'final-form';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { Field as FField, Form as FForm } from 'react-final-form';
import { Row, Col, Form as AForm } from 'antd';

import { promiseListener } from '../../../../store';
import { SET_STUDENT_PHONE_NUMBER_REQUEST, SET_STUDENT_PHONE_NUMBER_SUCCESS, SET_STUDENT_PHONE_NUMBER_FAILURE } from '../../../../modules/sign-up/redux/set-student-phone-number';
import { handleShowToast } from '../../../../modules/notification/redux';

import { PhoneInputControl } from '../../../fields/PhoneNumberInput';
import { StyledTitle as Title } from '../../../fields/Title';
import { StyledButton } from '../../../fields/Button';
import { CustomCheckboxInputControl } from '../../../fields/Fields';
import { YellowUnderline } from '../../../../styles/StyledComponents';

import * as validations from '../../../../utils/validations';
import LoadingSpinner from '../../../../lib/components/loadingSpinner';

const StyledOptInInput = styled.div`
  margin-top: 1rem;

  & .opt-in-field__checkbox {
    display: flex;
    float: left;
    margin-right: 0.25rem;
  }
`;

const SetPhoneNumberForm = ({ isLoading }) => {
  const dispatch = useDispatch();

  return (
    <MakeAsyncFunction
      listener={promiseListener}
      start={SET_STUDENT_PHONE_NUMBER_REQUEST}
      resolve={SET_STUDENT_PHONE_NUMBER_SUCCESS}
      reject={SET_STUDENT_PHONE_NUMBER_FAILURE}
      getError={(action) => {
        const payload = action.payload;
        return payload[FORM_ERROR] ? dispatch(handleShowToast({ message: payload[FORM_ERROR], type: 'error' })) : undefined;
      }}
    >
      {(onSubmit) => (
        <div>
          <FForm
            name='set-phone-number-form'
            initialValues={{ callingCode: '+1', countryCode: 'US' }}
            onSubmit={onSubmit}
            render={(props) => {
              return (
                <div>
                  <LoadingSpinner spinning={isLoading}>
                    <AForm onFinish={props.handleSubmit}>
                      <Row
                        justify='center'
                        align='middle'
                        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                        style={{ minHeight: '70vh' }}
                      >
                        <Col xl={14} sm={20} xs={22}>
                          <YellowUnderline>
                            <Title level={2}>
                              <FormattedMessage id='enrollment.set-student-phone-number.sign-up.title' />
                            </Title>
                          </YellowUnderline>
                          <FormattedMessage id='enrollment.set-student-phone-number.sign-up.subTitle' />
                          <small style={{ display: 'flex', justifyContent: 'left', paddingTop: 30 }}>
                            Phone Number
                          </small>
                          <FField
                            name='phoneNumber'
                            label='phoneNumber'
                            type='text'
                            placeholder='(323) 555-5555'
                            component={PhoneInputControl}
                            callingCode='callingCode'
                            countryCode='countryCode'
                            limitMaxLength
                            autoFocus
                            validate={validations.composeValidators(
                              validations.required,
                              validations.minLength10,
                              validations.maxLength(20)
                            )}
                          />
                          <StyledButton
                            size='large'
                            type='primary'
                            htmlType='submit'
                            block
                            disabled={!props.values.smsOptIn || !props.values.phoneNumber}
                          >
                            Send Code
                          </StyledButton>

                          <StyledOptInInput>
                            <small style={{ display: 'block', justifyContent: 'left', marginBottom: 5 }}>
                              <FField
                                name='smsOptIn'
                                component={CustomCheckboxInputControl}
                                type='checkbox'
                                className='opt-in-field__checkbox'
                                initialValue
                              />

                              By selecting this box and clicking Send Code, you agree to our
                              <Link to={{ pathname: 'https://app.termly.io/document/terms-of-service/ec2ee45b-62dd-4816-80d0-f7e574c19bf0' }} target='_blank' style={{ textDecoration: 'underline' }}>Terms and Conditions,</Link>
                              <Link to={{ pathname: 'https://app.termly.io/document/privacy-policy/438a711d-5cdf-45ae-8df4-0cd0e71e0f23' }} target='_blank' style={{ textDecoration: 'underline' }}> Privacy Policy,</Link>
                              <Link to={{ pathname: 'https://app.termly.io/document/acceptable-use/0d414270-e7d5-4b9b-bcaa-8891f2841711' }} target='_blank' style={{ textDecoration: 'underline' }}> Acceptable Use Policy,</Link> and
                              <Link to={{ pathname: 'https://app.termly.io/document/cookie-policy/5ea13866-93d0-4d6f-82e7-82553407e4a0' }} target='_blank' style={{ marginLeft: 2, textDecoration: 'underline' }}>Cookies Policy.</Link>
                              You may receive SMS Notifications from us and can opt out any time by sending "Opt out".
                            </small>
                          </StyledOptInInput>
                          <StyledOptInInput>
                            <small style={{ display: 'block', justifyContent: 'left', marginBottom: 20 }}>
                              <FField
                                name='marketingOptIn'
                                component={CustomCheckboxInputControl}
                                type='checkbox'
                                className='opt-in-field__checkbox'
                                initialValue
                              />

                              By selecting this box you want to learn more about what we are doing to make an impact in the
                              learning experience and our communities
                              via our <Link to='/#' style={{ textDecoration: 'underline' }}>newsletter.</Link>
                            </small>
                          </StyledOptInInput>

                        </Col>
                      </Row>
                      {/* values: */}
                      {/* <pre>{JSON.stringify(props.values, 0, 2)}</pre> */}
                      {/* props: */}
                      {/*  <pre>{JSON.stringify(props, 0, 2)}</pre> */}
                    </AForm>
                  </LoadingSpinner>
                </div>
              );
            }}
          />
        </div>
      )}
    </MakeAsyncFunction>
  );
};

export default withRouter(SetPhoneNumberForm);
