import React, { useMemo } from 'react';
import styled from 'styled-components';
import 'ant-design-pro/dist/ant-design-pro.css';
import { Switch, Tabs } from 'antd';
import { StyledTitle as Title } from '../../../fields/Title';
import { StyledContainer } from '../../../../styles/StyledComponents';
import CustomTreeSelect from '../../../fields/TreeSelect';
import { StudentsSection } from './StudentsSection';
import { InfoCircleOutlined, SearchOutlined } from '@ant-design/icons';
import usePerformance from '../hooks/usePerformance';
import { ConditionalWithEmpty } from '../../../fields/ConditionalWithEmpty';
import StudentTrendChart from './performance-graphs/StudentTrendChart';
import LearningStylesChart from './performance-graphs/LearningStylesChart';
import EngagementChart from './performance-graphs/EngagementChart';
import SearchInput from '../../../fields/SearchInput';
import { getQueryParam } from '../../../../utils/url';

// COLORS FOR CHART SEGMENTS
const chartBarColors = ['#8053d7', '#FFCC00', '#D7753E', '#5BBCBD', '#EB5757'];

// COLORS FOR PIE CHART LEGEND
const legendColors = [
  'rgba(128, 83, 215, 0.2)',
  'rgba(255, 204, 0, 0.2)',
  'rgba(215, 117, 62, 0.2)',
  'rgba(91, 188, 189, 0.2)',
  'rgba(235, 87, 87, 0.2)'
];

// RENDER COLORS BY CHART LEGEND FIELDS
const getBackgroundColor = (index) =>
  `
    & .antd-pro-charts-pie-legend > li:nth-child(${index + 1}) {
      background-color: ${legendColors[index]}};
    }
  `;

const setBackgrounds = () => {
  let str = '';

  legendColors.forEach((_, index) => (str += getBackgroundColor(index)));

  return str;
};

const StyledPerformanceSectionContainer = styled.div`
  width: 100%;
`;

const StyledPerformanceSectionHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 38px;

  @media (min-width: 600px) {
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }

  & .ant-typography {
    @media (min-width: 600px) {
      margin-bottom: 0;
    }
  }
`;

const StyledPerformanceSectionInnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  @media (min-width: 1200px) {
    flex-direction: row;
    gap: 16px;
  }
`;

const StyledPerformanceTabsContainer = styled.div`
  width: 100%;

  @media (min-width: 1200px) {
    max-width: 70%;
  }

  & .ant-tabs-nav {
    padding: 0 20px;
  }

  & .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
    padding: 0;
  }

  & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #8053d7;
  }

  & .ant-tabs-tab:hover,
  & .ant-tabs-tab-btn:active {
    color: #8053d7;
  }

  & .ant-tabs-ink-bar {
    background-color: #8053d7;
  }
`;

const StyledPerformanceStudentsContainer = styled.div`
  width: 100%;

  @media (min-width: 1200px) {
    max-width: 70%;
    max-width: 30%;
    padding-top: 5px;
  }
`;

const StyledPerformanceTabContainer = styled(StyledContainer)`
  width: 100%;
  overflow: auto;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  }

  & .antd-pro-charts-pie-total {
    max-height: max-content;
  }

  & .pie-chart-total {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    user-select: none;
  }

  & .antd-pro-charts-pie-legend {
    margin: 0 50px;

    @media (min-width: 1200px) {
      margin: 0 20px;
    }

    @media (min-width: 1500px) {
      margin: 0 50px;
    }
  }

  & .antd-pro-charts-pie-legend > li {
    padding-left: 10px;
    border-radius: 16px;
    width: calc(100% + 30px);
  }

  ${setBackgrounds()}

  & .ant-divider {
    background-color: #bfbfbf;
  }

  & .antd-pro-charts-pie-legendTitle {
    display: inline-block;
    min-width: 80px;
  }

  & .antd-pro-charts-pie-percent,
  & .antd-pro-charts-pie-value,
  & .antd-pro-charts-pie-legendTitle {
    font-weight: 700;
    color: #3f3356;
  }

  & .antd-pro-charts-pie-value {
    right: -20px;
  }

  & .pie-chart-total__title {
    font-size: 20px;
    line-height: 22px;
  }

  & .pie-chart-total__subtitle {
    font-size: 17px;
    line-height: 22px;
    color: #999999;
  }

  & .pie-chart-total__count {
    font-size: 34px;
    line-height: 41px;
  }
`;

const StyledEmptyDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const { TabPane } = Tabs;

export const PerformanceSection = () => {
  // CUSTOM HOOK WITH ALL FUNCTIONALITY FOR PERFORMANCE SECTION
  const {
    activeTab,
    students,
    searchedStudents,
    studentWithLearningStyles,
    searchedValue,
    treeData,
    isScaled,
    setIsScaled,
    selectedFilter,
    selectedFilterValue,
    studentTrendChartConfig,
    engagementChartConfig,
    learningStylesChartConfig,
    handleFilterChange,
    handleStudentsSearch,
    handleTabChange
  } = usePerformance();

  // EMPTY STATE DESCRIPTION
  const renderedStudentsEmptyDescription = useMemo(() => {
    if (!!searchedValue && searchedValue.length > 0) {
      return (
        <StyledEmptyDescription>
          <span>Sorry, the student with the searched name wasn`t found!</span>
        </StyledEmptyDescription>
      );
    }

    return (
      <StyledEmptyDescription>
        <span>Sorry, you don't have any students!</span>
        <span>Please add the first student</span>
      </StyledEmptyDescription>
    );
  }, [searchedValue]);
  return (
    <StyledPerformanceSectionContainer>
      {/* PERFORMANCE SECTION */}
      <StyledPerformanceSectionHeader>
        <Title level={2}>Performance</Title>
        <SearchInput
          allowClear
          value={searchedValue}
          onChange={handleStudentsSearch}
          prefix={<SearchOutlined style={{ fontSize: 18, color: '#BFBFBF' }} />}
          suffix={
            <InfoCircleOutlined style={{ fontSize: 18, color: '#8C8C8C' }} />
          }
          placeholder='Search Students'
        />
      </StyledPerformanceSectionHeader>
      <StyledPerformanceSectionInnerContainer>
        <StyledPerformanceTabsContainer>
          <Tabs
            defaultActiveKey={getQueryParam("activeTab")?getQueryParam("activeTab"):'engagement'}
            value={getQueryParam("activeTab")?getQueryParam("activeTab"):activeTab}
            onChange={handleTabChange}
            tabBarExtraContent={
              <CustomTreeSelect
                treeData={treeData}
                value={selectedFilterValue}
                onChange={handleFilterChange}
              />
            }
            size='large'
          >
            <TabPane tab='Engagement' key='engagement'>
              <StyledPerformanceTabContainer ref={engagementChartConfig.ref}>
                {/* ENGAGEMENT CHART */}
                <ConditionalWithEmpty
                  conditional={
                    engagementChartConfig.data &&
                    engagementChartConfig.data.length > 0
                  }
                  emptyStyles={{ height: 420 }}
                >
                  <EngagementChart
                    data={engagementChartConfig.data}
                    selectedFilter={selectedFilter}
                    itemWidth={engagementChartConfig.maxItemWidth}
                    height={engagementChartConfig.height}
                  />
                </ConditionalWithEmpty>
              </StyledPerformanceTabContainer>
            </TabPane>
            <TabPane tab='Student Trends' key='trends'>
              <StyledPerformanceTabContainer ref={studentTrendChartConfig.ref}>
                {/* STUDENT TREND CHART */}
                <ConditionalWithEmpty
                  conditional={
                    studentTrendChartConfig.data &&
                    studentTrendChartConfig.data.length > 0
                  }
                  emptyStyles={{ height: 420 }}
                >
                  {selectedFilter === 'group' && (
                    <span
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: 6
                      }}
                    >
                      <span>Scaled</span>
                      <Switch
                        checked={isScaled}
                        onChange={() => setIsScaled(!isScaled)}
                      />
                    </span>
                  )}
                  <StudentTrendChart
                    data={studentTrendChartConfig.data}
                    scale={studentTrendChartConfig.scale}
                    label={studentTrendChartConfig.label}
                    chartBarColors={chartBarColors}
                    height={studentTrendChartConfig.height}
                    itemWidth={studentTrendChartConfig.maxItemWidth}
                  />
                </ConditionalWithEmpty>
              </StyledPerformanceTabContainer>
            </TabPane>
            <TabPane tab='Learning Styles' key='learning'>
              <StyledPerformanceTabContainer>
                {/* LEARNING STYLES CHART */}
                <ConditionalWithEmpty
                  conditional={
                    learningStylesChartConfig.data &&
                    learningStylesChartConfig.data.length > 0
                  }
                  emptyStyles={{ height: 420 }}
                  emptyDescription={learningStylesChartConfig.emptyDescription}
                >
                  <LearningStylesChart
                    data={learningStylesChartConfig.data}
                    chartBarColors={chartBarColors}
                    currentCount={studentWithLearningStyles?.length}
                    totalCount={students?.length}
                  />
                </ConditionalWithEmpty>
              </StyledPerformanceTabContainer>
            </TabPane>
          </Tabs>
        </StyledPerformanceTabsContainer>
        <StyledPerformanceStudentsContainer>
          {/* STUDENTS SECTION */}
          <StudentsSection
            myStudents={searchedStudents}
            emptyDescription={renderedStudentsEmptyDescription}
          />
        </StyledPerformanceStudentsContainer>
      </StyledPerformanceSectionInnerContainer>
    </StyledPerformanceSectionContainer>
  );
};
