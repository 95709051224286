import React from 'react';
import { Col, Layout, Row } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import _ from 'lodash';

import Header from '../../template/Header';
import { CenteredContainer as Container } from '../../fields/Container';
import { BackPage, BigTitle, MainCol, WhiteText, YellowUnderline } from '../../../styles/StyledComponents';
import EmailSent from '../../../assets/email-sent.svg';
import useContactUsPage from './hooks/useContactUsPage';

const { Content } = Layout;

const ContactUsEmailSentPage = () => {
  const { user, isLoading } = useContactUsPage();

  return (
    <Container style={{ width: '100%' }}>
      <Row justify='center'>
        <Col xl={10} xs={24} style={{ background: '#5CC9D3', paddingBottom: 30 }}>
          <Header />
          <div style={{ padding: 24, textAlign: 'center' }}>
            <BigTitle>We'll be in Touch!</BigTitle>
            {/* <Title level={1}>
              <FormattedMessage id="verify-email-sent.title" />
            </Title> */}
            <img src={EmailSent} alt='Email Sent' style={{ marginBottom: 30 }} />
            <WhiteText style={{ fontSize: '1.5em' }}>
              <FormattedMessage id='verify-email-sent.subTitle' />
            </WhiteText>
            <Link to='/sign-up'>
              <BackPage>
                <ArrowLeftOutlined style={{ marginRight: 5 }} />
                Back
              </BackPage>
            </Link>
          </div>
        </Col>
        <MainCol xl={14} xs={24}>
          <YellowUnderline level={3}>
            We'll Be Reaching Out Shortly!
          </YellowUnderline>
          <div>
            Hey {_.capitalize(user.firstName)} {_.capitalize(user.lastName)}, we will be reaching out to you by the provided contact information:
            <br />
            email address: {user.email}
            <br />
            phone number: {user.phoneNumber}
            <br />
            subject: {user.subject}
            <br />
            message: {user.messageBody}
          </div>
        </MainCol>
      </Row>
    </Container>
  );
};

export default ContactUsEmailSentPage;
