import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'antd';
import { SignInForm } from './SignInForm';
import logo from '../../assets/tutord-logo-white.svg';
import { CenteredContainer as Container } from '../fields/Container';
import { StyledTitle as Title } from '../fields/Title';
import {
  BackPage,
  BigTitle,
  Img,
  SignInUpCol,
  WhiteText,
  YellowUnderline
} from '../../styles/StyledComponents';
import TutorDOwl from '../../assets/tutord-signin-page.svg';
import { ArrowLeftOutlined } from '@ant-design/icons';
import Header from '../template/Header';

class SignInPage extends Component {
  render () {
    const { isLoading } = this.props;
    return (
      <Container style={{ width: '100%' }}>
        <Row justify='center'>
          {/* WELCOME TO TUTORD COLUMN */}
          <Col xl={10} xs={24} style={{ paddingBottom: 30 }}>
            <Header />
            <div style={{ padding: '0px 24px', textAlign: 'center' }}>
              <BigTitle>
                <FormattedMessage id='sign-in.title' />
              </BigTitle>
              <Img src={TutorDOwl} alt='TutorD Owl' />
              {/* <Title level={3}>
                <FormattedMessage id='landing.title' />
              </Title> */}
              <br />
              <br />
              <WhiteText style={{ fontSize: 20 }}>
                <FormattedMessage id='sign-in.subTitle' />
              </WhiteText>
              <Link to='/'>
                <BackPage>
                  <ArrowLeftOutlined style={{ marginRight: 5 }} />
                  Back
                </BackPage>
              </Link>
            </div>
          </Col>
          {/* SIGNIN COLUMN */}
          <SignInUpCol xl={14} xs={24}>
            <YellowUnderline level={3}>
              Sign In
            </YellowUnderline>
            <div style={{ textAlign: 'center' }}>
              <SignInForm isLoading={isLoading} />
            </div>
          </SignInUpCol>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.auth.isLoading
  };
};

export default connect(mapStateToProps, {})(SignInPage);
