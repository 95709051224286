export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * Converts a Stripe stripeDate timestamp to the actual present date.
 * @param {number} stripeDate - The Stripe stripeDate timestamp in Unix epoch format.
 * @returns {Date} - The converted present date.
 */
export function convertStripeDate (stripeDate) {
  const convertedDate = new Date(stripeDate * 1000);
  const options = { year: 'numeric', month: 'short', day: 'numeric' };

  return convertedDate.toLocaleDateString('en-US', options);
}

/**
 * Converts a float number to formatted string like 9,000.00 with two decimal and comma
 * @param {number} float - number to be updated
 * @returns {string} - The formatted number.
 */
export function formatNumber (number) {
  return number.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
}

export const flattenMessages = (nestedMessages, prefix = '') => {
  if (!nestedMessages) {
    console.error('Please check your locale or messages on IntlProvider');
    return {};
  }
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
};

export const LOCALE_MAP = {
  enUS: 'en'
};

export const ONBOARDING_ENTRY_MAP_BY_NAME = {
  classes: {
    id: 0,
    title: 'What Courses do you teach?',
    subtitle: '',
    aside: {
      img: '',
      title: 'Classes',
      subtitle: 'Customize your experience'
    }
  },
  enrollments: {
    id: 1,
    title: 'Enroll you students!',
    subtitle: 'We can set up your classes and students so that you dont have to do it manually, unless you want to..',
    aside: {
      img: '',
      title: 'Enroll',
      subtitle: 'Enroll your students'
    }
  },
  goals: {
    id: 2,
    title: 'What do you want to achieve?',
    aside: {
      img: '',
      title: 'Goals',
      subtitle: 'We are changing the game..'
    }
  }
};
