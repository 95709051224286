import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Layout, Typography } from 'antd';

import ClassroomIcon from '../../../../assets/classroom.svg';
import SiderNav from '../../../template/SiderNav';
import {
  MainLayout,
  SecondaryLayout,
  StyledContent
} from '../../../../styles/StyledComponents';

import { CreateQuestionSetForm } from './createQuestionSetForm';

const { Title } = Typography;

class CreateQuestionSetPage extends Component {
  render () {
    const { isLoading } = this.props;

    return (
      <MainLayout hasSider>
        {/* SIDE NAV BAR */}
        <SiderNav />
        <SecondaryLayout>
          <StyledContent>
            {/* <Link to="#">
              <img src={ClassroomIcon} style={{float: 'right'}} alt="" />
            </Link> */}
            <CreateQuestionSetForm isLoading={isLoading} />
          </StyledContent>
        </SecondaryLayout>
      </MainLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: false
  };
};

export default connect(mapStateToProps, {})(CreateQuestionSetPage);

// Create Question Set Features
// Create 1 - 15 Questions - Done
// Each Question Item includes:
//  Question Statement - char limit of 125
//  Question Format - ['multiple-choice','true-false']
//  Question Difficulty - 1 - 5
//  Answer Items - [1 - 5] 'multiple-choice', [2] 'true-false'
//  Answer Items:
//    Answer Statement - char limit of 125
//    Answer isCorrect [bool]

// Send POST request to API success === 201 created response code
// error codes == [422 unprocess entity, 400 bad request data malformed];
// Display errors on form properly for whatever field has that error
// on success redirect to http://localhost:3000/teacher/question-sets for
