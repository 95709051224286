import React from 'react';
import {
  G2,
  Chart,
  Geom,
  Axis,
  Tooltip,
  Coord,
  Label,
  Legend,
  View,
  Annotation,
  Shape,
  Facet,
  Util,
  Slider
} from 'bizcharts';

const LineChartTimeline = ({ graphData }) => {
  const cols = {
    axisName: {
      range: [0.04, 0.95]
    },
    average: {
      min: 0,
      max: 100
    }
  };
  return (
    <div style={{ padding: '20px' }}>
      <Chart height={400} data={graphData} scale={cols} autoFit>
        <Legend position='top-left' />
        <Axis name='axisName' />
        <Axis
          name='average'
          label={{
            formatter: (val) => `${val}%`
          }}
        />
        <Tooltip
          crosshairs={{
            type: 'y'
          }}
        />
        <Geom type='line' position='axisName*average' size={2} color='title' />
        <Geom
          type='point'
          position='axisName*average'
          size={4}
          shape='circle'
          color='title'
          style={{
            stroke: '#fff',
            lineWidth: 1
          }}
        />
        {/** https://bizcharts.net/product/BizCharts4/category/62/page/108#text-1 */}

        <Annotation.Text
          position={['50%', '50%']}
          content='Group Average'
          style={{ fill: 'green' }}
        />
        <Annotation.Text
          position={['70%', '70%']}
          content='Student'
          style={{ fill: 'blue' }}
        />
        <Annotation.DataRegion
          start={['axisName 1', 0]}
          end={['axisName 4', 0]}
          region={{ style: { fill: '#eee' } }}
          lineLength={0}
          text={{ style: { fill: 'blue' }, content: 'Student' }}
        />
      </Chart>
    </div>
  );
};

export default LineChartTimeline;
