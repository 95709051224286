import React from 'react';
import { RightOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';
import { StyledTitle as Title } from '../../fields/Title';
import YellowImage from '../../../assets/landing-image-yellow.svg';
import LearningImage from '../../../assets/learning.svg';
import MobileImage from '../../../assets/mobile.svg';
import MicroScopeImage from '../../../assets/microscope.svg';
import { StyledButton } from '../../../styles/StyledComponents';
import { Link } from 'react-router-dom';

export const WhatIsTutorD = () => {
  return (
    <>
      <Row justify='center'>
        <Col
          xl={20}
          sm={22}
          style={{
            background: '#fff',
            padding: 40,
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
          }}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 40 }}>
            {/* LEFT COLUMN */}
            <Col xl={12}>
              <img src={YellowImage} alt='' />
            </Col>
            {/* RIGHT COLUMN */}
            <Col xl={12}>
              <Title level={2} style={{ color: '#333', fontWeight: 'bolder' }}>
                What is TutorD?
              </Title>
              <br />
              <strong style={{ color: '#333' }}>
                TutorD is an EdTech platform for Teachers and Tutors
                to enable distance learning with accessibility first in mind.
                We provide personalization by student learning styles and helps track
                engagement and progress.
              </strong>
              <br />
              <br />
              <p>
                Educators can use scholar profiles and engagement feedback to
                develop curriculum and effectively teach their curriculum. Reporting
                and analytics will provide educators with data to make more
                informed decisions before major exams and standardized tests.
              </p>
              <br />
              <Link to='/sign-up'>
                <StyledButton style={{ borderRadius: 6, height: 40, width: 150 }}>
                  JOIN NOW <RightOutlined />
                </StyledButton>
              </Link>
            </Col>
          </Row>
          {/* PURPLE CONTAINER */}
          <Row justify='center' style={{ margin: '40px 0' }}>
            <Col
              xl={24}
              style={{
                background: '#8053D7',
                borderRadius: 6,
                filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                padding: 40
              }}
            >
              <p
                style={{
                  fontSize: '2.5em',
                  fontFamily: 'Montserrat Bold',
                  color: '#fff',
                  textAlign: 'center'
                }}
              >
                Where education encompasses the{' '}
                <span style={{ color: '#FFDC54' }}>Whole Student.</span>
              </p>
              <p style={{ color: '#fff', textAlign: 'center' }}>
              Our mission is to provide accessibility first; using the technologies at hand.
              </p>
            </Col>
          </Row>
          {/* 3 ROW COLUMN */}
          <Row justify='center' gutter={[40, 40]} style={{ textAlign: 'center' }}>
            <Col xl={8}>
              <img src={MicroScopeImage} alt='' />
              <br />
              <br />
              <Title level={2} style={{ color: '#333' }}>
                A NEW APPROACH
              </Title>
              <p style={{ color: '#333' }}>
              Supported in classroom materials, teaching, and learning
              </p>
            </Col>
            <Col xl={8}>
              <img src={MobileImage} alt='' />
              <br />
              <br />
              <Title level={2} style={{ color: '#333' }}>
                MOBILE FRIENDLY
              </Title>
              <p style={{ color: '#333' }}>
              Adapted text message based platform designed to provide
              </p>
            </Col>
            <Col xl={8}>
              <img src={LearningImage} alt='' />
              <br />
              <br />
              <Title level={2} style={{ color: '#333' }}>
                PRACTICE LEARNING
              </Title>
              <p style={{ color: '#333' }}>
                An opportunity for Educators and Students discovery and learning via a very familiar tool
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
