import React from 'react';
import { Empty } from 'antd';
import styled from 'styled-components';

const StyledEmpty = styled(Empty)`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  user-select: none;
`;

export const ConditionalWithEmpty = ({
  conditional,
  children,
  emptyStyles,
  emptyDescription
}) => {
  if (conditional) {
    return children;
  }

  return (
    <StyledEmpty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      style={emptyStyles}
      description={emptyDescription}
    />
  );
};
