import { all, call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import request from '../../../lib/request';
import { FORM_ERROR } from 'final-form';
import { parseFormErrors } from '../../../utils/parseFormErrors';

// ACTION TYPES
export const SET_STUDENT_PHONE_NUMBER_REQUEST = 'sign-up/enrollment/SET_STUDENT_PHONE_NUMBER_REQUEST';
export const SET_STUDENT_PHONE_NUMBER_SUCCESS = 'sign-up/enrollment/SET_STUDENT_PHONE_NUMBER_SUCCESS';
export const SET_STUDENT_PHONE_NUMBER_FAILURE = 'sign-up/enrollment/SET_STUDENT_PHONE_NUMBER_FAILURE';

// ACTION CREATORS
export const handleSetStudentPhoneNumberRequest = payload => ({
  type: SET_STUDENT_PHONE_NUMBER_REQUEST,
  payload,
  isLoading: true
});

export const handleSetStudentPhoneNumberSuccess = payload => ({
  type: SET_STUDENT_PHONE_NUMBER_SUCCESS,
  payload,
  isLoading: false
});

export const handleSetStudentPhoneNumberFailure = payload => ({
  type: SET_STUDENT_PHONE_NUMBER_FAILURE,
  payload,
  isLoading: false
});

export function * onHandleSetStudentPhoneNumberRequest ({ payload: { phoneNumber, countryCode = 'US', callingCode = '1', smsOptIn, marketingOptIn = false } }) {
  console.log('#onHandleSetStudentPhoneNumberRequest');

  try {
    console.log('#onHandleSetStudentPhoneNumberRequest, try block');
    const response = yield call(request.post, '/accounts/student/sms', { phoneNumber, countryCode, callingCode, smsOptIn, marketingOptIn });

    const { user } = response.data;
    localStorage.setItem('user', JSON.stringify(user));

    yield all([
      put(handleSetStudentPhoneNumberSuccess(user)),
      put(push('/student/verify-phone-number'))
    ]);
  } catch (err) {
    console.error('Error, #onHandleSetStudentPhoneNumberRequest, err: ', err);

    if (err.message) {
      if (err.message === 'Network Error') {
        return yield put({
          type: SET_STUDENT_PHONE_NUMBER_FAILURE,
          payload: { [FORM_ERROR]: 'Network Error? Check your connection and please try again later...' }
        });
      }
    }

    const { status, message = '', errors = {} } = err.response?.data;

    if (message === 'Network Error') {
      return yield put({
        type: SET_STUDENT_PHONE_NUMBER_FAILURE,
        payload: { [FORM_ERROR]: 'Network Error? Check your connection and please try again later...' }
      });
    }

    if (status === 409) {
      return yield put({
        type: SET_STUDENT_PHONE_NUMBER_SUCCESS,
        payload: {
          [FORM_ERROR]: 'Already exists...',
          ...parseFormErrors(errors)
        }
      });
    }

    if (status === 422 || status === 400) {
      return yield put({
        type: SET_STUDENT_PHONE_NUMBER_SUCCESS,
        payload: {
          [FORM_ERROR]: message,
          ...parseFormErrors(errors)
        }
      });
    }

    if (err.response.status === 500) {
      return yield put({
        type: SET_STUDENT_PHONE_NUMBER_FAILURE,
        payload: { [FORM_ERROR]: 'Its not you, its us....... Please try again later.' }
      });
    }
  }
}

const INITIAL_STATE = {
  isLoading: false,
  error: null
};

export default function setStudentPhoneNumber (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_STUDENT_PHONE_NUMBER_REQUEST:
      return { ...state, isLoading: true };
    case SET_STUDENT_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload.user
      };
    case SET_STUDENT_PHONE_NUMBER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
}
