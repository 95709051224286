import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { Button, Tabs } from 'antd';
import SiderNav from '../../../template/SiderNav';
import {
  StyledContainer,
  StyledContent,
  MainLayout,
  SecondaryLayout
} from '../../../../styles/StyledComponents';

import useEditClass from './hooks/useEditClass';
import LoadingSpinner from '../../../../lib/components/loadingSpinner';
import { EditClassForm } from './EditClassForm';

import { ConditionalWithEmpty } from '../../../fields/ConditionalWithEmpty';
import LearningStylesChart from './components/performance-graphs/LearningStylesChart';
import GroupTrendChart from './components/performance-graphs/GroupTrendChart';
import StudentTrendChart from './components/performance-graphs/StudentTrendChart';
import EngagementChart from './components/performance-graphs/EngagementChart';
import QRCodeModal from '../../../../lib/components/QRCodeModal';

// COLORS FOR CHART SEGMENTS
const chartBarColors = ['#8053d7', '#FFCC00', '#D7753E', '#5BBCBD', '#EB5757'];

// COLORS FOR PIE CHART LEGEND
const legendColors = [
  'rgba(128, 83, 215, 0.2)',
  'rgba(255, 204, 0, 0.2)',
  'rgba(215, 117, 62, 0.2)',
  'rgba(91, 188, 189, 0.2)',
  'rgba(235, 87, 87, 0.2)'
];

// RENDER COLORS BY CHART LEGEND FIELDS
const getBackgroundColor = (index) =>
  `
    & .antd-pro-charts-pie-legend > li:nth-child(${index + 1}) {
      background-color: ${legendColors[index]}};
    }
  `;

const setBackgrounds = () => {
  let str = '';

  legendColors.forEach((_, index) => (str += getBackgroundColor(index)));

  return str;
};

const StyledInnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;

  @media (min-width: 1200px) {
    flex-direction: row;
  }
`;

const StyledEditClassFormContainer = styled.div`
  width: 100%;

  @media (min-width: 1200px) {
    max-width: 40%;
  }
`;

const StyledChartContainer = styled(StyledEditClassFormContainer)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (min-width: 1200px) {
    max-width: 60%;
  }
`;

const StyledLinkButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 1px solid #5bbcbd;
  border-radius: 10px;
  font-weight: bolder;
  color: #5bbcbd;
  padding: 0 17px;
  height: 50px;
  min-width: 200px;
  margin: 0 auto 30px;
  transition: all 0.2s linear;

  @media (min-width: 1200px) {
    margin: 0 50px 50px 0;
  }

  &:hover,
  &:focus {
    background-color: #5bbcbd;
    border-color: #5bbcbd;
    color: #fff;
    transition: all 0.2s linear;
  }
`;

const StyledEditClassTabsContainer = styled.div`
  width: 100%;

  & .ant-tabs-nav {
    padding: 0 20px;
  }

  & .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
    padding: 0;
  }

  & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #8053d7;
  }

  & .ant-tabs-tab:hover,
  & .ant-tabs-tab-btn:active {
    color: #8053d7;
  }

  & .ant-tabs-ink-bar {
    background-color: #8053d7;
  }
`;

const StyledEditClassTabContainer = styled(StyledContainer)`
  width: 100%;
  overflow: auto;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  }

  & .antd-pro-charts-pie-total {
    max-height: max-content;
  }

  & .pie-chart-total {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    user-select: none;
  }

  & .antd-pro-charts-pie-legend {
    margin: 0 50px;

    @media (min-width: 1200px) {
      margin: 0 20px;
    }

    @media (min-width: 1500px) {
      margin: 0 50px;
    }
  }

  & .antd-pro-charts-pie-legend > li {
    padding-left: 10px;
    border-radius: 16px;
    width: calc(100% + 30px);
  }

  ${setBackgrounds()}

  & .ant-divider {
    background-color: #bfbfbf;
  }

  & .antd-pro-charts-pie-legendTitle {
    display: inline-block;
    min-width: 80px;
  }

  & .antd-pro-charts-pie-percent,
  & .antd-pro-charts-pie-value,
  & .antd-pro-charts-pie-legendTitle {
    font-weight: 700;
    color: #3f3356;
  }

  & .antd-pro-charts-pie-value {
    right: -20px;
  }

  & .pie-chart-total__title {
    font-size: 20px;
    line-height: 22px;
  }

  & .pie-chart-total__subtitle {
    font-size: 17px;
    line-height: 22px;
    color: #999999;
  }

  & .pie-chart-total__count {
    font-size: 34px;
    line-height: 41px;
  }
`;

const { TabPane } = Tabs;

const EditClassPage = () => {
  // CUSTOM HOOK WITH ALL FUNCTIONALITY FOR EDIT CLASS PAGE
  const {
    user,
    isLoading,
    classId,
    currentClass = { title: '', students: [], tutorGroups: [] },
    allStudents = [],
    studentsByEntity,
    treeSelectConfig,
    tutorGroups,
    activeTab,
    engagementChartConfig,
    groupTrendChartConfig,
    studentTrendChartConfig,
    learningStylesChartConfig,
    studentsWithLearningStyles,
    handleClassDelete,
    handleTabChange
  } = useEditClass();

  const [loading, setLoading] = useState(isLoading ?? false);
  const [isQRCodeVisible, setIsQRCodeVisible] = useState(false);

  useEffect(() => {
    if (isLoading) {
      setLoading(true);
    }

    if (loading) {
      setTimeout(() => setLoading(false), 0);
    }
  }, []);

  const onCloseQR = () => {
    setIsQRCodeVisible(false);
  };

  const showQRCode = () => {
    setIsQRCodeVisible(true);
  };

  return (
    <MainLayout hasSider>
      {/* SIDE NAV BAR */}
      <SiderNav user={user} />
      <SecondaryLayout>
        <StyledContent>
          <LoadingSpinner spinning={isLoading}>
            <StyledInnerContainer>
              {/* EDIT CLASS FORM */}
              <StyledEditClassFormContainer>
                <EditClassForm
                  currentClass={currentClass}
                  isLoading={isLoading}
                  onClassDelete={handleClassDelete}
                  allStudents={allStudents}
                  treeSelectConfig={treeSelectConfig}
                  tutorGroups={tutorGroups}
                  showQRCode={showQRCode}
                />
              </StyledEditClassFormContainer>
              <StyledChartContainer>
                <StyledLinkButton
                  to={`/teacher/classes/${classId}/create-tutor-group-refactor`}
                >
                  NEW TUTOR GROUP
                </StyledLinkButton>

                <QRCodeModal
                  onClose={onCloseQR}
                  qrCodeValue={currentClass?.registrationUrl}
                  visible={isQRCodeVisible}
                  className={currentClass.title}
                  teacherName={user?.firstName + ' ' + user?.lastName}
                />
                {/* CLASS CHART WIT TABS */}
                <StyledEditClassTabsContainer>
                  <Tabs
                    defaultActiveKey='engagement'
                    value={activeTab}
                    onChange={handleTabChange}
                  >
                    <TabPane tab='Engagement' key='engagement'>
                      <StyledEditClassTabContainer>
                        {/* ENGAGEMENT CHART */}
                        <ConditionalWithEmpty
                          conditional={
                            engagementChartConfig.data &&
                            engagementChartConfig.data.length > 0
                          }
                          emptyStyles={{ height: 420 }}
                          emptyDescription={
                            groupTrendChartConfig.emptyDescription
                          }
                        >
                          <EngagementChart
                            data={engagementChartConfig.data}
                            itemWidth={engagementChartConfig.maxItemWidth}
                            height={engagementChartConfig.height}
                          />
                        </ConditionalWithEmpty>
                      </StyledEditClassTabContainer>
                    </TabPane>
                    <TabPane tab='Group Trends' key='group-trends'>
                      <StyledEditClassTabContainer>
                        {/* GROUP TRENDS CHART */}
                        <ConditionalWithEmpty
                          conditional={
                            groupTrendChartConfig.data &&
                            groupTrendChartConfig.data.length > 0
                          }
                          emptyStyles={{ height: 420 }}
                          emptyDescription={
                            groupTrendChartConfig.emptyDescription
                          }
                        >
                          <GroupTrendChart data={groupTrendChartConfig.data} />
                        </ConditionalWithEmpty>
                      </StyledEditClassTabContainer>
                    </TabPane>
                    <TabPane tab='Student Trends' key='student-trends'>
                      <StyledEditClassTabContainer>
                        {/* STUDENT TRENDS CHART */}
                        <ConditionalWithEmpty
                          conditional={
                            studentTrendChartConfig.data &&
                            studentTrendChartConfig.data.length > 0
                          }
                          emptyStyles={{ height: 420 }}
                          emptyDescription={
                            studentTrendChartConfig.emptyDescription
                          }
                        >
                          <StudentTrendChart
                            data={studentTrendChartConfig.data}
                            scale={studentTrendChartConfig.scale}
                            label={studentTrendChartConfig.label}
                            chartBarColors={chartBarColors}
                            height={studentTrendChartConfig.height}
                            itemWidth={studentTrendChartConfig.maxItemWidth}
                          />
                        </ConditionalWithEmpty>
                      </StyledEditClassTabContainer>
                    </TabPane>
                    <TabPane tab='Learning Styles' key='learning'>
                      <StyledEditClassTabContainer>
                        {/* LEARNING STYLES CHART */}
                        <ConditionalWithEmpty
                          conditional={
                            learningStylesChartConfig.data &&
                            learningStylesChartConfig.data.length > 0
                          }
                          emptyStyles={{ height: 420 }}
                          emptyDescription={
                            learningStylesChartConfig.emptyDescription
                          }
                        >
                          <LearningStylesChart
                            data={learningStylesChartConfig.data}
                            chartBarColors={chartBarColors}
                            currentCount={
                              studentsWithLearningStyles?.length ?? 0
                            }
                            totalCount={studentsByEntity?.length}
                          />
                        </ConditionalWithEmpty>
                      </StyledEditClassTabContainer>
                    </TabPane>
                  </Tabs>
                </StyledEditClassTabsContainer>
              </StyledChartContainer>
            </StyledInnerContainer>
          </LoadingSpinner>
        </StyledContent>
      </SecondaryLayout>
    </MainLayout>
  );
};

export default EditClassPage;
