import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from '../../../modules/auth/redux/selectors';
import {
  MainLayout,
  SecondaryLayout,
  StyledContent
} from '../../../styles/StyledComponents';
import SiderNav from '../../template/SiderNav';
import LoadingSpinner from '../../../lib/components/loadingSpinner';
import { useEffect, useState } from 'react';
import { handleAccountBillingHistoryRequest } from '../../../modules/account/redux';
import {
  accountSelector,
  invoicesSelector
} from '../../../modules/account/redux/selectors';
import { Button, Row, Table } from 'antd';
import { Link } from 'react-router-dom';
import { convertStripeDate, formatNumber } from '../../../lib/utils';
import styled from 'styled-components';
import Title from 'antd/lib/typography/Title';
import { LeftOutlined } from '@ant-design/icons';
import { StyledLink } from '../../fields/renderFields';
import { capitalize } from 'lodash';

const StyledButton = styled(Button)`
  background: #f0bd60;
  border: none;
  border-radius: 5px;
  color: white;

  &:active,
  &:hover {
    background-color: #fad602;
    border: none;
  }
`;

const columns = [
  {
    title: 'Customer Name',
    dataIndex: 'customer_name',
    key: 'customer_name'
  },
  {
    title: 'Customer Email',
    dataIndex: 'customer_email',
    key: 'customer_email'
  },
  {
    title: 'Effective Date',
    dataIndex: 'effective_at',
    key: 'effective_at',
    render: (text, record) => <span>{convertStripeDate(text)}</span>
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (text, record) => <span>{capitalize(text)}</span>
  },
  {
    title: 'Total Excluding Tax ($)',
    dataIndex: 'total_excluding_tax',
    key: 'total_excluding_tax',
    render: (text, record) => (
      <span>{formatNumber(parseFloat(text) / 100)}</span>
    )
  },
  {
    title: 'Subtotal ($)',
    dataIndex: 'subtotal',
    key: 'subtotal',
    render: (text, record) => (
      <span>{formatNumber(parseFloat(text) / 100)}</span>
    )
  },
  {
    title: 'Total ($)',
    dataIndex: 'total',
    key: 'total',
    render: (text, record) => (
      <span>{formatNumber(parseFloat(text) / 100)}</span>
    )
  },
  {
    title: 'Hosted Invoice URL',
    dataIndex: 'hosted_invoice_url',
    key: 'hosted_invoice_url',
    render: (text, record) => (
      <a href={text} target='_blank' rel='noopener noreferrer'>
        Detail
      </a>
    )
  }
];

const BillingHistoryPage = () => {
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  const invoices = useSelector(invoicesSelector);
  const { isLoading } = useSelector(accountSelector);
  const [previousList, setPreviousList] = useState([]);
  const [startFrom, setStartFrom] = useState('');

  useEffect(() => {
    dispatch(handleAccountBillingHistoryRequest(startFrom));
  }, [startFrom]);

  return (
    <MainLayout hasSider>
      <SiderNav user={user} />
      <SecondaryLayout>
        <LoadingSpinner spinning={isLoading}>
          <StyledContent>
            <Row>
              <Title level={3}>
                <StyledLink style={{ color: '#333' }} to='/teacher/billing'>
                  <LeftOutlined
                    style={{ padding: 4, marginRight: 5, cursor: 'pointer' }}
                  />
                </StyledLink>{' '}
                Transaction History
              </Title>
            </Row>
            <Table
              dataSource={invoices || []}
              columns={columns}
              style={{
                minWidth: '90%',
                minHeight: '90%',
                overflowX: 'auto',
                backgroundColor: '#fff'
              }}
              pagination={false}
            />
            <Row
              style={{
                gap: 10,
                marginTop: 10,
                justifyContent: 'flex-end'
              }}
            >
              <StyledButton
                disabled={!invoices?.length}
                onClick={() => {
                  if (invoices?.length) {
                    const lastInvoiceId = invoices[invoices.length - 1].id;
                    setPreviousList([...previousList, lastInvoiceId]);
                    setStartFrom(lastInvoiceId);
                  }
                }}
              >
                Previous
              </StyledButton>
              <StyledButton
                disabled={!previousList.length > 0}
                onClick={() => {
                  const previousListLastId =
                    previousList.length > 1
                      ? previousList[previousList.length - 2]
                      : '';
                  if (previousList.length === 1) {
                    setPreviousList([]);
                  } else {
                    previousListLastId &&
                      setPreviousList(
                        previousList.filter(
                          (item) => item === previousListLastId
                        )
                      );
                  }
                  setStartFrom(previousListLastId);
                }}
              >
                Next
              </StyledButton>
            </Row>
          </StyledContent>
        </LoadingSpinner>
      </SecondaryLayout>
    </MainLayout>
  );
};

export default BillingHistoryPage;
