import React from 'react';
import { withRouter } from 'react-router-dom';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { FormattedMessage } from 'react-intl';
import { Field as FField, Form as FForm } from 'react-final-form';
import { Row, Col, Form as AForm, Button } from 'antd';
import Icon from '@ant-design/icons';
import { StyledTitle as Title } from '../../../fields/Title';
import { YellowUnderline, Img } from '../../../../styles/StyledComponents';
import { promiseListener } from '../../../../store';
import { CheckboxInputControl } from '../../../fields/Fields';
import {
  IDENTIFY_STUDENT_LEARNING_STYLE_REQUEST,
  IDENTIFY_STUDENT_LEARNING_STYLE_SUCCESS,
  IDENTIFY_STUDENT_LEARNING_STYLE_FAILURE
} from '../../../../modules/sign-up/redux/identify-student-learning-style';
import Auditory from '../../../../assets/enrollment/auditory.svg';
import Visual from '../../../../assets/enrollment/visual.svg';
import Reading from '../../../../assets/enrollment/reading.svg';
import HandsOn from '../../../../assets/enrollment/hands-on.svg';
import NotSure from '../../../../assets/enrollment/not-sure.svg';
import RightArrow from '../../../../assets/rightArrow.svg';

const buttonStyle = {
  position: 'absolute',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 10,
  bottom: 0,
  background: 'none',
  color: '#D7753E'
};

const IdentifyStudentLearningStyleForm = ({ isLoading }) => (
  <MakeAsyncFunction
    listener={promiseListener}
    start={IDENTIFY_STUDENT_LEARNING_STYLE_REQUEST}
    resolve={IDENTIFY_STUDENT_LEARNING_STYLE_SUCCESS}
    reject={IDENTIFY_STUDENT_LEARNING_STYLE_FAILURE}
  >
    {onSubmit => (
      <FForm
        onSubmit={onSubmit}
        render={({
          submitError,
          handleSubmit,
          submitting,
          hasValidationErrors,
          hasSubmitErrors,
          dirtySinceLastSubmit,
          values
        }) => (
          <div>
            {isLoading && <div>Loading...</div>}
            {submitError && <div className='error'>{submitError}</div>}
            <AForm onFinish={handleSubmit}>
              <Row justify='center' align='middle' style={{ minHeight: '70vh' }}>
                <YellowUnderline>
                  <Title level={2}>
                    <FormattedMessage id='enrollment.2.sign-up.title' />
                  </Title>
                </YellowUnderline>
                <FormattedMessage id='enrollment.2.sign-up.subTitle' />
                <br />
                <br />
                <small style={{ display: 'flex', justifyContent: 'left' }}>
                  Please select from our suggestions:
                </small>
                {/* TODO: Need to refactor into CheckboxGroup */}

                <Row justify='center' gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 16]} align='middle'>
                  <Col style={{ paddingBottom: 20 }}>
                    <FField
                      name='learningStyles'
                      value='auditory'
                      type='checkbox'
                      component={CheckboxInputControl}
                      icon={<Icon component={() => (<Img src={Auditory} width={150} />)} />}
                    />
                  </Col>

                  <Col style={{ paddingBottom: 20 }}>
                    <FField
                      name='learningStyles'
                      value='visual'
                      type='checkbox'
                      component={CheckboxInputControl}
                      icon={<Icon component={() => (<Img src={Visual} width={150} />)} />}
                    />
                  </Col>

                  <Col style={{ paddingBottom: 20 }}>
                    <FField
                      name='learningStyles'
                      value='reading'
                      label='reading'
                      type='checkbox'
                      component={CheckboxInputControl}
                      icon={<Icon component={() => (<Img src={Reading} width={150} />)} />}
                    />
                  </Col>

                  <Col style={{ paddingBottom: 20 }}>
                    <FField
                      name='learningStyles'
                      value='hands-on'
                      type='checkbox'
                      component={CheckboxInputControl}
                      icon={<Icon component={() => (<Img src={HandsOn} width={150} />)} />}
                    />
                  </Col>

                  <Col style={{ paddingBottom: 20 }}>
                    <FField
                      name='learningStyles'
                      value='not-sure'
                      type='checkbox'
                      component={CheckboxInputControl}
                      icon={<Icon component={() => (<Img src={NotSure} width={150} />)} />}
                    />
                  </Col>

                  <Button
                    type='link'
                    htmlType='submit'
                    style={{ right: 0, ...buttonStyle }}
                  >
                    <span>Next </span>
                    <img
                      src={RightArrow}
                      alt=''
                      style={{
                        height: 15,
                        width: 15
                      }}
                    />
                  </Button>
                </Row>
              </Row>
              {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
            </AForm>
          </div>
        )}
      />
    )}
  </MakeAsyncFunction>
);

export default withRouter(IdentifyStudentLearningStyleForm);
