import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import styled from 'styled-components';
import {
  Input,
  Form as AForm,
  Select,
  Rate,
  Typography,
  Radio,
  Row,
  Upload,
  Button,
  Switch
} from 'antd';
import { Field as FField, useField } from 'react-final-form';
import ReactCodeInput from 'react-code-input';
import {
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from '@stripe/react-stripe-js';
import {
  CheckOutlined,
  CloseCircleFilled,
  CloseOutlined,
  EditFilled,
  PaperClipOutlined,
  PictureOutlined,
  UploadOutlined,
  VideoCameraOutlined
} from '@ant-design/icons';

const { Option } = Select;
const { Title, Text } = Typography;
const { TextArea } = Input;

export const StyledInput = styled(Input)`
  border-radius: 8px;
  padding: 10px;

  &:focus,
  &:hover {
    border-color: #8053d7;
  }
`;

export const StyledTextArea = styled(TextArea)`
  border-radius: 8px;
  padding: 10px;

  &:focus,
  &:hover {
    border-color: #8053d7;
  }
`;

export const StyledPasswordInput = styled(Input.Password)`
  border-radius: 8px;
  padding: 10px;
`;

export const StyledExtraInputField = styled(Row)`
  justify-content: ${({ error }) => (error ? 'space-between' : 'end')};
  margin-top: 5px;
`;

export const StyledCustomSelect = styled(Select)`
  width: 100%;

  &:hover .ant-select-selector {
    border-color: #8053d7 !important;
  }

  &.ant-select-open .ant-select-selector {
    border-color: #8053d7 !important;
  }

  & .ant-select-selector {
    border-radius: 8px !important;
    min-height: 44px !important;
  }

  & .ant-select-selection-item {
    display: flex;
    align-items: center;
  }

  & .ant-select-arrow {
    transition: all 0.25s linear;
    transform: rotate(-90deg);
  }

  &.ant-select-open > .ant-select-arrow {
    transition: all 0.25s linear;
    transform: rotate(0);
  }
`;

export const StyledCustomCheckbox = styled.label`
  & .custom-checkbox__real {
    display: none;
  }

  & .custom-checkbox__custom {
    width: 16px;
    height: 16px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: ${({ isChecked }) => `2px solid ${isChecked ? 'green' : 'red'}`};
  }
`;

export const StyledCustomUpload = styled.div`
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  padding: 8px 10px 8px 12px;
  border-radius: 8px;
  border: 1px solid
    ${({ error, focused }) => (error ? 'red' : focused ? '#8053d7' : '#d9d9d9')};
  transition: all 0.2s linear;

  &:focus,
  &:hover {
    border-color: #8053d7;
  }

  & > .custom-upload-real {
    display: none;
  }

  & .custom-upload__title {
    flex: 1;
    padding-right: 10px;
    color: ${({ isPlaceholder }) => (isPlaceholder ? '#BFBFBF' : 'black')};
  }

  & .custom-upload__input {
    flex: 1;
    padding: 0 10px 0 0;
    border: none;
    outline: none;

    &:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }
  }

  & .custom-upload__buttons-group {
    display: flex;
    align-items: center;
    gap: 3px;
  }

  & .custom-upload__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border: none;
    background: transparent;

    & svg {
      transition: all 0.2s linear;
    }

    &:hover,
    &:active,
    &:focus {
      background: transparent;
    }

    &:hover svg {
      fill: #f2994a;
    }
  }

  & .custom-upload__delete {
    &:hover svg {
      fill: #eb5757;
    }
  }
`;

export const StyledSwitchInput = styled(Switch)`
  &.ant-switch {
    background-color: #eb5757;
  }
  &.ant-switch-checked {
    background-color: #219653;
  }
`;

export const FieldsControl = ({
  names,
  subscription,
  fieldsState = {},
  children,
  originalRender
}) => {
  if (!names.length) {
    return (originalRender || children)(fieldsState);
  }
  const [name, ...rest] = names;
  return (
    <FField name={name} subscription={subscription}>
      {(fieldState) => (
        <FieldsControl
          names={rest}
          subscription={subscription}
          originalRender={originalRender || children}
          fieldsState={{ ...fieldsState, [name]: fieldState }}
        />
      )}
    </FField>
  );
};

// Stripe fields wrapped for use in our RFF form fields
export const CardInputControl = ({ options, onChange, onReady, onEscape }) => {
  return <CardElement onChange={onChange} options={options} />;
};

export const CardNumberInputControl = ({
  name,
  input,
  onChange,
  meta: {
    error,
    submitError,
    touched,
    pristine,
    dirtySinceLastSubmit,
    submitting,
    ...meta
  },
  ...rest
}) => {
  return <CardNumberElement name={name} onChange={onChange} {...rest} />;
};

export const CardExpiryInputControl = ({
  name,
  input,
  onChange,
  meta: {
    error,
    submitError,
    touched,
    pristine,
    dirtySinceLastSubmit,
    submitting,
    ...meta
  },
  ...rest
}) => {
  console.log('CardExpiryInputControl, name: ', name);
  console.log('CardExpiryInputControl, input: ', input);
  console.log('CardExpiryInputControl, rest: ', rest);

  return <CardExpiryElement onChange={onChange} {...input} {...rest} />;
};

export const CardCvcInputControl = ({
  name,
  input,
  onChange,
  meta: {
    error,
    submitError,
    touched,
    pristine,
    dirtySinceLastSubmit,
    submitting,
    ...meta
  },
  ...rest
}) => {
  console.log('CardCvcInputControl, name: ', name);
  console.log('CardCvcInputControl, input: ', input);
  console.log('CardCvcInputControl, rest: ', rest);

  return <CardCvcElement />;
};

export const InputControl = ({
  name,
  label,
  input,
  style,
  disabled = false,
  meta: {
    error,
    submitError,
    touched,
    pristine,
    dirtySinceLastSubmit,
    submitting,
    ...meta
  },
  ...rest
}) => {
  return (
    <AForm.Item
      name={name}
      style={style}
      hasFeedback
      validateStatus={(error || submitError) && touched ? 'error' : ''}
      help={
        (error || submitError) &&
        touched && <span className='error'>{error || submitError}</span>
      }
    >
      <StyledInput {...input} {...rest} disabled={disabled || submitting} />
    </AForm.Item>
  );
};

export const TextAreaControl = ({
  name,
  label,
  input,
  style,
  disabled = false,
  meta: {
    error,
    submitError,
    touched,
    pristine,
    dirtySinceLastSubmit,
    submitting,
    ...meta
  },
  ...rest
}) => {
  return (
    <AForm.Item
      name={name}
      style={style}
      hasFeedback
      validateStatus={(error || submitError) && touched ? 'error' : ''}
      help={
        (error || submitError) &&
        touched && <span className='error'>{error || submitError}</span>
      }
    >
      <StyledTextArea
        rows={4}
        {...input}
        {...rest}
        disabled={disabled || submitting}
      />
    </AForm.Item>
  );
};

export const InputControlWithExtraButton = ({
  name,
  input,
  disabled = false,
  button = null,
  marginBottom,
  meta: {
    error,
    submitError,
    touched,
    pristine,
    dirtySinceLastSubmit,
    submitting,
    ...meta
  },
  ...rest
}) => {
  return (
    <AForm.Item
      name={name}
      hasFeedback={!button}
      style={{ marginBottom }}
      validateStatus={(error || submitError) && touched ? 'error' : ''}
      help={
        !button &&
        (error || submitError) &&
        touched && <span className='error'>{error || submitError}</span>
      }
    >
      <StyledInput {...input} {...rest} disabled={disabled || submitting} />
      {button && (
        <StyledExtraInputField
          align='center'
          error={touched && (error || submitError) ? 1 : 0}
        >
          {(error || submitError) && touched && (
            <span className='error'>{error || submitError}</span>
          )}
          {button}
        </StyledExtraInputField>
      )}
    </AForm.Item>
  );
};

export const RatingControl = ({
  name,
  input,
  value,
  customIcons,
  meta,
  ...rest
}) => {
  return (
    <Rate
      count={5}
      {...input}
      {...rest}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      character={({ index }) => {
        return customIcons[index + 1];
      }}
    />
  );
};

export const EmailInputControl = ({
  name,
  input,
  disabled = false,
  submitError,
  meta: {
    error,
    touched,
    pristine,
    dirtySinceLastSubmit,
    submitting,
    ...meta
  },
  ...rest
}) => {
  return (
    <AForm.Item
      name={name}
      hasFeedback
      validateStatus={(error || submitError) && touched ? 'error' : ''}
      help={
        (error || submitError) &&
        touched && <span className='error'>{error || submitError}</span>
      }
    >
      <StyledInput
        {...input}
        {...rest}
        disabled={disabled || submitting}
        type='email'
      />
      {/* <pre>{JSON.stringify(meta, 0, 2)}</pre> */}
    </AForm.Item>
  );
};

export const PasswordControl = ({
  name,
  input,
  disabled = false,
  meta: {
    error,
    submitError,
    touched,
    pristine,
    dirtySinceLastSubmit,
    submitting,
    ...meta
  },
  ...rest
}) => {
  return (
    <AForm.Item
      name={name}
      hasFeedback
      validateStatus={(error || submitError) && touched ? 'error' : ''}
      help={
        (error || submitError) &&
        touched && <span className='error'>{error || submitError}</span>
      }
    >
      <StyledPasswordInput
        {...input}
        {...rest}
        disabled={disabled || submitting}
      />
      {/* <pre>{JSON.stringify(meta, 0, 2)}</pre> */}
    </AForm.Item>
  );
};

export const EditableTitleControl = ({
  name,
  input,
  level,
  meta: {
    error,
    submitError,
    touched,
    pristine,
    dirtySinceLastSubmit,
    submitting,
    ...meta
  },
  ...rest
}) => {
  return (
    <AForm.Item name={name} hasFeedback>
      <Title
        editable={{
          icon: <EditFilled style={{ height: '70%', marginLeft: '5px' }} />,
          tooltip: 'click to edit text',
          onChange: input.onChange
        }}
        level={level}
      >
        {input.value}
      </Title>
    </AForm.Item>
  );
};

export const EditableNameControl = ({
  name,
  input,
  level,
  editing,
  onEnd,
  meta: {
    error,
    submitError,
    touched,
    pristine,
    dirtySinceLastSubmit,
    submitting,
    ...meta
  },
  ...rest
}) => {
  return (
    <AForm.Item
      name={name}
      hasFeedback
    >
      <Title
        editable={{
          icon: <></>,
          tooltip: 'click to edit text',
          onChange: input.onChange,
          editing: editing,
          onEnd: onEnd
        }}
        level={level}
      >
        {input.value}
      </Title>
    </AForm.Item>
  );
};

export const SimpleSelectControl = ({ input, options, meta, ...rest }) => (
  <StyledCustomSelect {...input} options={options} {...rest}>
    {options.map((option) => (
      <Option
        value={option.value}
        key={option.value}
        disabled={option.disabled}
      >
        {option.label}
      </Option>
    ))}
  </StyledCustomSelect>
);

export const SelectControl = ({
  input,
  name,
  value,
  options,
  mode,
  placeholder,
  size,
  tagRender,
  onChange,
  dirtySinceLastSubmit,
  disabled
}) => {
  const {
    meta: { error, touched }
  } = useField(name, {
    subscription: { touched: false, error: false },
    type: 'select',
    value // important for RFF to manage list of strings
  });
  return (
    <AForm.Item
      name={name}
      validateStatus={error && dirtySinceLastSubmit ? 'error' : ''}
      help={
        (error) &&
        touched && <span className='error'>{error}</span>
      }
    >
      <Select
        {...input}
        mode={mode}
        placeholder={placeholder}
        tagRender={tagRender}
      >
        {options
          .filter((o) => !input.value.includes(o))
          .map((item) => (
            <Select.Option key={item} value={item} disabled={disabled}>
              {item}
            </Select.Option>
          ))}
      </Select>
    </AForm.Item>
  );
};

export const CustomSimpleSelectControl = ({
  input,
  options,
  meta,
  removeBatch,
  ...rest
}) => {
  const handleSelectChange = useCallback(
    (value) => {
      input.onChange(value);

      removeBatch?.(value);
    },
    [input, removeBatch]
  );

  return (
    <StyledCustomSelect
      {...input}
      onChange={handleSelectChange}
      options={options}
      {...rest}
    >
      {options.map((option) => (
        <Option
          value={option.value}
          key={option.value}
          disabled={option.disabled}
        >
          {option.label}
        </Option>
      ))}
    </StyledCustomSelect>
  );
};

export const UploaderControl = ({
  name,
  input,
  setHintType,
  hintType,
  style,
  disabled = false,
  edit = false,
  placeholder,
  meta: {
    error,
    submitError,
    touched,
    dirty,
    pristine,
    dirtySinceLastSubmit,
    submitting,
    ...meta
  },
  ...rest
}) => {
  const [focused, setFocused] = useState(false);
  const [errorState, setErrorState] = useState(false);

  // REF to click on real uploader

  const ref = useRef(null);

  // ERROR STATE

  useEffect(() => {
    if (error || submitError) {
      setErrorState(true);
    } else {
      setErrorState(false);
    }
  }, [dirty, error, submitError, touched]);

  const handleFileUpload = useCallback(
    (type) => () => {
      setErrorState(false);
      setHintType(type);

      if (input?.value) {
        input?.onChange?.('');
      }

      if (type !== 'url') {
        setTimeout(() => ref.current.click(), 0);
      }
    },
    [input]
  );

  const handleUploaderChange = useCallback(
    ({ file }) => {
      input?.onChange?.({ ...file });
    },
    [input]
  );

  const handleUrlInputChange = useCallback(
    ({ target: { value } }) => {
      input?.onChange?.({ url: value });
    },
    [input]
  );

  const handleUrlInputFocus = useCallback(() => setFocused(true), []);

  const handleUrlInputBlur = useCallback(() => {
    setFocused(false);

    if (!input?.value?.url) {
      setHintType('all');

      input?.onChange?.('');
    }
  }, [input]);

  const handleFileDelete = useCallback(() => {
    setErrorState(false);
    setHintType('all');

    input?.onChange?.('');
  }, [input]);

  const setIconActiveColor = useCallback(
    (condition) => (condition ? '#f2994a' : '#BDBDBD'),
    []
  );

  // FOR EDIT FLOW

  const getActualFileType = useCallback((fileName, type, url) => {
    if (type) {
      const textTypes = [
        '.html',
        '.pdf',
        '.doc',
        '.docx',
        '.xml',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ];
      const imageTypes = ['.png', '.jpg', '.jpeg'];
      const videoTypes = ['.mp4', '.mov'];

      textTypes.forEach((item) => {
        if (fileName.includes(item)) {
          setHintType('text');
        }
      });

      imageTypes.forEach((item) => {
        if (fileName.includes(item)) {
          setHintType('image');
        }
      });

      videoTypes.forEach((item) => {
        if (fileName.includes(item)) {
          setHintType('video');
        }
      });

      return;
    }

    if (url) {
      setHintType('url');

      return;
    }

    setHintType('all');
  }, []);

  useEffect(() => {
    if (edit && input.value && hintType === 'all') {
      getActualFileType(input.value.name, input.value.type, input.value.url);
    }
  }, [edit, getActualFileType, input.value, hintType]);

  const acceptedFileTypes = useMemo(() => {
    switch (hintType) {
      case 'url':
        return;
      case 'text':
        return '.html, .pdf, .doc, .docx, .xml, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      case 'image':
        return '.png, .jpg, .jpeg';
      case 'video':
        return '.mp4, .mov';

      default:
        return undefined;
    }
  }, [hintType]);

  const renderedPlaceholder = useMemo(() => {
    switch (hintType) {
      case 'all':
        return placeholder;
      default:
        return 'Please add the file';
    }
  }, [placeholder, hintType]);

  const renderedInputField = useMemo(
    () =>
      hintType === 'url' ? (
        <Input
          {...input}
          placeholder='Please enter the URL'
          value={input?.value?.url}
          onChange={handleUrlInputChange}
          onFocus={handleUrlInputFocus}
          onBlur={handleUrlInputBlur}
          {...rest}
          autoFocus={!edit}
          disabled={submitting}
          className='custom-upload__input'
        />
      ) : (
        <Text className='custom-upload__title' ellipsis>
          {input?.value?.name ?? renderedPlaceholder}
        </Text>
      ),
    [
      edit,
      handleUrlInputBlur,
      handleUrlInputChange,
      handleUrlInputFocus,
      input,
      renderedPlaceholder,
      rest,
      submitting,
      hintType
    ]
  );

  return (
    <AForm.Item
      name={name}
      style={style}
      validateStatus={errorState ? 'error' : ''}
      help={errorState && <span className='error'>{error || submitError}</span>}
    >
      <StyledCustomUpload
        className='custom-upload'
        isPlaceholder={!input?.value}
        error={errorState}
        focused={focused}
      >
        {renderedInputField}
        <div className='custom-upload__buttons-group'>
          {input?.value && (
            <Button
              type='text'
              icon={<CloseCircleFilled style={{ color: '#BDBDBD' }} />}
              onClick={handleFileDelete}
              className='custom-upload__btn custom-upload__delete '
            />
          )}
          <Button
            type='text'
            icon={
              <UploadOutlined
                style={{ color: setIconActiveColor(hintType === 'url') }}
              />
            }
            onClick={handleFileUpload('url')}
            className='custom-upload__btn'
          />
          <Button
            type='text'
            icon={
              <PaperClipOutlined
                style={{
                  color: setIconActiveColor(hintType === 'text' && input.value)
                }}
              />
            }
            onClick={handleFileUpload('text')}
            className='custom-upload__btn'
          />

          <Button
            type='text'
            icon={
              <PictureOutlined
                style={{
                  color: setIconActiveColor(hintType === 'image' && input.value)
                }}
              />
            }
            onClick={handleFileUpload('image')}
            className='custom-upload__btn'
          />

          <Button
            type='text'
            icon={
              <VideoCameraOutlined
                style={{
                  color: setIconActiveColor(hintType === 'video' && input.value)
                }}
              />
            }
            onClick={handleFileUpload('video')}
            className='custom-upload__btn'
          />
        </div>

        <Upload
          {...input}
          onChange={handleUploaderChange}
          // FIXME: ADD the custom request
          action={function (file) {
            console.log('file: ', file);
          }}
          fileList={input?.value?.fileList}
          {...rest}
          accept={acceptedFileTypes}
          disabled={disabled || submitting}
          showUploadList={false}
          className='custom-upload-real'
          maxCount={1}
        >
          <Button ref={ref} />
        </Upload>
      </StyledCustomUpload>
    </AForm.Item>
  );
};

export const CheckboxInputControl = ({
  name,
  input,
  disabled = false,
  meta: {
    error,
    submitError,
    touched,
    pristine,
    dirtySinceLastSubmit,
    submitting,
    ...meta
  },
  ...rest
}) => (
  <AForm.Item
    hasFeedback
    validateStatus={(error || submitError) && touched ? 'error' : ''}
    help={
      (error || submitError) &&
      touched && <span className='error'>{error || submitError}</span>
    }
  >
    <Input {...input} {...rest} disabled={submitting} /> {rest && rest.icon}
    {/* <pre>{JSON.stringify(meta, 0, 2)}</pre> */}
  </AForm.Item>
);

export const RadioControl = ({
  name,
  input,
  disabled = false,
  meta: {
    error,
    submitError,
    touched,
    pristine,
    dirtySinceLastSubmit,
    submitting,
    ...meta
  },
  ...rest
}) => (
  <AForm.Item
    hasFeedback
    validateStatus={(error || submitError) && touched ? 'error' : ''}
    help={
      (error || submitError) &&
      touched && <span className='error'>{error || submitError}</span>
    }
  >
    <Radio.Group>
      <label htmlFor='' style={{ marginRight: 10 }}>
        My personal account
      </label>
      <Radio {...input} {...rest} value={1} disabled={submitting}>
        {Radio.$$typeof.value === 1 ? (
          <Input style={{ width: 100, marginLeft: 10 }} />
        ) : null}
      </Radio>
      <label htmlFor='' style={{ marginRight: 10 }}>
        A business or institution
      </label>
      <Radio {...input} {...rest} value={2} disabled={submitting}>
        {Radio.$$typeof.value === 2 ? (
          <Input style={{ width: 100, marginLeft: 10 }} />
        ) : null}
      </Radio>
      {rest && rest.icon}
    </Radio.Group>
    {/* <pre>{JSON.stringify(meta, 0, 2)}</pre> */}
  </AForm.Item>
);

export const CustomCheckboxInputControl = ({
  name,
  input,
  disabled = false,
  meta: {
    error,
    submitError,
    touched,
    pristine,
    dirtySinceLastSubmit,
    submitting,
    ...meta
  },
  ...rest
}) => {
  const renderedIcon = useMemo(
    () =>
      input?.checked ? (
        <CheckOutlined
          style={{
            color: '#219653',
            fontSize: 10
          }}
        />
      ) : (
        <CloseOutlined
          style={{
            color: '#EB5757',
            fontSize: 10
          }}
        />
      ),
    [input?.checked]
  );

  return (
    <StyledCustomCheckbox isChecked={input.checked} className={rest.className}>
      <Input
        {...input}
        {...rest}
        checked={input.checked}
        disabled={submitting}
        className='custom-checkbox__real'
      />
      <span className='custom-checkbox__custom'>{renderedIcon}</span>
    </StyledCustomCheckbox>
  );
};

export const InputWithCheckboxControl = ({
  name,
  input,
  disabled = false,
  meta: {
    error,
    submitError,
    touched,
    pristine,
    dirtySinceLastSubmit,
    submitting,
    ...meta
  },
  ...rest
}) => (
  <AForm.Item
    hasFeedback
    validateStatus={(error || submitError) && touched ? 'error' : ''}
    help={
      (error || submitError) &&
      touched && <span className='error'>{error || submitError}</span>
    }
  >
    <Input {...input} {...rest} disabled={submitting} /> {rest && rest.icon}
    {/* <pre>{JSON.stringify(meta, 0, 2)}</pre> */}
  </AForm.Item>
);

export const CodeInputControl = ({
  input,
  meta: {
    valid,
    error,
    submitError,
    touched,
    pristine,
    dirtySinceLastSubmit,
    ...meta
  },
  label
}) => (
  <>
    <AForm.Item
      label={label}
      hasFeedback
      validateStatus={(error || submitError) && touched ? 'error' : ''}
      help={
        (error || submitError) &&
        touched && <span className='error'>{error || submitError}</span>
      }
    >
      <ReactCodeInput
        {...input}
        type='number'
        fields={6}
        isValid={!error || !dirtySinceLastSubmit}
        inputStyle={{
          padding: 10,
          paddingLeft: 5,
          maxWidth: 45,
          margin: 4,
          borderRadius: 5,
          fontSize: 20,
          fontWeight: 'bold',
          border: '1px solid #00000060',
          textAlign: 'center'
        }}
      />
      {/* <pre>{JSON.stringify(meta, 0, 2)}</pre> */}
    </AForm.Item>
  </>
);

export const SwitchInputControl = ({
  name,
  input,
  disabled = false,
  meta: {
    error,
    submitError,
    touched,
    pristine,
    dirtySinceLastSubmit,
    submitting,
    ...meta
  },
  ...rest
}) => (
  <AForm.Item
    validateStatus={(error || submitError) && touched ? 'error' : ''}
    help={
      (error || submitError) &&
      touched && <span className='error'>{error || submitError}</span>
    }
  >
    <StyledSwitchInput
      {...input}
      checked={input.value}
      disabled={disabled}
      {...rest}
      unCheckedChildren={<CloseOutlined />}
      checkedChildren={<CheckOutlined />}
    />
  </AForm.Item>
);

// Does not work
export const conditionalRequired = (value, allValues, when, is) => {
  return value || typeof value === 'number' ? undefined : 'Required';
};

export const ConditionalRender = ({ when, is, children }) => (
  <FField name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </FField>
);

export const parsePhone = (value) => {
  return !value ? value : value.replace(/[^\d]/g, '');
};

export const formatPhone = (value) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 7) {
    return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 7)}`;
  }

  return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(
    6,
    10
  )}`;
};

export const capitalize = (value) => {
  if (typeof value !== 'string') return '';
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const phoneNumber = (value) => {
  return value && !/^(0|[1-9][0-9]{9})$/i.test(value)
    ? 'Invalid phone number, must be 10 digits'
    : undefined;
};
