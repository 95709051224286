import React, { useCallback } from 'react';
import { Chart, Tooltip, Legend, Facet } from 'bizcharts';
import styled from 'styled-components';

const initialLegendData = {
  correct: 'Correct',
  missed: 'Missed'
};

const StyledEngagementChartContainer = styled.div`
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  margin: 0 auto;
  max-width: ${({ itemWidth }) =>
    itemWidth ? `${itemWidth + 200}px` : '100%'};
`;

const EngagementChart = ({
  data,
  legendData = initialLegendData,
  selectedFilter,
  itemWidth,
  height
}) => {
  const chartColors = ['#8053d7', '#FFCC00', '#D7753E', '#5BBCBD', '#EB5757'];

  const getColorIndex = useCallback((index, arr) => {
    if (index < arr.length) {
      return index;
    }

    return index % arr.length;
  }, []);

  return (
    <StyledEngagementChartContainer>
      <Chart
        autoFit
        visible
        data={data}
        height={height}
        padding={[
          40,
          20,
          40,
          window.innerWidth < 800 ? 80 : itemWidth < 200 ? itemWidth : 200
        ]}
      >
        <Legend
          visible
          itemName={{
            formatter: (text) => legendData[text]
          }}
        />
        <Tooltip showMarkers={false} />
        <Facet
          fields={selectedFilter === 'group' ? ['entity'] : undefined}
          type="rect"
          columnTitle={{
            offsetY: -15,
            style: {
              fontSize: 14,
              fontWeight: 300,
              fill: '#505050'
            }
          }}
          eachView={(view, facet) => {
            view.coordinate().transpose();

            if (facet.columnIndex === 0) {
              view.axis('name', {
                tickLine: null,
                line: null
              });
              view.axis('value', false);
            } else {
              view.axis(false);
            }

            view
              .interval()
              .adjust('stack')
              .position('name*value')
              .color('type', [
                chartColors[getColorIndex(facet.columnIndex, chartColors)],
                '#ebedf0'
              ])
              .size(25)
              .label('value*type', (value, type) => {
                if (type === 'missed' || value === '') {
                  return null;
                }

                const offset = value < 7 ? 15 : 0;
                const fill = value < 7 ? '#2c3542' : '#fff';

                return {
                  content: `${value}%`,
                  offset,
                  style: {
                    fill,
                    fontWeight: 700
                  }
                };
              })
              .tooltip('value*name*type', (value, name, type) => {
                if (value === '') {
                  return null;
                }

                return {
                  title: `${legendData[type]}`,
                  value: `${value}%`,
                  name: `${name}`
                };
              });
            view.interaction('element-active');
          }}
        />
      </Chart>
    </StyledEngagementChartContainer>
  );
};

export default EngagementChart;
