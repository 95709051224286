import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const StyledSearchInput = styled(Input)`
  width: 220px;
  border-radius: 6px;
  padding: 8px 10px;
  border-color: #8c8c8c;
  background: transparent;

  &:focus,
  &:hover {
    border-color: #8053d7 !important;
  }

  &.ant-input-affix-wrapper-disabled {
    border-color: #c0c7ce !important;
  }

  & .ant-input {
    background: transparent;
  }
`;

const SearchInput = ({
  allowClear = true,
  disabled = false,
  value,
  onChange,
  prefix = <SearchOutlined style={{ fontSize: 18, color: '#BFBFBF' }} />,
  suffix,
  placeholder = 'Please enter...'
}) => (
  <StyledSearchInput
    allowClear={allowClear}
    value={value}
    onChange={onChange}
    prefix={prefix}
    suffix={suffix}
    placeholder={placeholder}
    disabled={disabled}
  />
);

export default SearchInput;
