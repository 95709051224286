import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  handleAccountBillingInfoRequest,
  handleAccountPlanSwitchRequest,
  handleProductPricesRequest,
  handleSetProductPriceOnAccountRequest
} from '../../../../modules/account/redux';
import {
  accountBillingInfoSelector,
  accountSelector
} from '../../../../modules/account/redux/selectors';
import { userSelector } from '../../../../modules/auth/redux/selectors';
import { useHistory } from 'react-router-dom';

const usePlans = () => {
  const [isMonth, setIsMonth] = useState(true);
  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(handleProductPricesRequest());
    dispatch(handleAccountBillingInfoRequest());
  }, [dispatch]);

  const user = useSelector(userSelector);

  const {
    data: account,
    isLoading,
    prices: allPrices
  } = useSelector(accountSelector);
  const accountBillingInfo = useSelector(accountBillingInfoSelector);

  const handlePlanChange = useCallback((value) => setIsMonth(value), []);

  const setAccountProductPlan = useCallback(
    ({ priceId, productId, productName, isUpdating }) => {
      if (isUpdating) {
        dispatch(handleAccountPlanSwitchRequest({ priceId }));
      } else {
        dispatch(handleSetProductPriceOnAccountRequest({ priceId, productId, productName }));
      }
    },

    [dispatch]
  );

  const monthlyPrices = useMemo(
    () => allPrices?.filter((price) => price?.recurring?.interval === 'month'),
    [allPrices]
  );

  const annualPrices = useMemo(
    () => allPrices?.filter((price) => price?.recurring?.interval === 'year'),
    [allPrices]
  );

  const prices = useMemo(() => {
    if (isMonth) {
      return monthlyPrices;
    }

    return annualPrices;
  }, [annualPrices, isMonth, monthlyPrices]);

  return {
    user,
    accountBillingInfo,
    account,
    prices,
    isLoading,
    isMonth,
    handlePlanChange,
    setAccountProductPlan
  };
};

export default usePlans;
