import { all, call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import request from '../../../lib/request';
import {
  CREATE_TUTOR_GROUP_FAILURE,
  CREATE_TUTOR_GROUP_REQUEST,
  CREATE_TUTOR_GROUP_SUCCESS,
  DELETE_TUTOR_GROUP_SUCCESS,
  EDIT_TUTOR_GROUP_FAILURE,
  EDIT_TUTOR_GROUP_REQUEST,
  EDIT_TUTOR_GROUP_SUCCESS,
  TUTOR_GROUP_FAILURE,
  TUTOR_GROUP_SUCCESS
} from './tutor-groups';
import { FORM_ERROR } from 'final-form';
import { parseFormErrors } from '../../../utils/parseFormErrors';

export const RESET_CLASS_ERROR = 'teacher/classes/RESET_CLASS_ERROR';

export const CLASSES_DASHBOARD_REQUEST =
  'teacher/classes/CLASSES_DASHBOARD_REQUEST';
export const CLASSES_DASHBOARD_SUCCESS =
  'teacher/classes/CLASSES_DASHBOARD_SUCCESS';
export const CLASSES_DASHBOARD_FAILURE =
  'teacher/classes/CLASSES_DASHBOARD_FAILURE';

export const CLASS_REQUEST = 'teacher/classes/CLASS_REQUEST';
export const CLASS_SUCCESS = 'teacher/classes/CLASS_SUCCESS';
export const CLASS_FAILURE = 'teacher/classes/CLASS_FAILURE';

export const CREATE_CLASS_REQUEST = 'teacher/classes/CREATE_CLASS_REQUEST';
export const CREATE_CLASS_SUCCESS = 'teacher/classes/CREATE_CLASS_SUCCESS';
export const CREATE_CLASS_FAILURE = 'teacher/classes/CREATE_CLASS_FAILURE';

export const EDIT_CLASS_REQUEST = 'teacher/classes/EDIT_CLASS_REQUEST';
export const EDIT_CLASS_SUCCESS = 'teacher/classes/EDIT_CLASS_SUCCESS';
export const EDIT_CLASS_FAILURE = 'teacher/classes/EDIT_CLASS_FAILURE';

export const DELETE_CLASS_REQUEST = 'teacher/classes/DELETE_CLASS_REQUEST';
export const DELETE_CLASS_SUCCESS = 'teacher/classes/DELETE_CLASS_SUCCESS';
export const DELETE_CLASS_FAILURE = 'teacher/classes/DELETE_CLASS_FAILURE';

export const CLEAR_NEW_CLASS = 'teacher/classes/CLEAR_NEW_CLASS';

export const GET_CLASS_USING_TOKEN_REQUEST =
  'classes/GET_CLASS_USING_TOKEN_REQUEST';
export const GET_CLASS_USING_TOKEN_SUCCESS =
  'classes/GET_CLASS_USING_TOKEN_SUCCESS';
export const GET_CLASS_USING_TOKEN_FAILURE =
  'classes/GET_CLASS_USING_TOKEN_FAILURE';

export const GET_ORGANIZATION_USING_TOKEN_REQUEST =
  'classes/GET_ORGANIZATION_USING_TOKEN_REQUEST';
export const GET_ORGANIZATION_USING_TOKEN_SUCCESS =
  'classes/GET_ORGANIZATION_USING_TOKEN_SUCCESS';
export const GET_ORGANIZATION_USING_TOKEN_FAILURE =
  'classes/GET_ORGANIZATION_USING_TOKEN_FAILURE';

// Get Classes Dashboard
export const handleClassesDashboardRequest = (payload) => ({
  type: CLASSES_DASHBOARD_REQUEST,
  payload
});

export const handleClassesDashboardSuccess = (payload) => ({
  type: CLASSES_DASHBOARD_SUCCESS,
  payload
});

export const handleClassesDashboardFailure = (payload) => ({
  type: CLASSES_DASHBOARD_FAILURE,
  payload
});

export const handleGetClassWithTokenRequest = (payload) => ({
  type: GET_CLASS_USING_TOKEN_REQUEST,
  payload
});

export const handleGetClassWithTokenSuccess = (payload) => ({
  type: GET_CLASS_USING_TOKEN_SUCCESS,
  payload
});

export const handleGetClassWithTokenFailure = () => ({
  type: GET_CLASS_USING_TOKEN_FAILURE
});

export const handleGetOrganizationWithTokenRequest = (payload) => ({
  type: GET_ORGANIZATION_USING_TOKEN_REQUEST,
  payload
});

export const handleGetOrganizationWithTokenSuccess = (payload) => ({
  type: GET_ORGANIZATION_USING_TOKEN_SUCCESS,
  payload
});

export const handleGetOrganizationWithTokenFailure = () => ({
  type: GET_ORGANIZATION_USING_TOKEN_FAILURE
});

// Get Class by Id
export const handleClassRequest = (payload) => ({
  type: CLASS_REQUEST,
  payload
});

export const handleClassSuccess = (payload) => ({
  type: CLASS_SUCCESS,
  payload
});

export const handleClassFailure = (payload) => ({
  type: CLASS_FAILURE,
  payload
});

// Create Class
export const handleCreateClassRequest = (payload) => ({
  type: CREATE_CLASS_REQUEST,
  payload
});

export const handleClearNewClass = () => ({
  type: CLEAR_NEW_CLASS
});

export const handleCreateClassSuccess = (payload) => ({
  type: CREATE_CLASS_SUCCESS,
  payload
});

export const handleCreateClassFailure = (payload) => ({
  type: CREATE_CLASS_FAILURE,
  payload
});

// Edit Class
export const handleEditClassRequest = (payload) => ({
  type: EDIT_CLASS_REQUEST,
  payload
});

export const handleEditClassSuccess = (payload) => ({
  type: EDIT_CLASS_SUCCESS,
  payload
});

export const handleEditClassFailure = (payload) => ({
  type: EDIT_CLASS_FAILURE,
  payload
});

// Delete Class
export const handleDeleteClassRequest = (payload) => ({
  type: DELETE_CLASS_REQUEST,
  payload
});

export const handleDeleteClassSuccess = (payload) => ({
  type: DELETE_CLASS_SUCCESS,
  payload
});

export const handleDeleteClassFailure = (payload) => ({
  type: DELETE_CLASS_FAILURE,
  payload
});

export function * onHandleGetClassWithToken ({ payload }) {
  try {
    console.log('#onHandleGetClassWithToken, try block');
    const response = yield call(request.post, '/classes/withToken', {
      registrationToken: payload
    });
    console.log('#onHandleGetClassWithToken, response: ', response);

    const { orgId, teacher, className } = response.data;

    return yield all([
      put(handleGetClassWithTokenSuccess({ orgId, teacher, className }))
    ]);
  } catch (err) {
    console.error('Error, #onHandleGetClassWithToken, err: ', err);
    return yield all([put(handleGetClassWithTokenFailure())]);
  }
}

export function * onHandleGetOrganizationWithToken ({ payload }) {
  try {
    console.log('#onHandleGetOrganizationWithToken, try block');
    const response = yield call(request.post, '/orgs/withToken', {
      organizationToken: payload
    });
    console.log('#onHandleGetOrganizationWithToken, response: ', response);

    const { organization } = response.data;

    return yield all([
      put(handleGetOrganizationWithTokenSuccess({ organization }))
    ]);
  } catch (err) {
    console.error('Error, #onHandleGetOrganizationWithToken, err: ', err);
    return yield all([put(handleGetOrganizationWithTokenFailure())]);
  }
}

export function * onHandleClassesDashboardRequest () {
  console.log('#onHandleClassesDashboardRequest');

  try {
    console.log('#onHandleClassesDashboardRequest, try block');
    const response = yield call(request.get, '/classes');
    console.log('#onHandleClassesDashboardRequest, response: ', response);

    const { classes } = response.data;

    return yield all([put(handleClassesDashboardSuccess(classes))]);
  } catch (err) {
    console.error('Error, #onHandleQuestionSetsDashboardRequest, err: ', err);
    // TODO: Add Notifications to let user know QuestionSetDashboard failed to get loaded and further instructions
    return yield all([put(handleClassesDashboardFailure(err))]);
  }
}

export function * onHandleClassRequest ({ payload: classId }) {
  console.log('#onHandleClassRequest, classId: ', classId);

  try {
    console.log('#onHandleClassRequest, try block');
    const response = yield call(request.get, `/classes/${classId}`);
    // console.log('#onHandleQuestionSetRequest, response: ', response);
    const {
      data: { _class }
    } = response;
    console.log('#onHandleClassRequest, _class: ', _class);

    return yield all([put(handleClassSuccess(_class))]);
  } catch (err) {
    console.error('Error, #onHandleClassRequest, err: ', err);
    return yield all([put(handleClassFailure(err))]);
  }
}

export function * onHandleClearNewClass () {
  return yield put(handleClearNewClass);
}

export function * onHandleCreateClassRequest ({ payload }) {
  console.log('#onHandleCreateClassRequest, payload', payload);

  try {
    console.log('#onHandleCreateClassRequest, try block');
    const response = yield call(request.post, '/classes', payload);
    const {
      data: { _class }
    } = response;
    console.log('#onHandleCreateClassRequest, _class: ', _class);
    return yield all([put(handleCreateClassSuccess(_class))]);
  } catch (err) {
    console.error('Error, #onHandleCreateClassRequest, err: ', err);

    const { status, message, errors } = err.response.data;
    if (message === 'Network Error') {
      return yield put({
        type: CREATE_CLASS_FAILURE,
        payload: {
          [FORM_ERROR]:
            'Network Error? Check your connection and please try again later...'
        }
      });
    }

    if (status === 409) {
      return yield put({
        type: CREATE_CLASS_SUCCESS,
        payload: {
          [FORM_ERROR]: 'Already exists...',
          ...parseFormErrors(errors)
        }
      });
    }

    if (status === 422 || status === 400) {
      return yield put({
        type: CREATE_CLASS_SUCCESS,
        payload: {
          [FORM_ERROR]: message,
          ...parseFormErrors(errors)
        }
      });
    }

    if (err.response.status === 500) {
      return yield put({
        type: CREATE_CLASS_SUCCESS,
        payload: {
          [FORM_ERROR]: 'Its not you, its us....... Please try again later.'
        }
      });
    }
  }
}

export function * onHandleEditClassRequest ({ payload }) {
  console.log('onHandleEditClassRequest, payload: ', payload);

  try {
    console.log('#onHandleEditClassRequest, try block');

    const response = yield call(request.put, `/classes/${payload.id}`, {
      title: payload.title,
      enrolledStudents: payload.students
    });

    console.log('#onHandleEditClassRequest, response: ', response);
    const {
      data: { _class }
    } = response;
    // console.log('questionSet: ', title);
    return yield all([
      put(
        handleEditClassSuccess({
          title: _class.title,
          students: payload.students
        })
      )
    ]);
  } catch (err) {
    console.error('Error, #onHandleEditClassRequest, err: ', err);

    const { status, message, errors } = err.response.data;
    if (message === 'Network Error') {
      return yield put({
        type: EDIT_CLASS_FAILURE,
        payload: {
          [FORM_ERROR]:
            'Network Error? Check your connection and please try again later...'
        }
      });
    }

    if (status === 422 || status === 400) {
      return yield put({
        type: EDIT_CLASS_FAILURE,
        payload: {
          [FORM_ERROR]: message,
          ...parseFormErrors(errors)
        }
      });
    }

    if (status === 500) {
      return yield put({
        type: EDIT_CLASS_FAILURE,
        payload: {
          [FORM_ERROR]: 'Its not you, its us....... Please try again later.'
        }
      });
    }
  }
}

export function * onHandleDeleteClassRequest ({ payload: classId }) {
  console.log('#onHandleDeleteClassRequest, classId', classId);

  try {
    console.log('#onHandleDeleteClassRequest, try block');
    const response = yield call(request.del, `/classes/${classId}`);
    console.log('#onHandleDeleteClassRequest, response: ', response);

    return yield all([
      put(handleDeleteClassSuccess({ classId })),
      put(push('/teacher/classes'))
    ]);
  } catch (err) {
    console.error('Error, #onHandleDeleteClassRequest, err: ', err);

    const { status, message } = err.response.data;
    let errorMessage = '';
    if (message === 'Network Error') {
      errorMessage =
        'Network Error? Check your connection and please try again later...';
    }

    if (status === 422 || status === 400) {
      errorMessage = message;
    }

    if (err.response.status === 500) {
      errorMessage = 'Its not you, its us....... Please try again later.';
    }

    return yield put({
      type: DELETE_CLASS_FAILURE,
      payload: errorMessage
    });
  }
}

const INITIAL_STATE = {
  isLoading: false,
  data: [],
  error: null
};

export default function classes (state = INITIAL_STATE, action) {
  switch (action.type) {
    case CREATE_CLASS_REQUEST:
    case EDIT_CLASS_REQUEST:
    case DELETE_CLASS_REQUEST:
    case EDIT_TUTOR_GROUP_REQUEST:
    case CREATE_TUTOR_GROUP_REQUEST:
    case CLASSES_DASHBOARD_REQUEST:
    case CLASS_REQUEST:
    case GET_CLASS_USING_TOKEN_REQUEST:
      return { ...state, isLoading: true };
    case GET_ORGANIZATION_USING_TOKEN_REQUEST:
      return { ...state, isLoading: true };
    case CLASSES_DASHBOARD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload
      };
    case GET_CLASS_USING_TOKEN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        validatedClass: action.payload
      };
    case GET_ORGANIZATION_USING_TOKEN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        validatedOrganization: action.payload
      };
    case CLASS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        _class: action.payload
      };
    case TUTOR_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tutorGroup: action.payload.tutorGroup,
        students: action.payload.students
      };
    case CREATE_TUTOR_GROUP_SUCCESS:
    case EDIT_TUTOR_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tutorGroup: action.payload.tutorGroup
      };
    case CREATE_CLASS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: [...state.data, action.payload],
        newClass: action.payload
      };
    case CLEAR_NEW_CLASS:
      return {
        ...state,
        newClass: undefined
      };
    case EDIT_CLASS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        _class: {
          ...state._class,
          ...action.payload
        }
      };
    case DELETE_CLASS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: state.data.filter(
          (_class) => _class.id !== action.payload.classId
        )
      };
    case DELETE_TUTOR_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        _class: {
          ...state._class,
          tutorGroups: state._class.tutorGroups.filter(
            (group) => group.id !== action.payload.tutorGroupId
          )
        }
      };
    case GET_CLASS_USING_TOKEN_FAILURE:
      return {
        ...state,
        classNotFound: true
      };
    case GET_ORGANIZATION_USING_TOKEN_FAILURE:
      return {
        ...state,
        organizationNotFound: true
      };
    case EDIT_CLASS_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    case TUTOR_GROUP_FAILURE:
    case CLASS_FAILURE:
    case CREATE_CLASS_FAILURE:
    case DELETE_CLASS_FAILURE:
    case EDIT_TUTOR_GROUP_FAILURE:
    case CLASSES_DASHBOARD_FAILURE:
    case CREATE_TUTOR_GROUP_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case RESET_CLASS_ERROR:
      return {
        ...state,
        error: null
      };
    default:
      return state;
  }
}
