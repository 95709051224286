import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout, Typography } from 'antd';
import styled from 'styled-components';
import { QuestionSetList } from '../../question-sets/question-set-list';
import ClassroomIcon from '../../../assets/classroom.svg';
import SiderNav from '../../template/SiderNav';
import { CreateQuestion } from './create-question-set/CreateQuestion';
import { MainLayout, SecondaryLayout, StyledContent, AddQuestionButton } from '../../../styles/StyledComponents';
import { Link } from 'react-router-dom';

const { Title } = Typography;
const Button = styled.button`
  display: block;
  margin: 0 auto;
  background: #8053d7;
  padding: 5px 20px;
  border: none;
  border-radius: 20px;
  font-weight: bolder;
  color: #fff;

  &:hover {
    background-color: #8053D7;
    border: none;
  }
`;

class NewQuestionSetPage extends Component {
  render () {
    const { questionSets, isLoading } = this.props;

    return (
      <MainLayout hasSider>
        {/* SIDE NAV BAR */}
        <SiderNav />

        <SecondaryLayout>
          <StyledContent>
            <Link to=''>
              <img src={ClassroomIcon} style={{ float: 'right' }} alt='' />
            </Link>
            <Title level={3} style={{ fontWeight: 'bolder' }}>
                 New Question Set
            </Title>
            <br />
            <Title level={4} style={{ fontWeight: 'bolder' }}>Questions</Title>
            <p>1/15 questions added</p>
            {/* LIST OF QUESTION SETS */}
            <QuestionSetList qs={questionSets} isLoading={isLoading} />
            <Button>Create my first question!</Button>
            {/* CONTAINER WITH QUESTION INFO */}
            <CreateQuestion />
            {/* ADD A QUESTION BUTTON */}
            <AddQuestionButton>Add Question</AddQuestionButton>
          </StyledContent>
        </SecondaryLayout>
      </MainLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    questionSets: state.app.questionSets.data,
    isLoading: state.app.questionSets.isLoading
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NewQuestionSetPage);
