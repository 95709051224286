import React from 'react';
import { Row, Col, Dropdown, Rate, Divider, Menu } from 'antd';
import styled from 'styled-components';
import { StyledInput } from '../../../fields/renderFields';
import { StyledContainer } from '../../../../styles/StyledComponents';
import {
  DownOutlined,
  EyeOutlined,
  MinusCircleOutlined,
  FireOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';

const Button = styled.button`
  display: block;
  margin: 0 auto;
  background: #8053d7;
  padding: 5px 20px;
  border: none;
  border-radius: 20px;
  font-weight: bolder;
  color: #fff;

  &:hover {
    background: #8053d7;
    border: none;
  }
`;

const FormatList = (
  <Menu>
    <Menu.Item>
      <a href='#'>Multiple Choice</a>
    </Menu.Item>
  </Menu>
);
// ICONS FOR DIFFICULTY
const customIcons = {
  1: <FireOutlined />,
  2: <FireOutlined />,
  3: <FireOutlined />,
  4: <FireOutlined />,
  5: <FireOutlined />
};
export const CreateQuestion = () => {
  return (
    <>
      {/* CONTAINER WITH QUESTION INFO */}
      <StyledContainer style={{ marginTop: 20 }}>
        <Row gutter={[16, 16]} style={{ width: '100%', position: 'relative' }}>
          {/* QUESTION SECTION */}
          <Col xl={8}>
            <p style={{ fontWeight: 'bolder' }}>Question #1</p>
            <StyledInput placeholder='EX: What is a Molecule?' />
            <div>
              <p style={{ float: 'right' }}>+ Add Hint</p>
            </div>
          </Col>
          {/* FORMAT SECTION */}
          <Col xl={8}>
            <p style={{ fontWeight: 'bolder' }}>Format</p>
            {/* DROPDOWN FOR FORMAT INPUT */}
            <Dropdown overlay={FormatList} trigger={['click']} arrow>
              <div
                style={{
                  padding: 10,
                  border: '1px solid #ddd',
                  borderRadius: 8
                }}
              >
                <a
                  className='ant-dropdown-link'
                  onClick={(e) => e.preventDefault()}
                  style={{ color: '#aaa' }}
                >
                  Select Format{' '}
                  <DownOutlined
                    style={{ float: 'right', margin: '5px auto' }}
                  />
                </a>
              </div>
            </Dropdown>
            {/* <StyledInput placeholder='Select Format' /> */}
          </Col>
          {/* DIFFICULTY SECTION */}
          <Col xl={8}>
            <p style={{ fontWeight: 'bolder' }}>Difficulty</p>
            <Rate
              character={({ index }) => {
                return customIcons[index + 1];
              }}
            />
          </Col>
          {/* HINTS SECTION */}
          <Col xl={8} style={{ paddingTop: 0 }}>
            <p style={{ fontWeight: 'bolder' }}>Hints</p>
            <StyledInput />
          </Col>
          {/* LEARNER TYPE SECTION */}
          <Col xl={8} style={{ paddingTop: 0 }}>
            <p style={{ fontWeight: 'bolder' }}>Learner Type</p>
            {/* DROPDOWN FOR LEARNER TYPE INPUT */}
            <Dropdown overlay={FormatList} trigger={['click']} arrow>
              <div
                style={{
                  padding: 10,
                  border: '1px solid #ddd',
                  borderRadius: 8
                }}
              >
                <a
                  className='ant-dropdown-link'
                  onClick={(e) => e.preventDefault()}
                  style={{ color: '#aaa' }}
                >
                  <EyeOutlined />
                  <DownOutlined
                    style={{ float: 'right', margin: '5px auto' }}
                  />
                </a>
                <MinusCircleOutlined
                  style={{ float: 'right', marginRight: -50 }}
                />
              </div>
            </Dropdown>
          </Col>
          <Divider dashed />
          <Button
            style={{ maxHeight: '40px', position: 'absolute', bottom: 0 }}
          >
            DONE
          </Button>
          {/* ANSWERS SECTION */}
          <Col xl={8} style={{ paddingTop: 0, margin: '0 auto' }}>
            <p style={{ fontWeight: 'bolder' }}>Answer #1</p>
            {/* ANSWER #1 INPUT */}
            <StyledInput />
            <MinusCircleOutlined
              style={{ float: 'right', marginRight: -30, marginTop: 15 }}
            />
            <p style={{ fontWeight: 'bolder' }}>Answer #2</p>
            {/* ANSWER #2 INPUT */}
            <StyledInput />
            <MinusCircleOutlined
              style={{ float: 'right', marginRight: -30, marginTop: 15 }}
            />
            <p style={{ fontWeight: 'bolder' }}>Answer #3</p>
            {/* ANSWER #3 INPUT */}
            <StyledInput />
            <MinusCircleOutlined
              style={{ float: 'right', marginRight: -30, marginTop: 15 }}
            />
            <p style={{ fontWeight: 'bolder' }}>Answer #4</p>
            {/* ANSWER #4 INPUT */}
            <StyledInput />
            <MinusCircleOutlined
              style={{ float: 'right', marginRight: -30, marginTop: 15 }}
            />
          </Col>
          <a href='' style={{ position: 'absolute', bottom: 0, right: 0 }}>
            <p>DELETE</p>
          </a>
        </Row>
      </StyledContainer>
    </>
  );
};
