import React from 'react';
import { Avatar, Col, List as AList, Row, Empty } from 'antd';
import { Link } from 'react-router-dom';
import { StyledTitle as Title } from '../../../fields/Title';
import { StyledContainer } from '../../../../styles/StyledComponents';
import { RightOutlined, UserOutlined } from '@ant-design/icons';

const { Item } = AList;

export const StudentEnrollmentList = ({
  students,
  isLoading,
  emptyDescription
}) => {
  console.log('StudentEnrollmentList, students: ', students);
  return (
    <AList
      loading={isLoading}
      dataSource={students}
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={emptyDescription}
          />
        )
      }}
      renderItem={(student) => (
        <Row>
          <Col xl={14} sm={24} xs={24}>
            <StyledContainer
              style={{
                borderLeft: '20px solid #D7753E',
                padding: 10,
                marginTop: 20
              }}
            >
              <Row gutter={16} align='middle' justify='center'>
                <Col style={{ marginLeft: 10 }}>
                  <Avatar size={60} icon={<UserOutlined />} />
                </Col>
                <Col flex={1}>
                  <Item>
                    <Link to={`/student/profile/${student.id}`}>
                      <Title level={5}>
                        <div style={{ marginBottom: -5 }}>
                          {student.firstName} {student.lastName}
                        </div>
                      </Title>
                      <small>{student.email}</small>
                    </Link>
                  </Item>
                </Col>
                <Col >
                  <div style={{ minWidth:'180px',color: '#8053D7', textAlign: 'left' }}>
                    {student.learningStyles.join(', ')}
                  </div>
                </Col>
                <Col>
                  <Link to={`/student/profile/${student.id}`}>
                    <RightOutlined style={{ float: 'right', fontSize: 20, marginRight: 10 }} />
                  </Link>
                </Col>
              </Row>
            </StyledContainer>
          </Col>
        </Row>
      )}
    />
  );
};
