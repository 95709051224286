import { useSelector } from 'react-redux';
import {
  dashboardClassByIdSelector,
  dashboardDataSelector,
  dashboardStudentsByEntityPerformanceSelector,
  dashboardStudentsByEntitySelector,
  dashboardStudentsByTutorSessionsPerformanceSelector,
  dashboardStudentsSelector,
  dashboardTutorGroupByIdSelector,
  sortedAllStudentsLearningStylesByQuantitySelector,
  sortedStudentsLearningStylesQuantityByEntitySelector
} from '../../../../modules/dashboard/redux/selectors';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  addQueryParam,
  getQueryParam,
  removeQueryParam
} from '../../../../utils/url';

const usePerformance = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('engagement');
  const [searchedValue, setSearchedValue] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [selectedFilterValue, setSelectedFilterValue] = useState('all');
  const [selectedEntityId, setSelectedEntityId] = useState('');
  const [engagementContainerWidth, setEngagementContainerWidth] = useState(0);
  const [studentTrendContainerWidth, setStudentTrendContainerWidth] =
    useState(0);
  const [isScaled, setIsScaled] = useState(false);

  const engagementContainerRef = useRef(null);
  const studentTrendContainerRef = useRef(null);

  const handleResize = useCallback(() => {
    if (engagementContainerRef && engagementContainerRef?.current) {
      setEngagementContainerWidth(engagementContainerRef?.current?.offsetWidth);
    }

    if (studentTrendContainerRef && studentTrendContainerRef?.current) {
      setStudentTrendContainerWidth(
        studentTrendContainerRef?.current?.offsetWidth
      );
    }
  }, [engagementContainerRef, studentTrendContainerRef]);

  useEffect(() => {
    setIsScaled(false);
    if (
      (engagementContainerRef && engagementContainerRef?.current) ||
      (studentTrendContainerRef && studentTrendContainerRef?.current)
    ) {
      handleResize();
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [
    handleResize,
    engagementContainerRef,
    selectedFilter,
    engagementContainerWidth,
    studentTrendContainerWidth,
    activeTab
  ]);

  // ALL CLASSES BY TEACHER
  const { myClasses } = useSelector(dashboardDataSelector);

  // CURRENT CLASS BY SELECTED ID
  const selectedClass = useSelector((state) =>
    dashboardClassByIdSelector(state, { id: selectedEntityId })
  );

  // CURRENT GROUP BY SELECTED ID
  const selectedGroup = useSelector((state) =>
    dashboardTutorGroupByIdSelector(state, { id: selectedEntityId })
  );

  // ALL STUDENTS BY TEACHER
  const allStudents = useSelector(dashboardStudentsSelector);

  // ALL STUDENTS BY CLASS OR TUTOR GROUP
  const studentsByEntity = useSelector((state) =>
    dashboardStudentsByEntitySelector(state, {
      id: selectedEntityId,
      type: selectedFilter
    })
  );

  // OBJECT WITH QUANTITY OF LEARNING STYLES BY ALL STUDENTS
  const sortedAllStudentsLearningTypesQuantity = useSelector(
    sortedAllStudentsLearningStylesByQuantitySelector
  );

  // OBJECT WITH QUANTITY OF LEARNING STYLES BY STUDENTS IN TUTOR GROUP OR CLASS
  const sortedStudentsByEntityLearningTypesQuantity = useSelector((state) =>
    sortedStudentsLearningStylesQuantityByEntitySelector(state, {
      id: selectedEntityId,
      type: selectedFilter
    })
  );

  // EACH STUDENT PERFORMANCE IN TUTOR GROUP
  const studentPerformancesByTutorGroup = useSelector((state) =>
    dashboardStudentsByEntityPerformanceSelector(state, {
      id: selectedEntityId,
      type: 'group'
    })
  );

  // EACH STUDENT PERFORMANCE IN TUTOR GROUP BY ALL TUTOR SESSIONS
  const studentPerformancesByTutorGroupAndAllTutorSessions = useSelector(
    (state) =>
      dashboardStudentsByTutorSessionsPerformanceSelector(state, {
        id: selectedEntityId,
        type: 'group'
      })
  );

  // RENDERED STUDENTS DATA ACCORDING TO FILTER TYPE
  const students = useMemo(
    () => (selectedFilter === 'all' ? allStudents : studentsByEntity),
    [allStudents, selectedFilter, studentsByEntity]
  );

  // SEARCH STUDENTS ONCHANGE HANDLER
  const handleStudentsSearch = useCallback(
    (e) => {
      const { value } = e.target;

      if (!searchedValue && value === ' ') {
        return;
      }

      setSearchedValue(value);
    },
    [searchedValue]
  );

  // TAB ONCHANGE HANDLER
  const handleTabChange = useCallback((key) => {
    if (!key.length) return;
    setActiveTab(key);
    addQueryParam('activeTab', key);
  }, []);

  const setFilterChange = (value) => {
    if (!value.length || value === 'all') {
      setSelectedFilter('all');
      setSelectedFilterValue('all');
      setSelectedEntityId('');
    } else {
      const accentStrIndex = value.indexOf('-');
      const type = value.substring(0, accentStrIndex);
      const id = value.substring(accentStrIndex + 1, value.length);
      setSelectedFilter(type);
      setSelectedFilterValue(value);
      setSelectedEntityId(id);
    }
  };

  // TREE SELECT FILTER ONCHANGE HANDLER
  const handleFilterChange = useCallback(
    (value) => {
      if (value.startsWith('group')) {
        const tutorGroupId = value.split('group-')[1];
        const parentClass = myClasses?.find((classItem) =>
          classItem.tutorGroups.some(
            (tutorGroup) => tutorGroup.id === tutorGroupId
          )
        );
        addQueryParam('class', `class-${parentClass.id}`);
        addQueryParam('group', value.toString());
      }

      if (value.startsWith('class')) {
        addQueryParam('class', value.toString());
        removeQueryParam('group');
      }
      setFilterChange(value);
    },
    [myClasses]
  );

  useEffect(() => {
    if (location.search) {
      const _class = getQueryParam('class');
      const group = getQueryParam('group');
      setFilterChange(group.length ? group : _class);
      handleTabChange(getQueryParam('activeTab'));
    }
  }, []);

  // SEARCHED STUDENT BY FIRST AND LAST NAMES
  const searchedStudents = useMemo(() => {
    if (!!searchedValue && searchedValue.length > 0) {
      return students.filter(
        ({ firstName, lastName }) =>
          firstName
            .toLocaleLowerCase()
            .startsWith(searchedValue.toLocaleLowerCase()) ||
          lastName
            .toLocaleLowerCase()
            .startsWith(searchedValue.toLocaleLowerCase())
      );
    }

    return students;
  }, [searchedValue, students]);

  // DATA FOR TREE SELECT FILTER
  const treeData = useMemo(
    () => [
      {
        value: 'all',
        title: 'All Classes',
        children: myClasses?.map((item) => ({
          value: `class-${item.id}`,
          title: item.title,
          children: item?.tutorGroups?.map((group) => ({
            value: `group-${group.id}`,
            title: group.title
          }))
        }))
      }
    ],
    [myClasses]
  );

  // LEARNING STYLES TAB

  // STUDENTS WITH SELECTED LEARNING TYPES
  const studentWithLearningStyles = students?.filter(
    (student) => student.learningStyles?.length > 0
  );

  // SORTED QUANTITY OF THE LEARNING STYLES
  const sortedLearningTypesQuantity = useMemo(
    () =>
      selectedFilter === 'all'
        ? sortedAllStudentsLearningTypesQuantity
        : sortedStudentsByEntityLearningTypesQuantity,
    [
      selectedFilter,
      sortedAllStudentsLearningTypesQuantity,
      sortedStudentsByEntityLearningTypesQuantity
    ]
  );

  // DATA FOR LEARNING TYPES CHART BY STUDENTS
  const learningStylesChartData = Object.entries(
    sortedLearningTypesQuantity ?? {}
  ).map(([key, value]) => ({ x: key.toUpperCase(), y: value }));

  // DESCRIPTION FOR THE EMPTY STATE OF THE LEARNING STYLES CHART
  const learningStylesTabEmptyDescription = useMemo(() => {
    switch (selectedFilter) {
      case 'class':
        return 'Sorry, in this class no students with chosen learning style';
      case 'group':
        return 'Sorry, in this tutor group no students with chosen learning style';
      default:
        return 'Sorry, there are no students with chosen learning style';
    }
  }, [selectedFilter]);

  // CONFIG FOR LEARNING STYLES CHART
  const learningStylesChartConfig = useMemo(
    () => ({
      data: learningStylesChartData ?? [],
      emptyDescription: learningStylesTabEmptyDescription
    }),
    [learningStylesChartData, learningStylesTabEmptyDescription]
  );

  // STUDENT TREND TAB

  // STUDENT TREND BY ALL CLASSES
  const studentsTrendsDataByAllClasses = useMemo(
    () =>
      myClasses?.map((currentClass) => {
        let classAverage = 0;
        let numOfGroup = 0;
        currentClass.tutorGroups.map((group) => {
          const maxPossibleScore =
            group.maxPossibleScore > 0 ? group.maxPossibleScore : 1;
          classAverage +=
            ((group.totalScore / group.students.length) * 100) /
            maxPossibleScore;
          numOfGroup += 1;
        });
        return {
          name: currentClass?.title,
          score: classAverage / numOfGroup
        };
      }),
    [myClasses]
  );

  // STUDENT TREND BY CURRENT CLASS
  const studentsTrendsDataByClass = useMemo(() => {
    return selectedClass?.tutorGroups?.map((group) => {
      const maxPossibleScore = +group.maxPossibleScore;
      const tutorgropScrore = +group.totalScore / group.students.length;
      return {
        name: group?.title,
        score: (100 * tutorgropScrore) / maxPossibleScore
      };
    });
  }, [selectedClass?.tutorGroups]);

  // STUDENT TREND BY CURRENT TUTOR GROUP
  const studentsTrendsDataByTutorGroup = useMemo(() => {
    return studentPerformancesByTutorGroup.map((student) => ({
      name: student?.name,
      score: isScaled
        ? (+student?.performance?.totalScore * 100) /
          Math.max.apply(
            null,
            selectedGroup?.tutorSessions?.map(
              (session) => session?.maxPossibleIndividualScore
            )
          )
        : +student?.performance?.totalScore
    }));
  }, [studentPerformancesByTutorGroup, isScaled]);

  // DATA FOR STUDENT TREND CHART
  const studentTrendData = useMemo(() => {
    if (selectedFilter === 'class') {
      return studentsTrendsDataByClass;
    }

    if (selectedFilter === 'group') {
      return studentsTrendsDataByTutorGroup;
    }

    return studentsTrendsDataByAllClasses;
  }, [
    selectedFilter,
    studentsTrendsDataByAllClasses,
    studentsTrendsDataByClass,
    studentsTrendsDataByTutorGroup
  ]);

  // MAX VALUE FOR STUDENT TREND CHART AXIS
  const maxScaleValue = useMemo(() => {
    if (selectedFilter === 'group') {
      const maxScores = selectedGroup?.tutorSessions?.map(
        (session) => session?.maxPossibleIndividualScore
      );

      return isScaled ? 100 : Math.max.apply(null, maxScores);
    }

    return 100;
  }, [selectedFilter, selectedGroup?.tutorSessions, isScaled]);

  // AXIS FOR STUDENT TREND CHART
  const scale = useMemo(
    () => ({
      score: {
        min: 0,
        max: maxScaleValue
      }
    }),
    [maxScaleValue]
  );

  // AXIS LABEL FOR STUDENT TREND CHART
  const label = useMemo(() => {
    if (selectedFilter !== 'group') {
      return {
        formatter: (val) => `${val}%`
      };
    } else if (selectedFilter === 'group' && isScaled) {
      return {
        formatter: (val) => `${val}%`
      };
    }
  }, [selectedFilter, isScaled]);

  // MAX STUDENT TREND CHART ITEM WIDTH
  const studentTrendChartItemWidth = useMemo(() => {
    const itemWidths = studentTrendData?.map(({ name }) => name?.length * 8);

    const maxItemWidth = Math.max.apply(null, itemWidths);

    return maxItemWidth;
  }, [studentTrendData]);

  // MAX STUDENT TREND CHART HEIGHT
  const studentTrendChartHeight = useMemo(
    () =>
      studentTrendData?.length * 25 + 200 > 485
        ? studentTrendData?.length * 25 + 200
        : 485,
    [studentTrendData]
  );

  // CONFIG FOR STUDENT TREND CHART
  const studentTrendChartConfig = useMemo(
    () => ({
      scale,
      label,
      data: studentTrendData,
      ref: studentTrendContainerRef,
      maxItemWidth: studentTrendChartItemWidth,
      height: studentTrendChartHeight
    }),
    [
      label,
      scale,
      studentTrendChartHeight,
      studentTrendChartItemWidth,
      studentTrendData
    ]
  );

  // ENGAGEMENT TAB

  // ENGAGEMENT BY ALL CLASSES
  const engagementDataByAllClasses = useMemo(
    () =>
      myClasses
        ?.map((currentClass) => [
          {
            name: currentClass?.title,
            entity: 'classes',
            type: 'correct',
            value: +(+currentClass?.totalClassEngagementScore * 100).toFixed(1)
          },
          {
            name: currentClass?.title,
            entity: 'classes',
            type: 'missed',
            value: +(
              (1 - +currentClass?.totalClassEngagementScore) *
              100
            ).toFixed(1)
          }
        ])
        .flat(),
    [myClasses]
  );

  // ENGAGEMENT BY CURRENT CLASSES
  const engagementDataByClass = useMemo(
    () =>
      selectedClass?.tutorGroups
        ?.map((group) => [
          {
            name: group?.title,
            entity: 'class',
            type: 'correct',
            value: +(+group?.totalEngagementScore * 100).toFixed(1)
          },
          {
            name: group?.title,
            entity: 'class',
            type: 'missed',
            value: +((1 - +group?.totalEngagementScore) * 100).toFixed(1)
          }
        ])
        .flat(),
    [selectedClass]
  );

  // ENGAGEMENT BY CURRENT TUTOR GROUP
  const engagementDataByTutorGroup = useMemo(
    () =>
      studentPerformancesByTutorGroupAndAllTutorSessions
        ?.map((session) => [
          {
            name: session?.studentName,
            entity: session?.title,
            type: 'correct',
            value: +(+session?.performance?.totalEngagementScore * 100).toFixed(
              1
            )
          },
          {
            name: session?.studentName,
            entity: session?.title,
            type: 'missed',
            value: +(
              (1 - +session?.performance?.totalEngagementScore) *
              100
            ).toFixed(1)
          }
        ])
        .flat(),
    [studentPerformancesByTutorGroupAndAllTutorSessions]
  );

  // DATA FOR ENGAGEMENT CHART
  const engagementChartData = useMemo(() => {
    if (selectedFilter === 'class') {
      return engagementDataByClass;
    }

    if (selectedFilter === 'group') {
      return engagementDataByTutorGroup;
    }

    return engagementDataByAllClasses;
  }, [
    engagementDataByAllClasses,
    engagementDataByClass,
    engagementDataByTutorGroup,
    selectedFilter
  ]);

  // MAX ENGAGEMENT CHART ITEM WIDTH
  const maxEngagementChartItemWidth = useMemo(() => {
    const itemNames = [
      ...new Set(engagementChartData?.map(({ name }) => name))
    ];

    const itemWidths = itemNames.map((name) => name?.length * 8);

    const maxItemWidth = Math.max.apply(null, itemWidths);

    return maxItemWidth;
  }, [engagementChartData]);

  // ENGAGEMENT CHART HEIGHT
  const engagementChartHeight = useMemo(
    () =>
      engagementChartData?.length * 20 + 200 > 485
        ? engagementChartData?.length * 20 + 100
        : 485,
    [engagementChartData?.length]
  );

  // CONFIG FOR ENGAGEMENT CHART
  const engagementChartConfig = useMemo(
    () => ({
      data: engagementChartData,
      maxItemWidth: maxEngagementChartItemWidth,
      height: engagementChartHeight,
      ref: engagementContainerRef
    }),
    [engagementChartData, engagementChartHeight, maxEngagementChartItemWidth]
  );

  return {
    isScaled,
    setIsScaled,
    activeTab,
    students,
    searchedStudents,
    studentWithLearningStyles,
    searchedValue,
    treeData,
    selectedFilter,
    selectedFilterValue,
    learningStylesChartConfig,
    studentTrendChartConfig,
    engagementChartConfig,
    handleStudentsSearch,
    handleTabChange,
    handleFilterChange
  };
};

export default usePerformance;
