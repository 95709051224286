import React from 'react';
import { Input, Form, Select, Checkbox } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Field } from 'react-final-form';
const { TextArea } = Input;

export const StyledInput = styled(Input)`
border-radius: 8px;
padding: 10px;
`;
export const StyledSearch = styled(Input.Search)`
border-radius: 8px;
padding: 10px;
`;

export const StyledTextArea = styled(TextArea)`
`;

export const StyledLink = styled(Link)`
`;

export const StyledPasswordInput = styled(Input.Password)`
`;

export const StyledMultiSelect = styled(Select)`
`;

export const renderInput = ({ input, placeholder, label, meta, disabled }) => (
  <Form.Item
    name={label}
    validateStatus={(meta.error || meta.submitError) && meta.touched ? 'error' : ''}
    help={(meta.error || meta.submitError) && meta.touched && (
      <span className='error'>{meta.error || meta.submitError}</span>
    )}
  >
    <StyledInput {...input} placeholder={placeholder || label} disabled={disabled || meta.submitting} />
    <pre>{JSON.stringify(meta, 0, 2)}</pre>
  </Form.Item>
);

export const renderSearch = ({ input, placeholder, label, meta, disabled }) => (
  <Form.Item
    name={label}
    validateStatus={(meta.error || meta.submitError) && meta.touched ? 'error' : ''}
    help={(meta.error || meta.submitError) && meta.touched && (
      <span className='error'>{meta.error || meta.submitError}</span>
    )}
  >
    <StyledSearch {...input} placeholder={placeholder || label} disabled={disabled || meta.submitting} />
  </Form.Item>
);

export const renderPasswordInput = ({ input, placeholder, label, meta }) => (
  <Form.Item
    name={label}
    validateStatus={(meta.error || meta.submitError) && meta.touched ? 'error' : ''}
    help={(meta.error || meta.submitError) && meta.touched && (
      <span className='error'>{meta.error || meta.submitError}</span>
    )}
  >
    <StyledPasswordInput {...input} placeholder={placeholder} label={label} disabled={meta.submitting} />
  </Form.Item>
);

export const renderSelect = ({ input, options, mode, placeholder, size }) => {
  return (
    <StyledMultiSelect {...input} mode={mode} placeholder={placeholder} size={size} style={{ width: 200 }}>
      {options
        .filter(o => !input.value.includes(o))
        .map((option, index) => (
          <Select.Option key={index} value={option}>
            {option}
          </Select.Option>
        ))}
    </StyledMultiSelect>
  );
};

export const normalizePhone = value => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 7) {
    return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 7)}`;
  }

  return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
};

export const capitalize = value => {
  if (typeof value !== 'string') return '';
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const renderTextArea = ({ input, label, placeholder }) => (
  <>
    <StyledTextArea
      {...input}
      placeholder={placeholder}
    />
  </>
);

export const renderCheckbox = ({ input, label, type, defaultValue }) => {
  return (
    <Checkbox {...input} type={type} defaultChecked={defaultValue}>
      {label}
    </Checkbox>
  );
};

export const ConditionalRender = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
);

export const renderError = ({ meta: { touched, error } }) => (
  <div>{touched && error ? <span>{error}</span> : false}</div>
);
