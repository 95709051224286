import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Drawer, List as AList, Row, Col, Empty } from 'antd';
import { StyledTitle as Title } from '../../../fields/Title';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { TutorGroupStudentsListItem } from '../create-tutor-group/TutorGroupStudentsListItem';

const StyledDivider = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0;
`;

const StyledEmptyDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const StyledSubtitle = styled.span`
  display: inline-block;
  width: 100%;
  font-size: 14px;
  color: #000000;
  margin-bottom: 15px;
`;

export const EditClassStudentsListDrawer = ({
  allStudents,
  currentClassStudents,
  onClose,
  visible,
  remove,
  push
}) => {
  const currentClassDiffStudents = useMemo(() => {
    let filteredStudents = [];

    allStudents?.forEach((student) => {
      const foundStudent = currentClassStudents?.find(
        ({ id }) => id === student.id
      );

      if (foundStudent) {
        filteredStudents = [...filteredStudents];
      } else {
        filteredStudents = [...filteredStudents, student];
      }
    });

    return filteredStudents;
  }, [allStudents, currentClassStudents]);

  const addStudentOnClick = useCallback(
    ({ event, student }) => {
      console.log('addStudentOnClick, event: ', event);
      event.preventDefault();
      event.stopPropagation();

      push(student);
    },
    [push]
  );

  const removeStudentOnClick = useCallback(
    ({ event, student, index }) => {
      console.log('removeStudentOnClick, event: ', event);
      console.log('removeStudentOnClick, student: ', student);

      event.preventDefault();
      event.stopPropagation();

      remove(index);
    },
    [remove]
  );

  return (
    <Drawer
      title='Students'
      placement='right'
      width={window.innerWidth > 600 ? 600 : window.innerWidth}
      onClose={onClose}
      visible={visible}
    >
      <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 16]}>
        <Col style={{ width: '100%' }}>
          <Title level={5}>Current Class Students</Title>
          <StyledSubtitle>
            {currentClassStudents?.length ?? '0'} / 25 Students added
          </StyledSubtitle>
          <AList
            itemLayout='horizontal'
            dataSource={currentClassStudents}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={
                    <StyledEmptyDescription>
                      <span>Sorry, in this class no students!</span>
                      <span>Please add the first student to this class</span>
                    </StyledEmptyDescription>
                  }
                />
              )
            }}
            renderItem={(student, index) => (
              <TutorGroupStudentsListItem
                student={student}
                index={index}
                onClick={removeStudentOnClick}
                icon={<MinusOutlined width={10} />}
              />
            )}
          />
        </Col>
      </Row>
      <StyledDivider />
      <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 16]}>
        <Col style={{ width: '100%' }}>
          <Title level={5}>All Students</Title>
          <StyledSubtitle>
            {currentClassDiffStudents?.length ?? '0'} Students
          </StyledSubtitle>
          <AList
            itemLayout='horizontal'
            dataSource={currentClassDiffStudents}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={
                    <StyledEmptyDescription>
                      <span>
                        Sorry, you don't have any students to add to this class
                      </span>
                    </StyledEmptyDescription>
                  }
                />
              )
            }}
            renderItem={(student, index) => (
              <TutorGroupStudentsListItem
                student={student}
                count={currentClassStudents?.length}
                maxCount={25}
                index={index}
                onClick={addStudentOnClick}
                icon={<PlusOutlined />}
              />
            )}
          />
        </Col>
      </Row>
    </Drawer>
  );
};
