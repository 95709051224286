import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'antd';

import { CenteredContainer as Container } from '../../../fields/Container';
import Header from '../../../template/Header';
import VerifyStudentPhoneNumberForm from './VerifyStudentPhoneNumberForm';
import TutorDOwl from '../../../../assets/tutord-signin-page.svg';
import { BigTitle, Img, MainCol, WhiteText, YellowUnderline } from '../../../../styles/StyledComponents';

class VerifyStudentPhoneNumberPage extends Component {
  render () {
    const { isLoading } = this.props;
    return (
      <Container style={{ width: '100%' }}>
        <Row justify='center'>
          <Col xl={11} sm={24} xs={24}>
            <Header />
            <Col xl={23} xs={22} style={{ textAlign: 'center', paddingLeft: 20 }}>
              <BigTitle>
                <FormattedMessage id='enrollment.verify-student-phone-number.aside.title' />
              </BigTitle>
              <Img src={TutorDOwl} alt='TutorD Owl' style={{ marginBottom: 20 }} />
              <WhiteText>
                <FormattedMessage
                  id='enrollment.verify-student-phone-number.aside.welcome'
                  values={{
                    studentFullName: 'Admin Bailey'
                  }}
                />
              </WhiteText>
              <WhiteText style={{ fontWeight: 100, fontSize: '1.5em', fontFamily: 'Montserrat Light' }}>
                <FormattedMessage id='enrollment.verify-student-phone-number.aside.subTitle' />
              </WhiteText>
            </Col>
          </Col>
          <MainCol xl={13} xs={24}>
            <VerifyStudentPhoneNumberForm isLoading={isLoading} />
          </MainCol>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.verifyStudentPhoneNumber.isLoading
  };
};

export default connect(mapStateToProps, {})(VerifyStudentPhoneNumberPage);
