import React, { Component } from 'react';
import Footer from '../template/Footer';
import Header from '../template/Header';
import { Col, Layout, Row, Result } from 'antd';

import NotFoundSvg from '../../assets/404-not-found.svg';
import { Link } from 'react-router-dom';
import { OrangeButton } from '../../styles/StyledComponents';

const { Content } = Layout;

class NotFoundPage extends Component {
  render () {
    return (
      <>
        <Layout style={{ width: '100%', backgroundColor: '#e5e5e5' }}>
          <Header />
          <Content className='site-layout' style={{ margin: '30px 0' }}>
            <Row gutter={[16, 16]} justify='center' style={{ marginTop: 100 }}>
              <Col
                xl={20}
                sm={22}
                xs={22}
                style={{
                  background: '#fff',
                  padding: 40,
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
                }}
              >
                <Result
                  icon={<img src={NotFoundSvg} alt={'Oscar the Owl can\'t seem to figure it out!'} />}
                  title='404 - Page Not Found'
                  subTitle={'I\'m sorry, the page you were looking for cannot be found!'}
                  extra={
                    <Link to='/'>
                      <OrangeButton type='primary'>
                      Back Home
                      </OrangeButton>
                    </Link>
                  }
                />
              </Col>
            </Row>
          </Content>
          <Footer />
        </Layout>
      </>
    );
  }
}

export default NotFoundPage;
