import React from 'react';
import { Chart, Tooltip, Legend, Facet } from 'bizcharts';
import styled from 'styled-components';

const initialLegendData = {
  correct: 'Correct',
  missed: 'Missed'
};

const StyledEngagementChartContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: ${({ itemWidth }) =>
    itemWidth ? `${itemWidth + 200}px` : '100%'};
`;

const EngagementChart = ({
  data,
  legendData = initialLegendData,
  itemWidth,
  height
}) => {
  return (
    <StyledEngagementChartContainer>
      <Chart
        autoFit
        visible
        data={data}
        height={height}
        padding={[0, 0, 20, window.innerWidth < 800 ? 80 : itemWidth < 200 ? itemWidth : 200]}
      >
        <Legend
          visible
          itemName={{
            formatter: (text) => legendData[text]
          }}
        />
        <Tooltip showMarkers={false} />
        <Facet
          type='rect'
          columnTitle={{
            offsetY: -15,
            style: {
              fontSize: 14,
              fontWeight: 300,
              fill: '#505050'
            }
          }}
          eachView={(view, facet) => {
            view.coordinate().transpose();

            if (facet.columnIndex === 0) {
              view.axis('name', {
                tickLine: null,
                line: null
              });
              view.axis('value', false);
            } else {
              view.axis(false);
            }

            view
              .interval()
              .adjust('stack')
              .position('name*value')
              .color('type', ['#8053D7', '#ebedf0'])
              .size(25)
              .label('value*type', (value, type) => {
                if (type === 'missed' || value === '') {
                  return null;
                }

                const offset = value < 13 ? 15 : 0;
                const fill = value < 13 ? '#2c3542' : '#fff';

                return {
                  content: `${value}%`,
                  offset,
                  style: {
                    fill,
                    fontWeight: 700
                  }
                };
              })
              .tooltip('value*name*type', (value, name, type) => {
                if (value === '') {
                  return null;
                }

                return {
                  title: `${legendData[type]}`,
                  value: `${value}%`,
                  name: `${name}`
                };
              });
            view.interaction('element-active');
          }}
        />
      </Chart>
    </StyledEngagementChartContainer>
  );
};

export default EngagementChart;
