import { createSelector } from 'reselect';

// Org Selector
export const validatedOrganizationSelector = (state) => state.classes.validatedOrganization;
export const organizationNotFoundSelector = (state) => state.classes.organizationNotFound;

// ALL CLASSES SELECTOR
export const classesSelector = (state) => state.classes;
export const validatedClassSelector = (state) => state.classes.validatedClass;
export const classNotFoundSelector = (state) => state.classes.classNotFound;
export const selectNewlyAddedClass = (state) => state.classes.newClass;
// CUSTOM ENTITY SELECTOR
export const getEntityFromProps = (state, props) => props;

// CURRENT CLASS SELECTOR
export const currentClassSelector = createSelector(
  [classesSelector],
  (classes) => classes?._class
);

// TUTOR GROUPS BY CLASS SELECTOR
export const tutorGroupsByClassSelector = createSelector(
  [currentClassSelector],
  (currentClass) => currentClass?.tutorGroups
);

// ALL STUDENTS BY CURRENT CLASS SELECTOR
export const studentsByCurrentClassSelector = createSelector(
  [currentClassSelector],
  (currentClass) => currentClass?.students
);

// ALL STUDENTS BY ENTITY SELECTOR
export const studentsByEntitySelector = createSelector(
  [tutorGroupsByClassSelector, getEntityFromProps],
  (tutorGroups, props) => {
    console.log('studentsByEntitySelector, tutorGroups: ', tutorGroups);
    console.log('studentsByEntitySelector, props: ', props);
    let students = [];

    if (props?.type === 'active-session') {
      const groupsWithActiveSession = tutorGroups?.filter(
        (group) => group?.activeSession
      );

      groupsWithActiveSession?.forEach((group) =>
        group?.students?.forEach((student) => {
          const foundedStudent = students?.find(({ id }) => id === student?.id);

          if (foundedStudent) {
            students = [...students];
          } else {
            students = [...students, student];
          }
        })
      );
    }

    if (props?.type === 'completed-session') {
      const groupsCompletedSession = tutorGroups?.filter((group) =>
        group?.tutorSessions?.some((session) => session?.status === 'completed')
      );

      groupsCompletedSession?.forEach((group) =>
        group?.students?.forEach((student) => {
          const foundedStudent = students?.find(({ id }) => id === student?.id);

          if (foundedStudent) {
            students = [...students];
          } else {
            students = [...students, student];
          }
        })
      );
    }

    if (props?.type === 'group') {
      const currentGroup = tutorGroups?.find(({ id }) => id === props?.id);

      if (currentGroup) {
        students = currentGroup?.students;
      }
    }

    return students;
  }
);

// GET THE LEARNING TYPES QUANTITY OF ALL STUDENTS BY CURRENT CLASS
export const studentsByCurrentClassLearningStylesSelector = createSelector(
  [studentsByCurrentClassSelector],
  (students) => students?.map((student) => student?.learningStyles[0]).flat()
);

// GET THE LEARNING TYPES QUANTITY OF ALL STUDENTS BY ENTITY
export const studentsByEntityLearningStylesSelector = createSelector(
  [studentsByEntitySelector],
  (students) => students?.map((student) => student?.learningStyles[0]).flat()
);

// SET THE LEARNING TYPES QUANTITY BY ALL STUDENTS
export const sortedAllStudentsByCurrentClassLearningStylesQuantitySelector =
  createSelector([studentsByCurrentClassLearningStylesSelector], (types) =>
    types?.reduce(
      (acc, type) => {
        if (type) {
          acc[type] = (acc[type] || 0) + 1;
        }

        return acc;
      },
      {},
      null,
      2
    )
  );

// SET THE LEARNING TYPES QUANTITY OF ALL STUDENTS BY ENTITY
export const sortedStudentsByEntityLearningStylesQuantitySelector =
  createSelector([studentsByEntityLearningStylesSelector], (types) =>
    types?.reduce(
      (acc, type) => {
        if (type) {
          acc[type] = (acc[type] || 0) + 1;
        }

        return acc;
      },
      {},
      null,
      2
    )
  );
