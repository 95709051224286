import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import SiderNav from '../../../template/SiderNav';
import { LeftOutlined } from '@ant-design/icons';
import { EditTutorGroupForm } from './EditTutorGroupForm';
import {
  MainLayout,
  SecondaryLayout,
  StyledContainer,
  StyledContent
} from '../../../../styles/StyledComponents';
import { StyledTitle as Title } from '../../../fields/Title';
import useEditTutorGroupPage from './hooks/useEditTutorGroupPage';
import LoadingSpinner from '../../../../lib/components/loadingSpinner';
import { Tabs } from 'antd';
import { ConditionalWithEmpty } from '../../../fields/ConditionalWithEmpty';
import LearningStylesChart from './components/performance-graphs/LearningStylesChart';
import EngagementChart from './components/performance-graphs/EngagementChart';
import StudentTrendChart from './components/performance-graphs/StudentTrendChart';

// COLORS FOR CHART SEGMENTS
const chartBarColors = ['#8053d7', '#FFCC00', '#D7753E', '#5BBCBD', '#EB5757'];

// COLORS FOR PIE CHART LEGEND
const legendColors = [
  'rgba(128, 83, 215, 0.2)',
  'rgba(255, 204, 0, 0.2)',
  'rgba(215, 117, 62, 0.2)',
  'rgba(91, 188, 189, 0.2)',
  'rgba(235, 87, 87, 0.2)'
];

// RENDER COLORS BY CHART LEGEND FIELDS
const getBackgroundColor = (index) =>
  `
    & .antd-pro-charts-pie-legend > li:nth-child(${index + 1}) {
      background-color: ${legendColors[index]}};
    }
  `;

const setBackgrounds = () => {
  let str = '';

  legendColors.forEach((_, index) => (str += getBackgroundColor(index)));

  return str;
};

const StyledEditTutorGroupPageHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 15px;

  & .ant-typography {
    margin-bottom: 3px;
    user-select: none;
  }

  & .active-marker {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #58f467;
  }
`;

const StyledEditTutorGroupPageInnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;

  @media (min-width: 1200px) {
    flex-direction: row;
  }
`;

const StyledChartContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: -12px;

  @media (min-width: 1200px) {
    max-width: 60%;
  }
`;

const StyledEditTutorGroupTabsContainer = styled.div`
  width: 100%;

  & .ant-tabs-nav {
    padding: 0 20px;
  }

  & .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
    padding: 0;
  }

  & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #8053d7;
  }

  & .ant-tabs-tab:hover,
  & .ant-tabs-tab-btn:active {
    color: #8053d7;
  }

  & .ant-tabs-ink-bar {
    background-color: #8053d7;
  }
`;

const StyledEditTutorGroupTabContainer = styled(StyledContainer)`
  width: 100%;
  overflow: auto;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  }

  & .antd-pro-charts-pie-total {
    max-height: max-content;
  }

  & .pie-chart-total {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    user-select: none;
  }

  & .antd-pro-charts-pie-legend {
    margin: 0 50px;

    @media (min-width: 1200px) {
      margin: 0 10px;
    }

    @media (min-width: 1500px) {
      margin: 0 20px;
    }
  }

  & .antd-pro-charts-pie-legend > li {
    padding-left: 10px;
    border-radius: 16px;
    width: calc(100% + 30px);
  }

  ${setBackgrounds()}

  & .ant-divider {
    background-color: #bfbfbf;
  }

  & .antd-pro-charts-pie-legendTitle {
    display: inline-block;
    min-width: 80px;
  }

  & .antd-pro-charts-pie-percent,
  & .antd-pro-charts-pie-value,
  & .antd-pro-charts-pie-legendTitle {
    font-weight: 700;
    color: #3f3356;
  }

  & .antd-pro-charts-pie-value {
    right: -20px;
  }

  & .pie-chart-total__title {
    font-size: 20px;
    line-height: 22px;
  }

  & .pie-chart-total__subtitle {
    font-size: 17px;
    line-height: 22px;
    color: #999999;
  }

  & .pie-chart-total__count {
    font-size: 34px;
    line-height: 41px;
  }
`;

const { TabPane } = Tabs;

const EditTutorGroupPage = () => {
  const {
    user,
    classId = '',
    questionSets = [],
    currentClassStudents = [],
    currentTutorGroup = {},
    studentsWithLearningStyles,
    engagementChartConfig,
    studentTrendChartConfig,
    learningStylesChartConfig,
    isLoading,
    activeTab,
    handleTabChange,
    handleGroupDelete
  } = useEditTutorGroupPage();

  const renderedActiveMarker = currentTutorGroup?.activeSession && (
    <span className='active-marker' />
  );

  return (
    <MainLayout hasSider>
      <SiderNav user={user} />
      <SecondaryLayout>
        <StyledContent>
          <LoadingSpinner spinning={isLoading}>
            <StyledEditTutorGroupPageHeader>
              <Link to={`/teacher/classes/${classId}`}>
                <LeftOutlined style={{ color: '#000000', fontSize: 20 }} />
              </Link>
              <Title level={3} style={{ fontWeight: 'bolder' }}>
                Edit Tutor Group
              </Title>
              {renderedActiveMarker}
            </StyledEditTutorGroupPageHeader>
            <StyledEditTutorGroupPageInnerContainer>
              <EditTutorGroupForm
                classId={classId}
                isLoading={isLoading}
                currentClassStudents={currentClassStudents}
                tutorGroup={currentTutorGroup}
                questionSets={questionSets}
                onGroupDelete={handleGroupDelete}
              />
              <StyledChartContainer>
                {/* GROUP CHART WIT TABS */}
                <StyledEditTutorGroupTabsContainer>
                  <Tabs
                    defaultActiveKey='engagement'
                    value={activeTab}
                    onChange={handleTabChange}
                  >
                    <TabPane tab='Engagement' key='engagement'>
                      <StyledEditTutorGroupTabContainer>
                        {/* ENGAGEMENT CHART */}
                        <ConditionalWithEmpty
                          conditional={
                            engagementChartConfig.data &&
                            engagementChartConfig.data.length > 0
                          }
                          emptyStyles={{ height: 420 }}
                        >
                          <EngagementChart
                            data={engagementChartConfig.data}
                            itemWidth={engagementChartConfig.maxItemWidth}
                            height={engagementChartConfig.height}
                          />
                        </ConditionalWithEmpty>
                      </StyledEditTutorGroupTabContainer>
                    </TabPane>
                    <TabPane tab='Student Trends' key='trends'>
                      <StyledEditTutorGroupTabContainer>
                        {/* GROUP TRENDS CHART */}
                        <ConditionalWithEmpty
                          conditional={
                            studentTrendChartConfig.data &&
                            studentTrendChartConfig.data.length > 0
                          }
                          emptyStyles={{ height: 420 }}
                        >
                          <StudentTrendChart
                            data={studentTrendChartConfig.data}
                            scale={studentTrendChartConfig.scale}
                            chartBarColors={chartBarColors}
                            height={studentTrendChartConfig.height}
                            itemWidth={studentTrendChartConfig.maxItemWidth}
                          />
                        </ConditionalWithEmpty>
                      </StyledEditTutorGroupTabContainer>
                    </TabPane>
                    <TabPane tab='Learning Styles' key='learning'>
                      <StyledEditTutorGroupTabContainer>
                        {/* LEARNING STYLES CHART */}
                        <ConditionalWithEmpty
                          conditional={
                            learningStylesChartConfig.data &&
                            learningStylesChartConfig.data.length > 0
                          }
                          emptyStyles={{ height: 420 }}
                          emptyDescription={
                            learningStylesChartConfig.emptyDescription
                          }
                        >
                          <LearningStylesChart
                            data={learningStylesChartConfig.data}
                            chartBarColors={chartBarColors}
                            currentCount={studentsWithLearningStyles?.length ?? 0}
                            totalCount={currentTutorGroup?.students?.length}
                          />
                        </ConditionalWithEmpty>
                      </StyledEditTutorGroupTabContainer>
                    </TabPane>
                  </Tabs>
                </StyledEditTutorGroupTabsContainer>
              </StyledChartContainer>
            </StyledEditTutorGroupPageInnerContainer>
          </LoadingSpinner>
        </StyledContent>
      </SecondaryLayout>
    </MainLayout>
  );
};

export default EditTutorGroupPage;
