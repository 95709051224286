import React from 'react';
import styled from 'styled-components';
import { LineChart } from 'bizcharts';

const StyledGroupTrendChartContainer = styled.div`
  width: ${({ width }) => (width ? `${width}px` : '100%')};
`;

const GroupTrendChart = ({
  data,
  scale,
  label,
  chartBarColors,
  height = 485,
  padding,
  width
}) => {
  const config = {
    data,
    height,
    autoFit: true,
    xField: 'name',
    yField: 'value',
    seriesField: 'group',
    smooth: true,
    padding,
    label,
    scale,
    animation: {
      appear: {
        animation: 'path-in',
        duration: 1000
      }
    },
    color: chartBarColors
  };

  return (
    <StyledGroupTrendChartContainer width={width}>
      <LineChart {...config} />
    </StyledGroupTrendChartContainer>
  );
};

export default GroupTrendChart;
