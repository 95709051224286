import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { handleQuestionSetsDashboardRequest } from '../../../../../modules/question-set/redux';
import { classesSelector } from '../../../../../modules/classes/redux/selectors';
import { questionSetsSelector } from '../../../../../modules/question-set/redux/selectors';
import {
  handleDeleteTutorGroupRequest,
  handleTutorGroupRequest
} from '../../../../../modules/classes/redux/tutor-groups';
import { handleClassRequest } from '../../../../../modules/classes/redux';
import { userSelector } from '../../../../../modules/auth/redux/selectors';

const useEditTutorGroupPage = () => {
  const [activeTab, setActiveTab] = useState('engagement');

  const { classId, tutorGroupId } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    if (classId) {
      dispatch(handleClassRequest(classId));
    }

    if (classId && tutorGroupId) {
      dispatch(handleTutorGroupRequest({ classId, tutorGroupId }));
    }

    dispatch(handleQuestionSetsDashboardRequest());
  }, [classId, dispatch, tutorGroupId]);

  const {
    isLoading: classLoading,
    _class: currentClass,
    tutorGroup: currentTutorGroup
  } = useSelector(classesSelector);

  const { isLoading: questionSetsLoading, data: questionSets } =
    useSelector(questionSetsSelector);

  const user = useSelector(userSelector);

  // TAB ONCHANGE HANDLER
  const handleTabChange = useCallback((key) => setActiveTab(key), []);

  // DELETE THE TUTOR GROUP
  const handleGroupDelete = useCallback(
    () => dispatch(handleDeleteTutorGroupRequest({ classId, tutorGroupId })),
    [dispatch, tutorGroupId]
  );

  // LOADING STATE
  const isLoading = useMemo(
    () => classLoading || questionSetsLoading,
    [classLoading, questionSetsLoading]
  );

  // ENGAGEMENT TAB

  // STUDENTS PERFORMANCE BY CURRENT GROUP
  const studentsPerformanceDataByCurrentGroup = useMemo(() => {
    const activeSession = currentTutorGroup?.tutorSessions?.filter(
      (session) => session?.status === 'active'
    )[0];

    const session = activeSession || currentTutorGroup?.tutorSessions[0];

    return currentTutorGroup?.students?.map((student) => {
      const studentPerformance = session?.studentPerformance
        .map((data) => {
          if (data[student._id]) {
            return data[student._id];
          }
        })
        .filter((item) => item !== undefined)[0];
      return {
        name: `${student?.profile?.firstName} ${student?.profile?.lastName}`,
        performance: studentPerformance
      };
    });
  }, [currentTutorGroup?.students, currentTutorGroup?.tutorSessions]);

  // ENGAGEMENT CHART DATA BY CURRENT GROUP
  const engagementChartData = useMemo(() => {
    return studentsPerformanceDataByCurrentGroup
      ?.map((student) => {
        const engagementScore =
          parseFloat(student?.performance?.totalEngagementScore) || 0;

        return [
          {
            name: student?.name,
            type: 'correct',
            value: +(engagementScore * 100).toFixed(1)
          },
          {
            name: student?.name,
            type: 'missed',
            value: +((1 - engagementScore) * 100).toFixed(1)
          }
        ];
      })
      .flat();
  }, [studentsPerformanceDataByCurrentGroup]);

  // MAX ENGAGEMENT CHART ITEM WIDTH
  const maxEngagementChartItemWidth = useMemo(() => {
    const itemNames = [
      ...new Set(engagementChartData?.map(({ name }) => name))
    ];

    const itemWidths = itemNames.map((name) => name?.length * 5);

    const maxItemWidth = Math.max.apply(null, itemWidths);

    return maxItemWidth;
  }, [engagementChartData]);

  // ENGAGEMENT CHART HEIGHT
  const engagementChartHeight = useMemo(
    () =>
      engagementChartData?.length * 20 + 200 > 485
        ? engagementChartData?.length * 20 + 100
        : 485,
    [engagementChartData?.length]
  );

  // CONFIG FOR ENGAGEMENT CHART
  const engagementChartConfig = useMemo(
    () => ({
      data: engagementChartData,
      maxItemWidth: maxEngagementChartItemWidth,
      height: engagementChartHeight
    }),
    [engagementChartData, engagementChartHeight, maxEngagementChartItemWidth]
  );

  // STUDENT TREND TAB

  // DATA FOR STUDENT TREND CHART
  const studentTrendChartData = useMemo(
    () =>
      studentsPerformanceDataByCurrentGroup?.map((student) => ({
        name: student?.name,
        score: +student?.performance?.totalScore
      })),
    [studentsPerformanceDataByCurrentGroup]
  );

  // MAX VALUE FOR STUDENT TREND CHART AXIS
  const maxScaleValue = useMemo(() => {
    const maxScores = currentTutorGroup?.tutorSessions?.map(
      (session) => session?.maxPossibleIndividualScore
    );

    return Math.max.apply(null, maxScores);
  }, [currentTutorGroup?.tutorSessions]);

  // AXIS FOR STUDENT TREND CHART
  const scale = useMemo(
    () => ({
      score: {
        min: 0,
        max: isFinite(maxScaleValue) ? maxScaleValue : undefined
      }
    }),
    [maxScaleValue]
  );

  // MAX STUDENT TREND CHART ITEM WIDTH
  const studentTrendChartItemWidth = useMemo(() => {
    const itemWidths = studentTrendChartData?.map(
      ({ name }) => name?.length * 7
    );

    const maxItemWidth = Math.max.apply(null, itemWidths);

    return maxItemWidth;
  }, [studentTrendChartData]);

  // MAX STUDENT TREND CHART HEIGHT
  const studentTrendChartHeight = useMemo(
    () =>
      studentTrendChartData?.length * 25 + 200 > 485
        ? studentTrendChartData?.length * 25 + 200
        : 485,
    [studentTrendChartData]
  );

  // CONFIG FOR STUDENT TREND CHART
  const studentTrendChartConfig = useMemo(
    () => ({
      scale,
      data: studentTrendChartData,
      maxItemWidth: studentTrendChartItemWidth,
      height: studentTrendChartHeight
    }),
    [
      scale,
      studentTrendChartData,
      studentTrendChartHeight,
      studentTrendChartItemWidth
    ]
  );

  // LEARNING STYLES TAB

  // STUDENTS WITH SELECTED LEARNING TYPES
  const studentsWithLearningStyles = currentTutorGroup?.students?.filter(
    (student) => student?.learningStyles?.length > 0
  );

  // SORTED QUANTITY OF THE LEARNING STYLES
  const sortedLearningTypesQuantity = useMemo(
    () =>
      currentTutorGroup?.students
        ?.map((student) => student?.learningStyles[0])
        .flat()
        ?.reduce(
          (acc, type) => {
            if (type) {
              acc[type] = (acc[type] || 0) + 1;
            }

            return acc;
          },
          {},
          null,
          2
        ),
    [currentTutorGroup?.students]
  );

  // DATA FOR LEARNING TYPES CHART BY STUDENTS
  const learningStylesChartData = Object.entries(
    sortedLearningTypesQuantity ?? {}
  ).map(([key, value]) => ({ x: key.toUpperCase(), y: value }));

  // CONFIG FOR LEARNING STYLES CHART
  const learningStylesChartConfig = useMemo(
    () => ({
      data: learningStylesChartData ?? [],
      emptyDescription:
        'Sorry, there are no students with chosen learning style'
    }),
    [learningStylesChartData]
  );

  return {
    user,
    activeTab,
    classId,
    currentClass,
    questionSets,
    currentClassStudents: currentClass?.students,
    currentTutorGroup,
    isLoading,
    engagementChartConfig,
    studentTrendChartConfig,
    learningStylesChartConfig,
    studentsWithLearningStyles,
    handleGroupDelete,
    handleTabChange
  };
};

export default useEditTutorGroupPage;
