import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch } from '../../../store';
import { handleDashboardRequest } from '../../../modules/dashboard/redux';
import SiderNav from '../../template/SiderNav';
import { PerformanceSection } from './components/PerformanceSection';
import { RecentSection } from './components/RecentSection';

import {
  MainLayout,
  SecondaryLayout,
  StyledContent
} from '../../../styles/StyledComponents';
import { userSelector } from '../../../modules/auth/redux/selectors';
import {
  dashboardDataSelector,
  dashboardSelector
} from '../../../modules/dashboard/redux/selectors';
import LoadingSpinner from '../../../lib/components/loadingSpinner';

class DashboardPage extends Component {
  componentDidMount () {
    dispatch(handleDashboardRequest());
  }

  render () {
    const {
      isLoading,
      user,
      dashboard: { myQuestionSets, myClasses }
    } = this.props;

    return (
      <MainLayout hasSider>
        {/* SIDER NAV */}
        <SiderNav user={user} />
        <SecondaryLayout>
          <StyledContent>
            <LoadingSpinner spinning={isLoading} fullscreen>
              {/* PERFORMANCE SECTION */}
              <PerformanceSection />
              {/* RECENT SECTION */}
              <RecentSection
                myQuestionSets={myQuestionSets}
                myClasses={myClasses}
              />
              {/* ICON SECTION */}
              {/* <IconSection /> */}
            </LoadingSpinner>
          </StyledContent>
        </SecondaryLayout>
      </MainLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: userSelector(state),
    dashboard: dashboardDataSelector(state),
    isLoading: dashboardSelector(state).isLoading
  };
};

export default connect(mapStateToProps, {})(DashboardPage);
