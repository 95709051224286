import { all, call, put } from 'redux-saga/effects';

import request from '../../../lib/request';
import { setAuthToken } from '../../auth/redux';
import { push } from 'connected-react-router';
import { FORM_ERROR } from 'final-form';
import { parseFormErrors } from '../../../utils/parseFormErrors';
import { CREATE_QUESTION_SET_SUCCESS } from '../../question-set/redux';
import { ROLE_ADMIN } from '../../../utils/roles';

export const ACCOUNT_REQUEST = 'org/accounts/ACCOUNT_REQUEST';
export const ACCOUNT_SUCCESS = 'org/accounts/ACCOUNT_SUCCESS';
export const ACCOUNT_FAILURE = 'org/accounts/ACCOUNT_FAILURE';

export const ACCOUNT_BILLING_INFO_REQUEST =
  'org/accounts/ACCOUNT_BILLING_INFO_REQUEST';
export const ACCOUNT_BILLING_INFO_SUCCESS =
  'org/accounts/ACCOUNT_BILLING_INFO_SUCCESS';
export const ACCOUNT_BILLING_INFO_FAILURE =
  'org/accounts/ACCOUNT_BILLING_INFO_FAILURE';

export const ACCOUNT_BILLING_HISTORY_REQUEST =
  'org/accounts/ACCOUNT_BILLING_HISTORY_REQUEST';
export const ACCOUNT_BILLING_HISTORY_SUCCESS =
  'org/accounts/ACCOUNT_BILLING_HISTORY_SUCCESS';
export const ACCOUNT_BILLING_HISTORY_FAILURE =
  'org/accounts/ACCOUNT_BILLING_HISTORY_FAILURE';

export const ACCOUNT_PLAN_SWITCH_REQUEST =
  'org/accounts/ACCOUNT_PLAN_SWITCH_REQUEST';
export const ACCOUNT_PLAN_SWITCH_SUCCESS =
  'org/accounts/ACCOUNT_PLAN_SWITCH_SUCCESS';
export const ACCOUNT_PLAN_SWITCH_FAILURE =
  'org/accounts/ACCOUNT_PLAN_SWITCH_FAILURE';

export const PRODUCTS_PRICES_REQUEST = 'org/accounts/PRODUCTS_PRICES_REQUEST';
export const PRODUCTS_PRICES_SUCCESS = 'org/accounts/PRODUCTS_PRICES_SUCCESS';
export const PRODUCTS_PRICES_FAILURE = 'org/accounts/PRODUCTS_PRICES_FAILURE';

export const SET_PRODUCT_PRICE_ON_ACCOUNT_REQUEST =
  'org/accounts/SET_PRODUCT_PRICE_ON_ACCOUNT_REQUEST';
export const SET_PRODUCT_PRICE_ON_ACCOUNT_SUCCESS =
  'org/accounts/SET_PRODUCT_PRICE_ON_ACCOUNT_SUCCESS';
export const SET_PRODUCT_PRICE_ON_ACCOUNT_FAILURE =
  'org/accounts/SET_PRODUCT_PRICE_ON_ACCOUNT_FAILURE';

export const CREATE_ORG_ACCOUNT_REQUEST =
  'org/accounts/CREATE_ORG_ACCOUNT_REQUEST';
export const CREATE_ORG_ACCOUNT_SUCCESS =
  'org/accounts/CREATE_ORG_ACCOUNT_SUCCESS';
export const CREATE_ORG_ACCOUNT_FAILURE =
  'org/accounts/CREATE_ORG_ACCOUNT_FAILURE';

export const CREATE_SUBSCRIPTION_REQUEST =
  'org/accounts/CREATE_SUBSCRIPTION_REQUEST';
export const CREATE_SUBSCRIPTION_SUCCESS =
  'org/accounts/CREATE_SUBSCRIPTION_SUCCESS';
export const CREATE_SUBSCRIPTION_FAILURE =
  'org/accounts/CREATE_SUBSCRIPTION_FAILURE';

export const ADD_ORG_MEMBERS_REQUEST = 'org/accounts/ADD_ORG_MEMBERS_REQUEST';
export const ADD_ORG_MEMBERS_SUCCESS = 'org/accounts/ADD_ORG_MEMBERS_SUCCESS';
export const ADD_ORG_MEMBERS_FAILURE = 'org/accounts/ADD_ORG_MEMBERS_FAILURE';

export const CREATE_STUDENT_WITH_CLASS_TOKEN_REQUEST =
  'accounts/CREATE_STUDENT_WITH_CLASS_TOKEN_REQUEST';
export const CREATE_STUDENT_WITH_CLASS_TOKEN_SUCCESS =
  'accounts/CREATE_STUDENT_WITH_CLASS_TOKEN_SUCCESS';
export const CREATE_STUDENT_WITH_CLASS_TOKEN_FAILURE =
  'accounts/CREATE_STUDENT_WITH_CLASS_TOKEN_FAILURE';

export const CREATE_STUDENT_WITH_ORG_TOKEN_REQUEST =
  'accounts/CREATE_STUDENT_WITH_ORG_TOKEN_REQUEST';
export const CREATE_STUDENT_WITH_ORG_TOKEN_SUCCESS =
  'accounts/CREATE_STUDENT_WITH_ORG_TOKEN_SUCCESS';
export const CREATE_STUDENT_WITH_ORG_TOKEN_FAILURE =
  'accounts/CREATE_STUDENT_WITH_ORG_TOKEN_FAILURE';

export const handleCreateStudentWithTokenRequest = (payload) => ({
  type: CREATE_STUDENT_WITH_CLASS_TOKEN_REQUEST,
  payload
});

export const handleCreateStudentWithTokenSuccess = (payload) => ({
  type: CREATE_STUDENT_WITH_CLASS_TOKEN_SUCCESS,
  payload
});

export const handleCreateStudentWithTokenFailure = (payload) => ({
  type: CREATE_STUDENT_WITH_CLASS_TOKEN_FAILURE,
  payload
});

export const handleCreateStudentWithOrgTokenRequest = (payload) => ({
  type: CREATE_STUDENT_WITH_ORG_TOKEN_REQUEST,
  payload
});

export const handleCreateStudentWithOrgTokenSuccess = (payload) => ({
  type: CREATE_STUDENT_WITH_ORG_TOKEN_SUCCESS,
  payload
});

export const handleCreateStudentWithOrgTokenFailure = (payload) => ({
  type: CREATE_STUDENT_WITH_ORG_TOKEN_FAILURE,
  payload
});

// Request Account information
export const handleAccountRequest = (payload) => ({
  type: ACCOUNT_REQUEST,
  payload
});

export const handleAccountSuccess = (payload) => ({
  type: ACCOUNT_SUCCESS,
  payload
});

export const handleAccountFailure = (payload) => ({
  type: ACCOUNT_FAILURE,
  payload
});

// Request Account billing information
export const handleAccountBillingInfoRequest = (payload) => ({
  type: ACCOUNT_BILLING_INFO_REQUEST,
  payload
});

export const handleAccountBillingInfoSuccess = (payload) => ({
  type: ACCOUNT_BILLING_INFO_SUCCESS,
  payload
});

export const handleAccountBillingInfoFailure = (payload) => ({
  type: ACCOUNT_BILLING_INFO_FAILURE,
  payload
});

// Request Account billing history
export const handleAccountBillingHistoryRequest = (payload) => ({
  type: ACCOUNT_BILLING_HISTORY_REQUEST,
  payload
});

export const handleAccountBillingHistorySuccess = (payload) => ({
  type: ACCOUNT_BILLING_HISTORY_SUCCESS,
  payload
});

export const handleAccountBillingHistoryFailure = (payload) => ({
  type: ACCOUNT_BILLING_HISTORY_FAILURE,
  payload
});

// Request Account billing switch req
export const handleAccountPlanSwitchRequest = (payload) => ({
  type: ACCOUNT_PLAN_SWITCH_REQUEST,
  payload
});

export const handleAccountPlanSwitchSuccess = (payload) => ({
  type: ACCOUNT_PLAN_SWITCH_SUCCESS,
  payload
});

export const handleAccountPlanSwitchFailure = (payload) => ({
  type: ACCOUNT_PLAN_SWITCH_FAILURE,
  payload
});

// Request Products and Prices
export const handleProductPricesRequest = (payload) => ({
  type: PRODUCTS_PRICES_REQUEST,
  payload
});

export const handleProductPricesSuccess = (payload) => ({
  type: PRODUCTS_PRICES_SUCCESS,
  payload
});

export const handleProductPricesFailure = (payload) => ({
  type: PRODUCTS_PRICES_FAILURE,
  payload
});

// Set Product and Price on an Account
export const handleSetProductPriceOnAccountRequest = (payload) => ({
  type: SET_PRODUCT_PRICE_ON_ACCOUNT_REQUEST,
  payload
});

export const handleSetProductPriceOnAccountSuccess = (payload) => ({
  type: SET_PRODUCT_PRICE_ON_ACCOUNT_SUCCESS,
  payload
});

export const handleSetProductPriceOnAccountFailure = (payload) => ({
  type: SET_PRODUCT_PRICE_ON_ACCOUNT_FAILURE,
  payload
});

// Create New Account and Parent Org if applicable
export const handleCreateOrgAccountRequest = (payload) => ({
  type: CREATE_ORG_ACCOUNT_REQUEST,
  payload
});

export const handleCreateOrgAccountSuccess = (payload) => ({
  type: CREATE_ORG_ACCOUNT_SUCCESS,
  payload
});

export const handleCreateOrgAccountFailure = (payload) => ({
  type: CREATE_ORG_ACCOUNT_FAILURE,
  payload
});

// Create new subscription request on account
export const handleCreateSubscriptionRequest = (payload) => ({
  type: CREATE_SUBSCRIPTION_REQUEST,
  payload
});

export const handleCreateSubscriptionSuccess = (payload) => ({
  type: CREATE_SUBSCRIPTION_SUCCESS,
  payload
});

export const handleCreateSubscriptionFailure = (payload) => ({
  type: CREATE_SUBSCRIPTION_FAILURE,
  payload
});

// Request Account information
export const handleAddOrgMembersRequest = (payload) => ({
  type: ADD_ORG_MEMBERS_REQUEST,
  payload
});

export const handleAddOrgMembersSuccess = (payload) => ({
  type: ADD_ORG_MEMBERS_SUCCESS,
  payload
});

export const handleAddOrgMembersFailure = (payload) => ({
  type: ADD_ORG_MEMBERS_FAILURE,
  payload
});

export function * onHandleAccountRequest () {
  console.log('#onHandleAccountRequest');

  try {
    console.log('#onHandleAccountRequest, try block');
    const response = yield call(request.get, '/accounts');

    console.log('#onHandleAccountRequest, response: ', response);

    const {
      data: { account }
    } = response;

    return yield all([put(handleAccountSuccess(account))]);
  } catch (err) {
    console.error('Error, #onHandleAccountRequest, err: ', err);
    return yield all([put(handleAccountFailure(err))]);
  }
}

export function * onHandleAccountBillingInfoReq () {
  console.log('#onHandleAccountBillingInfoReq');

  try {
    console.log('#onHandleAccountBillingInfoReq, try block');
    const response = yield call(request.get, '/accounts/billingInfo');

    console.log('#onHandleAccountBillingInfoReq, response: ', response);

    return yield all([
      put(
        handleAccountBillingInfoSuccess({
          complementaryPrice: response.data.complementaryPrice,
          accountBillingInfo: response.data
        })
      )
    ]);
  } catch (err) {
    console.error('Error, #onHandleAccountBillingInfoReq, err: ', err);
    return yield all([put(handleAccountBillingInfoFailure(err))]);
  }
}

export function * onHandleAccountBillingHistoryReq ({ payload }) {
  console.log('#onHandleAccountBillingHistoryReq');

  try {
    console.log('#onHandleAccountBillingHistoryReq, try block');
    const response = yield call(
      request.get,
      `/accounts/billingHistory?starting_after=${payload}`
    );

    console.log('#onHandleAccountBillingHistoryReq, response: ', response);

    return yield all([
      put(
        handleAccountBillingHistorySuccess({
          invoices: response.data.invoices
        })
      )
    ]);
  } catch (err) {
    console.error('Error, #onHandleAccountBillingHistoryReq, err: ', err);
    return yield all([put(handleAccountBillingHistoryFailure(err))]);
  }
}

export function * onHandleAccountPlanSwitchReq ({ payload }) {
  console.log('#onHandleAccountPlanSwitchReq');

  try {
    console.log('#onHandleAccountPlanSwitchReq, try block');
    const response = yield call(request.post, '/accounts/updateSubscription', { priceId: payload.priceId });

    console.log('#onHandleAccountPlanSwitchReq, response: ', response);

    let redirectURL = '/teacher/billing';

    return yield all([
      put(handleAccountPlanSwitchSuccess({ complementaryPrice: response.data.complementaryPrice })),
      put(push(redirectURL))
    ]);
  } catch (err) {
    console.error('Error, #onHandleAccountPlanSwitchReq, err: ', err);
    return yield all([put(handleAccountPlanSwitchFailure(err))]);
  }
}

// TODO: Create a choose plan generator function with req, success, failure action types and action creators
// TODO: Create a set up org generator function with req, success, failure action types and action creators

export function * onHandleAccountProductPricesRequest () {
  console.log('#onHandleAccountProductPricesRequest');

  try {
    console.log('#onHandleAccountProductPricesRequest, try block');
    const response = yield call(request.get, '/accounts/plans/prices');
    console.log('#onHandleAccountProductPricesRequest, response: ', response);

    const {
      data: { prices }
    } = response;

    return yield all([put(handleProductPricesSuccess(prices))]);
  } catch (err) {
    console.error('Error, #onHandleAccountProductPricesRequest, err: ', err);
    // TODO: Add Notifications to let user know dashboard failed to get loaded and further instructions
    return yield all([put(handleProductPricesFailure(err))]);
  }
}

export function * onHandleCreateStudentWithToken ({ payload }) {
  console.log('#onHandleCreateStudentWithToken');

  try {
    console.log('#onHandleCreateStudentWithToken, try block');

    const response = yield call(
      request.post,
      '/accounts/create/student/withClassRegistrationToken',
      payload
    );

    return yield all([put(handleCreateStudentWithTokenSuccess(response.data))]);
  } catch (err) {
    console.error('Error, #onHandleCreateStudentWithToken, err: ', err);

    const { status, message, errors } = err.response.data;
    let errorAction;
    if (status === 409) {
      errorAction = put({
        type: CREATE_STUDENT_WITH_CLASS_TOKEN_FAILURE,
        payload: {
          [FORM_ERROR]: 'Email already exist',
          ...parseFormErrors(errors)
        }
      });
    }

    return yield all([
      errorAction,
      put(handleCreateStudentWithTokenFailure(message))
    ]);
  }
}

export function * onHandleCreateStudentWithOrgToken ({ payload }) {
  console.log('#onHandleCreateStudentWithOrgToken');

  try {
    console.log('#onHandleCreateStudentWithOrgToken, try block');

    const response = yield call(
      request.post,
      '/accounts/create/student/withOrgToken',
      { ...payload, organizationToken: payload.registrationToken }
    );

    return yield all([
      put(handleCreateStudentWithOrgTokenSuccess(response.data))
    ]);
  } catch (err) {
    console.error('Error, #onHandleCreateStudentWithOrgToken, err: ', err);

    const { status, message, errors } = err.response.data;
    let errorAction;
    if (status === 409) {
      errorAction = put({
        type: CREATE_STUDENT_WITH_ORG_TOKEN_FAILURE,
        payload: {
          [FORM_ERROR]: 'Email already exist',
          ...parseFormErrors(errors)
        }
      });
    }

    return yield all([
      errorAction,
      put(handleCreateStudentWithOrgTokenFailure(message))
    ]);
  }
}
export function * onHandleSetProductPriceOnAccountRequest ({
  payload: { priceId, productId, productName }
}) {
  console.log(`#onHandleSetProductPriceOnAccountRequest priceId: ${priceId} productId: ${productId} productName: ${productName}`);

  const productAndPrice = {
    priceId: priceId,
    productId: productId,
    productName: productName
  };

  try {
    console.log('#onHandleSetProductPriceOnAccountRequest, try block, productAndPrice: ', productAndPrice);
    const response = yield call(request.post, '/accounts/plans', productAndPrice);
    console.log('#onHandleSetProductPriceOnAccountRequest, response: ', response);

    const { data: { user, account } } = response;

    // if the admin / owner or account isOnboarded == true then we will redirect them to the billing page
    // otherwise we will send them to the next step, the org setup, then billing

    console.log('#onHandleSetProductPriceOnAccountRequest, user: ', user);
    console.log('#onHandleSetProductPriceOnAccountRequest, account: ', account);

    return yield all([
      put(handleSetProductPriceOnAccountSuccess(account)),
      put(push('/teacher/organizations/setup'))
    ]);
  } catch (err) {
    console.error(
      'Error, #onHandleSetProductPriceOnAccountRequest, err: ',
      err
    );
    // TODO: Add Notifications to let user know dashboard failed to get loaded and further instructions
    const { status, message, errors } = err.response.data;

    let errorAction;
    if (message === 'Network Error') {
      errorAction = put({
        type: PRODUCTS_PRICES_SUCCESS,
        payload: {
          [FORM_ERROR]:
            'Network Error? Check your connection and please try again later...'
        }
      });
    }

    if (status === 409) {
      errorAction = put({
        type: PRODUCTS_PRICES_SUCCESS,
        payload: {
          [FORM_ERROR]: 'Already exists...',
          ...parseFormErrors(errors)
        }
      });
    }

    if (status === 422 || status === 400) {
      errorAction = put({
        type: PRODUCTS_PRICES_SUCCESS,
        payload: {
          [FORM_ERROR]: message,
          ...parseFormErrors(errors)
        }
      });
    }

    if (err.response.status === 500) {
      errorAction = put({
        type: PRODUCTS_PRICES_SUCCESS,
        payload: {
          [FORM_ERROR]: 'Its not you, its us....... Please try again later.'
        }
      });
    }

    return yield all([errorAction, put(handleProductPricesFailure(err))]);
  }
}

export function * onHandleCreateOrgAccountRequest ({
  payload: { accountName, accountType, parentOrg }
}) {
  console.log(`#onHandleCreateOrgAccountRequest, accountName, ${accountName}, accountType: ${accountType}, parentOrg: ${parentOrg}`);

  const orgAccountDetails = {
    orgAccountName: accountName,
    orgAccountType: accountType,
    ...(accountType === 'business-institution' && { parentOrgName: parentOrg })
  };
  console.log('#onHandleCreateOrgAccountRequest, orgAccountDetails: ');
  console.log(orgAccountDetails);

  try {
    console.log('#onHandleCreateOrgAccountRequest, try block');
    const response = yield call(request.post, '/accounts/setup', orgAccountDetails);
    console.log('#onHandleCreateOrgAccountRequest, response: ', response);

    const {
      data: { account }
    } = response;

    // TODO: Need to set a conditional check on the account info to decide where to send user next
    return yield all([
      put(handleCreateOrgAccountSuccess(account)),
      put(push('/teacher/organizations/payment'))
    ]);
  } catch (err) {
    const { status, message, errors } = err.response.data;

    let errorAction;
    if (message === 'Network Error') {
      errorAction = put({
        type: CREATE_ORG_ACCOUNT_FAILURE,
        payload: {
          [FORM_ERROR]:
            'Network Error? Check your connection and please try again later...'
        }
      });
    }

    if (status === 409) {
      errorAction = put({
        type: CREATE_ORG_ACCOUNT_FAILURE,
        payload: {
          [FORM_ERROR]: message,
          ...parseFormErrors(errors)
        }
      });
    }

    if (status === 422 || status === 400) {
      errorAction = put({
        type: CREATE_ORG_ACCOUNT_FAILURE,
        payload: {
          [FORM_ERROR]: message,
          ...parseFormErrors(errors)
        }
      });
    }

    if (err.response.status === 500) {
      errorAction = put({
        type: CREATE_ORG_ACCOUNT_FAILURE,
        payload: {
          [FORM_ERROR]: 'Its not you, its us....... Please try again later.'
        }
      });
    }

    return yield all([errorAction, put(handleProductPricesFailure(err))]);
  }
}

export function *onHandleCreateSubscriptionRequest ({ payload: {firstName, lastName, billingEmail, promoCode, cardElement, stripe} }) {
  console.log('#onHandleCreateSubscriptionRequest');

  const subscriptionData = {
    billingEmail: billingEmail,
    firstName: firstName,
    lastName: lastName,
    ...(promoCode && { 'promoCode': promoCode })
  };

  console.log('#onHandleCreateSubscriptionRequest, subscriptionData: ');
  console.log(subscriptionData);

  console.log('#onHandleCreateSubscriptionRequest, stripe: ');
  console.log(stripe);

  console.log('#onHandleCreateSubscriptionRequest, cardElement: ');
  console.log(cardElement);
  try {
    console.log('#onHandleCreateSubscriptionRequest, try block');
    const response = yield call(request.post, '/accounts/subscriptions', subscriptionData);
    console.log('#onHandleCreateSubscriptionRequest, response: ', response);

    const { data: { account, client_secret, updatedPaymentMethod } } = response;

    console.log('#onHandleCreateSubscriptionRequest, clientSecret: ', client_secret);

    const stripeData = {
      payment_method: {
        type: 'card',
        card: cardElement,
        billing_details: {
          name: `${firstName} ${lastName}`,
          email: billingEmail
        }
      }
    };

    // Use card Element to tokenize payment details
    // let { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, );
    if (promoCode) {
      const { error, setupIntent } = yield call(stripe.confirmCardSetup, client_secret, stripeData);
      
      if (error) {
        console.error('#onHandleCreateSubscriptionRequest, throwing error from Stripe, error: ', error);
        return new Error(error.message);
      }
      
      // console.log('#onHandleCreateSubscriptionRequest, setupIntent: ', setupIntent);
    } else {
      const { error, paymentIntent } = yield call(stripe.confirmCardPayment, client_secret, stripeData);
      
      if (error) {
        console.error('#onHandleCreateSubscriptionRequest, throwing error from Stripe, error: ', error);
        return new Error(error.message);
      }
      
      // console.log('#onHandleCreateSubscriptionRequest, paymentIntent: ', paymentIntent);
    }
    
    const addDefaultPaymentMethodPaymentMethod = yield call(request.post, '/accounts/add-default-payment-method');
    console.log('#onHandleCreateSubscriptionRequest, updatePaymentMethod: ', addDefaultPaymentMethodPaymentMethod)

    const redirectUrl = updatedPaymentMethod ? '/teacher/billing':
      account.subscriptionPlan === 'basic'
        ? '/teacher/on-boarding/0'
        : '/teacher/organizations/members';

    // TODO: Need to set a conditional check on the account info to decide where to send user next
    return yield all([
      put(handleCreateSubscriptionSuccess(account)),
      put(push(redirectUrl))
    ]);
  } catch (err) {
    console.error('Error, #onHandleCreateSubscriptionRequest, err: ', err);
    const { status, message, errors } = err.response.data;

    let errorAction;
    if (message === 'Network Error') {
      errorAction = put({
        type: CREATE_SUBSCRIPTION_FAILURE,
        payload: {
          [FORM_ERROR]:
            'Network Error? Check your connection and please try again later...'
        }
      });
    }

    if (status === 409) {
      errorAction = put({
        type: CREATE_SUBSCRIPTION_SUCCESS,
        payload: {
          [FORM_ERROR]: 'Already exists...',
          ...parseFormErrors(errors)
        }
      });
    }

    if (status === 422 || status === 400) {
      errorAction = put({
        type: CREATE_SUBSCRIPTION_SUCCESS,
        payload: {
          [FORM_ERROR]: message,
          ...parseFormErrors(errors)
        }
      });
    }

    if (err.response.status === 500) {
      errorAction = put({
        type: CREATE_SUBSCRIPTION_FAILURE,
        payload: {
          [FORM_ERROR]: 'Its not you, its us....... Please try again later.'
        }
      });
    }

    return yield all([errorAction, put(handleCreateSubscriptionFailure(err))]);
  }
}

export function * onHandleAddOrgMembersRequest ({ payload }) {
  console.log('#onHandleAddOrgMembersRequest, payload,');
  console.log(payload);

  try {
    console.log('#onHandleAddOrgMembersRequest, try block');
    const response = yield call(
      request.post,
      '/accounts/create/members',
      payload
    );
    console.log('#onHandleAddOrgMembersRequest, response: ', response);

    const {
      data: { account }
    } = response;

    return yield all([
      put(handleAddOrgMembersSuccess(account)),
      put(push('/admin/dashboard'))
    ]);
  } catch (err) {
    console.error('Error, #onHandleAddOrgMembersRequest, err: ', err);
    const { status, message, errors } = err.response.data;

    let errorAction;
    if (message === 'Network Error') {
      errorAction = put({
        type: ADD_ORG_MEMBERS_SUCCESS,
        payload: {
          [FORM_ERROR]:
            'Network Error? Check your connection and please try again later...'
        }
      });
    }

    if (status === 409) {
      errorAction = put({
        type: ADD_ORG_MEMBERS_SUCCESS,
        payload: {
          [FORM_ERROR]: 'Already exists...',
          ...parseFormErrors(errors)
        }
      });
    }

    if (status === 422 || status === 400) {
      errorAction = put({
        type: ADD_ORG_MEMBERS_SUCCESS,
        payload: {
          [FORM_ERROR]: message,
          ...parseFormErrors(errors)
        }
      });
    }

    if (err.response.status === 500) {
      errorAction = put({
        type: ADD_ORG_MEMBERS_SUCCESS,
        payload: {
          [FORM_ERROR]: 'Its not you, its us....... Please try again later.'
        }
      });
    }

    return yield all([errorAction, put(handleAddOrgMembersFailure(err))]);
  }
}

const INITIAL_STATE = {
  isLoading: false,
  error: null,
  data: {}
};

export default function account (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ACCOUNT_REQUEST:
    case ADD_ORG_MEMBERS_REQUEST:
    case PRODUCTS_PRICES_REQUEST:
    case CREATE_ORG_ACCOUNT_REQUEST:
    case CREATE_SUBSCRIPTION_REQUEST:
    case SET_PRODUCT_PRICE_ON_ACCOUNT_REQUEST:
    case CREATE_STUDENT_WITH_ORG_TOKEN_REQUEST:
    case CREATE_STUDENT_WITH_CLASS_TOKEN_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case PRODUCTS_PRICES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        prices: action.payload
      };
    case ACCOUNT_SUCCESS:
    case ADD_ORG_MEMBERS_SUCCESS:
    case CREATE_ORG_ACCOUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload
      };
    case ACCOUNT_BILLING_HISTORY_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case ACCOUNT_BILLING_HISTORY_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    }
    case ACCOUNT_BILLING_HISTORY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        invoices: action.payload.invoices
      };
    }
    case ACCOUNT_BILLING_INFO_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        accountBillingInfo: action.payload.accountBillingInfo,
        // we only need to update complementaryPrice here if it's null
        complementaryPrice: state.complementaryPrice
          ? state.complementaryPrice
          : action.payload.complementaryPrice
      };
    }
    case ACCOUNT_PLAN_SWITCH_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case ACCOUNT_PLAN_SWITCH_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        complementaryPrice: action.payload.complementaryPrice
      };
    }
    case ACCOUNT_PLAN_SWITCH_FAILURE:
    case ACCOUNT_BILLING_INFO_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    }
    case ACCOUNT_BILLING_INFO_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case CREATE_STUDENT_WITH_ORG_TOKEN_SUCCESS:
    case CREATE_STUDENT_WITH_CLASS_TOKEN_SUCCESS:
      return {
        ...state,
        registeredStudent: action.payload.student,
        isLoading: false
      };
    case CREATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload
      };
    case ACCOUNT_FAILURE:
    case PRODUCTS_PRICES_FAILURE:
    case ADD_ORG_MEMBERS_FAILURE:
    case CREATE_ORG_ACCOUNT_FAILURE:
    case CREATE_SUBSCRIPTION_FAILURE:
    case SET_PRODUCT_PRICE_ON_ACCOUNT_FAILURE:
    case CREATE_STUDENT_WITH_ORG_TOKEN_FAILURE:
    case CREATE_STUDENT_WITH_CLASS_TOKEN_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
}
