import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { LeftOutlined } from '@ant-design/icons';
import SiderNav from '../../../template/SiderNav';
import { CreateTutorGroupForm } from './CreateTutorGroupForm';
import { StyledTitle as Title } from '../../../../components/fields/Title';
import {
  MainLayout,
  SecondaryLayout,
  StyledContent
} from '../../../../styles/StyledComponents';
import useCreateTutorGroupPage from './hooks/useCreateTutorGroupPage';
import LoadingSpinner from '../../../../lib/components/loadingSpinner';

const StyledCreateTutorGroupPageHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  & .ant-typography {
    margin-bottom: 3px;
    user-select: none;
  }
`;

const CreateTutorGroupPage = () => {
  const {
    classId = '',
    currentClass = {},
    questionSets = [],
    isLoading
  } = useCreateTutorGroupPage();

  return (
    <MainLayout>
      <SiderNav />
      <SecondaryLayout>
        <StyledContent>
          <LoadingSpinner spinning={isLoading}>
            <StyledCreateTutorGroupPageHeader>
              <Link
                to={`/teacher/classes/${classId}`}
                style={{ marginRight: 12 }}
              >
                <LeftOutlined style={{ color: '#000000', fontSize: 20 }} />
              </Link>
              <Title level={3} style={{ fontWeight: 'bolder' }}>
                Create Tutor Group
              </Title>
            </StyledCreateTutorGroupPageHeader>
            <CreateTutorGroupForm
              classId={classId}
              currentClassStudents={currentClass.students}
              questionSets={questionSets}
            />
          </LoadingSpinner>
        </StyledContent>
      </SecondaryLayout>
    </MainLayout>
  );
};

export default CreateTutorGroupPage;
