import { all, take } from 'redux-saga/effects';
import { SHOW_TOAST, onHandleShowToast } from './redux';

// This is where we will be handling making BASIC requests to the backend / stripe api for admins who are
// either completing their onboarding and are at the BASIC details screen or updating their BASIC information

function * watchShowToast () {
  console.log('#watchShowToast');
  yield take(SHOW_TOAST, onHandleShowToast);
}

export default function * sagas () {
  yield all([watchShowToast()]);
}
