export const MessageContexts = {
  STUDENT_MESSAGE: 'student-message',
  SYSTEM_MESSAGE: 'system-message',
  SESSION_COMPLETED_MESSAGE: 'session-completed',
  QUESTION_STATEMENT: 'question-statement',
  CORRECT_ANSWER_RESPONSE: 'correct-answer-response',
  INCORRECT_ANSWER_RESPONSE: 'incorrect-answer-response',
  ANSWER_ATTEMPT: 'answer-attempt',
  HINT_REQUEST: 'hint-request',
  NO_HINTS_AVAILABLE: 'no-hints-available',
  DEFAULT_HINT: 'default-hint',
  VISUAL_HINT: 'visual-hint',
  AUDITORY_HINT: 'auditory-hint',
  READING_HINT: 'reading-hint',
  HANDS_ON_HINT: 'hands-on-hint'
};
