import React, { useState, useCallback } from 'react';
import { Button, Modal, Typography, Row, Col } from 'antd';
import { QRCodeSVG } from 'qrcode.react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import styled from 'styled-components';
import { handleShowToast } from '../../modules/notification/redux';
import { dispatch } from '../../store';

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0px 16px 0px;
`;

const StyledQRCodeInputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  @media (max-width: 768px) {
    width: auto;
    flex-direction: column;
    align-items: center;
  }

  > * {
    margin-bottom: 10px;
  }
`;

const StyledQRCodeSpan = styled.span`
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 280px;
  overflow: hidden;
  padding: 4px 12px;
  border-radius: 8px;
  border: 1px solid #dbd7d6;
  font-size: 16px;
  margin-right: 10px;
`;

const StyledMessageName = styled(Typography.Text)`
  font-family: 'Montserrat Light';
  font-size: 1.2rem;
  color: #333;
  text-align: center;
  padding: 0 20px;
  display: block;
`;

const StyledQRTitle = styled(Typography.Text)`
  font-family: 'Montserrat Light';
  font-size: 1.8rem;
  font-weight: bolder;
  color: #333;
`;

const Line = styled.div`
  width: 100%;
  margin: 10px 0;
  border-top: 1px solid #ccc;
`;

const QRCodeModal = ({
  visible,
  onClose,
  qrCodeValue,
  className,
  teacherName
}) => {
  const handleCopy = useCallback(() => {
    dispatch(
      handleShowToast({
        message: 'Link Copied',
        type: 'success'
      })
    );
  }, [dispatch]);

  return (
    <Modal visible={visible} onCancel={onClose} footer={null} centered>
      <StyledModalContent>
        <Row gutter={[16, 16]} style={{ marginBottom: '20px' }}>
          <Col span={24}>
            <div style={{ flex: 1, textAlign: 'center' }}>
              <StyledQRTitle>Scan QR Code</StyledQRTitle>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center'
              }}
            >
              <div>
                <StyledMessageName>
                  Instructor {teacherName && teacherName} invites you to their {className ? `${className} class.` : 'organization'}
                </StyledMessageName>
              </div>
            </div>
          </Col>
        </Row>

        <QRCodeSVG
          size={280}
          includeMargin={false}
          value={qrCodeValue}
          style={{ border: '1px solid #DBD7D6', borderRadius: 10, padding: 10 }}
          imageSettings={{
            src: `${qrCodeValue}`,
            x: undefined,
            y: undefined,
            height: 16,
            width: 20,
            excavate: true
          }}
        />
        <Line />
        <Typography.Text style={{ textAlign: 'center', color: '#666' }}>
          or enter the link manually
        </Typography.Text>
        <StyledQRCodeInputContainer>
          <StyledQRCodeSpan>{qrCodeValue}</StyledQRCodeSpan>
          <CopyToClipboard text={qrCodeValue} onCopy={handleCopy}>
            <Button
              type='primary'
              style={{
                background: '#43AA8B',
                border: 'none',
                borderRadius: '10px',
                fontFamily: 'Arial, sans-serif',
                fontSize: '1rem',
                fontWeight: 'bold',
                padding: '12px 24px',
                boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
                color: '#fff',
                cursor: 'pointer',
                transition: 'transform 0.2s, box-shadow 0.2s',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              Copy
            </Button>
          </CopyToClipboard>
        </StyledQRCodeInputContainer>
      </StyledModalContent>
    </Modal>
  );
};

export default QRCodeModal;
