import React from 'react';
import { Chart, Axis, Tooltip, Interval, Coordinate, Legend } from 'bizcharts';
import styled from 'styled-components';

const StyledStudentTrendChartContainer = styled.div`
  width: ${({ width }) => (width ? `${width}px` : '100%')};
`;

const StudentTrendChart = ({
  data,
  scale,
  label,
  chartBarColors,
  height = 485,
  itemWidth
}) => {
  console.log(window.innerWidth)
  return (
    <StyledStudentTrendChartContainer>
      <Chart
        data={data}
        padding={[
          60,
          20,
          40,
          window.innerWidth < 800 ? 80 : itemWidth < 200 ? itemWidth : 200
        ]}
        scale={scale}
        height={height}
        autoFit
      >
        <Coordinate transpose />
        <Axis
          name="name"
          labels={null}
          title={null}
          line={null}
          tickLine={null}
          grid={null}
        />
        <Axis
          name="score"
          labels={null}
          label={label}
          title={null}
          line={null}
          tickLine={null}
          grid={{
            align: 'center',
            line: {
              type: 'line',
              style: {
                lineWidth: 1,
                lineDash: [4, 4]
              }
            }
          }}
        />
        <Interval
          position="name*score"
          color={['name', chartBarColors]}
          size={25}
        />
        <Legend position="top" />
        <Tooltip />
      </Chart>
    </StyledStudentTrendChartContainer>
  );
};

export default StudentTrendChart;
