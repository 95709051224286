import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Form as FForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { Form as AForm, Button, Typography } from 'antd';
import RightArrow from '../../../assets/rightArrow.svg';
import LoadingSpinner from '../../../lib/components/loadingSpinner';

class OnBoardingWizard extends Component {
    static Page = ({ children }) => children;

    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            values: props.initialValues || {},
            error: ''
        };

        this.props.history.push(`/teacher/on-boarding/${this.state.page}`);
    }

    next = (values) => {
        console.log('OnBoarding#next, values: ', values);
        this.props.history.push(`/teacher/on-boarding/${this.state.page + 1}`);
        this.setState((state) => ({
            page: Math.min(state.page + 1, this.props.children.length - 1),
            values
        }));
    };

    previous = (args) => {
        console.log('OnBoarding#previous, args: ', args);
        this.setState((state) => ({
            page: Math.max(state.page - 1, 0)
        }));
        this.props.history.push(`/teacher/on-boarding/${this.state.page - 1}`);
    };

    validate = (values) => {
        const activePage = React.Children.toArray(this.props.children)[this.state.page];
        return activePage.props.validate ? activePage.props.validate(values) : {};
    };

    handleSubmit = (values) => {
        const { children, onSubmit, handleDataFromChild } = this.props;
        const { page } = this.state;
        const isLastPage = page === React.Children.count(children) - 1;
        handleDataFromChild(values); // added to pass the values to the parent component
        if (isLastPage) {
            return onSubmit(values);
        } else {
            this.setState({
                error: ''
            });
            this.next(values);
        }
    };

    buttonStyle = {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 10,
        bottom: 0,
        background: 'none',
        color: '#D7753E'
    };

    render() {
        const { children, isLoading } = this.props;
        const { page, values, error } = this.state;
        const activePage = React.Children.toArray(children)[page];
        const isLastPage = page === React.Children.count(children) - 1;

        return (
            <LoadingSpinner spinning={isLoading} fullscreen>
                <FForm
                    name='ff-onboarding-wizard'
                    initialValues={values}
                    validate={this.validate}
                    mutators={{
                        ...arrayMutators
                    }}
                    onSubmit={this.handleSubmit}
                >
                    {({
                        submitError,
                        handleSubmit,
                        form, // injected from final-form-arrays above
                        submitting,
                        values
                    }) => {
                        return (
                            
                            <AForm name='onboarding-wizard' initialValues={values} onFinish={handleSubmit} style={{ position: 'relative', minHeight: '70vh' }}>
                                {isLoading && <div>Loading...</div>}
                                {submitError && <div className='error'>{submitError}</div>}

                                {activePage}

                                <div className='buttons'>
                                    {page > 0 && (
                                        <Button type='link' onClick={this.previous} style={{ ...this.buttonStyle }}>
                                            <img
                                                src={RightArrow}
                                                alt=''
                                                style={{
                                                    height: 15,
                                                    width: 15,
                                                    rotate: '180deg'
                                                }}
                                            />
                                            <span>Back </span>
                                        </Button>
                                    )}
                                    {error && (
                                        <Typography
                                            style={{
                                                color: 'red',
                                                flex: 1,
                                                textAlign: 'center'
                                            }}
                                        >
                                            {error}
                                        </Typography>
                                    )}
                                    {!isLastPage && (
                                        <Button type='link' htmlType='submit' style={{ right: 0, ...this.buttonStyle }}>
                                            <span>Next </span>
                                            <img
                                                src={RightArrow}
                                                alt=''
                                                style={{
                                                    height: 15,
                                                    width: 15
                                                }}
                                            />
                                        </Button>
                                    )}
                                    {isLastPage && (
                                        <Button htmlType='submit' disabled={submitting} style={{ right: 0, ...this.buttonStyle }}>
                                            Done
                                        </Button>
                                    )}
                                </div>
                                {/*<pre>{JSON.stringify(values, 0, 2)}</pre>*/}
                            </AForm>
                        );
                    }}
                </FForm>
            </LoadingSpinner>
        );
    }
}

export default withRouter(OnBoardingWizard);
