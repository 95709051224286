import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { FieldArray as FFieldArray } from 'react-final-form-arrays';
import { Button, List as AList, Empty } from 'antd';

import { EditTutorGroupQuestionSetsListDrawer } from './EditTutorGroupQuestionSetsListDrawer';
import BorderedContainerWithTitle from '../../../fields/BorderedContainerWithTitle';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { RightOutlined } from '@ant-design/icons';

const StyledEditTutorGroupQuestionSetsListContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const StyledEmptyDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const StyledButton = styled(Button)`
  background-color: #5bbcbd;
  border: none;
  border-radius: 10px;
  font-weight: bolder;
  color: #fff;
  height: 50px;
  padding: 0 17px;
  min-width: 100px;
  margin-top: 20px;

  &:hover,
  &:focus {
    background-color: #60e4e5;
    border: none;
    color: #fff;
  }
`;

export const StyledContainer = styled.div`
  display: block;
  margin-bottom: 20px;
  padding: 30px;
  width: 100%;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.31);
`;

const StyledQuestionSetsItem = styled(StyledContainer)`
  width: 100%;
  min-height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 20px 0 30px;
  border-left: ${({ isActive }) =>
    `15px solid ${isActive ? '#5BBCBD' : '#D7753E'}`};

  & > .qs-item__link {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & > .qs-item__link .qs-item__arrow {
    transition: all 0.2s linear;
  }

  & > .qs-item__link .ant-list-item-meta-title {
    width: 100%;
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: all 0.2s linear;
    margin-bottom: 0;
  }

  & > .qs-item__link:hover .ant-list-item-meta-title,
  & > .qs-item__link:hover .qs-item__arrow {
    color: #8053d7;
    transition: all 0.2s linear;
  }

  & > .qs-item__link:hover .qs-item__arrow {
    transform: translateX(5px);
  }
`;

export const EditTutorGroupQuestionSetList = ({
  questionSets = [],
  tutorGroupQuestionSets = []
}) => {
  const [visible, setVisible] = useState(false);

  const showDrawer = useCallback(() => setVisible(true), []);

  const onClose = useCallback(() => setVisible(false), []);

  const renderedQuestionSetsButtonText = useMemo(
    () =>
      tutorGroupQuestionSets && tutorGroupQuestionSets?.length > 0
        ? '+/- QUESTION SETS'
        : 'ADD QUESTION SETS +',
    [tutorGroupQuestionSets]
  );

  return (
    <StyledEditTutorGroupQuestionSetsListContainer>
      <FFieldArray name='tutorGroupQuestionSets'>
        {({ fields, meta }) => (
          <>
            <BorderedContainerWithTitle title='Question Sets'>
              <AList
                itemLayout='horizontal'
                dataSource={fields.value}
                locale={{
                  emptyText: (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={
                        <StyledEmptyDescription>
                          <span>
                            Sorry, in this tutor group no question sets!
                          </span>
                          <span>
                            Please add the first question set to this tutor
                            group
                          </span>
                        </StyledEmptyDescription>
                      }
                    />
                  )
                }}
                renderItem={(qSet) => (
                  <StyledQuestionSetsItem className='qs-item'>
                    <div
                      onClick={showDrawer}
                      className='qs-item__link'
                    >
                      <AList.Item
                        key={`${qSet.title}-${qSet.id}`}
                        style={{ width: '100%' }}
                      >
                        <AList.Item.Meta title={qSet.title} />
                        <RightOutlined className='qs-item__arrow' />
                      </AList.Item>
                    </div>
                  </StyledQuestionSetsItem>
                )}
              />
            </BorderedContainerWithTitle>
            <EditTutorGroupQuestionSetsListDrawer
              tutorGroupQuestionSets={fields.value}
              questionSets={questionSets}
              onClose={onClose}
              visible={visible}
              remove={fields.remove}
              push={fields.push}
            />
          </>
        )}
      </FFieldArray>
      <StyledButton onClick={showDrawer}>
        {renderedQuestionSetsButtonText}
      </StyledButton>
    </StyledEditTutorGroupQuestionSetsListContainer>
  );
};
