import React from 'react';
import { Link } from 'react-router-dom';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { Field as FField, Form as FForm } from 'react-final-form';
import { Row, Col, Form, Select, Form as AForm } from 'antd';
import { promiseListener } from '../../../store';
import { capitalize } from '../../fields/renderFields';
import { InputControl, EmailInputControl, PasswordControl } from '../../fields/Fields';
import { StyledButton } from '../../fields/Button';
import * as validations from '../../../utils/validations';
import {
  ADD_ORG_MEMBERS_FAILURE,
  ADD_ORG_MEMBERS_REQUEST,
  ADD_ORG_MEMBERS_SUCCESS
} from '../../../modules/account/redux';

const MembersForm = ({ isLoading }) => {
  return (
    <MakeAsyncFunction
      listener={promiseListener}
      start={ADD_ORG_MEMBERS_REQUEST}
      resolve={ADD_ORG_MEMBERS_SUCCESS}
      reject={ADD_ORG_MEMBERS_FAILURE}
    >
      {onSubmit => (
        <FForm
          style={{ textAlign: 'center' }}
          onSubmit={onSubmit}
          initialValues={{
            membersEmail: []
          }}
          render={({
            submitError,
            handleSubmit,
            submitting,
            hasValidationErrors,
            hasSubmitErrors,
            dirtySinceLastSubmit,
            values
          }) => (
            <div>
              {isLoading && <div>Loading...</div>}
              {submitError && <div className='error'>{submitError}</div>}
              <Form onFinish={handleSubmit}>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col flex={1}>
                    <strong style={{ display: 'flex', justifyContent: 'left' }}>Invite by email address</strong>
                    <FField
                      name='membersEmail'
                      type='text'
                      placeholder='Email*'
                      multiple
                      validate={validations.composeValidators(
                        validations.requiredArray,
                        validations.emailArray
                      )}
                    >
                      {({
                        input: { name, ...input },
                        meta: { error, submitError, touched, pristine, dirtySinceLastSubmit, submitting, ...meta },
                        ...rest
                      }) => {
                        return (
                          <AForm.Item
                            name={name}
                            hasFeedback
                            validateStatus={(error || submitError) && touched ? 'error' : ''}
                            help={(error || submitError) && touched &&
                            (<span className='error'>{error || submitError}</span>)}
                          >
                            <Select {...input} mode='tags' style={{ width: '100%' }} />
                          </AForm.Item>
                        );
                      }}
                    </FField>
                  </Col>
                </Row>
                {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col flex={1}>
                    <StyledButton
                      size='large'
                      type='primary'
                      htmlType='submit'
                      disabled={submitting ||
                      ((hasSubmitErrors || hasValidationErrors) && !dirtySinceLastSubmit)}
                      loading={isLoading || submitting}
                      block
                    >
                      Complete Setup
                    </StyledButton>
                  </Col>
                </Row>
                <Row justify='center' style={{ textAlign: 'center', marginTop: 10 }}>
                  <Col>
                    <a href=''>
                      <small>Skip this step</small>
                    </a>
                  </Col>
                </Row>
              </Form>
            </div>
          )}
        />
      )}
    </MakeAsyncFunction>
  );
};

export default MembersForm;
