import React from 'react';
import { RightOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import 'react-bulma-components/dist/react-bulma-components.min.css';
import { Row, Col } from 'antd';
import LandingImage from '../../../assets/landing-image.svg';
import { StyledButton } from '../../../styles/StyledComponents';
import { Link } from 'react-router-dom';

const PurpleGradientRow = styled(Row)`
  position: relative;
  box-shadow: 0px 30px 40px rgba(0, 0, 0, 0.1);
  display: flex;
  height: 545px;
  margin-top: 40px;
`;

const TextCol = styled(Col)`
  position: absolute;
  width: 100%;
  padding: 5px;
  margin-top: 30px;
`;

const HeaderTitle = styled.p`
  font-size: 3em;
  font-family: Montserrat Bold;
  color: #fff;
`;

export const LandingPageHeader = () => {
  return (
    <>
      <PurpleGradientRow
        align='middle'
        style={{
          background: `linear-gradient(90deg, rgba(128, 83, 215, 1), rgba(128, 84, 216, 0.17)), url(${LandingImage})`,
          backgroundSize: 'cover',
          width: '101vw'
        }}
      >
        <TextCol>
          <Row justify='space-around' align='middle'>
            <Col xl={6}>
              <HeaderTitle>A learning experience at any distance</HeaderTitle>
              <p style={{ color: '#fff', maxWidth: 500 }}>
                Closing the technology gap in education via SMS, TutorD takes a
                unique approach to individualized learning, enabling scholars
                to explore course curriculum, and reinforce knowledge.
              </p>
              <br />
              <Link to='/sign-up'>
                <StyledButton style={{ borderRadius: 6, height: 40, width: 150 }}>
                  GET STARTED <RightOutlined />
                </StyledButton>
              </Link>
            </Col>
            <Col xl={10} />
          </Row>
        </TextCol>
      </PurpleGradientRow>
    </>
  );
};
