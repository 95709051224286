import React from 'react';
import { RightOutlined } from '@ant-design/icons';
import { Row, Col, Tag } from 'antd';
import { StyledTitle as Title } from '../../fields/Title';
import AuditoryIcon from '../../../assets/enrollment/auditory.svg';
import VisualIcon from '../../../assets/enrollment/visual.svg';
import ReadingIcon from '../../../assets/enrollment/reading.svg';
import NotSureIcon from '../../../assets/enrollment/not-sure.svg';
import HandsOnIcon from '../../../assets/enrollment/hands-on.svg';
import { StyledButton } from '../../../styles/StyledComponents';
import { Link } from 'react-router-dom';

export const LearningStyles = () => {
  return (
    <>
      <Row justify='center' style={{ margin: '40px auto' }}>
        <Col
          xl={20}
          style={{ padding: 40 }}
        >
          {/* LEARNING PREFERENCE TAG */}
          <div style={{ marginBottom: 40 }}>
            <Tag
              style={{
                background: '#5BBCBD',
                color: '#fff',
                border: 'none',
                fontWeight: 'bolder'
              }}
            >
                LEARNING PREFERENCE
            </Tag>
          </div>
          {/* ROW FOR TWO COLUMNS */}
          <Row justify='space-around'>
            {/* LEFT COLUMN */}
            <Col xl={10} style={{ marginBottom: '20px' }}>
              <br />
              <br />
              <Title style={{ color: '#333', fontWeight: 'bolder' }}>
                  Students Self Identify their Learning Styles
              </Title>
              <p style={{ color: '#333' }}>
                  We allow Students a space to add their insight to how
                  they perceive information and how they best learn.
                  With that an Educator can tailor a learning experience
                  that best supports their students.
              </p>
              <br />
              <Link to='/sign-up'>
                <StyledButton style={{ borderRadius: 6, height: 40, background: '#333' }}>
                    LEARN YOUR STUDENTS <RightOutlined />
                </StyledButton>
              </Link>
            </Col>
            {/* RIGHT COLUMN */}
            <Col xl={10} sm={24} style={{ margin: '20px auto' }}>
              {/* FIRST ROW */}
              <Row gutter={[16, 16]}>
                <Col xl={12} sm={10}>
                  <img src={AuditoryIcon} alt='' />
                </Col>
                <Col xl={12} sm={10}>
                  <img src={VisualIcon} alt='' />
                </Col>
              </Row>
              {/* SECOND ROW */}
              <Row gutter={[16, 16]}>
                <Col xl={12} sm={10}>
                  <img src={HandsOnIcon} alt='' />
                </Col>
                <Col xl={12} sm={10}>
                  <img src={ReadingIcon} alt='' />
                </Col>
              </Row>
              {/* THIRD ROW */}
              <Row gutter={[16, 16]}>
                <Col xl={12} sm={10}>
                  <img src={NotSureIcon} alt='' />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
