import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Layout } from 'antd';

import LandingPage from './pages/LandingPage';
import NotFoundPage from './pages/NotFoundPage';
import SignInPage from './sign-in/SignInPage';
import SignUpPage from './sign-up/SignUpPage';

import AdminDashboardPage from './admins/dashboard/AdminDashboardPage';

import DashboardPage from './teachers/dashboard/DashboardPage';
import ClassesPage from './teachers/classes/classes-dashboard/ClassesPage';
import EditClassPage from './teachers/classes/edit-class/EditClassPage';
import QuestionSetsPage from './teachers/question-sets/QuestionSetsPage';
import EditQuestionSetPage from '../components/teachers/question-sets/edit-question-set/editQuestionSetPage';
import EditQuestionSetsPage from '../components/question-sets/edit-question-set';
import NewQuestionSetPage from './teachers/question-sets/NewQuestionSetPage';
import CreateQuestionSetPage from './teachers/question-sets/create-question-set/createQuestionSetPage';

import StudentsPage from './teachers/students/students-dashboard/StudentsPage';
import VerifyEmailSentPage from './sign-up/VerifyEmailSentPage';
import VerifiedEmailPage from './sign-up/VerifiedEmailPage';
import VerifiedStudentEmailPage from './sign-up/VerifiedStudentEmailPage';
import OnBoardingPage from './teachers/on-boarding/index';
import ContactUsEmailSentPage from './pages/contact-us/ContactUsEmailSentPage';

import RegisterStudentWithClassToken from './teachers/students/students-registration/RegisterStudentWithClassToken';
import SetStudentPhoneNumberPage from './students/enrollment/set-student-phone-number/SetStudentPhoneNumberPage';
import VerifyStudentPhoneNumberPage from './students/enrollment/verify-student-phone-number/VerifyStudentPhoneNumberPage';
import IdentifyLearningStylePage from './students/enrollment/identify-student-learning-style/IdentifyStudentLearningStylePage';
import DoneEnrollmentPage from './students/enrollment/DoneEnrollmentPage';
import ProfilePage from './teachers/profile/ProfilePage';
import StudentProfilePage from './teachers/students/students-profile/StudentProfilePage';

import EditClassesPage from './teachers/classes/EditClassesPage';
import EditTutorGroupPage from './teachers/classes/edit-tutor-group/EditTutorGroupPage';
import CreateTutorGroupPage from './teachers/classes/create-tutor-group/CreateTutorGroupPage';

import Educators from './pages/Educators';
import Students from './pages/Students';
import ContactUsPage from './pages/contact-us/ContactUsPage';
import Parents from './pages/Parents';
import Pricing from './pages/Pricing';

import BillingPage from './teachers/billing/BillingPage';
import OrganizationPage from './teachers/organization/OrganizationPage';
import OrganizationPlans from './teachers/organization/OrganizationPlans';
import SetupOrganization from './teachers/organization/SetupOrganization';
import PaymentDetailsPage from './teachers/organization/PaymentDetailsPage';
import OrganizationMembersPage from './teachers/organization/OrganizationMembersPage';
import Notification from './notification/Notification';
import RegisterStudentWithOrgToken from './teachers/students/students-registration/RegisterStudentWithOrgToken';
import BillingHistoryPage from './teachers/billing/BillingHistoryPage';

class Index extends Component {
  render () {
    return (
      <Layout>
        <Switch>
          <Route exact path='/' component={LandingPage} />
          <Route exact path='/educators' component={Educators} />
          <Route exact path='/students' component={Students} />
          <Route exact path='/contact' component={ContactUsPage} />
          <Route exact path='/parents' component={Parents} />
          <Route exact path='/sign-in' component={SignInPage} />
          <Route path='/sign-up' component={SignUpPage} />
          <Route exact path='/pricing' component={Pricing} />

          {/* THESES ARE THE ADMIN ROUTES */}
          <Route exact path='/admin/dashboard' component={AdminDashboardPage} />

          {/* THESES ARE THE TEACHER ROUTES */}
          <Route exact path='/teacher/dashboard' component={DashboardPage} />
          <Route path='/teacher/on-boarding/:step' component={OnBoardingPage} />

          <Route exact path='/teacher/question-sets/create-question-set' component={CreateQuestionSetPage} />
          <Route path='/teacher/question-sets/:questionSetId' component={EditQuestionSetsPage} />
          <Route path='/teacher/question-sets' component={QuestionSetsPage} />
          {/* LEGACY CREATE NEW QUESTION SET */}
          <Route path='/teacher/new-question-set' component={NewQuestionSetPage} />
          <Route path='/teacher/edit-question-set/:questionSetId' component={EditQuestionSetPage} />

          {/* Below is Registration page */}
          <Route path='/teacher/students' component={StudentsPage} />

          <Route path='/teacher/classes/:classId/create-tutor-group-refactor' component={CreateTutorGroupPage} />
          <Route exact path='/teacher/classes/:classId/tutor-group/:tutorGroupId' component={EditTutorGroupPage} />
          <Route exact path='/teacher/classes/:classId' component={EditClassPage} />
          <Route path='/teacher/classes' component={ClassesPage} />
          <Route path='/teacher/edit-class' component={EditClassesPage} />

          {/* THIS IS THE PROFILE ROUTES */}
          <Route exact path='/teacher/profile' component={ProfilePage} />
          <Route exact path='/teacher/billing' component={BillingPage} />
          <Route exact path='/teacher/billing/history' component={BillingHistoryPage} />
          <Route exact path='/teacher/organizations' component={OrganizationPage} />
          <Route path='/teacher/organizations/plans' component={OrganizationPlans} />
          <Route path='/teacher/organizations/setup' component={SetupOrganization} />
          <Route path='/teacher/organizations/payment' component={PaymentDetailsPage} />
          <Route path='/teacher/organizations/members' component={OrganizationMembersPage} />

          {/* THESE ARE THE STUDENT ROUTES */}
          <Route exact path='/student/profile/:studentId' component={StudentProfilePage} />
          <Route exact path='/student/set-phone-number' component={SetStudentPhoneNumberPage} />
          <Route exact path='/student/verify-phone-number' component={VerifyStudentPhoneNumberPage} />
          <Route exact path='/student/identify-learning-styles' component={IdentifyLearningStylePage} />
          <Route exact path='/student/enrollment-complete' component={DoneEnrollmentPage} />
          <Route exact path='/student/registration/class/:token' component={RegisterStudentWithClassToken} />
          <Route exact path='/student/registration/org/:token' component={RegisterStudentWithOrgToken} />

          <Route exact path='/verify/email-sent' component={VerifyEmailSentPage} />
          <Route exact path='/contact/email-sent' component={ContactUsEmailSentPage} />
          <Route exact path='/verify/email/:verifyEmailToken' component={VerifiedEmailPage} />
          <Route exact path='/verify/student/email/:verifyEmailToken' component={VerifiedStudentEmailPage} />

          <Route path='*' component={NotFoundPage} />
        </Switch>
        <Notification />
      </Layout>
    );
  }
}

export default Index;
