import {
  all,
  // call,
  // cancel,
  // fork,
  // take,
  takeEvery
  // takeLatest,
} from 'redux-saga/effects';

import {
  SIGNIN_REQUEST,
  onHandleSignInRequest,
  SIGNOUT_REQUEST,
  onHandleSignOutRequest
} from './redux';
// import { VERIFY_EMAIL_SUCCESS } from '../sign-up/redux';

// function* watchUserAuthentication() {
//   while (true) {
//     const { payload } = yield take(SIGNIN_REQUEST);
//     const task = yield fork(onHandleSignInRequest, payload);
//     // console.log('#watchUserAuthentication while loop, waiting for action');
//     const action = yield take([SIGNOUT_REQUEST, VERIFY_EMAIL_SUCCESS]);
//     console.log('#watchUserAuthentication while loop, action: ', action);
//     if (action.type === SIGNOUT_REQUEST) {
//       yield cancel(task);
//       yield call(onHandleSignOutRequest);
//     }
//   }
// }
function * watchUserAuthentication () {
  yield takeEvery(SIGNIN_REQUEST, onHandleSignInRequest);
}

function * watchUserUnAuthentication () {
  yield takeEvery(SIGNOUT_REQUEST, onHandleSignOutRequest);
}

export default function * sagas () {
  yield all([watchUserAuthentication(), watchUserUnAuthentication()]);
}
