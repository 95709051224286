import React from 'react';
import { RightOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';
import { StyledTitle as Title } from '../../fields/Title';
import PhoneImage from '../../../assets/phone.gif';
import { StyledButton } from '../../../styles/StyledComponents';

export const StudentsWhy = () => {
  return (
    <>
      <Row justify='center'>
        <Col
          xl={20}
          sm={22}
          style={{
            background: '#fff',
            padding: 40,
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
          }}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 40 }}>
            {/* LEFT COLUMN */}
            <Col xl={12}>
              <img src={PhoneImage} style={{ height: 400, margin: '0 auto', display: 'block' }} alt='' />
            </Col>
            {/* RIGHT COLUMN */}
            <Col xl={12}>
              <Title level={2} style={{ color: '#333', fontWeight: 'bolder' }}>
                Why TutorD?
              </Title>
              <br />
              <strong style={{ color: '#333' }}>
                Lets talk about it, do you know if your students are engaged?
                Barely hanging on? Or disconnected from their learning
                experience? education.
              </strong>
              <br />
              <br />
              <p>
                Studies show that 95% of Americans have a cell phone.
                Some dont have internet at home, some dont have multiple
                computers at home, others dont have the emotional strength to get
                to their computer. We meet our scholars on their cell phones.
              </p>
              <br />
              <StyledButton style={{ borderRadius: 6, height: 40, width: 150 }}>
                ABOUT US <RightOutlined />
              </StyledButton>
            </Col>
          </Row>
          {/* PURPLE CONTAINER */}
          {/* <Row justify="center" style={{margin: '40px 0'}}>
            <Col
              xl={24}
              style={{
                background: '#8053D7',
                borderRadius: 6,
                filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                padding: 40,
              }}
            >
              <p
                style={{
                  fontSize: '2.5em',
                  fontFamily: 'Montserrat Bold',
                  color: '#fff',
                  textAlign: 'center',
                }}
              >
                Allowing you to address the{' '}
                <span style={{color: '#FFDC54'}}>Whole Student.</span>
              </p>
              <p style={{color: '#fff', textAlign: 'center'}}>
                Students self identifying their learning language can provide
                educators with the information to make for a more engaging
                experience. We also have to
              </p>
            </Col>
          </Row> */}
        </Col>
      </Row>
    </>
  );
};
