import React from 'react';
import { Col, Row, Typography } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import { Field as FField } from 'react-final-form';
import {
  CustomCheckboxInputControl,
  InputControl
} from '../../../fields/Fields';
import styled from 'styled-components';
import { required } from '../../../../utils/validations';

const { Title } = Typography;

const StyledAnswerInput = styled.div`
  width: 100%;

  & .answer-field__container {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
  }

  & .answer-field__checkbox {
    position: absolute;
    left: 14px;
    z-index: 1;
  }

  & .answer-field__input {
    padding-left: 40px;
    width:'100%'
  }

  & .ant-form-item {
    margin: 0;
    width: 100%;
  }

  & .ant-form-item-explain {
    position: absolute;
    bottom: -25px;
  }

  & .answer-field__delete {
    margin-left: 15px;
  }
`;

export const AnswerItem = ({ name, index, remove }) => (
  <div>
    <Row
      align='middle'
      gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 16]}
      style={{ marginBottom: 20 }}
    >
      <Col offset={2} lg={7} style={{ paddingLeft: 0, paddingRight: 0, width: '100%' }}>
        <StyledAnswerInput className='answer-field'>
          <Title level={5} style={{ fontWeight: 'bolder' }}>
            Answer {index + 1}
          </Title>
          <div className='answer-field__container'>
            <FField
              name={`${name}.isCorrect`}
              component={CustomCheckboxInputControl}
              type='checkbox'
              initialValue={false}
              className='answer-field__checkbox'
            />
            <FField
              name={`${name}.answerStatement`}
              component={InputControl}
              type='text'
              placeholder='Enter answer here...'
              className='answer-field__input'
              validate={required}
            />
            <MinusCircleOutlined
              className='answer-field__delete'
              style={{ color: '#BDBDBD', fontSize: 20 }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                remove(index);
              }}
            />
          </div>
        </StyledAnswerInput>
      </Col>
    </Row>
  </div>
);
