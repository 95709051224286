import styled from 'styled-components';
import { Button } from 'antd';

export const StyledButton = styled(Button)`
  background: #F0BD60;
  border: none;
  border-radius: 5px;

  &:active,
  &:hover {
    background-color: #FAD602;
    border: none;
  }
`;

export const StyledGoogleButton = styled(Button)`
  background-color: white;
  color: #4285f4;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #4285f4;

  img {
    margin-right: 10px;
  }
`;
