import React from 'react';
import styled from 'styled-components';
import arrayMutators from 'final-form-arrays';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { Field as FField, Form as FForm } from 'react-final-form';
import { Button, Form as AForm, Typography } from 'antd';
import { promiseListener } from '../../../../store';
import {
  CREATE_TUTOR_GROUP_REQUEST,
  CREATE_TUTOR_GROUP_SUCCESS,
  CREATE_TUTOR_GROUP_FAILURE
} from '../../../../modules/classes/redux/tutor-groups';
import {
  CustomSimpleSelectControl,
  InputControl
} from '../../../fields/Fields';
import { TutorGroupStudentsList } from './TutorGroupStudentsList';
import { TutorGroupQuestionSetList } from './TutorGroupQuestionSetList';
import { required } from '../../../../utils/validations';
import { ClockCircleOutlined } from '@ant-design/icons';

// OPTIONS FOR DAYS INTERVAL SELECTOR
const intervalDaysOptions = [
  {
    label: 'Everyday',
    value: 'everyday'
  },
  {
    label: 'Daily, Mon - Fri',
    value: 'daily'
  },
  {
    label: 'Mon',
    value: 'monday'
  },
  {
    label: 'Tues',
    value: 'tuesday'
  },
  {
    label: 'Wed',
    value: 'wednesday'
  },
  {
    label: 'Thurs',
    value: 'thursday'
  },
  {
    label: 'Fri',
    value: 'friday'
  },
  {
    label: 'Weekend',
    value: 'weekend'
  }
];

// CREATE 12H TIME ARRAY
const getTimeArray = () => {
  const times = [];
  const periods = ['AM', 'PM'];
  const hours = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  let prop = null;
  let hour = null;
  let min = null;

  for (prop in periods) {
    for (hour in hours) {
      for (min = 0; min < 60; min += 15) {
        times.push(
          ('0' + hours[hour]).slice(-2) +
            ':' +
            ('0' + min).slice(-2) +
            ' ' +
            periods[prop]
        );
      }
    }
  }

  return ['Immediate', ...times];
};

// OPTIONS FOR TIME INTERVAL SELECTOR
const intervalTimeOptions = getTimeArray().map((el) => ({
  label: el,
  value: el.toLocaleLowerCase().split(' ').join('')
}));

// OPTIONS FOR INTERVAL SELECTOR
const intervalOptions = [
  {
    label: '1 Question per week',
    value: '1 question per week'
  },
  {
    label: '1 Question per day',
    value: '1 question per day'
  },
  {
    label: '1 Question per hour',
    value: '1 question per hour'
  },
  {
    label: '1 Question per minute',
    value: '1 question per minute'
  }
];

const StyledCreateTutorGroupFormContainer = styled.div`
  width: 100%;

  @media (min-width: 1200px) {
    padding-left: 35px;
    max-width: 650px;
  }

  & .create-tutor-group-form__name {
    width: 100%;
    max-width: 400px;
    margin-bottom: 20px;
  }

  & .create-tutor-group-form__subtitle {
    display: inline-block;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #262626;
    margin-bottom: 5px;
    user-select: none;
  }

  & .ant-form-item {
    margin-bottom: 0px;
  }
`;

const StyledCreateTutorGroupFormIntervalContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 13px;
  flex-wrap: wrap;
  margin-bottom: 30px;

  & .create-tutor-group-form__field {
    width: 100%;
  }

  & .create-tutor-group-form__day {
    max-width: 200px;
  }

  & .create-tutor-group-form__time {
    max-width: 130px;
  }

  & .create-tutor-group-form__interval {
    max-width: 255px;
  }
`;

const StyledAddButton = styled(Button)`
  background-color: #5bbcbd;
  border: none;
  border-radius: 10px;
  font-weight: bolder;
  color: #fff;
  height: 50px;
  padding: 0 17px;
  min-width: 100px;

  @media (min-width: 1200px) {
    min-width: 200px;
  }

  &:hover,
  &:focus {
    background-color: #60e4e5;
    border: none;
    color: #fff;
  }
`;

const { Title } = Typography;

export const CreateTutorGroupForm = ({
  classId = '',
  currentClassStudents = [],
  questionSets = [],
  isLoading = false
}) => (
  <MakeAsyncFunction
    listener={promiseListener}
    start={CREATE_TUTOR_GROUP_REQUEST}
    resolve={CREATE_TUTOR_GROUP_SUCCESS}
    reject={CREATE_TUTOR_GROUP_FAILURE}
  >
    {(onSubmit) => (
      <FForm
        name='create-tutor-group'
        initialValues={{
          classId: classId,
          title: 'New Tutor Group',
          tutorGroupStudents: [],
          tutorGroupQuestionSets: [],
          dispatchInterval: {
            days: 'everyday',
            startDateTime: 'immediate',
            interval: '1 question per day'
          }
        }}
        mutators={{
          ...arrayMutators
        }}
        onSubmit={onSubmit}
        render={({
          pristine,
          submitError,
          handleSubmit,
          values,
          submitting,
          hasValidationErrors,
          hasSubmitErrors,
          dirtySinceLastSubmit
        }) => (
          <StyledCreateTutorGroupFormContainer>
            {submitError && <div className='error'>{submitError}</div>}
            <AForm onFinish={handleSubmit}>
              <div className='create-tutor-group-form__name'>
                <small className='create-tutor-group-form__subtitle'>
                  Group Name
                </small>
                <FField
                  name='title'
                  component={InputControl}
                  type='text'
                  placeholder='New Tutor Group'
                  validate={required}
                />
              </div>
              <TutorGroupStudentsList
                tutorGroupStudents={values?.tutorGroupStudents}
                currentClassStudents={currentClassStudents}
              />
              <TutorGroupQuestionSetList
                questionSets={questionSets}
                tutorGroupQuestionSets={values?.tutorGroupQuestionSets}
              />
              <Title level={4}>Dispatch Interval:</Title>
              <StyledCreateTutorGroupFormIntervalContainer>
                <div className='create-tutor-group-form__field create-tutor-group-form__day'>
                  <small className='create-tutor-group-form__subtitle'>
                    Day
                  </small>
                  <FField
                    name='dispatchInterval.days'
                    component={CustomSimpleSelectControl}
                    options={intervalDaysOptions}
                    disabled
                  />
                </div>
                <div className='create-tutor-group-form__field create-tutor-group-form__time'>
                  <small className='create-tutor-group-form__subtitle'>
                    Time
                  </small>
                  <FField
                    name='dispatchInterval.startDateTime'
                    component={CustomSimpleSelectControl}
                    options={intervalTimeOptions}
                    animatedArrow={false}
                    arrowWidth={20}
                    arrowHeight={20}
                    suffixIcon={
                      <ClockCircleOutlined style={{ fontSize: 20 }} />
                    }
                  />
                </div>
                <div className='create-tutor-group-form__field create-tutor-group-form__interval'>
                  <small className='create-tutor-group-form__subtitle'>
                    Interval
                  </small>
                  <FField
                    name='dispatchInterval.interval'
                    component={CustomSimpleSelectControl}
                    options={intervalOptions}
                  />
                </div>
              </StyledCreateTutorGroupFormIntervalContainer>
              <StyledAddButton
                htmlType='submit'
                disabled={
                  pristine ||
                  submitting ||
                  ((hasSubmitErrors || hasValidationErrors) &&
                    !dirtySinceLastSubmit) ||
                  !values?.tutorGroupStudents?.length ||
                  !values?.tutorGroupQuestionSets?.length
                }
                loading={isLoading || submitting}
              >
                Create
              </StyledAddButton>
            </AForm>
          </StyledCreateTutorGroupFormContainer>
        )}
      />
    )}
  </MakeAsyncFunction>
);
