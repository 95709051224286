import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { dispatch, promiseListener } from '../../../store';
import { Col, Row } from 'antd';
import { FormattedMessage } from 'react-intl';
import OnBoardingWizard from './onboarding-wizard';
import { CenteredContainer as Container } from '../../fields/Container';
import Header from '../../template/Header';
import ClassesImg from '../../../assets/onboarding/onboarding-classes.svg';
import OnBoardingSteps from './onboarding-wizard-steps';
import ClassForm from './classes/ClassForm';
import EnrollmentsForm from './EnrollmentsForm';
import GoalsForm from './GoalsForm';
import { ONBOARDING_REQUEST, ONBOARDING_SUCCESS, ONBOARDING_FAILURE } from '../../../modules/sign-up/redux/onboarding';
import { BigTitle, Img, MainCol, WhiteText } from '../../../styles/StyledComponents';
import { handleShowToast } from '../../../modules/notification/redux';

const resolveErrors =
    (asyncFn) =>
    (...args) => {
        console.log('OnBoardingPage#resolveErrors, asyncFn: ', asyncFn);
        console.log('OnBoardingPage#resolveErrors, args: ', args);

        return asyncFn(...args).then(
            (success) => success,
            (error) => Promise.resolve(error)
        );
    };

class OnBoardingPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            onBoardingData: undefined
        };
    }

    handleDataFromChild = (data) => {
        this.setState({
            onBoardingData: data
        });
    };

    render() {
        const { isLoading, match } = this.props;

        return (
            <Container style={{ minWidth: '100%' }}>
                <MakeAsyncFunction
                    listener={promiseListener}
                    start={ONBOARDING_REQUEST}
                    resolve={ONBOARDING_SUCCESS}
                    reject={ONBOARDING_FAILURE}
                    getError={(action) => {
                        dispatch(
                            handleShowToast({
                                message: action.payload['FINAL_FORM/form-error'],
                                type: 'error'
                            })
                        );
                    }}
                >
                    {(onSubmit) => (
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col xl={10} xs={24} style={{ background: '#ff6633' }}>
                                <Header />
                                <div style={{ padding: 24, textAlign: 'center' }}>
                                    <BigTitle>
                                        <FormattedMessage id={`on-boarding.${match.params.step}.aside.title`} defaultMessage={'Onboarding'} />
                                    </BigTitle>
                                    <Img src={ClassesImg} alt='Classes' />
                                    <WhiteText>
                                        <FormattedMessage id={`on-boarding.${match.params.step}.aside.subTitle`} />
                                    </WhiteText>
                                    <WhiteText
                                        style={{
                                            fontSize: '1.5em',
                                            fontWeight: 100,
                                            fontFamily: 'Montserrat Light'
                                        }}
                                    >
                                        <FormattedMessage id={`on-boarding.${match.params.step}.aside.description`} />
                                    </WhiteText>
                                </div>
                            </Col>
                            <MainCol xl={14} xs={24} style={{ paddingTop: 10 }}>
                                <OnBoardingSteps />
                                <Col xl={24} xs={22}>
                                    <OnBoardingWizard
                                        initialValues={{
                                            classes: [],
                                            studentEnrollments: []
                                        }}
                                        isLoading={isLoading}
                                        onSubmit={resolveErrors(onSubmit)}
                                        handleDataFromChild={this.handleDataFromChild}
                                    >
                                        <OnBoardingWizard.Page props>
                                            <ClassForm />
                                        </OnBoardingWizard.Page>
                                        <OnBoardingWizard.Page props>
                                            <EnrollmentsForm onBoardingData={this.state.onBoardingData} />
                                        </OnBoardingWizard.Page>
                                        <OnBoardingWizard.Page>
                                            <GoalsForm />
                                        </OnBoardingWizard.Page>
                                    </OnBoardingWizard>
                                </Col>
                            </MainCol>
                        </Row>
                    )}
                </MakeAsyncFunction>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.onBoarding.isLoading
    };
};

export default withRouter(connect(mapStateToProps, {})(OnBoardingPage));
