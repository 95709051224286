export default {
  'en-US': {
    landing: {
      title: 'Welcome to TutorD!',
      subTitle: 'Expanding the Learning Experience no matter the distance.',
      'sign-in': 'Sign In',
      'sign-up': 'Sign Up'
    },
    'sign-in': {
      title: 'Sign In',
      subTitle: 'Expanding the Learning Experience, Focused on the whole student ensuring accessibility for all.',
      email: 'Email',
      password: 'Password',
      'sign-up-link': 'No account?  Sign Up here!',
      'sign-in-button': 'Sign In'
    },
    'sign-up': {
      title: 'Sign Up',
      subTitle: 'Expanding the Learning Experience, Focused on the whole student ensuring accessibility for all.',
      firstName: 'First Name',
      lastName: 'Last Name',
      password: 'Password',
      'sign-in-link': 'Already have an account?  Sign In here!',
      'sign-up-button': 'Sign Up'
    },
    'verify-email-sent': {
      title: 'Verify Email',
      subTitle: 'We\'re Here to Change the Game... TutorD will allow you reach your students; no accessibility or connectivity gap, we meet students where they are!',
      'email-address-form': {
        title: 'Verification Email Sent',
        subTitle: 'Please check your email and click on the link provided.',
        button: 'Resend Email'
      }
    },
    enrollment: {
      'set-student-phone-number': {
        aside: {
          title: 'TutorD',
          welcome: 'Welcome, ',
          subTitle: 'Instructor ',
          subTitleTwo: ' invites you to enroll in their class.'
        },
        'sign-up': {
          title: 'Please enter your phone number',
          subTitle: 'Please check your phone for an SMS with the code',
          firstName: 'First Name',
          lastName: 'Last Name',
          password: 'Password',
          'sign-in-link': 'Already have an account?  Sign In here!',
          'sign-up-button': 'Sign Up'
        }
      },
      'verify-student-phone-number': {
        aside: {
          title: 'Verify',
          welcome: 'We want to change the game...',
          subTitle: 'TutorD is a game changer and will allow you to do this and that and do we have copy for this?'
        },
        'sign-up': {
          title: 'Number verification',
          subTitle: 'Please check your phone for an SMS with the code'
        }
      },
      0: {
        aside: {
          title: 'TutorD',
          welcome: 'Welcome, Ellie McDonald!',
          subTitle: 'Mr Smith invites you to join his Chemistry class.'
        },
        'sign-up': {
          title: 'Please enter your phone number',
          subTitle: 'Please check your phone for an SMS with the code',
          firstName: 'First Name',
          lastName: 'Last Name',
          password: 'Password',
          'sign-in-link': 'Already have an account?  Sign In here!',
          'sign-up-button': 'Sign Up'
        }
      },
      1: {
        aside: {
          title: 'Verify',
          welcome: 'We want to change the game...',
          subTitle: 'TutorD is a game changer and will allow you to do this and that and do we have copy for this?'
        },
        'sign-up': {
          title: 'Number verification',
          subTitle: 'Please check your phone for an SMS with the code'
        }
      },
      2: {
        aside: {
          title: 'How do you learn?',
          welcome: 'Customize your experience',
          subTitle: 'TutorD gives you access to plenty of tests, question sets and materials from our teacher working on the same subjects as you.'
        },
        'sign-up': {
          title: 'What type of learner are you?',
          subTitle: 'We are all different and we learn better in different ways - do you know what works best for you? Don’t worry if you don’t, we’ll help you find out throughout this journey.'
        }
      },
      3: {
        aside: {
          title: 'All good!',
          welcome: 'Customize your experience',
          subTitle:
            'TutorD gives you access to plenty of tests, question sets and materials from our teacher working on the same subjects as you.'
        },
        'sign-up': {
          title: 'Get ready for your first test!',
          subTitle: 'We can set up your classes and students so that you don’t have to do it manually, you will still be able to edit them afterwards.'
        }
      }
    },
    'on-boarding': {
      0: {
        aside: {
          title: 'Classes',
          subTitle: 'Customize your experience',
          description: 'TutorD gives you access to plenty of test, question sets and materials from our teacher working on',
          img: '../assets/onboarding/onboarding-classes.svg'
        },
        main: {
          title: 'What courses do you teach?',
          subTitle: 'Please select from our suggestions:'
        }
      },
      1: {
        aside: {
          title: 'Enrollments',
          subTitle: 'Customize your experience',
          description: 'TutorD gives you access to plenty of tests, question sets and materials from our teachers',
          img: '../assets/enrollment/enrollment-learning-style.svg'
        },
        main: {
          title: 'Enroll your students!',
          subTitle: 'Please select from our suggestions:'
        }
      },
      2: {
        aside: {
          title: 'Goals',
          subTitle: 'We want to change the game...',
          description: 'Tutor D is a game changer and will allow you to do this and that and do we have some copy for this?'
        },
        main: {
          title: 'What do you want to achieve?',
          subTitle: 'Please select all that apply.',
          titleTwo: 'How many students do you teach?'
        }
      }
    },
    studentRegistration: {
      'register-student-with': {
        aside: {
          title: 'TutorD',
          subTitle: 'Instructor ',
          subTitleTwo: ' invites you to enroll in their ',
          subTitleThree: ' class.'
        }
      },
      'register-student-using-org-token': {
        aside: {
          subTitle: 'You have been invited to ',
          subTitleTwo: 'organization.'
        }
      }
    }
  },
  'es-ES': {
    landing: {
      title: '¡Bienvenido a TutorD!',
      subTitle: 'Expandiendo la Experiencia de Aprendizaje, enfocado en la totalidad del estudiante asegurando la accesibilidad para todos.',
      'sign-in': 'Registrarse',
      'sign-up': 'Regístrate'
    },
    'sign-in': {
      title: 'Registrarse',
      email: 'Email',
      password: 'Contraseña',
      'sign-up-link': '¿No tienes cuenta? ¡Registrate aquí!',
      'sign-in-button': 'Registrarse'
    },
    'sign-up': {
      title: 'Regístrate',
      firstName: 'Nombre de pila',
      lastName: 'Apellido',
      password: 'Contraseña',
      'sign-in-link': '¿Ya tienes una cuenta? ¡Firme aquí!',
      'sign-up-button': 'Regístrate'
    }
  }
};
