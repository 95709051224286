import React from 'react';
import arrayMutators from 'final-form-arrays';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { Field as FField, Form as FForm } from 'react-final-form';
import { FieldArray as FFieldArray } from 'react-final-form-arrays';
import { Row, Col, Form, Empty, Space, Button, Form as AForm, Modal } from 'antd';
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { dispatch, promiseListener } from '../../../../store';
import { EditableTitleControl } from '../../../fields/Fields';

import {
  EDIT_QUESTION_SET_FAILURE,
  EDIT_QUESTION_SET_REQUEST,
  EDIT_QUESTION_SET_SUCCESS,
  handleDeleteQuestionSetRequest
} from '../../../../modules/question-set/redux';

import QuestionSetIcon from '../../../../assets/question-set-image.svg';

import { EditQuestionItem } from './EditQuestionItem';

import {
  AddQuestionButton,
  DoneButton,
  StyledContainer,
  StyledButton
} from '../../../../styles/StyledComponents';
import * as validations from '../../../../utils/validations';
import LoadingSpinner from '../../../../lib/components/loadingSpinner';
import { handleShowToast } from '../../../../modules/notification/redux';
import { FORM_ERROR } from 'final-form';
import styled from 'styled-components';

const StyledDeleteButton = styled(Button)`
  background: transparent;
  padding: 5;
  border: '2px solid #e64732';
  color: #e64732;
  outline: none;
  box-shadow: none;

  &:hover,
  &:focus,
  &:active {
    color: red;
    background: transparent;
    outline: '2px solid #e64732';
    box-shadow: none;
  }
`;

export const EditQuestionSetForm = ({ isLoading, questionSet }) => {
  const { confirm } = Modal;
  function showPromiseConfirm (questionSet) {
    confirm({
      title: `Do you want to delete ${questionSet.title}?`,
      icon: <ExclamationCircleOutlined />,
      content:
        'All questions and hints will be deleted if you do delete ',
      onOk () {
        console.log(
          'showPromiseConfirm, attempting to dispatch handleDeleteQuestionSet, classId: ',
          questionSet
        );
        dispatch(handleDeleteQuestionSetRequest(questionSet.id));
      },
      onCancel () {}
    });
  }

  return <MakeAsyncFunction
    listener={promiseListener}
    start={EDIT_QUESTION_SET_REQUEST}
    resolve={EDIT_QUESTION_SET_SUCCESS}
    reject={EDIT_QUESTION_SET_FAILURE}
    getError={(action) => {
      const payload = action.payload;
      const error = payload[FORM_ERROR] ? payload[FORM_ERROR] : 'something went wrong';
      dispatch(handleShowToast({ message: error, type: 'error' }));
    }}
  >
    {(onSubmit) => (
      <FForm
        name='edit-question-set'
        initialValues={questionSet}
        mutators={{
          ...arrayMutators
        }}
        onSubmit={onSubmit}
        render={({
          submitError,
          handleSubmit,
          form: {
            mutators: { push, pop, removeBatch }
          },
          submitting,
          hasValidationErrors,
          hasSubmitErrors,
          dirtySinceLastSubmit,
          values
        }) => (
          <div>
            <LoadingSpinner spinning={isLoading}>
              <AForm onFinish={handleSubmit}>
                <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 16]}>
                  <Col style={{ width: '100%' }}>
                    <Row style={{ justifyContent: 'space-between' }}>
                      <FField
                        name='title'
                        component={EditableTitleControl}
                        tooltip='Question title'
                        level={3}
                        validate={validations.required}
                      />
                      <StyledDeleteButton
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          showPromiseConfirm(questionSet);
                        }}
                      >
                    Delete Question Set
                      </StyledDeleteButton>
                    </Row>
                    {values.questions && values.questions.length}/15 questions
                  added
                  </Col>
                </Row>
                <Space direction='vertical' style={{ width: '100%' }}>
                  <FFieldArray name='questions'>
                    {({ fields, meta }) =>
                      fields.map((name, index) => (
                        <StyledContainer
                          key={name}
                          style={{
                            marginTop: 20,
                            width: '100%',
                            padding: { lg: '40px 50px', md: '20px 30px', sm: '20px 30px', xs: '20px 30px' }
                          }}
                        >
                          <EditQuestionItem
                            name={name}
                            index={index}
                            push={push}
                            numOfQuestions={fields.length}
                            remove={fields.remove}
                            removeBatch={removeBatch}
                          />
                        </StyledContainer>
                      ))}
                  </FFieldArray>
                  {values.questions &&
                  values.questions.length > 0 &&
                  values.questions.length < 15 && (
                    <Row gutter={[16, 16]} justify='center'>
                      <Col span={12}>
                        <AddQuestionButton
                          block
                          type='dashed'
                          style={{}}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            push('questions', null);
                          }}
                        >
                          <PlusOutlined /> Question
                        </AddQuestionButton>
                      </Col>
                      <Col span={12}>
                        <DoneButton
                          htmlType='submit'
                          disabled={
                            submitting ||
                            ((hasSubmitErrors || hasValidationErrors) &&
                              !dirtySinceLastSubmit)
                          }
                          loading={isLoading || submitting}
                          block
                        >
                          Done!
                        </DoneButton>
                      </Col>
                    </Row>
                  )}
                  {values.questions && values.questions.length === 15 && (
                    <div>
                      <Button
                        type='primary'
                        htmlType='submit'
                        disabled={
                          submitting ||
                        ((hasSubmitErrors || hasValidationErrors) &&
                          !dirtySinceLastSubmit)
                        }
                        loading={isLoading || submitting}
                        block
                      >
                      Done!
                      </Button>
                    </div>
                  )}
                </Space>
                <div>
                  {values.questions && values.questions.length === 0 && (
                    <Empty
                      image={QuestionSetIcon}
                      imageStyle={{ height: 90 }}
                      description=''
                    >
                      <Form.Item>
                        <StyledButton
                          type='primary'
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            push('questions', null);
                          }}
                        >
                        Create my first question!
                        </StyledButton>
                      </Form.Item>
                    </Empty>
                  )}
                </div>
                {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
              </AForm>
            </LoadingSpinner>
          </div>
        )}
      />
    )}
  </MakeAsyncFunction>;
};
