import React, { useEffect } from 'react';
import { Field as FField, Form as FForm } from 'react-final-form';
import { Col, Button, Form as AForm, Row, Typography } from 'antd';
import styled from 'styled-components';
import { InputControl } from '../../../fields/Fields';
import { capitalize } from '../../../fields/renderFields';
import * as validations from '../../../../utils/validations';
import LoadingSpinner from '../../../../lib/components/loadingSpinner';

const StyledSendButton = styled(Button)`
  background-color: #5bbcbd;
  border: none;
  border-radius: 10px;
  font-weight: bolder;
  color: #fff;
  height: 50px;
  padding: 0 17px;
  min-width: 100px;
  margin-top: 30px;

  @media (min-width: 1200px) {
    min-width: 200px;
  }

  &:hover,
  &:focus {
    background-color: #60e4e5;
    border: none;
    color: #fff;
  }
`;

const { Title } = Typography;

export const RegisterForm = ({
  isLoading = false,
  registrationToken,
  onSubmit
}) => {
  return (
    <FForm
      name='Register Student with QR code'
      initialValues={{
        firstName: '',
        lastName: '',
        email: ''
      }}
      onSubmit={(values) => {
        const valuesWithToken = { ...values, registrationToken };
        onSubmit(valuesWithToken);
      }}
      render={({
        submitError,
        pristine,
        submitting,
        handleSubmit,
        hasSubmitErrors,
        hasValidationErrors,
        dirtySinceLastSubmit,
        form,
        ...rest
      }) => {
        return (
          <LoadingSpinner spinning={isLoading} fullwidth>
            <div>
              <AForm
                onFinish={(event) => {
                  console.log('onFinish, event: ', event);
                  handleSubmit();
                }}
              >
                {submitError && <div className='error'>{submitError}</div>}
                <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 16]}>
                  <Col xl={12} sm={20} xs={24} style={{ padding: '1px' }}>
                    <Title level={5}>First Name</Title>
                    <FField
                      name='firstName'
                      component={InputControl}
                      type='text'
                      placeholder='First Name*'
                      parse={(value) => value && value.toLowerCase()}
                      format={capitalize}
                      validate={validations.composeValidators(
                        validations.required,
                        validations.minLength(2),
                        validations.maxLength(30)
                      )}
                    />
                  </Col>
                </Row>
                <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 16]}>
                  <Col xl={12} sm={20} xs={24} style={{ padding: '1px' }}>
                    <Title level={5}>Last Name</Title>
                    <FField
                      name='lastName'
                      component={InputControl}
                      type='text'
                      placeholder='Last Name*'
                      parse={(value) => value && value.toLowerCase()}
                      format={capitalize}
                      validate={validations.composeValidators(
                        validations.required,
                        validations.minLength(2),
                        validations.maxLength(30)
                      )}
                    />
                  </Col>
                </Row>
                <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 16]}>
                  <Col xl={12} sm={20} xs={24} style={{ padding: '1px' }}>
                    <Title level={5}>Email Address</Title>
                    <FField
                      name='email'
                      component={InputControl}
                      type='text'
                      placeholder='student@institution.edu*'
                      parse={(value) => value && value.toLowerCase()}
                      validate={validations.composeValidators(
                        validations.email,
                        validations.required,
                        validations.minLength(4)
                      )}
                    />
                  </Col>
                </Row>

                <StyledSendButton
                  htmlType='submit'
                  disabled={
                    pristine ||
                    submitting ||
                    ((hasSubmitErrors || hasValidationErrors) &&
                      !dirtySinceLastSubmit)
                  }
                  loading={isLoading || submitting}
                >
                  Submit
                </StyledSendButton>
              </AForm>
            </div>
          </LoadingSpinner>
        );
      }}
    />
  );
};

export default RegisterForm;
