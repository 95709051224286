import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'antd';
import { capitalizeFirstLetter } from '../../../../lib/utils';
import { CenteredContainer as Container } from '../../../fields/Container';
import Header from '../../../template/Header';
import TutorDOwl from '../../../../assets/tutord-signin-page.svg';
import SetPhoneNumberForm from './SetStudentPhoneNumberForm';
import {
  Img,
  MainCol,
  BigTitle,
  WhiteText
} from '../../../../styles/StyledComponents';
import {
  enrollmentTeacherSelector,
  enrollmentUserSelector
} from '../../../../modules/sign-up/redux/selectors';
import { useHistory } from 'react-router-dom';

const SetStudentPhoneNumberPage = (props) => {
  const { isLoading, studentUser, teacher } = props;
  const router = useHistory();

  const capitalizedFirstName = capitalizeFirstLetter(
    studentUser?.firstName ?? 'Student'
  );
  const capitalizedLastName = capitalizeFirstLetter(
    studentUser?.lastName ?? ''
  );
  const capitalizedTeacherLastName = capitalizeFirstLetter(
    teacher?.lastName ?? 'warmly'
  );

  useEffect(() => {
    if (studentUser?.isOnboarded) {
      router.push('/student/enrollment-complete');
    }
  }, []);

  return (
    <Container style={{ width: '100%' }}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xl={10} sm={24} xs={24}>
          <Header />
          <Col xl={23} xs={22} style={{ textAlign: 'center', paddingLeft: 20 }}>
            <BigTitle>
              <FormattedMessage id='enrollment.set-student-phone-number.aside.title' />
            </BigTitle>
            <Img src={TutorDOwl} alt='TutorD Owl' style={{ marginBottom: 20 }} />
            <WhiteText>
              <FormattedMessage id='enrollment.set-student-phone-number.aside.welcome' />
              <span> {capitalizedFirstName + ' ' + capitalizedLastName} </span>
            </WhiteText>
            <WhiteText style={{ fontSize: '1.5em', fontFamily: 'Montserrat Light', fontWeight: 100 }}>
              <FormattedMessage id='enrollment.set-student-phone-number.aside.subTitle' />
              <span>{capitalizedTeacherLastName}</span>
              <FormattedMessage id='enrollment.set-student-phone-number.aside.subTitleTwo' />
            </WhiteText>
          </Col>
        </Col>
        <MainCol xl={14} xs={24}>
          <SetPhoneNumberForm isLoading={isLoading} />
        </MainCol>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    // studentFullName: state.enrollment.studentFullName,
    // teacherLastName: state.enrollment.teacherLastName,
    isLoading: state.enrollment.isLoading,
    studentUser: enrollmentUserSelector(state),
    teacher: enrollmentTeacherSelector(state)
  };
};

export default connect(mapStateToProps, {})(SetStudentPhoneNumberPage);
