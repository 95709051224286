import React from 'react';
import { dispatch } from '../../store';
import { Button, Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import {
  UserOutlined,
  BookOutlined,
  LogoutOutlined,
  SettingOutlined,
  DashboardOutlined,
  QuestionCircleOutlined,
  UsergroupAddOutlined
} from '@ant-design/icons';

import logo from '../../assets/tutord-logo-white.svg';
import { MainSider } from '../../styles/StyledComponents';
import { SIGNOUT_REQUEST } from '../../modules/auth/redux';
import styled from 'styled-components';

const { Header, Sider } = Layout;
const { SubMenu } = Menu;

function getItem (label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type
  };
}

const StyledSider = styled(Sider)`
  overflow: inherit;
  min-height: 100vh;
  background: #8053d7;
  position: fixed;
  left: 0;
  z-index: 2;

  .ant-layout-sider-zero-width-trigger {
    background: #8053d7;
  }
`;

const SiderNav = ({ user }) => {
  return (
    <StyledSider
      breakpoint='lg'
      collapsedWidth='0'
      onBreakpoint={(broken) => {
        console.log('onBreakpoint:');
        console.log(broken);
      }}
      onCollapse={(collapsed, type) => {
        console.log('onCollapse:');
        console.log(collapsed, type);
      }}
    >
      <Header style={{ background: '#8053D7', paddingLeft: 24 }}>
        <div style={{ marginTop: 20 }}>
          <Link to='/'>
            <img src={logo} alt='' srcSet='' />
          </Link>
        </div>
      </Header>
      {/* MENU STARTS HERE */}
      <Menu
        theme='dark'
        mode='inline'
        style={{
          background: '#8053D7',
          position: 'relative',
          height: '85vh',
          paddingTop: 20
        }}
        // defaultOpenKeys={['sub1']}
        // defaultSelectedKeys={['dashboard']}
      >
        {/* MENU ITEMS */}
        <Menu.Item key='dashboard' icon={<DashboardOutlined />}>
          <Link to='/teacher/dashboard'>Dashboard</Link>
        </Menu.Item>
        <Menu.Item key='students' icon={<UserOutlined />}>
          <Link to='/teacher/students'>Students</Link>
        </Menu.Item>
        <Menu.Item key='question-sets' icon={<QuestionCircleOutlined />}>
          <Link to='/teacher/question-sets'>Question Sets</Link>
        </Menu.Item>
        <Menu.Item key='classes' icon={<BookOutlined />}>
          <Link to='/teacher/classes'>Classes</Link>
        </Menu.Item>
        <SubMenu key='courses' title='Settings' icon={<SettingOutlined />}>
          <Menu.Item key='profile' icon={<UserOutlined />}>
            <Link to='/teacher/profile'>Profile</Link>
          </Menu.Item>
          <Menu.Item key='billing' icon={<BookOutlined />}>
            <Link to='/teacher/billing'>Billing</Link>
          </Menu.Item>
          <Menu.Item key='organizations' icon={<UsergroupAddOutlined />}>
            <Link to='/teacher/organizations'>Organizations</Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item
          key='logout'
          icon={<LogoutOutlined />}
          style={{ position: 'absolute', bottom: 0 }}
        >
          <Button
            type='text'
            style={{ color: 'white' }}
            onClick={() => {
              dispatch({ type: SIGNOUT_REQUEST });
            }}
          >
            Logout
          </Button>
        </Menu.Item>
      </Menu>
    </StyledSider>
  );
};

export default SiderNav;
