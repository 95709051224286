import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { FieldArray as FFieldArray } from 'react-final-form-arrays';
import { Tooltip, Avatar, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { EditTutorGroupStudentsListDrawer } from './EditTutorGroupStudentsListDrawer';

const StyledEditTutorGroupStudentsListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 35px;
  align-items: flex-start;

  & .students-list__title {
    display: inline-block;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #262626;
    margin-bottom: 15px;
  }

  & .students-list__placeholder {
    color: #bfbfbf;
    user-select: none;
  }
`;

const StyledButton = styled(Button)`
  background-color: #5bbcbd;
  border: none;
  border-radius: 10px;
  font-weight: bolder;
  color: #fff;
  height: 50px;
  padding: 0 17px;
  min-width: 100px;
  margin-top: 20px;

  &:hover,
  &:focus {
    background-color: #60e4e5;
    border: none;
    color: #fff;
  }
`;

export const EditTutorGroupStudentsList = ({
  tutorGroupStudents = [],
  currentClassStudents = []
}) => {
  console.log(
    'EditTutorGroupStudentsList, currentClassStudents: ',
    currentClassStudents
  );
  console.log(
    'EditTutorGroupStudentsList, tutorGroupStudents: ',
    tutorGroupStudents
  );

  const [visible, setVisible] = useState(false);

  const showDrawer = useCallback(() => setVisible(true), []);

  const onClose = useCallback(() => setVisible(false), []);

  const renderedStudentsButtonText = useMemo(
    () =>
      tutorGroupStudents && tutorGroupStudents?.length > 0
        ? '+/- STUDENTS'
        : 'ADD STUDENTS +',
    [tutorGroupStudents]
  );

  return (
    <StyledEditTutorGroupStudentsListContainer>
      <small className='students-list__title'>Students</small>
      <FFieldArray name='tutorGroupStudents'>
        {({ fields }) => {
          return (

            <>
              <Avatar.Group
                maxCount={4}
                size={54}
                maxStyle={{
                  color: '#fff',
                  backgroundColor: '#E5A84F',
                  border: 0
                }}
              >
                {fields.value?.map((student) => {
                // THis will be updated after the response from the backend is updated
                  const firstName = student?.firstName ? student?.firstName : student?.profile?.firstName;
                  const lastName = student?.lastName ? student?.lastName : student?.profile?.lastName;

                  return (

                    <Tooltip
                      key={student.id}
                      title={`${firstName} ${lastName}`}
                    >
                      <Avatar
                        style={{ background: '#BFBFBF' }}
                        icon={<UserOutlined />}
                        src={student?.avatar}
                      />
                    </Tooltip>
                  );
                })}
                {(!fields.value || fields.value?.length === 0) && (
                  <span className='students-list__placeholder'>
                  Please add students to the tutor group
                  </span>
                )}
              </Avatar.Group>
              <EditTutorGroupStudentsListDrawer
                tutorGroupStudents={fields.value}
                currentClassStudents={currentClassStudents}
                onClose={onClose}
                visible={visible}
                remove={fields.remove}
                push={fields.push}
              />
            </>
          );
        }}
      </FFieldArray>
      <StyledButton onClick={showDrawer}>
        {renderedStudentsButtonText}
      </StyledButton>
    </StyledEditTutorGroupStudentsListContainer>
  );
};
