import React from 'react';
import { Row, Col } from 'antd';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import Header from '../../template/Header';
import TutorDOwl from '../../../assets/tutord-signin-page.svg';
import { StyledTitle as Title } from '../../fields/Title';
import { CenteredContainer as Container } from '../../fields/Container';
import { BigTitle, Img, MainCol, WhiteText, YellowUnderline } from '../../../styles/StyledComponents';
import { useSelector } from 'react-redux';
import { enrollmentClassSelector, enrollmentTeacherSelector, enrollmentUserSelector } from '../../../modules/sign-up/redux/selectors';
import { capitalizeFirstLetter } from '../../../lib/utils';

const DoneEnrollmentPage = () => {
  const studentUser = useSelector(enrollmentUserSelector);
  const teacher = useSelector(enrollmentTeacherSelector);
  const classes = useSelector(enrollmentClassSelector);

  const capitalizedFirstName = capitalizeFirstLetter(studentUser?.firstName ?? 'Student');
  const capitalizedLastName = capitalizeFirstLetter(studentUser?.lastName ?? '');
  const capitalizedTeacherLastName = capitalizeFirstLetter(teacher?.lastName ?? 'warmly');

  return (
    <>
      <Row justify='center'>
        <Col xl={11} sm={24} xs={24}>
          <Header />
          <div style={{ padding: 30, textAlign: 'center' }}>
            <BigTitle>
              <FormattedMessage id='enrollment.set-student-phone-number.aside.title' />
            </BigTitle>
            <Img src={TutorDOwl} alt='TutorD Owl' style={{ marginBottom: 20 }} />
            <WhiteText>
              <FormattedMessage id='enrollment.set-student-phone-number.aside.welcome' />
              <span> {capitalizedFirstName + ' ' + capitalizedLastName} </span>
            </WhiteText>
            <WhiteText style={{ fontWeight: 100, fontSize: '1.5em', fontFamily: 'Montserrat Light' }}>
              <FormattedMessage id='enrollment.set-student-phone-number.aside.subTitle' />
              <span>{capitalizedTeacherLastName}</span>
              <FormattedMessage id='enrollment.set-student-phone-number.aside.subTitleTwo' />
            </WhiteText>
          </div>
        </Col>
        <MainCol xl={13} sm={20} xs={24}>
          <YellowUnderline>
            <Title level={2}>
              <FormattedMessage id='enrollment.3.sign-up.title' />
            </Title>
          </YellowUnderline>
          <FormattedMessage id='enrollment.3.sign-up.subTitle' />
        </MainCol>
      </Row>
    </>
  );
};

export default withRouter(DoneEnrollmentPage);
