import React from 'react';
import styled from 'styled-components';

const StyledBorderedContainerWithTitle = styled.div`
  width: 100%;
`;

const StyledBorderedContainerWithTitleHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
`;

export const StyledBorderedContainerWithTitleWrapper = styled.div`
  width: 100%;
  position: relative;
  border: 1px solid #d7753e;
  border-radius: 8px;
  padding: 35px 20px;

  @media (min-width: 1200px) {
    padding: 35px 40px 10px 17px;
  }

  & .bordered-container__title {
    font-weight: bold;
    font-size: 16px;
    color: #000000;
    padding: 10px 20px;
    background-color: #eee;
    position: absolute;
    white-space: nowrap;
    left: 20px;
    top: 0;
    transform: translateY(-50%);

    @media (min-width: 1200px) {
      padding: 10px 50px;
    }
  }
`;

const BorderedContainerWithTitle = ({
  title = 'Title',
  children,
  extraContent
}) => (
  <StyledBorderedContainerWithTitle>
    {extraContent && (
      <StyledBorderedContainerWithTitleHeader>
        {extraContent}
      </StyledBorderedContainerWithTitleHeader>
    )}
    <StyledBorderedContainerWithTitleWrapper className='bordered-container'>
      <span className='bordered-container__title'>{title}</span>
      {children}
    </StyledBorderedContainerWithTitleWrapper>
  </StyledBorderedContainerWithTitle>
);

export default BorderedContainerWithTitle;
