import React from 'react';
import { Field as FField, Form as FForm } from 'react-final-form';
import { Row, Col, Form } from 'antd';
import MakeAsyncFunction from 'react-redux-promise-listener';

import { promiseListener } from '../../store';
import { EmailInputControl } from '../fields/Fields';
import * as validations from '../../utils/validations';
import { RESEND_EMAIL_REQUEST, RESEND_EMAIL_SUCCESS, RESEND_EMAIL_FAILURE } from '../../modules/sign-up/redux';
import { StyledButton } from '../fields/Button';

export const VerifyEmailSentForm = ({ isLoading, email }) => (
  <MakeAsyncFunction
    listener={promiseListener}
    start={RESEND_EMAIL_REQUEST}
    resolve={RESEND_EMAIL_SUCCESS}
    reject={RESEND_EMAIL_FAILURE}
  >
    {onSubmit => (

      <FForm
        onSubmit={onSubmit}
        initialValues={{ email }}
        render={({ submitError, handleSubmit, submitting }) => (
          <Form
            onFinish={handleSubmit}
            onFinishFailed={(errInfo) => { console.error('onFinishFailed, errInfo: ', errInfo); }}
          >
            {isLoading && <div>Loading...</div>}
            {submitError && <div className='error'>{submitError}</div>}
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col lg={12} xs={20}>
                <FField
                  name='email'
                  component={EmailInputControl}
                  type='text'
                  placeholder={email}
                  disabled
                  validate={validations.composeValidators(
                    validations.required,
                    validations.email,
                    validations.minLength(4),
                    validations.maxLength(25)
                  )}
                />
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col lg={12} xs={20}>
                <StyledButton
                  size='large'
                  type='primary'
                  htmlType='submit'
                  disabled
                  loading={isLoading || submitting}
                  block
                >
                  Resend
                </StyledButton>
              </Col>
            </Row>
          </Form>
        )}
      />
    )}
  </MakeAsyncFunction>
);
