import axios from 'axios';
import qs from 'qs';

import env from './env';
import { authHeader } from './auth_headers';
import * as mockAPI from '../mock-api';

export default {
  del: (url, data, origin) => makeRequest(url, 'delete', data, origin),
  get: (url, data, origin) => makeRequest(url, 'get', data, origin),
  post: (url, data, headers, origin) => makeRequest(url, 'post', data, headers, origin),
  put: (url, data, headers, origin) => makeRequest(url, 'put', data, headers, origin),
  // MOCK REQUESTS
  mockDel: (url, data, origin) => mockRequest(url, 'delete', data, origin),
  mockGet: (url, data, origin) => mockRequest(url, 'get', data, origin),
  mockPost: (url, data, origin) => mockRequest(url, 'post', data, origin),
  mockPut: (url, data, origin) => mockRequest(url, 'put', data, origin)
};

const makeRequest = async (url, method, requestData, headers, origin) => {
  // Remove leading slash if it's present
  if (url.startsWith('/')) {
    url = url.substr(1);
  }

  const apiUrl = `${origin || env.REACT_APP_TUTORD_API_ORIGIN}/api/v1/${url}`;
  let config = {
    headers: { ...authHeader(), ...(headers && headers) },
    method,
    url: apiUrl,
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'brackets' });
    }
  };

  if (requestData) {
    config = {
      ...config,
      data: requestData
    };
  }

  const req = await axios(config);
  return req;
};

// eslint-disable-next-line
const mockRequest = async (url, method, requestData, origin) => {
  console.log('#mockRequest');
  if (url.startsWith('/')) {
    url = url.substr(1);
  }

  let config = {
    headers: { ...authHeader() },
    method,
    url: url,
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'brackets' });
    }
  };

  if (requestData) {
    config = {
      ...config,
      data: requestData
    };
  }

  try {
    switch (url) {
      case 'auth/login':
        return await mockAPI.signInResponse({ email: config.email, password: config.password });
      case 'accounts/create':
        return await mockAPI.signUpResponse({
          firstName: config.data.firstName,
          lastName: config.data.lastName,
          orgName: config.data.orgName,
          email: config.data.email,
          password: config.data.password
        });
      case 'verify/resend/email':
        return await mockAPI.verifyResendEmailResponse();
      case 'teachers/on-boarding':
        return await mockAPI.okResponse();
      case 'accounts/plans/prices':
        return await mockAPI.getPlansResponse();
      case 'question-sets':
        return await mockAPI.fetchQuestionSets();
      case 'classes':
        return await mockAPI.fetchClasses();
      case 'enrollments/students':
        return await mockAPI.fetchStudents();
      default:
        return await mockAPI.okResponse();
    }
  } catch (err) {
    console.error('Error: Failed to make request, err: ', err);
    return err;
  }
};
