import React from 'react';
import { Col, Layout, Row, Typography } from 'antd';
import Header from '../../template/Header';
import Footer from '../../template/Footer';
import { ContactUsForm } from './ContactUsForm';
import useContactUsPage from './hooks/useContactUsPage';

const { Title } = Typography;
const { Content } = Layout;
const ContactUsPage = () => {
  const { isLoading } = useContactUsPage();

  return (
    <>
      <Layout style={{ width: '100%', backgroundColor: '#e5e5e5' }}>
        <Header />
        <Content className='site-layout' style={{ margin: '30px 0' }}>
          {/* WHAT IS TUTORD CONTAINER */}
          <Row
            gutter={[16, 16]} justify='left'
            style={{
              marginTop: '4.5em',
              background: '#fff',
              padding: 40,
              paddingTop: '.75em',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
            }}
          >
            <Col xl={12} sm={12} xs={12}>
              <Title level={1} style={{ fontWeight: 'bolder' }}>Keep Expanding Your Reach!</Title>
              <Title level={4} style={{ fontWeight: 'bolder' }}>
                Looking to utilize our platform? want to learn more about what we are doing
                at TutorD? Or simply connect?
              </Title>
              <br />
              <p>
                Please fill out our contact form and we will be sure to follow
                up with you, we are looking forward to see how we can help be of
                service to you or support you, or maybe you can help us out, you
                never know!
              </p>
            </Col>
            <Col
              xl={20}
              sm={22}
              xs={22}
            >
              <ContactUsForm isLoading={isLoading} />
            </Col>
          </Row>
        </Content>
        <Footer />
      </Layout>
    </>
  );
};

export default ContactUsPage;
